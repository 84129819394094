import { useParams } from "react-router-dom";
import {useEffect, useState} from 'react'
import { useOktaAuth } from '@okta/okta-react';

import Box from "@mui/material/Box";

import RiskGrid from "../components/RiskGrid" 
import LoadingComponent from "../components/LoadingComponent";
import RiskHitTop from "../components/RiskHitTop";

import Grid from "@mui/material/Grid";
import UpdateCheck from "../components/UpdateCheck";



export const RiskHitListPage = () => {
  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState('');
  const {orderId} = useParams();
  const {salespersonId} = useParams();

  
    

  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [riskData, setRiskData] = useState([{}])
  const [salespersonName, setSalespersonName] = useState('')


  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {

    } else {
   //   oktaAuth.getUser().then((info) => {
        var options = {
          method: 'GET',
          headers: {"Content-Type": "application/json"}
    
        }
        options.headers["Authorization"] = "Bearer " + authState.accessToken.accessToken;
  
  
        fetch("/toprisk/"+ salespersonId, options).then(
          response => response.json()
        ).then(
          data => {

            console.log(data.name)
            setRiskData(data.lines)
            setSalespersonName(data.name)

            setLoading(false)
        

          }
        )

    //  });
    }



    
  }, [authState, oktaAuth]); // Update if authState changes

  if (loading) {
    return (          
    <LoadingComponent />
    )
  } 
   else {
        return (    
          <Box sx={{ backgroundColor: '#fafafa' }}>
                  <Grid container rowSpacing={0} columnSpacing={{ xs: 1}} alignItems="center" sx={{borderBottom: '1px solid #DBDCDC',
    backgroundColor: '#ffffff'}} >
            <RiskHitTop salespersonId={salespersonId} salesperson_nm={salespersonName} />
            </Grid>
            <br></br>

            {riskData.length > 0 ?
            <Grid container rowSpacing={0} columnSpacing={{ xs: 1}} alignItems="center" sx={{
    backgroundColor: '#ffffff'}} >
      <Grid item xs={0.25}>
    </Grid>
      <Grid item xs={11.50}>    
      <Box sx={{ boxShadow: 1, border: '1px solid #DBDCDC', 
        backgroundColor: '#ffffff' }}>    
           <RiskGrid riskData={riskData}/>
           </Box>
           </Grid>
           <Grid item xs={0.25}>
    </Grid>
           </Grid>
           :
           <Box height={200} sx={{ backgroundColor: '#ffffff'}}>No risk found</Box>
            }
            <UpdateCheck />
           </Box>
        )
        
        }
}