import React, { useEffect, useState } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import "../styles.css";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Box from '@mui/material/Box';
import { red } from '@mui/material/colors';


  function LineGraph2({dailyData, bookedRev }){

    const searchRegExp = /\s/g;
    const replaceWith = '_';
    //const advertiser = advertiser_nm.replace(searchRegExp, replaceWith)


    //const document_id = orderId.concat("_", salespersonId, "_", lineId).toLowerCase().replace("/","")


    const fakeData = [
      [{value: ''}, {value: ''}, {value: ''},]

];
    

    //const [dailyData, setDailyData] = useState([]);
    const [alignment, setAlignment] = useState('delivery');

    const handleChange = (
      event
    ) => {
       // console.log(event.target.value)
      setAlignment(event.target.value);
    };
/*
        useEffect(() => {
          var options = {
            method: 'GET',
            headers: {"Content-Type": "application/json"}
      
          }
          options.headers["Authorization"] = "Bearer " + token;

            fetch("/daily/"+document_id, options)
                .then((response) => response.json())
                .then((json) => {setDailyData(json.data);
                })
                .catch(() => onError())
        }, []);
    
        if (!dailyData) {
    
            return (          <p>Loading...</p>
            )
          } else {
    */
            if (dailyData.length > 0){
    


    return (
<div style={{height:'100%', width:'100%'}}>
    <div>
        <Box display="flex" justifyContent="center" sx={{padding: '5px'}}>
        <ToggleButtonGroup 
     color="primary"
     value={alignment}
     exclusive
     onChange={handleChange}
     aria-label="Platform"

   >
     <ToggleButton value="delivery">Daily Delivery</ToggleButton>
     <ToggleButton value="risk">Risk</ToggleButton>
     <ToggleButton value="discrepancy">Discrepancy</ToggleButton>
   </ToggleButtonGroup>
   </Box>
  </div>
  
  {alignment==='delivery' &&
<div style={{height:'100%', weight:'100%'}}>
        <ResponsiveContainer width="100%" height="85%">
        <LineChart
          width={500}
          height={200}
          data={dailyData}
          margin={{
            top: 15,
            right: 30,
            left: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid opacity={0.4}/>
          <XAxis fontWeight={'bold'} fontSize={'14px'} dataKey="date" />
          <YAxis fontWeight={'bold'} fontSize={'14px'} tickFormatter={tick => {
              return tick.toLocaleString();
            }}/>

          <Tooltip wrapperStyle={{ outline: "none" }} content={<CustomTooltip />}/>
          <Legend wrapperStyle={{fontSize: "12px"}}/>
          <Line type="monotone" dataKey="Final Billable" stroke="#3498db" strokeWidth={3} dot={{fill:'#2980b9', stroke:'#2980b9', strokeWidth:2,r:3}}
           activeDot={{ r: 5 }} />
          <Line type="monotone" dataKey="1P P2+" stroke="#1abc9c" strokeWidth={3} 
          dot={{fill:'#16a085', stroke:'#16a085', strokeWidth:2,r:3}} opacity={0.5}/>



          <Line type="monotone" dataKey="3P P2+" stroke="#9b59b6" strokeWidth={3} 
          dot={{fill:'#8e44ad', stroke:'#8e44ad', strokeWidth:2,r:3}} opacity={0.5} />

             {/*
                    <Line type="monotone" dataKey="On Target Net" stroke="#e74c3c" strokeWidth={3} 
          dot={{fill:'#c0392b', stroke:'#c0392b', strokeWidth:2,r:3}} opacity={0.5} />
          */}
        </LineChart>
      </ResponsiveContainer>
    </div>
            }


{alignment==='risk' &&
<div style={{height:'100%', weight:'100%'}}>
        <ResponsiveContainer width="100%" height="85%">
        <LineChart
          width={500}
          height={200}
          data={dailyData}
          margin={{
            top: 15,
            right: 30,
            left: 30,
            bottom: 5,
          }}
        >



            <ReferenceLine y={0} />
          <CartesianGrid opacity={0.4}/>
          <XAxis fontWeight={'bold'} fontSize={'14px'} dataKey="date" />
          <YAxis allowDecimals="false" type="number" 
          ticks={[0, Math.round(bookedRev/1000)/4*1000, Math.round(bookedRev/1000)/4*2*1000, 
          Math.round(bookedRev/1000)/4*3*1000, Math.round(bookedRev/1000)*1000]} 
          //ticks={[-Math.round(bookedRev/1000)*1000, -Math.round(bookedRev/1000)/4*3*1000,
       //   -Math.round(bookedRev/1000)/4*2*1000, -Math.round(bookedRev/1000)/4*1000, 0, Math.round(bookedRev/1000)/4*1000, Math.round(bookedRev/1000)/4*2*1000, 
//Math.round(bookedRev/1000)/4*3*1000, Math.round(bookedRev/1000)*1000]} 
fontWeight={'bold'} fontSize={'14px'} tickFormatter={tick => {
              return tick.toLocaleString();
            }}/>

          <Tooltip wrapperStyle={{ outline: "none" }} content={<CustomTooltipRisk />}/>
          <Legend wrapperStyle={{fontSize: "12px"}}/>
          <Line type="monotone" name="Risk" dataKey="Risk_adj" stroke="#3498db" strokeWidth={3} dot={{fill:'#2980b9', stroke:'#2980b9', strokeWidth:2,r:3}}
           activeDot={{ r: 5 }} />


        </LineChart>
      </ResponsiveContainer>
    </div>
            }
  {alignment==='discrepancy' &&
<div style={{height:'100%', weight:'100%'}}>
        <ResponsiveContainer width="100%" height="85%">
        <LineChart
          width={500}
          height={200}
          data={dailyData}
          margin={{
            top: 15,
            right: 30,
            left: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid opacity={0.4}/>
          <XAxis fontWeight={'bold'} fontSize={'14px'} dataKey="date" />
          <YAxis fontWeight={'bold'} fontSize={'14px'} tickFormatter={tick => {
              return tick.toLocaleString();
            }}/>

          <Tooltip wrapperStyle={{ outline: "none" }} content={<CustomTooltipDiscrepancy />}/>
          <Legend wrapperStyle={{fontSize: "12px"}}/>

          <Line type="monotone" dataKey="1P P2+" stroke="#1abc9c" strokeWidth={3} 
          dot={{fill:'#16a085', stroke:'#16a085', strokeWidth:2,r:3}} opacity={0.5}/>


          <Line type="monotone" dataKey="3P P2+" stroke="#9b59b6" strokeWidth={3} 
          dot={{fill:'#8e44ad', stroke:'#8e44ad', strokeWidth:2,r:3}} opacity={0.5} />


        </LineChart>
      </ResponsiveContainer>
    </div>
            }

    </div>
    )
    }else {
      return (
//<Typography variant="body2" marginTop={1} gutterBottom>No Delivery</Typography>
<div style={{height:'100%', weight:'100%'}}>
        <ResponsiveContainer width="100%" height="85%">
        <LineChart
          width={500}
          height={200}
          data={fakeData}
          margin={{
            top: 15,
            right: 30,
            left: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid opacity={0.4}/>
          <XAxis fontWeight={'bold'} fontSize={'14px'} dataKey="date" />
          <YAxis fontWeight={'bold'} fontSize={'14px'} tickFormatter={tick => {
              return tick.toLocaleString();
            }}/>

<Tooltip wrapperStyle={{ outline: "none" }} content={<CustomTooltip />}/>
          <Legend wrapperStyle={{fontSize: "12px"}}/>
          <Line type="monotone" dataKey="Final Billable" stroke="#3498db" strokeWidth={3} dot={{fill:'#2980b9', stroke:'#2980b9', strokeWidth:2,r:3}}
           activeDot={{ r: 5 }} />
          <Line type="monotone" dataKey="1P P2+" stroke="#1abc9c" strokeWidth={3} 
          dot={{fill:'#16a085', stroke:'#16a085', strokeWidth:2,r:3}} opacity={0.5}/>



          <Line type="monotone" dataKey="Billable P2+" stroke="#9b59b6" strokeWidth={3} 
          dot={{fill:'#8e44ad', stroke:'#8e44ad', strokeWidth:2,r:3}} opacity={0.5} />

                    <Line type="monotone" dataKey="On Target Net" stroke="#e74c3c" strokeWidth={3} 
          dot={{fill:'#c0392b', stroke:'#c0392b', strokeWidth:2,r:3}} opacity={0.5} />
          <Legend wrapperStyle={{fontSize: "12px"}}/>
         

        </LineChart>
      </ResponsiveContainer>
    </div>
      )
    }
}
  //}

  function CustomTooltip({active, payload, label}) {

    if (active){
       return (
       <div className='tooltip'>
        <h5>{label}</h5>

        {payload.map((data, i) => (
            <p style={{ color: data.stroke, fontSize:'12px', textAlign:'left' }} key={i}>{data.dataKey}: {Math.round(data.value).toLocaleString()}</p>
          ))}
         


        </div>
       ) 
    }
    return null

  }

  function CustomTooltipRisk({active, payload, label}) {

    if (active){
       return (
       <div className='tooltip'>
        <h5>{label}</h5>

        {payload.map((data, i) => (
            <p style={{ color: data.value <= 0 ? 'green':'red', 
            fontSize:'12px', textAlign:'left' }} key={i}>{data.dataKey}: ${Math.round(data.value).toLocaleString()}</p>
          ))}
         


        </div>
       ) 
    }
    return null

  }

  function CustomTooltipDiscrepancy({ active, payload, label }) {
    if (active) {
      const discrepancy = payload[0].payload.Discrepancy;
  
      // Set the color based on the value of discrepancy
      const colorStyle = {
        color: discrepancy < 0 ? 'red' : 'green',
        fontSize: '12px',
        textAlign: 'left',
      };
  
       return (
       <div className='tooltip'>
        <h5>{label}</h5>

        {payload.map((data, i) => (
            
            <p style={{ color: data.stroke, fontSize:'12px', textAlign:'left' }} key={i}>{data.dataKey}: {Math.round(data.value).toLocaleString()}</p>
            ))}
         

         <p style={{ ...colorStyle, fontSize:'12px', textAlign:'left' }}>Daily Discrepancy: {(payload[0].payload.Discrepancy*100).toFixed(2)}%</p>

          
        </div>
       ) 
    }
    return null

  }

    
    export default LineGraph2