import React, {useEffect, useState} from 'react'
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useNavigate } from "react-router-dom";

import { useLocalStorage } from "../hooks/useLocalStorage";
import { useOktaAuth } from '@okta/okta-react';



export const UserCheck = () => {
  
  const navigate = useNavigate();

  const theme = createTheme();
  const [role, setRole] = useLocalStorage("role", null);

  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      //setUserInfo(null);

    } else {
   //   oktaAuth.getUser().then((info) => {
       // setUserInfo(info);
       console.log('user check')
       var options = {
        method: 'POST',
        body: JSON.stringify({
            uid: authState.idToken.claims.sub, email: authState.idToken.claims.email, name: authState.idToken.claims.name
        }),
        headers: {"Content-Type": "application/json"}
        }
        options.headers["Authorization"] = "Bearer " + authState.accessToken.accessToken;
  
  
        fetch("/api/usercheck/", options).then(
          response => response.json()
        ).then(
          data => {
            if (data.exists === true){

                let defaultdash = data.assignments[0] ?? 94
                setRole(data.role)
                navigate("/dashboard/"+defaultdash, { replace: true });
            } else if (data.exists === false && data.assigned === true){
                navigate("/assignment", { replace: true });
            } else {
                navigate("/unassigned", { replace: true });

            }

          }
        )

  //    });
    }



    
  }, [authState, oktaAuth]); // Update if authState changes


    return (    
<Box sx={{ backgroundColor: '#ffffff' }}>
                <ThemeProvider theme={theme}>
  <Container component="main" maxWidth="xs">
    <CssBaseline />
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
                    <CircularProgress />
                    </Box>
                    </Container>
</ThemeProvider>

            </Box>   
                    )

    }

      