import Box from '@mui/material/Box';
import React, {useEffect, useState} from 'react'
import Snackbar from '@mui/material/Snackbar';
import { useOktaAuth } from '@okta/okta-react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from "@mui/material/Grid";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import LoadingComponent from "../components/LoadingComponent";



function SiteStatus() {
    const { authState, oktaAuth } = useOktaAuth();
    const [siteStatus, setSiteStatus] = useState([]);
    const [lastUpdateDate, setLastUpdateDate] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
          if (!authState || !authState.isAuthenticated) {
            // Handle the case when the user is not authenticated
          } else {
            try {
              let accessToken = authState.accessToken;
      
              // Check if the token is expired and refresh it if necessary
              if (oktaAuth.tokenManager.hasExpired(accessToken)) {
                accessToken = await oktaAuth.tokenManager.renew('accessToken');
                console.log('expired update check')
              }
      
              const options = {
                method: 'GET',
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + accessToken.accessToken
                }
              };
      
              const response = await fetch(`/site_status`, options);
              const json = await response.json();
              setSiteStatus(json.data); // Update state with fetched data
              setLastUpdateDate(json.last_update)
              setLoading(false)

      
            } catch (error) {
              console.error('Error fetching data: ', error);
              // Handle errors such as network issues, parsing issues, etc.
            }
          }
        };
      
        fetchData();
      }, [authState, oktaAuth]); // Dependencies for useEffect


 // Convert siteStatus object into an array of objects
 const dataArray = Object.keys(siteStatus).map((date) => ({
    date,
    value: siteStatus[date],
  }));

  if (loading) {
    return (    
        <LoadingComponent />
        )
                } else {
    return (  
<div>

      
<Box>
<Typography variant="h4" fontWeight={'bold'} gutterBottom>
Status Dashboard
</Typography>
<Typography variant="body" gutterBottom>
Impression data last updated {lastUpdateDate} ET
</Typography>
<Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {dataArray.map((item, index) => (
              <TableCell key={index} align="center">{item.date}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell><b>3rd Party Data</b></TableCell>
            {dataArray.map((item, index) => (
                <TableCell key={index} align="center">
                {item.value ? (
                  <CheckCircleIcon style={{ color: 'green' }} />
                ) : (
                  <CancelIcon style={{ color: 'red' }} />
                )}
              </TableCell>
                      ))}
          </TableRow>
        </TableBody>
      </Table>
      </Box>
      <br /><Typography variant="body2" gutterBottom>
If 3rd party delivery data is missing, 1st party delivery data will be used for that day until 3rd party data is available.
</Typography>
</div>  
)
                }

}

export default SiteStatus