import React, {useEffect, useState} from 'react'
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from "@mui/material/Box";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Link as ReactLink } from "react-router-dom";
import Link from '@mui/material/Link';

import { DataGrid, gridClasses, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import clsx from 'clsx';
import LoadingComponent from "../components/LoadingComponent";
import Button from '@mui/material/Button';
import { ExportToCsv } from 'export-to-csv';

import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useParams } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const formatStartEnd = (date) => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC'
  };
    return date.toLocaleDateString(undefined, options);
};

const columns = [
    {
      field: 'sales_order_line_item_id',
      headerName: 'Line ID',
      flex:1,
      minWidth: 100,
      align: 'center',
      renderCell: (params) => 
      <ReactLink style={{ textDecoration: 'none' }} to={`line/${params.row.sales_order_line_item_id}`}> 
      <Button size="small" variant="contained">
        
        {params.row.sales_order_line_item_id}
      </Button>
      </ReactLink>
    //  <a href={params.row.sales_order_line_item_id}>{params.row.sales_order_line_item_id}</a>,
      
    },
    {
      field: 'line_item_nm',
      headerName: 'Line Name',
      flex:1,
      minWidth: 400,
      renderCell: (params) => {
        const line_id_name = params.row.sales_order_line_item_id + "-" + params.row.line_item_nm
      return line_id_name
      }
    },
    {
    field: 'op_line_start_dt',
    headerName: 'Start Date',
    flex:1,
    minWidth: 100,
    /*
    renderCell: params => {
      const start_dt = new Date(params.row.op_line_start_dt);
      const formattedDate = formatStartEnd(start_dt);
      return <div>{formattedDate}</div>;
      }*/
  },
  {
    field: 'op_line_end_dt',
    headerName: 'End Date',
    flex:1,
    minWidth: 100,
    /*
    renderCell: params => {
      const end_dt = new Date(params.row.op_line_end_dt);
      const formattedDate = formatStartEnd(end_dt);
      return <div>{formattedDate}</div>;
      }*/
    },
  {
    field: 'line_status',
    headerName: 'Status',
    flex:1,
    minWidth: 100,
  },
  {
    field: 'op_impression_goal_contracted',
    headerName: 'Goal',
    flex:1,
    minWidth: 150,
    valueFormatter: ({ value }) => value.toLocaleString(),

  },

  {
    field: 'sold_cpm_amt',
    headerName: 'CPM',
    flex:1,
    minWidth: 100,
    },

  {
    field: 'booked_revenue_amt',
    headerName: 'Booked Revenue',
    flex:1,
    minWidth: 150,
    type: 'number',
    valueFormatter: ({ value }) => priorityFormater(value),

  },


  {
    field: 'rev_at_risk_amt',
    headerName: 'Rev at Risk',
    flex:1,
    minWidth: 150,
    type: 'number',
    cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
  
        return clsx('super-app', {
          red: params.value < 0 ,
          green: params.value > 0,        });
      },
    valueFormatter: ({ value }) => priorityFormater(value),
  },



  {
    field: 'pacing',
    headerName: 'Pacing',
    flex:1,
    minWidth: 150,
    type: 'number',
    valueFormatter: ({ value }) => `${value}%`,
    cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
  
        return clsx('super-app', {
          red: params.value < 90 ,
          yellow: params.value < 98 && params.value > 90,
          green: params.value > 98,
        });
      },
  },

  {
    field: 'rev_pacing',
    headerName: '$ Ahead/Behind',
    flex:1,
    minWidth: 150,
    type: 'number',
    cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
  
        return clsx('super-app', {
          red: params.value < 0 ,
          green: params.value > 0,        });
      },
    valueFormatter: ({ value }) => priorityFormater(value),
  },
  

  {
    field: 'final_billable',
    headerName: 'Final Billable Imps',
    flex:1,
    minWidth: 150,
    valueFormatter: ({ value }) =>  Math.round(value)?.toLocaleString(),

  },

  {
    field: 'first_party_p2_delivery',
    headerName: '1P P2+ Delivery',
    width: 150,
  },



  {
    field: 'billable_p2_delivery',
    headerName: '3P P2+ Delivery',
    flex:1,
    minWidth: 150,
  },



  {
    field: 'on_target_net',
    headerName: 'Ontarget Net Delivered Impressions',
    flex:1,
    minWidth: 150,
  },



  

  {
    field: 'discrepancy_3p',
    headerName: 'Discrepancy',
    flex:1,
    minWidth: 150,
    type: 'number',
    valueFormatter: ({ value }) => `${value}%`,
    cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
  
        return clsx('super-app', {
          red: params.value < 0 
        });
      },
  },

 {
    field: 'value_of_discrepancy',
    headerName: 'Value of Discrepancy',
    flex:1,
    minWidth: 150,
   
  },

  {
    field: 'needed_buffer',
    headerName: 'Needed Buffer',
    flex:1,
    minWidth: 150,
  },

  {
    field: 'fw_scheduled_quantity',
    headerName: 'Current Buffer',
    flex:1,
    minWidth: 150,
  },

  {
    field: 'rbp_comp_revised',
    headerName: 'RBP Comp',
    flex:1,
    minWidth: 150,
  },
  {
    field: 'to_goal',
    headerName: 'Total Delivery %',
    flex:1,
    minWidth: 150,
  },
  {
    field: 'spend_to_date',
    headerName: 'Dollars Spent (uncapped)',
    flex:1,
    minWidth: 180,
  },




  ];

  const priorityFormater = (cell) => {
    let cell_value = Math.round(cell)?.toLocaleString()
    if (cell_value.includes('-')){
      cell_value = cell_value.replace('-', '');
      cell_value = "-"+"$"+cell_value
      return cell_value
    } else {
      return "$"+cell_value?.toLocaleString()
    }
  

};



const TopItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    paddingTop: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const BottomItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    paddingBottom: theme.spacing(1),
    textAlign: 'center',
    fontWeight: 'bold',

    color: theme.palette.text.secondary,
  }));

  const LinkItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    paddingBottom: theme.spacing(1),
    textAlign: 'center',
    fontWeight: 500,
  
    '& a': {
      color: '#000787', // Change the link color to blue using a color name
      //textDecoration: 'none', // Remove the default underline
    },
  }));

  
  const PackageItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    paddingTop: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));

var groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

Object.defineProperty(Array.prototype, 'group', {
    enumerable: false,
    value: function (key) {
      let map = {};
      this.map(e => ({k: key(e), d: e})).forEach(e => {
        map[e.k] = map[e.k] || [];
        map[e.k].push(e.d);
      });
      return Object.keys(map).map(k => ({package_nm: k, line: map[k]}));
    }
  });


  function CustomToolbar(props) {

    let package_risk = Math.round(props.package_risk)?.toLocaleString()

                if (package_risk.includes('-')){
                  package_risk = package_risk.replace('-', '');
                  package_risk = "-"+"$"+package_risk
              } else {
                package_risk = "$"+package_risk
        
              }




    return (
      <GridToolbarContainer>

<Grid container rowSpacing={0} columnSpacing={{ xs: 1}} >
<Grid item marginTop={1} xs={6}>
<Typography variant="h5" gutterBottom>
&ensp;{props.name}</Typography>

</Grid>
<Grid item xs={6} marginTop={1} textAlign={'right'} >


<GridToolbarExport
        csvOptions={{ fileName: props.orderId + '-' + props.name}}
        printOptions={{ disableToolbarButton: true}}/>
    




</Grid>

</Grid>

        <Grid container rowSpacing={0} columnSpacing={{ xs: 6}} sx={{paddingLeft:1.5}} >

  <Grid item xs='auto'>
    <PackageItem elevation={0}>Booked Revenue: <b>{props.booked_revenue}</b></PackageItem>
  </Grid>
  <Grid item xs='auto'>
    <PackageItem elevation={0}>Impression Goal: <b>{props.impression_goal}</b></PackageItem>
  </Grid>
  <Grid item xs='auto'>
    <PackageItem elevation={0}>Delivered Impressions: <b>{props.delivered}</b></PackageItem>
  </Grid>
  <Grid item xs='auto'>
    <PackageItem elevation={0}>% Delivered: <b>{props.delivered_pct}</b></PackageItem>
  </Grid>
  <Grid item xs='auto'>
    <PackageItem elevation={0}>Package Pacing: <b>{props.io_package_pacing}%</b></PackageItem>
  </Grid>
  <Grid item xs='auto'>
    <PackageItem elevation={0}>Package Risk (excl. overpacing): <b>{props.line_risk}</b></PackageItem>
  </Grid>
  <Grid item xs='auto'>
    <PackageItem elevation={0}>Package Risk (incl. overpacing): <b>{package_risk}</b></PackageItem>
  </Grid>


  </Grid>

      </GridToolbarContainer>
    );
  }
  



function OrderGrid({orderId, token, onError }) {

    const [orderData, setOrderData] = useState([{}])
    const [rawData, setRawData] = useState([{}])

    const [topLevelOrderData, setTopLevelOrderData] = useState({})
    const [sortModel, setSortModel] = useState([
      {
        field: 'line_status',
        sort: 'asc',
      },
    ]);
    const {salespersonId} = useParams();

    const [quickFilter, setQuickFilter] = useState();

    const [loading, setLoading] = useState(true)

    const handleExportOrder = () => {
      const options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: orderId + '_' + topLevelOrderData.campaign_nm + '_' + 'delivery_data'
      };

      
    
    const csvExporter = new ExportToCsv(options);
    
    csvExporter.generateCsv(rawData);
    };

/*
    const [quickFilterModel, setQuickFilterModel] = useState({
      items: [
        {
          columnField: 'line_status',
          operatorValue: 'contains',
          value: quickFilter,
        },
      ],
    });
*/



    const [filterModel, setFilterModel] = useState({items: []});

    const handleSortChange = (
      newSortModel
    ) => {
      localStorage.setItem('savedSort', JSON.stringify(newSortModel));

      setSortModel(newSortModel);
    };

    const handleToggle = (
      event
    ) => {
    //  setAlignment(event.target.value);

            setQuickFilter(event.target.value);
            switch(event.target.value){
              case "delivering":
                localStorage.setItem('quickFilter', JSON.stringify(event.target.value));

                setFilterModel({
                  items: [
                    {
                      columnField: 'line_status',
                      operatorValue: 'contains',
                      value: 'Delivering',
                    },
                  ],
                })
                                break;
              
              case "completed":
                localStorage.setItem('quickFilter', JSON.stringify(event.target.value));

                setFilterModel({
                  items: [
                    {
                      columnField: 'line_status',
                      operatorValue: 'contains',
                      value: 'Completed',
                    },
                  ],
                })              
                  break;
                case "future":
                  setFilterModel({
                    items: [
                      {
                        columnField: 'line_status',
                        operatorValue: 'contains',
                        value: 'Future Start',
                      },
                    ],
                  })              
                    break;
                    case "late":
                      localStorage.setItem('quickFilter', JSON.stringify(event.target.value));

                  setFilterModel({
                    
                    items: [
                      {
                        columnField: 'line_status',
                        operatorValue: 'contains',
                        value: 'Not Live',
                      },
                    ],
                  })              
                    break;

                    case "paused":

                  setFilterModel({
                    
                    items: [
                      {
                        columnField: 'line_status',
                        operatorValue: 'contains',
                        value: 'Paused',
                      },
                    ],
                  })              
                    break;


                  case "all":
                    localStorage.removeItem('quickFilter')
                    setFilterModel({items: []})
                               
                      break;

                      default:
                        localStorage.removeItem('quickFilter')

                        setFilterModel({items: []})          
                          break;            
            }

     // setFilterModel(v)
    };

/*
    const handleFilterChange = (
      newFilterModel
    ) => {
      localStorage.setItem('savedFilter', JSON.stringify(newFilterModel));

      setFilterModel(newFilterModel);
    };
*/

    useEffect(() => {
      var options = {
        method: 'GET',
        headers: {"Content-Type": "application/json"}
  
      }
      options.headers["Authorization"] = "Bearer " + token;
        fetch("/api/order/getlinesfor/"+orderId, options)
          .then((response) => response.json())
          .then((json) => {
          setRawData(json.lines)
          return json
    })
          .then((json) =>
          json.lines.group(item => item.package_nm)

        ).then((groupedByPackage) => {
          if (groupedByPackage.length > 0) {

          
            let advertiser_nm = groupedByPackage[0].line[0].advertiser_nm
            let campaign_nm = groupedByPackage[0].line[0].campaign_nm
            let order_id = groupedByPackage[0].line[0].order_id
            let total_revenue_amt = 0
            let total_revenue_at_risk = 0
            for (let index = 0; index < groupedByPackage.length; index++) {

              let package_booked = 0
              let package_risk = 0
              let package_pacing = 0
              let package_goal = 0
              let package_delivered = 0

              let io_package_pacing = 0
              //  console.log(groupedByPackage[index].line[0])
                for (let i = 0; i < groupedByPackage[index].line.length; i++) {
                    total_revenue_amt += groupedByPackage[index].line[i].booked_revenue_amt
                    total_revenue_at_risk += groupedByPackage[index].line[i].rev_at_risk_amt

                    package_booked += groupedByPackage[index].line[i].booked_revenue_amt
                    package_risk += groupedByPackage[index].line[i].rev_at_risk_amt
                    package_pacing += groupedByPackage[index].line[i].rev_pacing

                    package_goal +=groupedByPackage[index].line[i].op_impression_goal_contracted
                    package_delivered +=groupedByPackage[index].line[i].final_billable

                    if(groupedByPackage[index].line[i].io_package_pacing > io_package_pacing){
                      io_package_pacing = groupedByPackage[index].line[i].io_package_pacing

                    }

                }
                groupedByPackage[index]['package_booked'] = '$'+ Math.round(package_booked)?.toLocaleString()
                groupedByPackage[index]['package_goal'] = package_goal?.toLocaleString()
                groupedByPackage[index]['package_delivered'] = Math.round(package_delivered)?.toLocaleString()

                package_risk = Math.round(package_risk)?.toLocaleString()
                
              //  package_pacing = Math.round(package_pacing)?.toLocaleString()

                if (package_risk.includes('-')){
                  package_risk = package_risk.replace('-', '');
                  package_risk = "-"+"$"+package_risk
              } else {
                package_risk = "$"+package_risk
        
              }
/*
              if (package_pacing.includes('-')){
                package_pacing = package_pacing.replace('-', '');
                package_pacing = "-"+"$"+package_pacing
            } else {
              package_pacing = "$"+package_pacing
      
            }
*/
                groupedByPackage[index]['package_risk'] = package_risk
                groupedByPackage[index]['package_pacing'] = package_pacing
                groupedByPackage[index]['io_package_pacing'] = io_package_pacing



                let package_delivered_pct = 0
                if (package_goal > 0){
                  package_delivered_pct = (package_delivered/package_goal)
                }
                groupedByPackage[index]['package_delivered_pct'] = ((package_delivered_pct)*100).toFixed(2) + '%'


            }

            
            total_revenue_amt = '$' + total_revenue_amt?.toLocaleString()
            total_revenue_at_risk = Math.round(total_revenue_at_risk)?.toLocaleString()
            if (total_revenue_at_risk.includes('-')){
              total_revenue_at_risk = total_revenue_at_risk.replace('-', '');
              total_revenue_at_risk = "-"+"$"+total_revenue_at_risk
          } else {
            total_revenue_at_risk = "$"+total_revenue_at_risk
    
          }
            setOrderData(groupedByPackage)
            //console.log(groupedByPackage)
            setTopLevelOrderData({'advertiser_nm': advertiser_nm, 'campaign_nm':campaign_nm, 'order_id': order_id,
        "total_revenue_amt": total_revenue_amt, 'total_revenue_at_risk': total_revenue_at_risk})
          }
        setLoading(false)
      })
         // .catch(() => onError())
  }, [orderId]);


  useEffect(() => {

    var quickFilter = localStorage.getItem('quickFilter')

    if (quickFilter !== null){

      quickFilter = JSON.parse(localStorage.getItem('quickFilter'))
      setQuickFilter(quickFilter)
      setFilterModel({
        items: [
          {
            columnField: 'line_status',
            operatorValue: 'contains',
            value: quickFilter,
          },
        ],
      })
    } else {
      setQuickFilter('all')

    }



    var savedSort = localStorage.getItem('savedSort')

    if (savedSort !== null){

      savedSort = JSON.parse(localStorage.getItem('savedSort'))
      setSortModel(savedSort)
    }

/*
    var savedFilter = localStorage.getItem('savedFilter')

    if (savedFilter !== null){
      
      savedFilter = JSON.parse(localStorage.getItem('savedFilter'))
      setFilterModel(savedFilter)
    }
*/

  }, []);


  if (loading) {
    return (             
    <LoadingComponent />
    )
  } else {





return (

    
    <div sx={{ backgroundColor: '#ffffff' }}>
<Grid container rowSpacing={0} columnSpacing={{ xs: 0}} alignItems="center" sx={{backgroundColor: '#ffffff'}}>
<Grid item xs={0.15}>
    </Grid> 
    <Grid item xs={11.85}>
<Typography variant="body2" marginTop={0} display="flex">

<ReactLink fontSize="small" style={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft:'20px',
        backgroundColor: '#ffffff',
        color: '#000787',
        fontWeight:500
      }} to={`/dashboard/${salespersonId}`}>Home</ReactLink> 
                          
                  <ReactLink fontSize="small" style={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft:'0px',
        backgroundColor: '#ffffff',
        color: '#000787',
        fontWeight:500
      }} to={``}><NavigateNextIcon fontSize="small" />{orderId}-{topLevelOrderData.campaign_nm}</ReactLink> 

</Typography>
</Grid>
        </Grid>         


        <Grid container columns={10} rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{borderBottom: '1px solid #DBDCDC',
    backgroundColor: '#ffffff'}} >


  <Grid item xs={2}>
    <TopItem elevation={0}>Advertiser</TopItem>
  </Grid>
  <Grid item xs={2}>
    <TopItem elevation={0}>Campaign</TopItem>
  </Grid>
  <Grid item xs={2}>
    <TopItem elevation={0}>Order Id</TopItem>
  </Grid>
  <Grid item xs={2}>
    <TopItem elevation={0}>Booked Revenue</TopItem>
  </Grid>
  <Grid item xs={2}>
    <TopItem elevation={0}>Revenue at Risk</TopItem>
  </Grid>




  <Grid item xs={2}>
    <BottomItem elevation={0}>{topLevelOrderData.advertiser_nm}</BottomItem>
  </Grid>
  <Grid item xs={2}>
    <BottomItem elevation={0}>{topLevelOrderData.campaign_nm}</BottomItem>
  </Grid>
  <Grid item xs={2}>
  <LinkItem elevation={0}>
  <Tooltip TransitionComponent={Zoom} title="View in Operative">
              <Link
      component="a"
      target="_blank"
      rel="noopener noreferrer"
      href={`https://www.operativeone.com/operativeone/sales.do?topNav=sales&id=${topLevelOrderData.order_id}#sales/salesorders/viewId`}
    >
      {topLevelOrderData.order_id}
    </Link>
    </Tooltip>
</LinkItem>
          
  </Grid>
  <Grid item xs={2}>
    <BottomItem elevation={0}>{topLevelOrderData.total_revenue_amt}</BottomItem>
  </Grid>
  <Grid item xs={2}>
    <BottomItem elevation={0}>{topLevelOrderData.total_revenue_at_risk}</BottomItem>
  </Grid>


</Grid>
<br></br>
<Grid container rowSpacing={0} columnSpacing={{ xs: 0}} >
<Grid item xs={1}>
    </Grid>

<Grid item xs={10}>       
<Box display="flex" justifyContent="center" sx={{padding: '5px'}}>
        <ToggleButtonGroup 
     color="primary"
     value={quickFilter}
     exclusive
     onChange={handleToggle
     // setQuickFilter(v);
     // setFilterModel(v)
    }
     
     aria-label="Platform"

   >
     <ToggleButton value="all">All</ToggleButton>
     <ToggleButton value="delivering">Delivering</ToggleButton>
     <ToggleButton value="paused">Paused</ToggleButton>
     <ToggleButton value="late">Not Live</ToggleButton>
     <ToggleButton value="completed">Completed</ToggleButton>
     <ToggleButton value="future">Future Start</ToggleButton>

   </ToggleButtonGroup>
   
   </Box>
    

        </Grid>
        <Grid item xs={0.75} textAlign='right'>
                  <Button sx={{paddingTop: '15px'}}
        id="basic-button"
        onClick={handleExportOrder}
      >
                <FileDownloadOutlinedIcon fontSize="small" /> Export

    </Button>
    </Grid>
    <Grid item xs={0.25}>

    </Grid>

</Grid>

<Grid container rowSpacing={0} columnSpacing={{ xs: 0}} >
<Grid item xs={0.25}>
    </Grid>

<Grid item xs={11.5}>

                {orderData?.map((item, index) => (
                  
                    <div key={index}>
                        <p />
        <Box key={index} sx={{ boxShadow: 1, border: '1px solid #DBDCDC', 
        backgroundColor: '#ffffff' }}> 


{/*
<Typography variant="h5" marginTop={1} gutterBottom>
&ensp;{item.package_nm}</Typography>
<Grid container rowSpacing={0} columnSpacing={{ xs: 1}} >
<Grid item xs={.1}>
  </Grid>
  <Grid item xs={2}>
    <PackageItem elevation={0}>Booked Revenue: <b>{item.package_booked}</b></PackageItem>
  </Grid>
  <Grid item xs={2}>
    <PackageItem elevation={0}>Impression Goal: <b>{item.package_goal}</b></PackageItem>
  </Grid>
  <Grid item xs={2}>
    <PackageItem elevation={0}>Total w/Risk: <b>{item.package_risk}</b></PackageItem>
  </Grid>
  <Grid item xs={2}>
    <PackageItem elevation={0}>Package Risk: <b>{item.package_pacing}</b></PackageItem>
  </Grid>

  <Grid item xs={3.9}>
  </Grid>
  </Grid>
                */}
          {item.line?.length > 0 &&
          
        <DataGrid autoHeight
        rows={item.line}
        columns={columns}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => {handleSortChange(newSortModel)}}
        components={{
          Toolbar: CustomToolbar,
        }}
        componentsProps={{ toolbar: { orderId: orderId, name: item.package_nm, 
        booked_revenue: item.package_booked, impression_goal: item.package_goal, delivered:item.package_delivered, delivered_pct:item.package_delivered_pct,
         line_risk: item.package_risk, 
        package_risk: item.package_pacing, io_package_pacing: item.io_package_pacing} }}

        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => {setQuickFilter('all')
          setFilterModel(newFilterModel)}}
          getRowHeight={() => 'auto'}

      getRowId={(row) => row.sales_order_line_item_id}
      disableSelectionOnClick
       // pageSize={1}
        hideFooter={true}
      //  rowsPerPageOptions={[10]}
     //   getRowHeight={() => 'auto'}
        sx={{
          
            border: 0,
          //  padding: 1,
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
            '& .super-app-theme--cell': {
                backgroundColor: 'rgba(224, 183, 60, 0.55)',
                color: '#1a3e72',
                fontWeight: '600',
              },
            '& .super-app.red': {
                backgroundColor: '#d47483',

      
              },
            
            '& .super-app.yellow': {
                backgroundColor: '#edff64',

      
              },
              '& .super-app.green': {
                backgroundColor: 'rgba(100, 255, 118, 0.49)',
              },}}            


    />

          }

         
          </Box>
       </div> 
      ))}
      </Grid>
      <Grid item xs={0.25}>
    </Grid>
    </Grid>

      </div>
)
}
}
                     //   <JSONPretty id="json-pretty" data={orderData}></JSONPretty>



export default OrderGrid