import React, {useEffect, useState} from 'react'
import { useLocalStorage } from "../hooks/useLocalStorage";
import LoadingComponent from "../components/LoadingComponent";
import Box from "@mui/material/Box";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CssBaseline from '@mui/material/CssBaseline';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from "react-router-dom";
import { useOktaAuth } from '@okta/okta-react';

const salespeople = [
  { id:2, salesperson:'Adele Weiss'},
  { id:3, salesperson:'Alan Goldstein'},
  { id:5, salesperson:'Alessandra Wendroff'},
  { id:6, salesperson:'Alexander Wolfe'},
  { id:7, salesperson:'Allasandrina Soffa'},
  { id:8, salesperson:'Amanda Gardley'},
  { id:10, salesperson:'Arielle Moore'},
  { id:12, salesperson:'Bianca Marcazzo'},
  { id:15, salesperson:'Brittany Tessler'},
  { id:16, salesperson:'Cailin Doherty'},
  { id:17, salesperson:'Caroline Coyer'},
  { id:19, salesperson:'Christina Muranelli'},
  { id:20, salesperson:'Christopher Diaz'},
  { id:22, salesperson:'Courtney Battle'},
  { id:24, salesperson:'Danielle Mudge'},
  { id:25, salesperson:'Dave Frieder'},
  { id:27, salesperson:'Debra Ashley'},
  { id:28, salesperson:'Dennis Burger'},
  { id:29, salesperson:'Derek Raneri'},
  { id:30, salesperson:'Elizabeth Sloan'},
  { id:33, salesperson:'Erin Whelan'},
  { id:34, salesperson:'Evan Noonan'},
  { id:35, salesperson:'Gaby Markey'},
  //{ id:37, salesperson:'Giancarlo Silva'},
  { id:40, salesperson:'Henry Palermo'},
  { id:42, salesperson:'Jacqueline Helweil'},
  { id:45, salesperson:'Jason Laderman'},
  { id:46, salesperson:'Jason Mezic'},
  { id:48, salesperson:'Jennifer Paulenoff'},
  { id:50, salesperson:'Jessica Lieberman'},
  { id:51, salesperson:'Jessie Koverman'},
  //{ id:52, salesperson:'Jim Cassaro'},
  { id:54, salesperson:'John Winchell'},
  { id:55, salesperson:'Jonathan Wesley'},
  { id:56, salesperson:'Jordan Gipson'},
  { id:58, salesperson:'Justen Creppy'},
  { id:61, salesperson:'Kara Tricarico'},
  { id:62, salesperson:'Karla Nielsen'},
  { id:64, salesperson:'Kerry Zuaro'},
  { id:65, salesperson:'Kevin Feinstein'},
  { id:67, salesperson:'Kristen Albanese'},
  { id:68, salesperson:'Kristin Rachlin'},
  { id:69, salesperson:'Kurt Hutchinson'},
  { id:71, salesperson:'Lauren Morris'},
  { id:75, salesperson:'Lyset Monson'},
  { id:76, salesperson:'Maggie Lessing'},
  { id:79, salesperson:'Mark Rodas'},
  { id:82, salesperson:'Megan O\'Brien'},
  { id:84, salesperson:'Michael Elias'},
  { id:85, salesperson:'Michael Mueller'},
  { id:87, salesperson:'Morgan Driscoll'},
  { id:90, salesperson:'Paul Mugler'},
  { id:92, salesperson:'Raymond Semente'},
  { id:93, salesperson:'Regina Conlin'},
  { id:94, salesperson:'Ryan Murray'},
  { id:95, salesperson:'Sanya Lewin'},
  { id:96, salesperson:'Sarik Kumar'},
  { id:97, salesperson:'Sawyer Harris'},
  { id:98, salesperson:'Shakira Hill'},
  { id:99, salesperson:'Shera Mayer'},
  { id:101, salesperson:'Stephanie Kahn Woods'},
  { id:102, salesperson:'Stephanie Leslie'},
  { id:104, salesperson:'Tamara Jones'},
  { id:107, salesperson:'Wanda Sutherland'},
  { id:141, salesperson:'Joy Sipple'},
  { id:38, salesperson:'CBS Local - Detroit'},
  { id:149, salesperson:'CBS Local - New York'},
  { id:161, salesperson:'CBS Local - Sacramento'},
  { id:173, salesperson:'CBS Local - Minneapolis'},
  { id:190, salesperson:'Darryl Hendricks'},
  { id:194, salesperson:'CBS Local - Pittsburgh'},
  { id:202, salesperson:'CBS Local - Atlanta'},
  { id:212, salesperson:'CBS Local - Denver'},
  { id:219, salesperson:'CBS Local - Chicago'},
  { id:237, salesperson:'CBS Local - Baltimore'},
  { id:238, salesperson:'CBS Local - Tampa'},
  { id:239, salesperson:'CBS Local - Boston'},
  { id:241, salesperson:'CBS Local - Seattle'},
  { id:244, salesperson:'CBS Local - Philadelphia'},
  { id:250, salesperson:'CBS Local - Dallas'},
  { id:259, salesperson:'CBS Local - Miami'},
  { id:280, salesperson:'CBS Local - Los Angeles'},
  { id:287, salesperson:'CBS Local - San Francisco'},
  { id:290, salesperson:'Meaghan Black'},
  { id:293, salesperson:'Siobhan Elvin'},
  { id:297, salesperson:'Rachel Oliveros'},
  { id:298, salesperson:'Kendall Doherty'},
  { id:300, salesperson:'Samantha Faust'},
  { id:301, salesperson:'Colleen Gerndt'},
  { id:303, salesperson:'Patrick Salter'},
  { id:304, salesperson:'Jessica Assaad'},
  { id:305, salesperson:'Stephen Russell'},
  { id:306, salesperson:'Jessica Heard'},
  { id:307, salesperson:'Nicholas Williams'},
  { id:308, salesperson:'Odean Mangum'},
  { id:309, salesperson:'Alden Geller'},
  { id:310, salesperson:'Chris Reil'},
  { id:311, salesperson:'Cristina Falbo'},
  { id:317, salesperson:'Craig Harrison'},
  { id:319, salesperson:'Sean Sultanik'},
]



export const AssignmentPage = () => {
    const [loading, setLoading] = useState(true);

    const [value, setValue] = useState([]);
    const [defaultDashboard, setDefaultDashboard] = useLocalStorage("dashboard", null);

    const navigate = useNavigate();

    salespeople.sort((a,b) => (a.salesperson > b.salesperson) ? 1 : ((b.salesperson > a.salesperson) ? -1 : 0))


    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);



    const handleSkip = () => {

      navigate("/dashboard/94", { replace: true });

    }


    const handleSave = () => {
        if(value.length > 0){
    //        var datetime=getservertime(false,"");

       //   const addComment = { id: comments.length+1, username: displayName, commentDate: moment().format('l LTS'), comment: commentValue }
      //  console.log(comments.length)
      let result = value.map(a => a.id);
     // console.log(result)

      var options = {
        method: 'POST',
        body: JSON.stringify({
            assignments: result, uid: userInfo.sub
        }),
        headers: {"Content-Type": "application/json"}
  
      }
      options.headers["Authorization"] = "Bearer " + authState.accessToken.accessToken;


        fetch("/update_assignments",options)
            .then(function(response){
            return response
            }).then(function(body){
              setDefaultDashboard(result)
                navigate("/dashboard/"+result[0], { replace: true });

                
            });
         //   handleAddCommentClicked(commentValue, orderId, lineId)
      //    const test = { id: 45, username: 'Snow', commentDate: 'Jon', comment: 'sdfsdfsdfsdfsd sdfsdfdsf' }
       //   setComments(test)
            
        } 
  
        
       
      };








  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
    //  oktaAuth.getUser().then((info) => {
        setUserInfo(authState.idToken.claims);
        setLoading(false)
    //  });
    }



    
  }, [authState, oktaAuth]); // Update


    if (loading) {


        return (    
<LoadingComponent /> 
                        )
        } else {
            return (



                
                <Box sx={{ backgroundColor: '#ffffff' }}textAlign="center">
      <Container component="main" maxWidth="xs" >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <SettingsIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Setup
          </Typography>
          <Typography variant="body1" align="center">
            Select your sales team or the sales teams you are responsible for covering:
          </Typography>
 


  


          </Box>
          <Autocomplete
        multiple
        id="tags-standard"
        options={salespeople}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
                getOptionLabel={(option) => option.salesperson}
       // defaultValue={[salespeople[0],salespeople[2]]}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <MyChip {...getTagProps({ index })} label={option.salesperson} />
          ));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Salespeople"
            placeholder=""
          />
        )}
      />
               <Button fullWidth
              variant="contained"
              onClick={handleSave}
              sx={{ mt: 3, mb: 2 }}>Save</Button>


                             <Button
              onClick={handleSkip}
              sx={{ mt: 3, mb: 2}}>Skip</Button>
      </Container>
    
                </Box>   
            )
        }
      
}
const MyChip = (props) => {

    return (
        <Chip
        color="primary"
        size="small"
                {...props}
        />
    );
  };