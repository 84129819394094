
import React, { useState} from 'react'

import Box from "@mui/material/Box";

import { Link } from "react-router-dom";

import { DataGrid, gridClasses } from '@mui/x-data-grid';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
//import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";



    // Your date format function
    const formatStartEnd = (date) => {
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'UTC'
      };
        return date.toLocaleDateString(undefined, options);
    };


const columns = [
    {
      field: 'order_id',
      headerName: 'Id',
      width: 75,
      renderCell: (params) => 
      <Link style={{ textDecoration: 'none' }} to={`order/${params.row.order_id}`}>      <Button size="small" variant="contained">
      {params.row.order_id}</Button></Link>

    },
    {
      field: 'campaign_nm',
      headerName: 'Campaign',
      width: 300,
    },

    {
        field: 'advertiser_nm',
        headerName: 'Advertiser',
        width: 200,
      },
    {
    field: 'op_line_start_dt',
    headerName: 'Start Date',
    width: 90,

  },
  {
    field: 'op_line_end_dt',
    headerName: 'End Date',
    width: 90,
    /*
    renderCell: params => {
      const end_dt = new Date(params.row.op_line_end_dt);
      const formattedDate = formatStartEnd(end_dt);
      return <div>{formattedDate}</div>;
      }*/
  },
  {
    field: 'order_status',
    headerName: 'Status',
    width: 90,
    hide: true
  },




  {
    field: 'booked_revenue_amt',
    headerName: 'Booked Revenue',
    width: 150,
    type: 'number',
    valueFormatter: ({ value }) => priorityFormater(value),

  },

  {
    field: 'active_straight_line_risk_adj',
    headerName: 'Line Risk',
    width: 150,
    type: 'number',
    cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
  
        return clsx('super-app', {
          red: params.value < 0 ,
          green: params.value > 8,        });
      },
    valueFormatter: ({ value }) => priorityFormater(value),
  },


  {
    field: 'rev_at_risk_amt',
    headerName: 'Package Pacing Ahead/Behind',
    width: 200,
    type: 'number',
    cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
  
        return clsx('super-app', {
          red: params.value < 0 ,
          green: params.value > 8,        });
      },
    valueFormatter: ({ value }) => priorityFormater(value),
  },

/*
  {
    field: 'active_revenue_amt',
    headerName: 'Active Revenue',
    width: 150,
    type: 'number',
    valueFormatter: ({ value }) => priorityFormater(value),

  },
*/

/*
  {
    field: 'completed_revenue_amt',
    headerName: 'Completed Revenue',
    width: 150,
    type: 'number',
    valueFormatter: ({ value }) => priorityFormater(value),

  },
*/

  {
    field: 'completed_straight_line_risk_adj',
    headerName: 'Under Delivery',
    width: 150,
    type: 'number',
    cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
  
        return clsx('super-app', {
          red: params.value < 0 ,
          green: params.value > 8,        });
      },
    valueFormatter: ({ value }) => priorityFormater(value),
  },

  {
    field: 'future_revenue_amt',
    headerName: 'Future Start Revenue',
    width: 150,
    type: 'number',
    valueFormatter: ({ value }) => priorityFormater(value),

  },










  ];

  const priorityFormater = (cell) => {
    let cell_value = Math.round(cell)?.toLocaleString()
    if (cell_value.includes('-')){
      cell_value = cell_value.replace('-', '');
      cell_value = "-"+"$"+cell_value
      return cell_value
    } else {
      return "$"+cell_value?.toLocaleString()
    }
  

};

function SalespersonGrid({salesLines, salespersonId}) {
  const [quickFilter, setQuickFilter] = useState('active');

  const [filterModel, setFilterModel] = useState({
    items: [
      {
        columnField: 'order_status',
        operatorValue: 'contains',
        value: 'Active',
      }]});


  const handleToggle = (
    event
  ) => {
     // console.log(event.target.value)
  //  setAlignment(event.target.value);

          setQuickFilter(event.target.value);
          switch(event.target.value){
            case "active":
              setFilterModel({
                items: [
                  {
                    columnField: 'order_status',
                    operatorValue: 'contains',
                    value: 'Active',
                  },
                ],
              })
                              break;
            
            case "completed":
              setFilterModel({
                items: [
                  {
                    columnField: 'order_status',
                    operatorValue: 'contains',
                    value: 'Completed',
                  },
                ],
              })              
                break;
              case "future":
                setFilterModel({
                  items: [
                    {
                      columnField: 'order_status',
                      operatorValue: 'contains',
                      value: 'Future Start',
                    },
                  ],
                })              
                  break;
               


                    default:
                      setFilterModel({items: []})          
                        break;            
          }

   // setFilterModel(v)
  };


    if (!salesLines) {

        return (          <p>Loading...</p>
        )
      } else {

        if (salesLines.length > 0){

    return (
        <Box> 

        {/*  <Box display="flex" justifyContent="center" sx={{padding: '5px'}}>*/}
        <Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >

<Grid item xs={12}>
<Box display="flex" justifyContent="center" sx={{paddingTop: '5px'}}>
           <ToggleButtonGroup 
     color="primary"
     value={quickFilter}
     exclusive
     onChange={handleToggle
     // setQuickFilter(v);
     // setFilterModel(v)
    }
     
     aria-label="Platform"

   >
     <ToggleButton value="active">Active</ToggleButton>
     <ToggleButton value="completed">Completed</ToggleButton>
     <ToggleButton value="future">Future Start</ToggleButton>

   </ToggleButtonGroup>
   </Box>
</Grid>

</Grid>
<div style={{ height: 600, width: '100%' }}>
        <DataGrid 
        rows={salesLines}
        columns={columns}
      //  loading={loading}
      getRowId={(row) => row.order_id}
      disableSelectionOnClick
      //hideFooter={true}
      //autoHeight={true}
      filterModel={filterModel}
      onFilterModelChange={(newFilterModel) => {setQuickFilter('all')
        setFilterModel(newFilterModel)}}
      initialState={{
        sorting: {
          sortModel: [{ field: 'active_straight_line_risk_adj', sort: 'asc' }],
        },
      }}
        pageSize={25}
        rowsPerPageOptions={[25]}
        getRowHeight={() => 'auto'}
        sx={{
            fontSize: '12px',
            border: 0,
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
            '& .super-app-theme--cell': {
                backgroundColor: 'rgba(224, 183, 60, 0.55)',
                color: '#1a3e72',
                fontWeight: '600',
              },
            '& .super-app.red': {
                backgroundColor: '#d47483',

      
              },
            
            '& .super-app.yellow': {
                backgroundColor: '#edff64',

      
              },
              '& .super-app.green': {
                backgroundColor: 'rgba(100, 255, 118, 0.49)',
              },}}            


    />
    </div>
    </Box>
    )
        }
      }
}

export default SalespersonGrid