import React from 'react'
import Grid from "@mui/material/Grid";
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate, createSearchParams } from "react-router-dom";
import Button from '@mui/material/Button';



function AdvertiserRisk({data}){

    const navigate = useNavigate();

const handleAdvertiserClick = (name) =>
navigate({
  pathname: '/advertiser',
  search: `?${createSearchParams(name)}`,
});


const columns = [

    {
      field: 'advertiser_nm',
      headerName: 'Advertiser',
      flex:1,
      minWidth: 100,
      renderCell: (params)=>
      <Button size='small' onClick={() => handleAdvertiserClick({name: params.row.advertiser_nm})}
      sx={{
        justifyContent: 'flex-start', // Aligns text to the right
        fontSize: '12px',
        textTransform: 'none'
      }}>
                {params.row.advertiser_nm}</Button>
    },

  
  
    {
        field: 'rev_at_risk_amt_current_day',
        headerName: 'Current Risk',
        flex: 0.5,
        minWidth: 100,
        type: 'number',
        valueFormatter: ({ value }) => priorityFormater(value),
        renderCell: (params) => (
          <Grid container rowSpacing={0} columnSpacing={{ xs: 0}} sx={{display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
            <Grid item xs={1}>
              {params.row.rev_at_risk_amt_7_days_ago !== 'N/A' && (
                params.row.rev_at_risk_amt_current_day > params.row.rev_at_risk_amt_7_days_ago ?
                <KeyboardDoubleArrowUpIcon style={{ color: 'red' }} /> :
                params.row.rev_at_risk_amt_current_day < params.row.rev_at_risk_amt_7_days_ago ?
                <KeyboardDoubleArrowDownIcon style={{ color: 'green' }} /> :
                null
              )}
            </Grid>
            <Grid item xs={11} textAlign='right'>
              {priorityFormater(params.row.rev_at_risk_amt_current_day)}
            </Grid>
          </Grid>
        ),
      },
  {
    field: 'rev_at_risk_amt_7_days_ago',
    headerName: 'Risk 7 Days Ago',
    flex:0.5,
    minWidth: 100,
    type: 'number',
   
    valueFormatter: ({ value }) => priorityFormater(value),
  },

  ]

  const priorityFormater = (cell) => {
    if (cell == null || cell == 'N/A'){
      return "N/A"
    } else {
    let cell_value = Math.round(cell)?.toLocaleString()
    if (cell_value.includes('-')){
      cell_value = cell_value.replace('-', '');
      cell_value = "-"+"$"+cell_value
      return cell_value
    } else {
      return "$"+cell_value?.toLocaleString()
    }
    }
  
  };



    if (!data || data.length == 0) {

        return (          
          <Grid container rowSpacing={0} columnSpacing={{ xs: 0}} 
          sx={{display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
          <Grid item xs={12} textAlign='left'>
          <Typography variant="h6" marginLeft={2} marginTop={1}>
          Risk By Advertiser</Typography>
          </Grid>
          <p style={{marginLeft:'15px'}}>No risk found</p>

          </Grid>
        )
      } else {

        if (data.length > 0){

return(
    <Box sx={{  height: '320px', width: '100%'}}> 
        
    <Grid container rowSpacing={0} columnSpacing={{ xs: 0}} 
sx={{display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
<Grid item xs={12} textAlign='left'>
<Typography variant="h6" marginLeft={2} marginTop={1}>
Risk By Advertiser</Typography>
</Grid>

<Grid item xs={.1}>
    </Grid>
    <Grid item xs={11.8}>
    <div style={{ height: 320, width: '100%' }}>
    <DataGrid 
        rowHeight={30}
        rows={data}
        columns={columns}
        
      //  loading={loading}
      hideFooterPagination={false}
      pageSize={7}
      disableColumnMenu={true}
      disableSelectionOnClick
      getRowId={(row) => row.advertiser_nm}
      rowsPerPageOptions={[7]}
     // pagination
     // getRowHeight={() => 'auto'}

                
        sx={{
            
            fontSize: '12px',
            "& .MuiDataGrid-columnSeparator": {
                display: "none"
            },
            '&, [class^=MuiDataGrid]': { borderTop: 'none' },
          border: 0,
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        }}

    />
        </div>

    </Grid>
    
    <Grid item xs={.1}>
    </Grid>
    </Grid>
    </Box>
)
    }
}

}

export default AdvertiserRisk