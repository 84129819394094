import React from 'react';

import { useNavigate } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import "./styles.css";

import config from './config';
import Routes from './components/Routes';
import Navbar from './pages/Navbar';
//import { Container } from 'semantic-ui-react';

const oktaAuth = new OktaAuth(config.oidc);

const App = () => {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth,  originalUri) => {
    navigate(toRelativeUrl('usercheck' || '/', window.location.origin));
    
  };
  
  const firebaseConfig = {
    apiKey: "AIzaSyCx-udIxkRZhu83h30hwI0MvWqLG3MJGEg",
    authDomain: "i-yields-prod.firebaseapp.com",
    projectId: "i-yields-prod",
    storageBucket: "i-yields-prod.appspot.com",
    messagingSenderId: "1062585395022",
    appId: "1:1062585395022:web:f855c3031659dd6fbc201f",
    measurementId: "G-CSHRE1NZ72"
  };
  const firebaseapp = initializeApp(firebaseConfig);
  const analytics = getAnalytics(firebaseapp);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <header className="App-header">
          <Navbar />
        </header>
        <main>
          <Routes />
        </main>
    </Security>
  );
};
export default App;