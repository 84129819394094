import Box from '@mui/material/Box';
import React from 'react'

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from "@mui/material/Grid";



function GettingStarted() {
    return (  
       
        <div>

      
        <Box>
        <Typography variant="h4" fontWeight={'bold'} gutterBottom>
        Getting Started
      </Typography>
      <Divider />
<br></br>
      <Typography
        id="introduction"
        variant="h6" gutterBottom>
        INTRODUCTION
      </Typography>
      <Divider />

      <Typography paragraph gutterBottom>
      The Rev at Risk application delivers daily, comprehensive snapshots of crucial campaign metrics, including pacing, revenue insights, data variances, and daily delivery status. 
      Seamlessly integrated with our ad servers and Operative, it serves as an indispensable tool for making informed and efficient campaign optimizations. 
      Here, data takes the lead in guiding decisions, and strategically positioning campaigns for optimal delivery.

      
      </Typography>
      <br></br>
      <Typography id="common-speed-bumps" variant="h6" gutterBottom>
        ACCOUNT SET UP
      </Typography>
      
      <Divider />
      <Typography variant='body' gutterBottom>
        
      <b>INITIAL LOGIN </b>
      <p>For demo guaranteed campaigns, check that the gross impression cap (GIC) is set correctly. The GIC should be set to 10x the on-target impression goal, regardless of the demo.
        While the GIC is not the goal that Freewheel will deliver to, it does act essentially as an emergency brake in case the actual demo comp is significantly lower than what we based pricing on. 
        Currently we do not use this for its intended purpose so to prevent lines from hitting this cap, we set it to 10x the on-target impression goal.
        </p>  






        </Typography>

        </Box>


        </div>





  
                                )


}

export default GettingStarted