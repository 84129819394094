import Box from '@mui/material/Box';

export function CustomNoRowsOverlay() {
    return (

        <Box display="flex"
        justifyContent="center"
        alignItems="center" sx={{ height:'300px',mt: 1 }}>No Comments</Box>
    );
  }
  