import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {useEffect, useState} from 'react'
import { useOktaAuth } from '@okta/okta-react';
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Box from "@mui/material/Box";
import RiskByAESVP from '../components/RiskByAESVP';
import PieChart from "../components/PieChart";
import AdvertiserRiskSVP from "../components/AdvertiserRiskSVP";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import LaunchIcon from '@mui/icons-material/Launch';
import IconButton from '@mui/material/IconButton';
import LoadingComponent from "../components/LoadingComponent";
import UpdateCheck from "../components/UpdateCheck";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import { DataGrid, gridClasses } from '@mui/x-data-grid';


const columnsTopLines = [
    {
      field: 'ad_server_url',
      headerName: '',
      flex:0.1,
      minWidth: 30,
      align: 'center',
      renderCell: (params) => {
      if (params.value === 'N/A') {
        return '';
      } else {
        return ( <Tooltip TransitionComponent={Zoom} title="View in ad server">
        <IconButton href={params.value} target="_blank" sx={{color: '#000000', marginBottom:1}}><LaunchIcon fontSize="small" /></IconButton>
        </Tooltip>)
      }
     
      }
    },
    {
      field: 'order_id',
      headerName: 'Order ID',
      flex:0.4,
      minWidth: 70,
      align: 'left',
      renderCell: (params) => 
      <Link style={{ textDecoration: 'none' }} to={`/dashboard/${params.row.salesperson_id}/order/${params.row.order_id}`}> <Button size="small" variant="contained">{params.row.order_id}</Button></Link>
    //  <a href={params.row.sales_order_line_item_id}>{params.row.sales_order_line_item_id}</a>,
      
    },
  
    {
      field: 'sales_order_line_item_id',
      headerName: 'Line ID',
      flex:0.4,
      minWidth: 80,
      align: 'left',
      renderCell: (params) => 
      <Link style={{ textDecoration: 'none' }} to={`/dashboard/${params.row.salesperson_id}/order/${params.row.order_id}/line/${params.row.sales_order_line_item_id}`}> <Button size="small" variant="contained">{params.row.sales_order_line_item_id}</Button></Link>
    //  <a href={params.row.sales_order_line_item_id}>{params.row.sales_order_line_item_id}</a>,
      
    },
    {
      field: 'advertiser_nm',
      headerName: 'Advertiser',
      flex:0.7,
      minWidth: 100,
    },
    {
      field: 'line_item_nm',
      headerName: 'Line Name',
      flex:.9,
      minWidth: 350,
    },
    {
      field: 'line_status',
      headerName: 'Status',
      flex:0.6,
      minWidth: 100,
    },
    {
    field: 'op_line_start_dt',
    headerName: 'Start Date',
    flex:0.6,
    minWidth: 100,
    /*
    renderCell: params => {
      const start_dt = new Date(params.row.op_line_start_dt);
      const formattedDate = formatStartEnd(start_dt);
      return <div>{formattedDate}</div>;
      }*/
  },
  {
    field: 'op_line_end_dt',
    headerName: 'End Date',
    flex:0.6,
    minWidth: 100,
    /*
    renderCell: params => {
      const end_dt = new Date(params.row.op_line_end_dt);
      const formattedDate = formatStartEnd(end_dt);
      return <div>{formattedDate}</div>;
      }*/
    },
  
  
  {
    field: 'rev_at_risk_amt',
    headerName: 'Rev at Risk',
    flex:0.7,
    minWidth: 100,
    type: 'number',
   
    valueFormatter: ({ value }) => priorityFormater(value),
  },
  
  {
    field: 'yield_risk_amt',
    headerName: 'Real Risk',
    flex:0.7,
    minWidth: 100,
    type: 'number',
   
    valueFormatter: ({ value }) => priorityFormater(value),
  },
  {
    field: 'comment',
    headerName: 'Comment',
    flex:1,
    minWidth: 250,
    renderCell: (params) => {
      if(params.row.comment && params.row.comment_date){
        return(
        <Box>{params.row.comment}<br></br>-{params.row.comment_date} {params.row.yield_comment_owner}</Box>
        
      )
        }
  
  
  },
  },
  ]
  
  
  const priorityFormater = (cell) => {
    if (cell == null){
      return "$0"
    } else {
    let cell_value = Math.round(Math.abs(cell))?.toLocaleString()

      return "$"+cell_value
    }
    }


export const SVPDashboardPage = () => {
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);  
    const [loading, setLoading] = useState(true)
    const [token, setToken] = useState('');
    const [salespeople, setSalespeople] = useState([])
  
    const {salespersonId} = useParams();
    const [salesperson, setSalesperson] = useState(salespersonId);
    const [salespersonName, setSalespersonName] = useState('')
    const [topLevelRiskData, setTopLevelRiskData] = useState()
    const [riskByAE, setRiskByAE] = useState()
    const [riskByAdvertiser, setRiskByAdvertiser] = useState()
    const [topRisk, setTopRisk] = useState()
    const [pieData, setPieData] = useState()

    const navigate = useNavigate();



    const handleChange = (event) => {
        setSalesperson(event.target.value);
      //  console.log(event.target.value)
        let path = "/sales-svp-director/"+event.target.value; 
        window.location.href=path
      //  navigate(path);
      };
  
  
    useEffect(() => {
        const fetchData = async () => {
      
        if (!authState || !authState.isAuthenticated) {
          // When user isn't authenticated, forget any user info
          setUserInfo(null);
      
        } else {
          try {
            let accessToken = authState.accessToken;
      
            // Check if the token is expired and refresh it if necessary
            if (oktaAuth.tokenManager.hasExpired(accessToken)) {
              accessToken = await oktaAuth.tokenManager.renew('accessToken');
              console.log('expired')
            }
      
            const options = {
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
              }
            };
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - 15);
            const formattedDate = currentDate.toISOString().slice(0, 10);

            const [response1, response2, response3, response4, response5, response6] = await Promise.all([
                fetch('/api/getsvps/', options),
                fetch(`/api/svp_team?day_dt=${formattedDate}&salespersonId=${salespersonId}`, options),
                fetch(`/api/svp_ae?salespersonId=${salespersonId}`, options),
                fetch(`/api/svp_campaign?salespersonId=${salespersonId}`, options),
                fetch(`/api/svp_advertiser?salespersonId=${salespersonId}`, options),
                fetch(`/api/svp_top_lines?salespersonId=${salespersonId}`, options),

              ]);
      
              if (!response1.ok || !response2.ok || !response3.ok || !response4.ok || !response5.ok || !response6.ok

              )
                 {
                throw new Error('Error');
              }
      
              const data1 = await response1.json();
              const data2 = await response2.json();
              const data3 = await response3.json();
              const data4 = await response4.json();
              const data5 = await response5.json();
              const data6 = await response6.json();


              if (!data2.exists){
                navigate("/dashboard/", { replace: true });
                          }
              setSalespeople(data1)
              setTopLevelRiskData(data2)
              setRiskByAE(data3)
              setTopRisk(data6)
              let tmpPieData = []

              let count = 0
              for (let i = 0; i < data4.length; i++){
                let active_risk = Math.round(data4[i].active_straight_line_risk_adj)
                let campaign_nm = data4[i].campaign_nm
                let advertiser_nm = data4[i].advertiser_nm
                let order_id = data4[i].order_id
                let salesperson_id = data4[i].salesperson_id
                let pieLine = {"id": order_id, "label": campaign_nm,"advertiser_nm":advertiser_nm, "campaign_nm": campaign_nm,
                "order_id":order_id,"value": Math.abs(active_risk), "index": count, "salesperson_id": salesperson_id}

                count +=1
                tmpPieData.push(pieLine)
              }



              setPieData(tmpPieData)
              setRiskByAdvertiser(data5)


             const result = data1.find(item => item.salesperson_id === Number(salespersonId));
             const name = result ? result.salesperson_nm : undefined;          
             setSalespersonName(name)


          
                setLoading(false)
      
               
      
             // }))
             const analytics = getAnalytics();
             const pageTitle = 'SVP Director Dashboard - ' + Number(salespersonId);
             logEvent(analytics, 'page_view', { email: authState.idToken.claims.email, page_title: pageTitle });
      
           } catch (error) {
            console.error('Error fetching data: ', error);
            // Handle errors such as network issues, parsing issues, etc.
          }
        }
      };
      
      fetchData();
      }, [authState, oktaAuth, salespersonId]);
      
  
              if (loading) {
                return (    
          <LoadingComponent />
          )
                  } else {
          return (    
            <Box sx={{ backgroundColor: '#fafafa' }}>
                          <Grid container rowSpacing={0} alignItems="center" columnSpacing={{ xs: 0}} sx={{borderBottom: '1px solid #DBDCDC',
    backgroundColor: '#ffffff'}} >
            <Grid item xs={.25}>
      </Grid>
        <Grid item xs={9.75}><Typography variant="h4" marginLeft={0}>
Team {salespersonName}</Typography>
      </Grid>
      <Grid item xs={1.75} textAlign='right'>

      <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id="demo-simple-select-filled-label">Salesperson</InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={salespersonId}
        onChange={handleChange}
      >

{salespeople.map((data, i) => (
                    <MenuItem key={i} value={data.salesperson_id}>{data.salesperson_nm}</MenuItem>

        ))}

      </Select>
      </FormControl>
      </Grid>
      <Grid item xs={.25}>
      </Grid>
    </Grid>
    
    <br></br>

<Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >

<Grid item xs={.25}></Grid>
<Grid item xs={.05} sx={{borderTop: '1px solid #DBDCDC',borderLeft: '1px solid #DBDCDC', borderBottom: '1px solid #DBDCDC',
        backgroundColor: '#000ac2', boxShadow: 1}}></Grid>

<Grid item xs={3.62} sx={{borderTop: '1px solid #DBDCDC',borderBottom: '1px solid #DBDCDC',borderRight: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>

<Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >
<Grid item xs={12}>
<Typography variant="h6" marginTop={0} marginLeft={1} textAlign='left'>

Team Snapshot
{/*/ Active campaigns / Active revenue / active risk /
 percentage of total  at risk 
        */}
</Typography>
</Grid>
<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center'>
{topLevelRiskData.unique_active_order_count}
</Typography>
</Grid>

<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center'>
  {topLevelRiskData.active_line_count}

</Typography>
</Grid>

<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center'>
  ${Math.round(topLevelRiskData.active_revenue_amt).toLocaleString()}

</Typography>
</Grid>
<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={0} textAlign='center' gutterBottom>
Active Campaigns
</Typography>

</Grid>


<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={0} textAlign='center' gutterBottom>
Active Lines
</Typography>

</Grid>

<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={0} textAlign='center' gutterBottom>
Booked Active Revenue
</Typography>

</Grid>

</Grid>
</Grid>


<Grid item xs={.25}></Grid>
<Grid item xs={.05} sx={{borderTop: '1px solid #DBDCDC',borderLeft: '1px solid #DBDCDC', borderBottom: '1px solid #DBDCDC',
        backgroundColor: '#000ac2', boxShadow: 1}}></Grid>

<Grid item xs={3.62} sx={{borderTop: '1px solid #DBDCDC',borderBottom: '1px solid #DBDCDC',borderRight: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>

<Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >
<Grid item xs={8}>
<Typography variant="h6" marginTop={0} marginLeft={1} textAlign='left'>

Risk Snapshot
{/*/ Active campaigns / Active revenue / active risk /
 percentage of total  at risk 
        */}
</Typography>
</Grid>
<Grid textAlign='right' item xs={4}>


  </Grid>

  <Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center'>
  {topLevelRiskData.orders_with_risk}

</Typography>
</Grid>

<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center'>
  {topLevelRiskData.line_risk_count}

</Typography>
</Grid>
<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center'>
  ${Math.round(Math.abs(topLevelRiskData.active_straight_line_risk_adj)).toLocaleString()}

</Typography>
</Grid>

<Grid item xs={4}>
<Typography variant="body2" textAlign='center' gutterBottom>
Orders with Risk
</Typography>

</Grid>

<Grid item xs={4}>
<Typography variant="body2" textAlign='center' gutterBottom>
Lines at Risk
</Typography>

</Grid>
<Grid item xs={4}>
<Typography variant="body2" textAlign='center' gutterBottom>
Straightline Risk
</Typography>

</Grid>

</Grid>
</Grid>

<Grid item xs={.25}></Grid>
<Grid item xs={.05} sx={{borderTop: '1px solid #DBDCDC',borderLeft: '1px solid #DBDCDC', borderBottom: '1px solid #DBDCDC',
        backgroundColor: '#000ac2', boxShadow: 1}}></Grid>

<Grid item xs={3.62} sx={{borderTop: '1px solid #DBDCDC',borderBottom: '1px solid #DBDCDC',borderRight: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>
<Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >
<Grid item xs={12}>
<Typography variant="h6" marginTop={0} marginLeft={1} textAlign='left'>

Delivered Snapshot

</Typography>
</Grid>


  <Grid item xs={6}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center'>
  ${Math.round(topLevelRiskData.qtr_spend).toLocaleString()}

</Typography>
</Grid>

<Grid item xs={6}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center'>
  ${Math.round(Math.abs(topLevelRiskData.qtr_ud)).toLocaleString()}

</Typography>
</Grid>


<Grid item xs={6}>
<Typography variant="body2" textAlign='center' gutterBottom>
Total Spend QTD
</Typography>

</Grid>

<Grid item xs={6}>
<Typography variant="body2" textAlign='center' gutterBottom>
Under Delivery QTD
</Typography>

</Grid>


</Grid>
</Grid>

</Grid>

<br></br>
        
<Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >
  <Grid item xs={.25}></Grid>

  <Grid item xs={3.67} sx={{border: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>
            <Typography variant="h6" marginTop={1} marginLeft={2}>
Active Campaign Risk Breakout</Typography>

<PieChart pieData={pieData} orderCount={topLevelRiskData.orders_with_risk} svpPage={true}/>
    
            </Grid>
            <Grid item xs={.25}></Grid>

  <Grid item xs={3.67} sx={{border: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>
  <Grid container rowSpacing={0} columnSpacing={{ xs: 0}} >
    

    <Grid item xs={12}>
        
    <Typography variant="h6" marginTop={1} marginLeft={2} gutterBottom>
Risk By AE</Typography>
</Grid>
<Grid item xs={12}>

<RiskByAESVP tableData={riskByAE}/>
    
</Grid>
</Grid>    
    
    </Grid>

    <Grid item xs={.25}></Grid>

<Grid item xs={3.67} sx={{border: '1px solid #DBDCDC',
      backgroundColor: '#ffffff',boxShadow: 1}}>
          <Grid item xs={12}>
        
        <Typography variant="h6" marginTop={1} marginLeft={2} gutterBottom>
    Risk By Advertiser</Typography>
    </Grid>
                    
        <AdvertiserRiskSVP data={riskByAdvertiser} />
                
      
        </Grid>
  



  <Grid item xs={.25}></Grid>

        </Grid>

        <br></br>

        <Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >
  <Grid item xs={.25}></Grid>
  <Grid item xs={.05} sx={{borderTop: '1px solid #DBDCDC',borderLeft: '1px solid #DBDCDC', borderBottom: '1px solid #DBDCDC',
        backgroundColor: '#000ac2', boxShadow: 1}}></Grid>
  <Grid item xs={11.45} sx={{border: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>
            <Typography variant="h6" marginTop={1} marginLeft={1}>
Top Lines at Risk</Typography>
<DataGrid 
autoHeight
        rows={topRisk}
        columns={columnsTopLines}
      //  loading={loading}
      disableColumnMenu={true}
      disableSelectionOnClick
      getRowId={(row) => row.sales_order_line_item_id}

      getRowHeight={() => 'auto'}

       // rowHeight={25}
                hideFooter={true}
                
        sx={{
          
          border: 0,
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        }}

    />
</Grid>


<Grid item xs={.25}></Grid>
  {/*comment section
  <Grid item xs={3} sx={{border: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>
            
            </Grid>
        <Grid item xs={.25}></Grid>
        */}
  </Grid>
<br></br>


             <UpdateCheck />
             </Box>
          )
          }
        
  }

