import React, {useEffect, useState} from 'react'
import { ResponsivePie } from '@nivo/pie'
import { Chip } from "@nivo/tooltip";
import { ThemeProvider } from '@nivo/core'

import { useNavigate,createSearchParams } from "react-router-dom";

const theme = {
    tooltip: {
      tableCell: {
        background: 'red',
      }
    }
  }
  const colorScheme = ['#5955FF', '#494873', '#A6A4FE','#130EFC', '#9B9AF4', '#7D7AFF', '#312DFF']

  const getColor = (responsivepie) => colorScheme[responsivepie.data.index%7]

  function YieldPieChart({pieData, pageType}) {
    const [data, setData] = useState([])


    const [lastHover, setLastHover] = useState(pieData[2])


    
    let navigate = useNavigate(); 
    const routeChange = (product) =>{ 
      navigate({
        pathname: '/yield/risk',
        search: `?${createSearchParams(product)}`,
      });



    }



    

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
      }
    useEffect(() => {

        delay(80).then(() => 
        {
            setData(pieData)

        });
      }, [])

    if (!data){
        return(
            <p>No risk</p>
        )
    } else {


        const CenteredMetric = ({ centerX, centerY }) => {

              return (
                  <>
                  <text
                      x={centerX}
                      y={centerY}
                      textAnchor="middle"
                      dominantBaseline="central"
                      style={{
                          fontSize: '20px',
                          fontWeight: 600,
                      }}
                  >
                      -${Number(lastHover.value).toLocaleString()}
                  </text>
                  
                              <text
                              x={centerX}
                              y={centerY+20}
                              textAnchor="middle"
                              dominantBaseline="central"
                              style={{
                                  fontSize: '12px',
                                  fontWeight: 500,
                              }}
                          >
                              {lastHover.label}
                          </text>
                          </>
              )
          }



    return(
        <div style={{height:'320px'}}>
    <ThemeProvider theme={theme}>

    <ResponsivePie
        data={data}
        margin={{ top: 25, right: 0, bottom: 20, left: 0 }}
        innerRadius={0.5}
        padAngle={0.9}
        cornerRadius={1}
        activeOuterRadiusOffset={8}
        colors={getColor}

           valueFormat={value =>
                        `$${Number(value).toLocaleString()}`
                    }

        arcLinkLabel={function(e){return '-$'+e.value.toLocaleString()}}


        arcLinkLabelsTextColor="#000000"
        arcLinkLabelsColor={{ from: 'color' }}
        enableArcLabels={false}
        arcLabelsRadiusOffset={0.7}
        arcLinkLabelsSkipAngle={20}
        
        transitionMode="outerRadius"
        layers={['arcs', 'arcLabels', 'arcLinkLabels'
        , CenteredMetric
    ]}
    //tooltip={() => (<></>)}
    
        tooltip={({ datum: { id, value, color, data } }) => (
                        <div
                            style={{
                                padding: 10,
                                border: '1px solid #DBDCDC',
                                background: '#ffffff',
                            }}
                        >


                        
                            <Chip color={color}></Chip>
                            {data.label}<br></br>
                            Straight Line Risk: -${value.toLocaleString()}<br></br>
                            Real Risk: -${data.real_risk.toLocaleString()}
                     
                        </div>
        )}
        
        onClick={(data) => {

                        routeChange({[pageType]:data.label})

                    }}
                    onMouseEnter={(data) => {
                        setLastHover(data)
                    }}
        /*
        legends={[
            { anchor: "right",
            direction: "column",
            justify: false,
            translateX: 85,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            itemTextColor: "#000000",
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1
                }
              
            }]}
            
        ]}
*/

       

    />
    </ThemeProvider>
    </div>
    )
    }
}

    export default YieldPieChart