import React, {useEffect, useState} from 'react'
import { ResponsivePie } from '@nivo/pie'
import { Chip } from "@nivo/tooltip";
import { ThemeProvider } from '@nivo/core'

import { useNavigate } from "react-router-dom";

const theme = {
    tooltip: {
      tableCell: {
        background: 'red',
      }
    }
  }
  const colorScheme = [
    '#5955FF', '#494873', '#A6A4FE', '#130EFC', '#242339', '#7D7AFF', '#312DFF',
    '#8E8CFF', '#7B79A3', '#C4C2FF', '#1E1BE0', '#BEBDFC', '#9999FF', '#4D4BFF',
    '#3D3BCC', '#37364C', '#7C7AFE', '#0D0CDB', '#7676D2', '#5C5CFF', '#2927CC',
  ];
  const getColor = (responsivepie) => colorScheme[responsivepie.data.id%21]

  function PieChart({pieData, orderCount, svpPage}) {
    const [data, setData] = useState([])




    
    let navigate = useNavigate(); 
    const routeChange = (data) =>{ 

      //  console.log(order_id)
      if(!svpPage){
      let path = 'order/'+data.order_id; 
      navigate(path);
      } else {
        let path = '/dashboard/' + data.salesperson_id + '/order/' +data.order_id 
        navigate(path);
      }
    }

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
      }
    useEffect(() => {

        delay(250).then(() => 
        {
            setData(pieData)

        });
      }, [])

    if (!data){
        return(
            <p>No risk</p>
        )
    } else {


        const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
           // let total = 0
           //  dataWithArc.forEach(datum => {
             //     total += 1
                  
             // })
          
              return (
                  <>
                  <text
                      x={centerX}
                      y={centerY}
                      textAnchor="middle"
                      dominantBaseline="central"
                      style={{
                          fontSize: '52px',
                          fontWeight: 600,
                      }}
                  >
                      {orderCount}
                  </text>
                  <br></br>
                              <text
                              x={centerX}
                              y={centerY+40}
                              textAnchor="middle"
                              dominantBaseline="central"
                              style={{
                                  fontSize: '16px',
                                  fontWeight: 500,
                              }}
                          >
                              At Risk
                          </text>
                          </>
              )
          }



    return(
        <div style={{height:'320px'}}>
    <ThemeProvider theme={theme}>

    <ResponsivePie
        data={data}
        margin={{ top: 25, right: 0, bottom: 20, left: 0 }}
        innerRadius={0.5}
        padAngle={0.9}
        cornerRadius={1}
        activeOuterRadiusOffset={8}
        colors={getColor}

           valueFormat={value =>
                        `$${Number(value).toLocaleString()}`
                    }

        arcLinkLabel={function(e){return '$'+e.value.toLocaleString()}}


        arcLinkLabelsTextColor="#000000"
        arcLinkLabelsColor={{ from: 'color' }}
        enableArcLabels={false}
        arcLabelsRadiusOffset={0.7}
        arcLinkLabelsSkipAngle={20}
        
        transitionMode="outerRadius"
        layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
        tooltip={({ datum: { id, value, color, data } }) => (
                        <div
                            style={{
                                padding: 10,
                                border: '1px solid #DBDCDC',
                                background: '#ffffff'
                            }}
                        >


                        
                            <Chip color={color}></Chip>
                            {data.advertiser_nm}<br></br>
                            {id}-{data.campaign_nm}<br></br>
                            ${value.toLocaleString()}
                     
                        </div>
        )}
        onClick={(data) => {

                        routeChange(data.data)

                    }}
        /*
        legends={[
            { anchor: "right",
            direction: "column",
            justify: false,
            translateX: 85,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            itemTextColor: "#000000",
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1
                }
              
            }]}
            
        ]}
*/

       

    />
    </ThemeProvider>
    </div>
    )
    }
}

    export default PieChart