import React, { useEffect, useState } from 'react'
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


const columns = [
    {
      field: 'sales_order_line_item_id',
      headerName: 'Line ID',
      width: 150,
      renderCell: (params) => 
      <a style={{ textDecoration: 'none' }} href={params.row.sales_order_line_item_id}>
        <Button size="small" variant="contained">{params.row.sales_order_line_item_id}</Button></a>,
      
    },
    {
      field: 'line_item_nm',
      headerName: 'Line Name',
      width: 400,
    },
    {
    field: 'op_line_start_dt',
    headerName: 'Start Date',
    width: 100,
    /*
    renderCell: params => {
      const start_dt = new Date(params.row.op_line_start_dt);
      const formattedDate = formatStartEnd(start_dt);
      return <div>{formattedDate}</div>;
      }*/
  },
  {
    field: 'op_line_end_dt',
    headerName: 'End Date',
    width: 100,
    /*
    renderCell: params => {
      const end_dt = new Date(params.row.op_line_end_dt);
      const formattedDate = formatStartEnd(end_dt);
      return <div>{formattedDate}</div>;
      }*/
  },
  {
    field: 'line_status',
    headerName: 'Status',
    width: 100,
  },
  {
    field: 'op_impression_goal_contracted',
    headerName: 'Goal',
    width: 150,
  },

  {
    field: 'sold_cpm_amt',
    headerName: 'CPM',
    width: 100,
  },

  {
    field: 'booked_revenue_amt',
    headerName: 'Booked Revenue',
    width: 150,
    type: 'number',
    valueFormatter: ({ value }) => priorityFormater(value),

  },
  {
    field: 'pacing',
    headerName: 'Pacing',
    width: 150,
    type: 'number',
    valueFormatter: ({ value }) => `${value}%`,
    cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
  
        return clsx('super-app', {
          red: params.value < 90 ,
          yellow: params.value < 98 && params.value > 90,
          green: params.value > 98,
        });
      },
  },

  {
    field: 'rev_at_risk_amt',
    headerName: '$ Ahead/Behind',
    width: 150,
    type: 'number',
    cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
  
        return clsx('super-app', {
          red: params.value < 0 ,
          green: params.value > 8,        });
      },
    valueFormatter: ({ value }) => priorityFormater(value),
  },


  

  {
    field: 'final_billable',
    headerName: 'Final Billable',
    width: 150,
  },

  {
    field: 'first_party_p2_delivery',
    headerName: '1P P2+ Delivery',
    width: 150,
  },



  {
    field: 'billable_p2_delivery',
    headerName: '3P P2+ Delivery',
    width: 150,
  },



  {
    field: 'on_target_net',
    headerName: 'Ontarget Net Delivered Impressions',
    width: 150,
  },



  

  {
    field: 'discrepancy_3p',
    headerName: 'Discrepancy',
    width: 150,
    type: 'number',
    valueFormatter: ({ value }) => `${value}`,
    cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
  
        return clsx('super-app', {
          red: params.value < 0 
        });
      },
  },

  {
    field: 'value_of_discrepancy',
    headerName: 'Value of Discrepancy',
    width: 150,
   
  },

  {
    field: 'needed_buffer',
    headerName: 'Needed Buffer',
    width: 150,
  },

  {
    field: 'fw_scheduled_quantity',
    headerName: 'Current Buffer',
    width: 150,
  },

  {
    field: 'rbp_comp_revised',
    headerName: 'RBP Comp',
    width: 150,
  },


  ];

  const priorityFormater = (cell) => {
    let cell_value = Math.round(cell)?.toLocaleString()
    if (cell_value.includes('-')){
      cell_value = cell_value.replace('-', '');
      cell_value = "-$"+cell_value
      return cell_value
    } else {
      return "$"+cell_value?.toLocaleString()
    }
  

};

function BundledGrid({orderId, package_nm, lineId, token, onError }) {
 // const { token } = useAuth();

    const [bundledLines, setBundledLines] = useState([]);
    const [loading, setLoading] = useState(true)
    const [quickFilter, setQuickFilter] = useState('all');

    const [filterModel, setFilterModel] = useState({items: []});


    const handleToggle = (
      event
    ) => {
    //  setAlignment(event.target.value);

            setQuickFilter(event.target.value);
            switch(event.target.value){
              case "delivering":
                //localStorage.setItem('quickFilter', JSON.stringify(event.target.value));

                setFilterModel({
                  items: [
                    {
                      columnField: 'line_status',
                      operatorValue: 'contains',
                      value: 'Delivering',
                    },
                  ],
                })
                                break;
              
              case "completed":
              //  localStorage.setItem('quickFilter', JSON.stringify(event.target.value));

                setFilterModel({
                  items: [
                    {
                      columnField: 'line_status',
                      operatorValue: 'contains',
                      value: 'Completed',
                    },
                  ],
                })              
                  break;
                case "future":
                  setFilterModel({
                    items: [
                      {
                        columnField: 'line_status',
                        operatorValue: 'contains',
                        value: 'Future Start',
                      },
                    ],
                  })              
                    break;
                    case "late":
                    //  localStorage.setItem('quickFilter', JSON.stringify(event.target.value));

                  setFilterModel({
                    
                    items: [
                      {
                        columnField: 'line_status',
                        operatorValue: 'contains',
                        value: 'Not Live',
                      },
                    ],
                  })              
                    break;

                    case "paused":
                      //  localStorage.setItem('quickFilter', JSON.stringify(event.target.value));
  
                    setFilterModel({
                      
                      items: [
                        {
                          columnField: 'line_status',
                          operatorValue: 'contains',
                          value: 'Paused',
                        },
                      ],
                    })              
                      break;
                  case "all":
                  //  localStorage.removeItem('quickFilter')
                    setFilterModel({items: []})
                               
                      break;

                      default:
                      //  localStorage.removeItem('quickFilter')

                        setFilterModel({items: []})          
                          break;            
            }

     // setFilterModel(v)
    };


    

    useEffect(() => {

      var options = {
        method: 'GET',
        headers: {"Content-Type": "application/json"}
      };
      options.headers["Authorization"] = "Bearer " + token;
    
      setLoading(true); // set loading to true before fetch request
      fetch("/api/bundle/"+orderId+"/"+encodeURIComponent(package_nm)+"/"+lineId, options)
        .then((response) => response.json())
        .then((json) => {
          setBundledLines(json.lines);
          setLoading(false); // set loading to false after fetch success
        })
        .catch(() => {
          setLoading(false); // set loading to false after fetch error
          onError();
        });
    }, [lineId]);

    if (loading) {
      return null; // or some kind of loading indicator
    }
    
    if (bundledLines && bundledLines.length > 0) {
    return (
        <Box sx={{ boxShadow: 1, border: '1px solid #DBDCDC', backgroundColor: '#ffffff' }}> 
        <Typography variant="h5" marginTop={1} gutterBottom>
&ensp;Bundled Lines within IO Package {package_nm}</Typography>
<Box display="flex" justifyContent="center" sx={{padding: '5px'}}>
        <ToggleButtonGroup 
     color="primary"
     value={quickFilter}
     exclusive
     onChange={handleToggle
     // setQuickFilter(v);
     // setFilterModel(v)
    }
     
     aria-label="Platform"

   >
     <ToggleButton value="all">All</ToggleButton>
     <ToggleButton value="delivering">Delivering</ToggleButton>
     <ToggleButton value="paused">Paused</ToggleButton>

     <ToggleButton value="late">Not Live</ToggleButton>
     <ToggleButton value="completed">Completed</ToggleButton>
     <ToggleButton value="future">Future Start</ToggleButton>

   </ToggleButtonGroup>
   </Box>
        <DataGrid 
        rows={bundledLines}
        columns={columns}
      //  loading={loading}
      getRowId={(row) => row.sales_order_line_item_id}
      disableSelectionOnClick
      hideFooter={true}
      autoHeight={true}
      filterModel={filterModel}

        pageSize={100}
        rowsPerPageOptions={[100]}
        getRowHeight={() => 'auto'}
        sx={{
            border: 0,
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
            '& .super-app-theme--cell': {
                backgroundColor: 'rgba(224, 183, 60, 0.55)',
                color: '#1a3e72',
                fontWeight: '600',
              },
            '& .super-app.red': {
                backgroundColor: '#d47483',

      
              },
            
            '& .super-app.yellow': {
                backgroundColor: '#edff64',

      
              },
              '& .super-app.green': {
                backgroundColor: 'rgba(100, 255, 118, 0.49)',
              },}}            


    />
    </Box>
    )
        } else {
          console.log("empty")
        return null
        }
      }


export default BundledGrid