import React, {useEffect, useState} from 'react'
import Snackbar from '@mui/material/Snackbar';
import { useOktaAuth } from '@okta/okta-react';


function UpdateCheck() {

    const [state, setState] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'right',
      });
      const { vertical, horizontal, open } = state;


      const { authState, oktaAuth } = useOktaAuth();


      
      useEffect(() => {
        const fetchData = async () => {
          if (!authState || !authState.isAuthenticated) {
            // Handle the case when the user is not authenticated
          } else {
            try {
              let accessToken = authState.accessToken;
      
              // Check if the token is expired and refresh it if necessary
              if (oktaAuth.tokenManager.hasExpired(accessToken)) {
                accessToken = await oktaAuth.tokenManager.renew('accessToken');
                console.log('expired update check')
              }
      
              const options = {
                method: 'GET',
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + accessToken.accessToken
                }
              };
      
              const response = await fetch(`/mmcheck`, options);
              const json = await response.json();
      
              // Update your state with the fetched data
              setState((prevState) => ({
                ...prevState,
                open: json.maintenance,
              }));
      
            } catch (error) {
              console.error('Error fetching data: ', error);
              // Handle errors such as network issues, parsing issues, etc.
            }
          }
        };
      
        fetchData();
      }, [authState, oktaAuth]); // Dependencies for useEffect
    


return (
    <Snackbar
    anchorOrigin={{ vertical, horizontal }}
    open={open}
    //onClose={handleClose}
    message={
        <div>
          The system is currently updating with yesterday's data. During this update window, data could be out of sync.
          <br />
           This is expected to last for 30 minutes.
        </div>
    }
    key={vertical + horizontal}
  />
)

}

export default UpdateCheck