
import React, {useEffect, useState} from 'react'
import Box from "@mui/material/Box";


import Grid from "@mui/material/Grid";

import { Link } from "react-router-dom";
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import { useOktaAuth } from '@okta/okta-react';
import LoadingComponent from "../components/LoadingComponent";
import { DataGrid, gridClasses, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
//import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import LaunchIcon from '@mui/icons-material/Launch';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Stack from '@mui/material/Stack';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import UpdateCheck from '../components/UpdateCheck';






  function CustomToolbar(props) {




    return (
      <GridToolbarContainer>

<Grid container rowSpacing={0} columnSpacing={{ xs: 1}} >


<Grid item xs={12} marginTop={1} textAlign={'right'} >

<GridToolbarExport
        csvOptions={{ fileName: props.queryName + ' - Campaigns', allColumns: true}}
        printOptions={{ disableToolbarButton: true}}/>

</Grid>

</Grid>



      </GridToolbarContainer>
    );
  }

export const AdvertiserPage = () => {
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);

    const [searchParams] = useSearchParams();

    const [orderData, setOrderData] = useState([{}])
    const [salespersonId, setSalespersonId] = useState(94)

    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1); // current page number
    const [itemsPerPage, setItemsPerPage] = useState(250); // number of items per page
    const [filterModel, setFilterModel] = useState({items: []});
    const navigate = useNavigate(); // Get the navigate function from react-router-dom

    function setSigned(params) {
     // const today = moment().format('M/D');
     const currentDate = new Date();

      // Extract the month and day
      const month = currentDate.getMonth() + 1; // Months are zero-indexed, so we add 1
      const day = currentDate.getDate();

      // Format the date as 'M/D'
      const today = `${month}/${day}`;
      const comment = params.value
      const name = userInfo.name
      const updatedRow = { ...params.row, comment, comment_date: today, yield_comment_owner:name };
      return updatedRow;
    }

    function getStartOfQuarter() {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentQuarter = Math.floor(currentMonth / 3);
      const startOfQuarter = new Date(currentDate.getFullYear(), currentQuarter * 3, 1);
    
      return startOfQuarter.toISOString().slice(0, 10);
    }

    function formatDateWithTime() {
      const currentDate = new Date();
    
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Zero-padding for single-digit months
      const day = String(currentDate.getDate()).padStart(2, '0'); // Zero-padding for single-digit days
    
      const hours = String(currentDate.getHours()).padStart(2, '0'); // Zero-padding for single-digit hours
      const minutes = String(currentDate.getMinutes()).padStart(2, '0'); // Zero-padding for single-digit minutes
      const seconds = String(currentDate.getSeconds()).padStart(2, '0'); // Zero-padding for single-digit seconds
    
      const formattedDate = `${month}/${day}/${year}`;
      const formattedTime = `${hours}:${minutes}:${seconds}`;
    
      return `${formattedDate} ${formattedTime}`;
    }


    // Your date format function
    const formatStartEnd = (date) => {
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'UTC'
      };
        return date.toLocaleDateString(undefined, options);
    };


    const columns = [
      {
          field: 'op_url',
          headerName: '',
          flex:0.2,
          minWidth: 25,
          align: 'center',
          renderCell: (params) => {
          if (params.value === 'N/A') {
            return '';
          } else {
            return ( <Tooltip TransitionComponent={Zoom} title="View in Operative">
            <IconButton href={params.value} target="_blank" sx={{color: '#000000', marginBottom:1}}><LaunchIcon fontSize="small" /></IconButton>
            </Tooltip>)
          }
         
          }
        },
  
        {
          field: 'order_id',
          headerName: 'Order ID',
          flex:0.4,
          minWidth: 70,
          align: 'left',
          renderCell: (params) => 
          <Link style={{ textDecoration: 'none' }} to={`/dashboard/${params.row.salesperson_id}/order/${params.row.order_id}`}> <Button size="small" variant="contained">{params.row.order_id}</Button></Link>
        //  <a href={params.row.sales_order_line_item_id}>{params.row.sales_order_line_item_id}</a>,
          
        },
        

      {
        field: 'campaign_nm',
        headerName: 'Campaign',
        flex:1,
        minWidth: 300,
        
      },

      {
        field: 'primary_salesperson_nm',
        headerName: 'Salesperson',
        flex:1,
        minWidth: 100,
        
      },

      {
        field: 'booked_revenue_amt',
        headerName: 'Booked Revenue',
        flex:1,
        minWidth: 150,
        type: 'number',
        valueFormatter: ({ value }) => priorityFormater(value),
    
      },
    
  
      {
        field: 'active_straight_line_risk_adj',
        headerName: 'Line Risk',
        flex:0.9,
        minWidth: 100,
        type: 'number',
        cellClassName: (params) => {
            if (params.value == null) {
              return '';
            }
      
            return clsx('super-app', {
              red: params.value < 0 ,
              green: params.value > 0,        });
          },
        valueFormatter: ({ value }) => priorityFormater(value),
      },
    
      {
        field: 'rev_pacing',
        headerName: 'Package Risk',
        flex:1,
        minWidth: 150,
        type: 'number',
        cellClassName: (params) => {
            if (params.value == null) {
              return '';
            }
      
            return clsx('super-app', {
              red: params.value < 0 ,
              green: params.value > 0,        });
          },
        valueFormatter: ({ value }) => priorityFormater(value),
      },


      {
        field: 'completed_straight_line_risk_adj',
        headerName: 'Under Delivery',
        width: 150,
        type: 'number',
        cellClassName: (params) => {
            if (params.value == null) {
              return '';
            }
      
            return clsx('super-app', {
              red: params.value < 0 ,
              green: params.value > 8,        });
          },
        valueFormatter: ({ value }) => priorityFormater(value),
      },
        
      {
      field: 'order_start_dt',
      headerName: 'Start Date',
      flex:1,
      minWidth: 100,

    },
    {
      field: 'order_end_dt',
      headerName: 'End Date',
      flex:1,
      minWidth: 100,

      },
    {
      field: 'order_status',
      headerName: 'Status',
      flex:1,
      minWidth: 100,
    },


    
    ];
  
  
  
  
    const priorityFormater = (cell) => {
      if (cell === ''){
        return ""
      } else {
      let cell_value = Math.round(cell)?.toLocaleString()
      if (cell_value.includes('-')){
        cell_value = cell_value.replace('-', '');
        cell_value = "-"+"$"+cell_value
        return cell_value
      } else {
        return "$"+cell_value?.toLocaleString()
      }
      }
  
  };
  

    const [sortModel, setSortModel] = useState([
        {
          field: 'order_status',
          sort: 'asc',
        },
      ]);
      const [quickFilter, setQuickFilter] = useState();




    let query = ''
    let queryType = 'name'
    if(searchParams.get('name')){
        //console.log(poc)
        query = searchParams.get('name')

     

  } else {

    }
    const [queryName, setQueryName] = useState(query);





    const handleSortChange = (
        newSortModel
      ) => {
  
        setSortModel(newSortModel);
      };

      const handleToggle = (
        event
      ) => {
      //  setAlignment(event.target.value);
  
              setQuickFilter(event.target.value);
              switch(event.target.value){
                case "active":
  
                  setFilterModel({
                    items: [
                      {
                        columnField: 'order_status',
                        operatorValue: 'contains',
                        value: 'Active',
                      },
                    ],
                  })
                                  break;
                
                case "completed":
  
                  setFilterModel({
                    items: [
                      {
                        columnField: 'order_status',
                        operatorValue: 'contains',
                        value: 'Completed',
                      },
                    ],
                  })              
                    break;
                  case "future":
                    setFilterModel({
                      items: [
                        {
                          columnField: 'order_status',
                          operatorValue: 'contains',
                          value: 'Future Start',
                        },
                      ],
                    })              
                      break;
                      case "late":
  
                    setFilterModel({
                      
                      items: [
                        {
                          columnField: 'order_status',
                          operatorValue: 'contains',
                          value: 'Not Live',
                        },
                      ],
                    })              
                      break;
                    case "all":
                      setFilterModel({items: []})
                                 
                        break;
  
                        default:
  
                          setFilterModel({items: []})          
                            break;            
              }
  
       // setFilterModel(v)
      };
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      //const itemsToDisplay = orderData.slice(startIndex, endIndex);
    
      //const totalPages = Math.ceil(orderData.length / itemsPerPage);




      useEffect(() => {

    

          setQuickFilter('all')
    
        
    
    
    

    
    
    
      }, []);

      useEffect(() => {
        const fetchData = async () => {
          if (!authState || !authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
          } else {
            try {
              let accessToken = authState.accessToken;
      
              // Check if the token is expired and refresh it if necessary
              if (oktaAuth.tokenManager.hasExpired(accessToken)) {
                accessToken = await oktaAuth.tokenManager.renew('accessToken');
              }
      
              setUserInfo(authState.idToken.claims);
              setQueryName(query);
              const encodedQuery = encodeURIComponent(query); // Encode the query parameter

              const options = {
                method: 'GET',
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + accessToken.accessToken
                }
              };
      
              const response = await fetch(`/advertiser_request?type=${queryType}&q=${encodedQuery}`, options);
              const json = await response.json();
      
              if (json.lines === 'error') {
                console.log('error');
                navigate('/dashboard'); // Replace '/dashboard' with the path of your error page
              } else {
                if (json.orders && json.orders.length > 0) {
                  setOrderData(json.orders);
                  setLoading(false);
                  setSalespersonId(json.orders[0].salesperson_id);
                } else {
                  navigate('/dashboard'); 
                }
              }
            } catch (error) {
              console.error('Error fetching data: ', error);
              // Handle errors such as network issues, parsing issues, etc.
            }
          }
        };
      
        fetchData();
      }, [authState, oktaAuth, query, queryType]); // Updated dependencies


    

  if (loading) {
    return (             
    <LoadingComponent />
    )
  } else {

    return (
        <div sx={{ backgroundColor: '#ffffff' }}>
        <Grid container rowSpacing={0} columnSpacing={{ xs: 0}} alignItems="center" sx={{borderBottom: '1px solid #DBDCDC',
            backgroundColor: '#ffffff'}}>
                    <Grid item xs={0.15}>
    </Grid> 
    <Grid item xs={11.85}>        
        <Typography variant="body2" marginTop={0} display="flex">
        
        <Link fontSize="small" style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft:'20px',
                backgroundColor: '#ffffff',
                color: '#000787',
                fontWeight:500
              }} to={`/dashboard/${salespersonId}`}>Home</Link> 
              
                        <Link fontSize="small" style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft:'0px',
                backgroundColor: '#ffffff',
                color: '#000787',
                fontWeight:500
              }} to={``}><NavigateNextIcon fontSize="small" />{queryName}</Link>            
                      
        
        </Typography>
        </Grid>
                </Grid>         
        
        
               <br></br>
        
        
        
        
        <Grid container rowSpacing={0} columnSpacing={{ xs: 0}} >
        
        
        
         
        
            <Grid item xs={0.25}>
            </Grid>
            <Grid item xs={3}>
            </Grid>
            <Grid item xs={5.5}>  
             
        <Box display="flex" justifyContent="center">
        <ToggleButtonGroup  
             color="primary"
             value={quickFilter}
             exclusive
             onChange={handleToggle
             // setQuickFilter(v);
             // setFilterModel(v)
            }
             
             aria-label="Platform"
        
           >
             <ToggleButton value="all">All</ToggleButton>
             <ToggleButton value="active">Active</ToggleButton>
             <ToggleButton value="late">Not Live</ToggleButton>
             <ToggleButton value="completed">Completed</ToggleButton>
             <ToggleButton value="future">Future Start</ToggleButton>
        
           </ToggleButtonGroup>
        
        </Box>
        </Grid>
        <Grid item xs={3}>  
        

           </Grid>
        
        
        <Grid item xs={0.25}>
            </Grid>
        
        
            <Grid item xs={0.25}>
            </Grid>
            <Grid item xs={3}>
            </Grid>
            <Grid item xs={5.5}>
         
        </Grid>
            <Grid item xs={3}>
            
            <Box display="flex" justifyContent="flex-end" sx={{paddingTop: '5px'}}>

        </Box>
        </Grid>
            <Grid item xs={0.25}>
            </Grid>
        
        </Grid>
        
        <Grid container rowSpacing={0} columnSpacing={{ xs: 0}} >
        <Grid item xs={0.25}>
            </Grid>
        
        <Grid item xs={11.5}>
        
        <div>
                        <p />
        <Box sx={{ boxShadow: 1, border: '1px solid #DBDCDC', 
        backgroundColor: '#ffffff'}}> 
                          <Grid style={{ height: '70vh', width: '100%' }} container rowSpacing={0} columnSpacing={{ xs: 0}} >
                <DataGrid
                //rows={itemsToDisplay}
                rows={orderData}
                columns={columns}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {handleSortChange(newSortModel)}}
                components={{
                  Toolbar: CustomToolbar,
                }}
                componentsProps={{ toolbar: { queryName: queryName} }}
        
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) => {setQuickFilter('all')
                  setFilterModel(newFilterModel)}}
                  getRowHeight={() => 'auto'}
             //rowHeight={100}
              getRowId={(row) => row.document_id}
              disableSelectionOnClick
             //   pageSize={10}
                hideFooter={true}

     
   
                sx={{
                    fontSize: 12,
                    border: 0,
                  //  padding: 1,
                    [`& .${gridClasses.cell}`]: {
                      py: 1,
                    },
                    '& .super-app-theme--cell': {
                        backgroundColor: 'rgba(224, 183, 60, 0.55)',
                        color: '#1a3e72',
                        fontWeight: '600',
                      },
                    '& .super-app.red': {
                        backgroundColor: '#d47483',
        
              
                      },
                    
                    '& .super-app.yellow': {
                        backgroundColor: '#edff64',
        
              
                      },
                      '& .super-app.green': {
                        backgroundColor: 'rgba(100, 255, 118, 0.49)',
                      },}}            
        
        
            />
            
        </Grid>
                  
        
                 

              </Box>
              </div>
              </Grid>
              <Grid item xs={0.25}>
            </Grid>
            </Grid>
            <div><br></br>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 0}} >
        <Grid item xs={0.25}>
            </Grid>
            <Grid item xs={11.5}>
            <Box display="flex" justifyContent="flex-end">
              {/*
                <Stack direction="row" spacing={1} alignItems="center">
        
            <Button variant="outlined" 
                  disabled={currentPage === 1}
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                    scrollToTop();
                  }}        >
                            <ArrowBackIosNewRoundedIcon fontSize="small" /> 
        
                </Button>
                <Typography variant="body2" gutterBottom>
        
                {currentPage} of {totalPages}
                </Typography>
                <Button variant="outlined" 
                disabled={endIndex >= orderData.length}
         onClick={() => {
                    setCurrentPage(currentPage + 1);
                    scrollToTop();
                  }}        >
                  <ArrowForwardIosRoundedIcon fontSize="small" /> 
                </Button>
        </Stack>
                */}
        </Box>
        </Grid>
        <Grid item xs={0.25}>
            </Grid>
        </Grid>
        <br></br>
        </div>
        <UpdateCheck />
              </div>
        )

    }
}
