import { useParams } from "react-router-dom";
import {useEffect, useState} from 'react'
import { useOktaAuth } from '@okta/okta-react';

import Box from "@mui/material/Box";

import OrderGrid from "../components/OrderGrid" 

import LoadingComponent from "../components/LoadingComponent";
import { getAnalytics, logEvent } from "firebase/analytics";
import UpdateCheck from "../components/UpdateCheck";




export const OrderPage = () => {
  const { authState, oktaAuth } = useOktaAuth();

  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState('');
  const {orderId} = useParams();

    



  useEffect(() => {
    const checkTokenAndLogEvent = async () => {
      if (!authState || !authState.isAuthenticated) {
        // Handle the case when the user is not authenticated
      } else {
        try {
          let accessToken = authState.accessToken;
  
          // Check if the token is expired and refresh it if necessary
          if (oktaAuth.tokenManager.hasExpired(accessToken)) {
            accessToken = await oktaAuth.tokenManager.renew('accessToken');
          }
  
          // Set the token
          setToken(accessToken.accessToken);
  
          // Additional logic for when the token is available
          if (accessToken.accessToken) {
            setLoading(false);
            const analytics = getAnalytics();
            logEvent(analytics, 'page_view', { email: authState.idToken.claims.email, page_title: 'Order' });
          }
  
        } catch (error) {
          console.error('Error with authentication token: ', error);
          // Handle errors such as token renewal issues, etc.
        }
      }
    };
  
    checkTokenAndLogEvent();
  }, [authState, oktaAuth]);

            if (loading) {
              return (    
        <LoadingComponent />
        )
                } else {
        return (    
          <Box sx={{ backgroundColor: '#fafafa' }}>
    
           <OrderGrid orderId={orderId} token={token} />
           <UpdateCheck />
           </Box>
        )
        }
      
}