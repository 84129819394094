//import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import App from "./App";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const theme = createTheme({
  palette: {
    //primary: { main: "#3a34d2" }
    primary: { main: "#000ac2" }

  }
});

root.render(
    <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
    </BrowserRouter>
);
