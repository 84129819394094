import {useEffect, useState} from 'react'
import { useOktaAuth } from '@okta/okta-react';

import Box from "@mui/material/Box";

import SponsorshipGrid from "../components/SponsorshipGrid"
import LoadingComponent from "../components/LoadingComponent";
import UpdateCheck from '../components/UpdateCheck';




export const SponsorshipPoliticalPage = () => {
  const { authState, oktaAuth } = useOktaAuth();

  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState('');

    



  useEffect(() => {
    const checkToken = async () => {
      if (!authState || !authState.isAuthenticated) {
        // Handle the case when the user is not authenticated
      } else {
        try {
          let accessToken = authState.accessToken;
  
          // Check if the token is expired and refresh it if necessary
          if (oktaAuth.tokenManager.hasExpired(accessToken)) {
            accessToken = await oktaAuth.tokenManager.renew('accessToken');
          }
  
          setToken(accessToken.accessToken);
          setLoading(false);
  
        } catch (error) {
          console.error('Error with token check: ', error);
          // Handle errors such as token renewal issues, etc.
        }
      }
    };
  
    checkToken();
  }, [authState, oktaAuth]); // Dependencies for useEffect

            if (loading) {
              return (    
        <LoadingComponent />
        )
                } else {
        return (    
          <Box sx={{ backgroundColor: '#fafafa' }}>
    
           <SponsorshipGrid token={token} sponsorships="political"/>
           <UpdateCheck />
           </Box>
        )
        }
      
}