import React, {useEffect, useState} from 'react'
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import YieldPieChart from "../components/YieldPieChart";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import LaunchIcon from '@mui/icons-material/Launch';
import IconButton from '@mui/material/IconButton';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import { useOktaAuth } from '@okta/okta-react';
import LoadingComponent from "../components/LoadingComponent";
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { useNavigate, createSearchParams } from "react-router-dom";

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import TableChartIcon from '@mui/icons-material/TableChart';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import YieldRotationsRiskTable from '../components/YieldRotationsRiskTable';

import YieldDisplayAdvertiserRiskTable from '../components/YieldDisplayAdvertiserRiskTable';
import UpdateCheck from '../components/UpdateCheck';





const columnsTopLines = [
  {
    field: 'ad_server_url',
    headerName: '',
    flex:0.1,
    minWidth: 30,
    align: 'center',
    renderCell: (params) => {
    if (params.value === 'N/A') {
      return '';
    } else {
      return ( <Tooltip TransitionComponent={Zoom} title="View in ad server">
      <IconButton href={params.value} target="_blank" sx={{color: '#000000', marginBottom:1}}><LaunchIcon fontSize="small" /></IconButton>
      </Tooltip>)
    }
   
    }
  },
  {
    field: 'order_id',
    headerName: 'Order ID',
    flex:0.4,
    minWidth: 70,
    align: 'left',
    renderCell: (params) => 
    <Link style={{ textDecoration: 'none' }} to={`/dashboard/${params.row.salesperson_id}/order/${params.row.order_id}`}> <Button size="small" variant="contained">{params.row.order_id}</Button></Link>
  //  <a href={params.row.sales_order_line_item_id}>{params.row.sales_order_line_item_id}</a>,
    
  },

  {
    field: 'sales_order_line_item_id',
    headerName: 'Line ID',
    flex:0.4,
    minWidth: 80,
    align: 'left',
    renderCell: (params) => 
    <Link style={{ textDecoration: 'none' }} to={`/dashboard/${params.row.salesperson_id}/order/${params.row.order_id}/line/${params.row.sales_order_line_item_id}`}> <Button size="small" variant="contained">{params.row.sales_order_line_item_id}</Button></Link>
  //  <a href={params.row.sales_order_line_item_id}>{params.row.sales_order_line_item_id}</a>,
    
  },
  {
    field: 'advertiser_nm',
    headerName: 'Advertiser',
    flex:0.7,
    minWidth: 100,
  },
  {
    field: 'line_item_nm',
    headerName: 'Line Name',
    flex:1,
    minWidth: 400,
  },
  {
    field: 'line_status',
    headerName: 'Status',
    flex:0.6,
    minWidth: 100,
  },
  {
  field: 'op_line_start_dt',
  headerName: 'Start Date',
  flex:0.6,
  minWidth: 100,
  /*
  renderCell: params => {
    const start_dt = new Date(params.row.op_line_start_dt);
    const formattedDate = formatStartEnd(start_dt);
    return <div>{formattedDate}</div>;
    }*/
},
{
  field: 'op_line_end_dt',
  headerName: 'End Date',
  flex:0.6,
  minWidth: 100,
  /*
  renderCell: params => {
    const end_dt = new Date(params.row.op_line_end_dt);
    const formattedDate = formatStartEnd(end_dt);
    return <div>{formattedDate}</div>;
    }*/
  },


{
  field: 'rev_at_risk_amt',
  headerName: 'Rev at Risk',
  flex:0.7,
  minWidth: 100,
  type: 'number',
 
  valueFormatter: ({ value }) => priorityFormater(value),
},

{
  field: 'risk_amt',
  headerName: 'Real Risk',
  flex:0.7,
  minWidth: 100,
  type: 'number',
 
  valueFormatter: ({ value }) => priorityFormater(value),
},
{
  field: 'comment',
  headerName: 'Comment',
  flex:1,
  minWidth: 150,
 
},
]


const priorityFormater = (cell) => {
  if (cell == null){
    return ""
  } else {
  let cell_value = Math.round(cell)?.toLocaleString()
  if (cell_value.includes('-')){
    cell_value = cell_value.replace('-', '');
    cell_value = "-"+"$"+cell_value
    return cell_value
  } else {
    return "$"+cell_value?.toLocaleString()
  }
  }

};

function getStartOfQuarter() {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentQuarter = Math.floor(currentMonth / 3);
  const startOfQuarter = new Date(currentDate.getFullYear(), currentQuarter * 3, 1);

  return startOfQuarter.toISOString().slice(0, 10);
}


export const YieldDisplayPage = () => {

  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [rotationsPieData, setRotationsPieData] = useState([])
  const [rotationsChartData, setRotationsChartData] = useState([])

  const [advertiserPieData, setAdvertiserPieData] = useState([])
  const [advertiserChartData, setAdvertiserChartData] = useState([])


  const [pocData, setPocData] = useState([])


  const [lineData, setLineData] = useState([])
  const [topLevelRisk, setTopLevelRisk] = useState([])

  const [loading, setLoading] = useState(true)

  const [productChartToggle, setProductChartToggle] = useState('table')

  const [advertiserChartToggle, setAdvertiserChartToggle] = useState('pie')
 
  const quarterStart = getStartOfQuarter();



  const navigate = useNavigate();

  const handleAEPOC = (display_ae) =>
    navigate({
      pathname: '/yield/risk',
      search: `?${createSearchParams(display_ae)}`,
    });
  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true
    //  renderCell: (params) => 
      /*
      <Link style={{ textDecoration: 'none' }} to={`order/${params.row.order_id}`}>      <Button size="small" variant="contained">
      {params.row.order_id}</Button></Link>
  */
    },
    {
      field: 'primary_salesperson_nm',
      headerName: 'Salesperson',
      flex:1,
        minWidth: 100,
        renderCell: (params)=>
<Button onClick={() => handleAEPOC({display_ae: params.row.primary_salesperson_nm})}>
          {params.row.primary_salesperson_nm}</Button>
  
    },
  
  
  {
    field: 'straight_line_risk',
    headerName: 'Risk',
    flex:0.5,
    minWidth: 100,
      type: 'number',
  
    valueFormatter: ({ value }) => priorityFormater(value),
  },
  
  {
    field: 'risk_amt',
    headerName: 'Real Risk',
     flex:0.5,
        minWidth: 100,  type: 'number',
  
    valueFormatter: ({ value }) => priorityFormater(value),
  },
  
  ];

  const handleChartToggle = (event, newChartType) => {
    setProductChartToggle(newChartType);
  };

  const handleAdvertiserChartToggle = (event, newChartType) => {
    setAdvertiserChartToggle(newChartType);
  };

    useEffect(() => {
      const fetchData = async () => {
        if (!authState || !authState.isAuthenticated) {
          // When user isn't authenticated, forget any user info
          setUserInfo(null);
        } else {
          try {
            let accessToken = authState.accessToken;
    
            // Check if the token is expired and refresh it if necessary
            if (oktaAuth.tokenManager.hasExpired(accessToken)) {
              accessToken = await oktaAuth.tokenManager.renew('accessToken');
            }
    
            const options = {
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
              }
            };
    
            const response = await fetch(`/yielddisplaydashboard?quarterStart=${quarterStart}`, options);
            const json = await response.json();

                setPocData(json.poc_data)
                setLineData(json.line_data)
                setTopLevelRisk(json.total_risk)
                let tmpPieData = []
                let tmpChartData = []
                for (let i = 0; i < json.product_data.length; i++){
                  let chartLine = {"id": json.product_data[i].id, 
                  "rotation_sold": json.product_data[i].product_sold, 
                  "real_risk":json.product_data[i].risk_amt, 
                  "straight_line_risk": json.product_data[i].straight_line_risk}
                  tmpChartData.push(chartLine)

                  let pieLine = {"id": json.product_data[i].id, "label": json.product_data[i].product_sold, "real_risk":json.product_data[i].risk_amt, 
                 "value": Math.round(Math.abs(json.product_data[i].straight_line_risk)), "index": json.product_data[i].id}
                  tmpPieData.push(pieLine)
                }


                let tmpAdvertiserPieData = []
                let tmpAdvertiserChartData = []
                for (let i = 0; i < json.advertiser_data.length; i++){

                  let chartLine = {"id": json.advertiser_data[i].id, 
                  "advertiser_nm": json.advertiser_data[i].advertiser_nm, 
                  "real_risk":json.advertiser_data[i].risk_amt, 
                  "straight_line_risk": json.advertiser_data[i].straight_line_risk}
                  tmpAdvertiserChartData.push(chartLine)

                  let pieLine = {"id": json.advertiser_data[i].id, 
                  "label": json.advertiser_data[i].advertiser_nm, 
                  "real_risk":json.advertiser_data[i].risk_amt, 
                 "value": Math.round(Math.abs(json.advertiser_data[i].straight_line_risk)), 
                 "index": json.advertiser_data[i].id}
                  tmpAdvertiserPieData.push(pieLine)
                }




              setRotationsChartData(tmpChartData)
             setRotationsPieData(tmpPieData)

             setAdvertiserChartData(tmpAdvertiserChartData)
             setAdvertiserPieData(tmpAdvertiserPieData)

             setLoading(false)

           // }))
            
          } catch (error) {
            console.error('Error fetching data: ', error);
            // Handle errors such as network issues, parsing issues, etc.
          }
        }
      };
    
      fetchData();
    }, [authState, oktaAuth, quarterStart]); // Include all used variables in the dependency array
    

    if (loading) {
      return (    
        <LoadingComponent />
        )
                } else {
    return (
      <Box sx={{ backgroundColor: '#fafafa' }}>
      <Grid container rowSpacing={0} alignItems="center" columnSpacing={{ xs: 0}} sx={{borderBottom: '1px solid #DBDCDC',
backgroundColor: '#ffffff', paddingTop: '1px', paddingBottom:'1px'}} >
<Grid item xs={.25}>
</Grid>
<Grid item xs={9.75}><Typography variant="h4" marginLeft={0}>
Display Overview</Typography>
</Grid>
<Grid item xs={1.75} textAlign='right'>
</Grid>
<Grid item xs={.25}>
</Grid>
</Grid>
<br></br>
<Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >

<Grid item xs={.25}></Grid>
<Grid item xs={.05} sx={{borderTop: '1px solid #DBDCDC',borderLeft: '1px solid #DBDCDC', borderBottom: '1px solid #DBDCDC',
        backgroundColor: '#000ac2', boxShadow: 1}}></Grid>

<Grid item xs={3.62} sx={{borderTop: '1px solid #DBDCDC',borderBottom: '1px solid #DBDCDC',borderRight: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>

<Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >
<Grid item xs={12}>
<Typography variant="h6" marginTop={0} marginLeft={1} textAlign='left'>

Active Revenue
{/*/ Active campaigns / Active revenue / active risk /
 percentage of total  at risk 
        */}
</Typography>
</Grid>
<Grid item xs={12}>
  <Typography variant="h4" marginTop={0} marginLeft={0} textAlign='center' gutterBottom>

${Math.round(topLevelRisk.running_revenue).toLocaleString()}
</Typography>
</Grid>



</Grid>
</Grid>




<Grid item xs={.25}></Grid>
<Grid item xs={.05} sx={{borderTop: '1px solid #DBDCDC',borderLeft: '1px solid #DBDCDC', borderBottom: '1px solid #DBDCDC',
        backgroundColor: '#000ac2', boxShadow: 1}}></Grid>

<Grid item xs={3.62} sx={{borderTop: '1px solid #DBDCDC',borderBottom: '1px solid #DBDCDC',borderRight: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>

<Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >
<Grid item xs={12}>
<Typography variant="h6" marginTop={0} marginLeft={1} textAlign='left'>

Straight Line Risk
{/*/ Active campaigns / Active revenue / active risk /
 percentage of total  at risk 
        */}
</Typography>
</Grid>
<Grid item xs={12}>
  <Typography variant="h4" marginTop={0} marginLeft={0} textAlign='center' gutterBottom>

  {topLevelRisk.total_risk}
</Typography>
</Grid>


</Grid>
</Grid>

<Grid item xs={.25}></Grid>
<Grid item xs={.05} sx={{borderTop: '1px solid #DBDCDC',borderLeft: '1px solid #DBDCDC', borderBottom: '1px solid #DBDCDC',
        backgroundColor: '#000ac2', boxShadow: 1}}></Grid>

<Grid item xs={3.62} sx={{borderTop: '1px solid #DBDCDC',borderBottom: '1px solid #DBDCDC',borderRight: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>

<Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >
<Grid item xs={12}>
<Typography variant="h6" marginTop={0} marginLeft={1} textAlign='left'>

Real Risk
{/*/ Active campaigns / Active revenue / active risk /
 percentage of total  at risk 
        */}
</Typography>
</Grid>
<Grid item xs={12}>
  <Typography variant="h4" marginTop={0} marginLeft={0} textAlign='center' gutterBottom>

  {topLevelRisk.real_risk}
</Typography>
</Grid>


</Grid>
</Grid>

</Grid>
<br></br>
<Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >
  <Grid item xs={.25}></Grid>
  <Grid item xs={.05} sx={{borderTop: '1px solid #DBDCDC',borderLeft: '1px solid #DBDCDC', borderBottom: '1px solid #DBDCDC',
        backgroundColor: '#000ac2', boxShadow: 1}}></Grid>
  <Grid item xs={3.62} sx={{border: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>
            <Typography variant="h6" marginTop={1} marginLeft={1}>
Risk by Salesperson</Typography>
<DataGrid 
        rows={pocData}
        columns={columns}
      //  loading={loading}
      disableColumnMenu={true}
      disableSelectionOnClick
      autoHeight={true}

        pageSize={10}
        rowsPerPageOptions={[10]}
        rowHeight={25}
              //  hideFooter={true}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'straight_line_risk', sort: 'asc' }],
                },
              }}
        sx={{
          
          border: 0,
        }}

    />
            </Grid>

            <Grid item xs={.25}></Grid>
  <Grid item xs={.05} sx={{borderTop: '1px solid #DBDCDC',borderLeft: '1px solid #DBDCDC', borderBottom: '1px solid #DBDCDC',
        backgroundColor: '#000ac2', boxShadow: 1}}></Grid>
  <Grid item xs={3.62} sx={{border: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>
          <Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >
<Grid item xs={9}>
            <Typography variant="h6" marginTop={1} marginLeft={1}>
Risk by Product Category</Typography>
</Grid>
<Grid item xs={3} marginTop={1} align='center'>
<ToggleButtonGroup
  color="primary"

      value={productChartToggle}
      exclusive
      onChange={handleChartToggle}
      aria-label="chart"
      size='small'
    >
      <ToggleButton value="table" aria-label="table">
        <TableChartIcon />
      </ToggleButton>
      <ToggleButton value="pie" aria-label="pie">
        <DonutSmallIcon />
      </ToggleButton>
      
    </ToggleButtonGroup>
    </Grid>
    <Grid item xs={12}>
    {productChartToggle==='table' &&
    <YieldRotationsRiskTable tableData={rotationsChartData} pageType={'display_product'} />}
    
    {productChartToggle ==='pie' && 
    <YieldPieChart pieData={rotationsPieData} pageType={'display_product'}/>

    }
</Grid>
            </Grid>
</Grid>

            <Grid item xs={.25}></Grid>
  <Grid item xs={.05} sx={{borderTop: '1px solid #DBDCDC',borderLeft: '1px solid #DBDCDC', borderBottom: '1px solid #DBDCDC',
        backgroundColor: '#000ac2', boxShadow: 1}}></Grid>
  <Grid item xs={3.62} sx={{border: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>
          <Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >
<Grid item xs={9}>
            <Typography variant="h6" marginTop={1} marginLeft={1}>
Risk by Advertiser</Typography>
</Grid>
<Grid item xs={3} marginTop={1} align='center'>
<ToggleButtonGroup
  color="primary"

      value={advertiserChartToggle}
      exclusive
      onChange={handleAdvertiserChartToggle}
      aria-label="chart"
      size='small'
    >
      <ToggleButton value="table" aria-label="table">
        <TableChartIcon />
      </ToggleButton>
      <ToggleButton value="pie" aria-label="pie">
        <DonutSmallIcon />
      </ToggleButton>
      
    </ToggleButtonGroup>
    </Grid>
    <Grid item xs={12}>
    {advertiserChartToggle==='table' &&
    <YieldDisplayAdvertiserRiskTable tableData={advertiserChartData} />}
    
    {advertiserChartToggle ==='pie' && 
    <YieldPieChart pieData={advertiserPieData} pageType={'display_advertiser'} />

    }
</Grid>
            </Grid>

            </Grid>


            </Grid>

            <br></br>
<Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >
  <Grid item xs={.25}></Grid>
  <Grid item xs={.05} sx={{borderTop: '1px solid #DBDCDC',borderLeft: '1px solid #DBDCDC', borderBottom: '1px solid #DBDCDC',
        backgroundColor: '#000ac2', boxShadow: 1}}></Grid>
  <Grid item xs={11.45} sx={{border: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>
            <Typography variant="h6" marginTop={1} marginLeft={1}>
Top Lines at Risk</Typography>
<DataGrid 
autoHeight
        rows={lineData}
        columns={columnsTopLines}
      //  loading={loading}
      disableColumnMenu={true}
      disableSelectionOnClick
      getRowId={(row) => row.sales_order_line_item_id}

      getRowHeight={() => 'auto'}

       // rowHeight={25}
                hideFooter={true}
                
        sx={{
          
          border: 0,
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        }}

    />
</Grid>
<Grid item xs={.25}></Grid>

</Grid>
<UpdateCheck />
<br></br>
</Box>
    )

      }
}