import React, { useEffect, useState } from 'react'

import "../styles.css";
import Grid from "@mui/material/Grid";

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import Typography from '@mui/material/Typography';


const TopItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    paddingTop: theme.spacing(0),
    textAlign: 'left',
    color: 'black',
    fontWeight: 'bold',
    lineHeight:'0em'
  }));

  const BottomItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    paddingBottom: theme.spacing(0),
    textAlign: 'left',
    lineHeight:'0em',
    
    color: theme.palette.text.secondary,
  }));

  function RiskComponent({topLevelRiskData }){

    return (

        <Box marginLeft={2}>

<Grid container rowSpacing={0} columnSpacing={{ xs: 0}} >
<Grid item xs={12}>
<Typography variant="h5" marginTop={1} gutterBottom>
Risk Snapshot</Typography>

</Grid>
<Grid item xs={12}>
<Grid item xs={12}>
                <BottomItem elevation={0}>
                <Typography variant="body2">
    
            Active Risk
            </Typography>
            </BottomItem>
            </Grid>
<TopItem elevation={0}>

<Typography variant="h5" gutterBottom>
{topLevelRiskData.active_straight_line_risk_adj}</Typography>
            </TopItem>
            <br></br>

            </Grid>
            <Grid item xs={12}>
                <BottomItem elevation={0}>
                <Typography variant="body2">

                Active Running Revenue
            </Typography>
            </BottomItem>
            </Grid>

            <Grid item xs={12}>
<TopItem elevation={0}>
<Typography variant="h5" gutterBottom>

           {topLevelRiskData.active_revenue_amt}
            </Typography>
            </TopItem>
            <br></br>
            </Grid>



            <Grid item xs={12}>
                <BottomItem elevation={0}>
                <Typography variant="body2">

                Active Campaigns
                </Typography>
            
            </BottomItem>
            </Grid>
            <Grid item xs={12}>
<TopItem elevation={0}>

<Typography variant="h5" gutterBottom>
{topLevelRiskData.campaign_number}</Typography>
            </TopItem>
            </Grid>



</Grid>

<br></br>                <br></br>

            </Box>
    )


  }


    
    export default RiskComponent