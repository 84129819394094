import React, { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react';
import { ResponsiveBar } from '@nivo/bar'
import { Chip } from "@nivo/tooltip";
import { ThemeProvider } from '@nivo/core'
import { useNavigate } from "react-router-dom";


const theme = {
    tooltip: {
      tableCell: {
        background: 'red',
      }
    }
  }
  


  function DashboardBarGraph({barData, keyData }){

    //const { token } = useAuth();
    const { authState, oktaAuth } = useOktaAuth();
    const [data, setData] = useState([])
    const [keys, setKeys] = useState([])
    const [loading, setLoading] = useState(true)

  

    let navigate = useNavigate(); 
    const routeChange = (id) =>{ 
        const numericalId = id.split('-')[0];
      let path = 'order/'+numericalId; 
      navigate(path);
    }


    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
      }
    useEffect(() => {

        delay(250).then(() => 
        {
            setData(barData)
            setKeys(keyData)
            setLoading(false)

        });
      }, [])

      const getColor = (bar) => {
        // Assuming 'keys' contains the unique key values for the bars
        const keyIndex = keyData.indexOf(bar.id);
       // console.log(keyIndex)
       const parts = bar.id.split("-");
       const order_id = parts[0]
       const colorScheme = [
        '#5955FF', '#494873', '#A6A4FE', '#130EFC', '#242339', '#7D7AFF', '#312DFF',
        '#8E8CFF', '#7B79A3', '#C4C2FF', '#1E1BE0', '#BEBDFC', '#9999FF', '#4D4BFF',
        '#3D3BCC', '#37364C', '#7C7AFE', '#0D0CDB', '#7676D2', '#5C5CFF', '#2927CC',
      ];
        return colorScheme[order_id % colorScheme.length];
      };
      const formatAxisLeft = (value) => `$${Number(value).toLocaleString()}`;


// Ensure data is defined and is an array
const isValidData = Array.isArray(data) && data.length > 0;

// Extracting unique day_dt values
const uniqueDays = isValidData ? [...new Set(data.map(item => item.day_dt))] : [];

// Select every other day
const everyOtherDay = uniqueDays.filter((_, index) => index % 2 === 0);

      /*
      const formatter = d3.timeFormat('%d %b');
      const timeScaleTicks = useMemo(() => {
        const scale = d3.scaleTime().domain([from, to]);
        const ticks = scale.ticks(10);
        return ticks.map(tick => (
          formatter(tick)
        ));
      }, [from, to]);
*/



//console.log(salespersonId)
if ((!data || data.length == 0) && loading == false ){
  console.log(data)
    return(
        <p style={{marginLeft:'15px'}}>No risk found over the last 14 days</p>
    )
} else {

return(
    <div style={{height:'320px'}}>
<ThemeProvider theme={theme}>

<ResponsiveBar
        data={data}
        keys={keys}        
        indexBy="day_dt"
        margin={{ top: 10, right: 5, bottom: 50, left: 70 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={getColor}
        layout={'vertical'}
       

        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 32,
            tickValues: everyOtherDay,
            
        }}
        axisLeft={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: -0,
            format:formatAxisLeft,
            tickValues:6,
        }}
            //    enableGridY={false}
            enableLabel={false}

        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}


        motionConfig="wobbly"
        tooltip={({ id, color, value }) => (
            <div
              style={{
                padding: 10,
                border: '1px solid #DBDCDC',
                background: '#ffffff',
                display: 'flex', // Use flex display to keep the elements on the same line
                alignItems: 'left', // Align the items vertically in the center
                flexDirection: 'column', // Arrange the items in a column
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Chip color={color}></Chip>
                <span style={{ marginLeft: 8 }}>{id}</span>
              </div>
              <div>${value.toLocaleString()}</div>
            </div>
          )}

          onClick={(data) => {

            routeChange(data.id)

        }}

       
    />

</ThemeProvider>
</div>

)
}      



  }





    
    export default DashboardBarGraph