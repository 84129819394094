import Box from '@mui/material/Box';
import React from 'react'

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from "@mui/material/Grid";





function OptimizationGuidelinesYield() {





    return (  

<div>

      
        <Box>
        <Typography variant="h4" fontWeight={'bold'} gutterBottom>
        Yield Optimization Guidelines
      </Typography>
      <Divider />
<br></br>
      <Typography
        id="introduction"
        variant="h6" gutterBottom>
        INTRODUCTION
      </Typography>
      <Divider />

      <Typography paragraph gutterBottom>
      Welcome to the Yield Optimization Guidelines. Our top priority is to ensure that guaranteed campaigns deliver in full. 
      This guide is designed to offer you the best practices and strategies for optimizing campaign delivery. While these guidelines strive to encompass as much as possible, 
      please keep in mind that this guide is not meant to be all-encompassing and should be regarded as a flexible resource that can be tailored to meet individual campaign requirements.
      </Typography>
      <br></br>
      <Typography id="common-speed-bumps" variant="h6" gutterBottom>
        COMMON SPEED BUMPS
      </Typography>
      
      <Divider />
      <Typography variant='body' gutterBottom>
        
      <b>GROSS IMPRESSION CAP </b>
      <p>For demo guaranteed campaigns, check that the gross impression cap (GIC) is set correctly. The GIC should be set to 10x the on-target impression goal, regardless of the demo.
        While the GIC is not the goal that Freewheel will deliver to, it does act essentially as an emergency brake in case the actual demo comp is significantly lower than what we based pricing on. 
        Currently we do not use this for its intended purpose so to prevent lines from hitting this cap, we set it to 10x the on-target impression goal.
        </p>  
        <Grid container rowSpacing={0} columnSpacing={{ xs: 1}}>
          
          <Grid item xs={6}>
          In Freewheel, the GIC can be found on the placement level under the budget section: 

            </Grid>
            <Grid item xs={6}>
          In Rev at Risk, the GIC and minimum needed GIC based on the current demo comp can be found on the line level: 

            </Grid>
            </Grid>
        <Grid container rowSpacing={0} columnSpacing={{ xs: 1}}alignItems="center">
          
  <Grid item xs={6}>
  <img src="/fw_gic.png"></img>
    </Grid>
    <Grid item xs={6}>
  <img src="/rr_gic.png"></img>
    </Grid>
    </Grid>
        
<Box sx={{
    backgroundColor: '#CBC3E3', borderRadius: '10px', padding:'10px'}}>
  <p> <b>Example</b><br></br>
        If an advertiser bought 1,000,000 A2549 impressions and the 
        in demo comp (% of total impressions landing on users A2549) is 50%, the ad server will need to deliver 2,000,000 impressions overall to deliver 1,000,000 impressions to users A2549 and deliver in full. 
        At a minimum the GIC would need to be set to 2,000,000 impressions in order to prevent the line from capping out but in order to be safe the GIC should be set to 10x the goal or in this case
        10,000,000 impressions. 
        </p>
        </Box>
        <br></br> <br></br>
      <b>COVIEWING</b>
      <p>Check if coviewing is applied or not applied correctly. Lines set up with coviewing will have a Budget Model in Freewheel set to Demographic Impression Target 
        and the P2+ target will be checked. Lines not set up with coviewing will have a Budget Model set to Impression Target. </p>

        <br></br> <br></br>
      <b>DARK DATES</b>
      <p>Dark dates are specific dates or date ranges within a campaign flight during which no impressions are scheduled to run. Sales Associates should note in the line name if a line has 
        dark dates to make it easier to identify those lines. Make sure Dark Dates have been communicated and are being implemented by Ad Ops.  Dark dates in Freewheel are implemented
        as custom pacing curves:</p>
        <img src="/rr_dark.png"></img>

        <br></br> <br></br>
      <b>DISCREPANCY</b>
      <p>A slight discrepancy between our ad server (Freewheel or Google Ad Manager depending on the type of line) and the client's third party ad server is common. In most situations
        the campaign is being billed off of the third party ad server so if there is a discrepancy where the third party impression total is less than our ad server total, a buffer will 
        need to be applied to cover the discrepancy between ad servers. Without a buffer, the line will underpace and eventually under deliver. Video lines with a discrepancy under 5%
        automatically have the over delivery % increased.
      </p>
  <p>
          In Rev at Risk, the Discrepancy, Needed Buffer, and Current Buffer are under the line level in the Discrepancy & Gross Impression Cap section. These data points lag by
          1 day so if the current buffer was adjusted today, the updated value will not be reflected in Rev at Risk until the next day. We can and should always confirm the current 
          buffer by looking at the Over Delivery section in Freewheel. 
          </p>

            <br></br>
        <Grid container rowSpacing={0} columnSpacing={{ xs: 1}}alignItems="center">
          
  <Grid item xs={6}>
  <img src="/rr_gic.png"></img>
    </Grid>
    <Grid item xs={6}>
  <img src="/fw_od.png"></img>
    </Grid>
    </Grid>
    <br></br>
    <Box sx={{
    backgroundColor: '#CBC3E3', borderRadius: '10px', padding:'10px'}}>
      
  <p> <b>Example</b><br></br>
       In the screenshots above, if the over delivery percentage wasn't increased, this line would under deliver by $1,082.54 from discrepancy alone. Overall it could UD by more if
       there are also capacity constraints. While this might seem minimal, a lot of lines with a small amount of under delivery due to discrepancy adds up.  Additionally 
       even minimal under delivery can impact client relationships. In the example above, the current buffer in Rev at Risk says 0.30% but in Freewheel it says 0.40%. This is due to 
       a lag in data. Rev at Risk looks at the state through yesterday but the Freewheel UI is real-time so in this case, the over delivery % was updated to 0.40% today and will be 
       reflected within Rev at Risk when the data is updated tomorrow.
        </p>
        </Box>
        <p>Discrepancies above 5% will not be automatically buffered. Client Services should open a discrepancy case to investigate where the discrepancy is coming from.
      </p>
      <br></br> <br></br>
      <b>CAMPAIGN GOALS</b>
      <p>Check to make sure the Operative goal and Ad Server goal match. Lines pace to the goal set in the ad server so if the goals do not match, pacing will be off.
      </p>

      <br></br> <br></br>
      <b>Platform Targeting</b>
      <p>If the Line is supposed to be fluid and cross-platform make sure that there are no platform restrictions placed on the line. If there is platform targeting that is not applied, 
        a discrepancy could occur if the 3p reporting does not include platforms that are supposed to be excluded
      </p>
      </Typography>
      <br></br>
      <Typography id="standard-optimization-hierarchy" variant="h6" gutterBottom>
        STANDARD OPTIMIZATION HIERARCHY
      </Typography>
      
      <Divider />
      <Typography variant='body' gutterBottom>
        Before optimizing a line, consider other factors besides just the current pacing and risk.  Did the line just recently start? Will a lot of inventory be made available
        in the near future for this line due to other lines ending before it? Are we expecting a traffic spike? Did we just make a shift into this line?
        <ol>
<li>Loosen Frequency Cap</li>
<ul>
  <li>If custom FCAP then with client approval set to standard 1x/30min</li>
  <li>If already at standard FCAP, set to 1x/15 min</li>
</ul>

<li>Opt-Out of Unified Yield</li>
<li>Increase Priority</li>
<ul>
  <li>This should be a last resort</li>
  <li>Please review the <b>Priority Increase Guidelines</b> before increasing priority </li>
</ul>
        </ol>
        </Typography>
      <br></br>
      <Typography id="priority-increase-guidelines" variant="h6" gutterBottom>
        PRIORITY INCREASE GUIDELINES
      </Typography>
      
      <Divider />
      <Typography variant='body' gutterBottom>
        <ul>
          <li>Take into account how increasing the priority will impact other lines</li>
          <ul>
            <li>When the priority is increased, 
              it is us telling the ad server this line is more important than other lines so this action can cause other lines at the standard priority to underdeliver</li>
              <li>The more targeted a line is the less of an impact a priority increase has on the rest of the lines running</li>
              <li>Lines with a flight ending soon should be given more consideration than lines ending further out in the future</li>
          </ul>
          <li>Consider the sell-thru rate of the inventory the line is targeting</li>
          <ul>
            <li>Certain bundles and/or specific types of inventory can become oversold and strategic decisions need to be made on what lines should be 
              prioritized and what lines should be allowed to underdeliver</li>
          </ul>
          <li>Never increase priority on standard EyeQ RON lines or non-guaranteed lines</li>
          <ul>
            <li>This has impacts across the full portfolio and causes more targeted and higher CPM EyeQ bundles to underdeliver</li>
          </ul>
        </ul>
        </Typography>

        <br></br>
      <Typography id="special-cases" variant="h6" gutterBottom>
        SPECIAL CASES
      </Typography>
      <Divider />
      <p>
      <b>EYEQ BET</b>
      </p>
      <ul>
        <li>Shift a small amount to the YouTube line if there is one</li>
        <li>If EyeQ BET (Data + ROS), Shifts into the better-performing line</li>
        <li>Requires sales approval: Include BET Pluto; Vh1/Vh1 Pluto</li>
        <li>Increase Priority</li>
        <ul>
          <li>This Starts P16 to start to not be out-prioritized by non-BET lines, the max this can go is P18</li>
        </ul>
      </ul>
      <br></br>
      <p>
      <b>EYEQ VANTAGE</b>
      </p>
      <ul>
        <li>Check If Segments are correctly applied</li>
        <li>Check if custom data segments have been refreshed</li>
        <li>Follow the Standard Optimization Hierarchy </li>
        <li>Open up to other segments</li>
      </ul>
      <br></br>
      <p>
      <b>EYEQ LATINO (DATA + CONTEXTUAL)</b>
      </p>
      <ul>
        <li>Shift between data/contextual lines</li>
      </ul>

      <br></br>
      <p>
      <b>LIVE SPORTS</b>
      </p>
      <ul>
        <li>Check sports schedule</li>
        <ul>
          <li>In-game live streaming lines only run in actual games so pacing will 
            fluctuate and is not indicative of how the line will end up delivering at the end of the campaign</li>
            <li>Actual risk and pacing need to be calculated manually by checking to see if there is enough
               inventory for them to deliver in full based on the forecasted delivery in the remaining games they are scheduled to run in</li>
        </ul>
        <li>Check Creative Schedule</li>
        <ul>
          <li>The Creative could be in a dark date within the creative schedule</li>
        </ul>
        <li>Check for delivery in the previous game</li>
        <ul>
          <li>There are a variety of reasons why a line 
            might not have delivered any impressions in the previous game but it is almost always due to an issue with the line and needs to be investigated</li>
        </ul>
        <li>Check priority</li>
        <ul>
          <li>In-game live streaming lines have a default priority of 19 so anything below that will not get little to no delivery</li>
        </ul>
        <li>Check frequency cap</li>
        <ul>
          <li>In-game NFL, College Football, Golf, and College Basketball can be loosened to 1 per 15 minutes from the standard 1 per 30 minute FCAP</li>
          <li>In-game Soccer can be loosened to as frequent as 1 per 5 minutes if necessary due to the limited ad time during Soccer games</li>
          </ul>
      </ul>

      <br></br>
      <p>
      <b>SPONSORSHIPS</b>
      </p>
      <ul>
        <li>Shift underpacing from sponsorship lines into rotational, we can always shift back into the sponsorship line if the pacing picks up</li>
        <li>Make sure the shift to the rotational line has enough time to deliver on adjusted goals</li>
        <ul>
          <li>
          Add any optimizations needed to the rotator line if needed following the Standard Optimization Hierarchy 
          </li>
        </ul>
        </ul>
        <br></br>
      <p>
      <b>PG Campaigns</b>
      </p>
      <ul>
        <li>Check Bid Rate</li>
        <ul>
          <li>Bid rate should be at least 90%</li>
        </ul>
        <li>Follow the Standard Optimization Hierarchy</li>
      </ul>
    </Box> 
    </div>
  
        )


}

export default OptimizationGuidelinesYield