
import React, {useEffect, useState} from 'react'
import Box from "@mui/material/Box";


import Grid from "@mui/material/Grid";

import { Link } from "react-router-dom";
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import { useOktaAuth } from '@okta/okta-react';
import LoadingComponent from "../components/LoadingComponent";
import { DataGrid, gridClasses, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
//import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import LaunchIcon from '@mui/icons-material/Launch';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Stack from '@mui/material/Stack';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import UpdateCheck from '../components/UpdateCheck';






  function CustomToolbar(props) {




    return (
      <GridToolbarContainer>

<Grid container rowSpacing={0} columnSpacing={{ xs: 1}} >


<Grid item xs={12} marginTop={1} textAlign={'right'} >

<GridToolbarExport
        csvOptions={{ fileName: props.queryName + '- Risk', allColumns: true}}
        printOptions={{ disableToolbarButton: true}}/>

</Grid>

</Grid>



      </GridToolbarContainer>
    );
  }

export const YieldRiskPage = () => {
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);

    const [searchParams] = useSearchParams();

    const [orderData, setOrderData] = useState([{}])
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1); // current page number
    const [itemsPerPage, setItemsPerPage] = useState(250); // number of items per page
    const [filterModel, setFilterModel] = useState({items: []});
    const navigate = useNavigate(); // Get the navigate function from react-router-dom

    function setSigned(params) {
     // const today = moment().format('M/D');
     const currentDate = new Date();

      // Extract the month and day
      const month = currentDate.getMonth() + 1; // Months are zero-indexed, so we add 1
      const day = currentDate.getDate();

      // Format the date as 'M/D'
      const today = `${month}/${day}`;
      const comment = params.value
      const name = userInfo.name
      const updatedRow = { ...params.row, comment, comment_date: today, yield_comment_owner:name };
      return updatedRow;
    }

    function getStartOfQuarter() {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentQuarter = Math.floor(currentMonth / 3);
      const startOfQuarter = new Date(currentDate.getFullYear(), currentQuarter * 3, 1);
    
      return startOfQuarter.toISOString().slice(0, 10);
    }

    function formatDateWithTime() {
      const currentDate = new Date();
    
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Zero-padding for single-digit months
      const day = String(currentDate.getDate()).padStart(2, '0'); // Zero-padding for single-digit days
    
      const hours = String(currentDate.getHours()).padStart(2, '0'); // Zero-padding for single-digit hours
      const minutes = String(currentDate.getMinutes()).padStart(2, '0'); // Zero-padding for single-digit minutes
      const seconds = String(currentDate.getSeconds()).padStart(2, '0'); // Zero-padding for single-digit seconds
    
      const formattedDate = `${month}/${day}/${year}`;
      const formattedTime = `${hours}:${minutes}:${seconds}`;
    
      return `${formattedDate} ${formattedTime}`;
    }


    // Your date format function
    const formatStartEnd = (date) => {
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'UTC'
      };
        return date.toLocaleDateString(undefined, options);
    };


    const columns = [
      {
          field: 'ad_server_url',
          headerName: '',
          flex:1,
          minWidth: 40,
          align: 'center',
          renderCell: (params) => {
          if (params.value === 'N/A') {
            return '';
          } else {
            return ( <Tooltip TransitionComponent={Zoom} title="View in ad server">
            <IconButton href={params.value} target="_blank" sx={{color: '#000000', marginBottom:1}}><LaunchIcon fontSize="small" /></IconButton>
            </Tooltip>)
          }
         
          }
        },
  
        {
          field: 'order_id',
          headerName: 'Order ID',
          flex:0.4,
          minWidth: 70,
          align: 'left',
          renderCell: (params) => 
          <Link style={{ textDecoration: 'none' }} to={`/dashboard/${params.row.salesperson_id}/order/${params.row.order_id}`}> <Button size="small" variant="contained">{params.row.order_id}</Button></Link>
        //  <a href={params.row.sales_order_line_item_id}>{params.row.sales_order_line_item_id}</a>,
          
        },
        
      {
        field: 'sales_order_line_item_id',
        headerName: 'Line ID',
        flex:1,
        minWidth: 100,
        align: 'left',
        renderCell: (params) => {
          return(
              <Tooltip TransitionComponent={Zoom} title="Line ID">
        <Link style={{ textDecoration: 'none' }} 
        to={`/dashboard/${params.row.salesperson_id}/order/${params.row.order_id}/line/${params.row.sales_order_line_item_id}`} target="_blank" rel="noopener noreferrer"> <Button size="small" variant="contained">{params.row.sales_order_line_item_id}</Button></Link>
          </Tooltip>
        )}
      },

      {
        field: 'primary_salesperson_nm',
        headerName: 'Salesperson',
        flex:0.7,
        minWidth: 100,
      },

      {
        field: 'advertiser_nm',
        headerName: 'Advertiser',
        flex:0.7,
        minWidth: 100,
      },
      {
        field: 'line_item_nm',
        headerName: 'Line Name',
        flex:1,
        minWidth: 400,
        
        renderCell: (params) =>  (
          <Tooltip title="Line Name" > 
           <span>{params.row.line_item_nm}</span>
           </Tooltip>
         ),
      },
  
      {
        field: 'rev_at_risk_amt',
        headerName: 'Rev at Risk',
        flex:0.9,
        minWidth: 100,
        type: 'number',
        cellClassName: (params) => {
            if (params.value == null) {
              return '';
            }
      
            return clsx('super-app', {
              red: params.value < 0 ,
              green: params.value > 0,        });
          },
        valueFormatter: ({ value }) => priorityFormater(value),
      },
      {
        field: 'real_risk_amt',
        headerName: 'Real Risk',
        flex:0.9,
        minWidth: 100,
        type: 'number',
        editable: true,
  
        cellClassName: (params) => {
            if (params.value == null) {
              return '';
            }
      
            return clsx('super-app', {
              red: params.value < 0 ,
              green: params.value > 0,        });
          },
        valueFormatter: ({ value }) => priorityFormater(value),
  
  
        valueParser: (value) => {
          if(value==0){
            return 0
          } else {
          return -Math.abs(value);
          }
        },
      },
  
      {
          field: 'comment',
          headerName: 'Comment',
          flex:1,
          minWidth: 250,
          editable: true,
          valueSetter: setSigned,
          renderCell: (params) => {
            if(params.row.comment && params.row.comment_date){
              return(
              <Box>{params.row.comment}<br></br>-{params.row.comment_date} {params.row.yield_comment_owner}</Box>
              
            )
              }
              if(params.row.comment && !params.row.comment_date){
              //  const today = moment().format('M/D');
              const currentDate = new Date();

              // Extract the month and day
              const month = currentDate.getMonth() + 1; // Months are zero-indexed, so we add 1
              const day = currentDate.getDate();
        
              // Format the date as 'M/D'
              const today = `${month}/${day}`;

                return(
                  <Box>{params.row.comment}<br></br>-{today} {userInfo.name}</Box>
                  
                )
              
              }
  
  
        },
      },
/*
      {
        field: 'comment_date',
        headerName: 'comment_date',
        flex:1,
        minWidth: 250,
        editable: true,
        hidden: true
    },
  */
        
      {
      field: 'op_line_start_dt',
      headerName: 'Start Date',
      flex:1,
      minWidth: 100,
      /*
      renderCell: params => {
        const start_dt = new Date(params.row.op_line_start_dt);
        const formattedDate = formatStartEnd(start_dt);
        return <div>{formattedDate}</div>;
        }*/
    },
    {
      field: 'op_line_end_dt',
      headerName: 'End Date',
      flex:1,
      minWidth: 100,
/*
     renderCell: params => {
     const end_dt = new Date(params.row.op_line_end_dt);
     const formattedDate = formatStartEnd(end_dt);
     return <div>{formattedDate}</div>;
     }*/
      },
    {
      field: 'line_status',
      headerName: 'Status',
      flex:1,
      minWidth: 100,
    },
    {
      field: 'op_impression_goal_contracted',
      headerName: 'Goal',
      flex:1,
      minWidth: 150,
      valueFormatter: ({ value }) => value.toLocaleString(),
  
    },
  
    {
      field: 'sold_cpm_amt',
      headerName: 'CPM',
      flex:1,
      minWidth: 100,
      },
  
    {
      field: 'booked_revenue_amt',
      headerName: 'Booked Revenue',
      flex:1,
      minWidth: 150,
      type: 'number',
      valueFormatter: ({ value }) => priorityFormater(value),
  
    },
  
  
  
  
  
  
    {
      field: 'pacing',
      headerName: 'Pacing',
      flex:1,
      minWidth: 150,
      type: 'number',
      valueFormatter: ({ value }) => `${value}%`,
      cellClassName: (params) => {
          if (params.value == null) {
            return '';
          }
    
          return clsx('super-app', {
            red: params.value < 90 ,
            yellow: params.value < 98 && params.value > 90,
            green: params.value > 98,
          });
        },
    },
  
    {
      field: 'rev_pacing',
      headerName: '$ Ahead/Behind',
      flex:1,
      minWidth: 150,
      type: 'number',
      cellClassName: (params) => {
          if (params.value == null) {
            return '';
          }
    
          return clsx('super-app', {
            red: params.value < 0 ,
            green: params.value > 0,        });
        },
      valueFormatter: ({ value }) => priorityFormater(value),
    },
    
  
    {
      field: 'final_billable',
      headerName: 'Final Billable Imps',
      flex:1,
      minWidth: 150,
      valueFormatter: ({ value }) =>  Math.round(value)?.toLocaleString(),
  
    },
  
    {
      field: 'first_party_p2_delivery',
      headerName: '1P P2+ Delivery',
      width: 150,
    },
  
  
  
    {
      field: 'billable_p2_delivery',
      headerName: '3P P2+ Delivery',
      flex:1,
      minWidth: 150,
    },
  
  
  
    {
      field: 'on_target_net',
      headerName: 'Ontarget Net Delivered Impressions',
      flex:1,
      minWidth: 150,
    },
  
  
  
    
  
    {
      field: 'discrepancy_3p',
      headerName: 'Discrepancy',
      flex:1,
      minWidth: 150,
      type: 'number',
      valueFormatter: ({ value }) => `${value}%`,
      cellClassName: (params) => {
          if (params.value == null) {
            return '';
          }
    
          return clsx('super-app', {
            red: params.value < 0 
          });
        },
    },
  
   {
      field: 'value_of_discrepancy',
      headerName: 'Value of Discrepancy',
      flex:1,
      minWidth: 150,
     
    },
  
    {
      field: 'needed_buffer',
      headerName: 'Needed Buffer',
      flex:1,
      minWidth: 150,
    },
  
    {
      field: 'fw_scheduled_quantity',
      headerName: 'Current Buffer',
      flex:1,
      minWidth: 150,
    },
  
    {
      field: 'rbp_comp_revised',
      headerName: 'RBP Comp',
      flex:1,
      minWidth: 150,
    },
    {
      field: 'to_goal',
      headerName: 'Total Delivery %',
      flex:1,
      minWidth: 150,
    },
    {
      field: 'spend_to_date',
      headerName: 'Dollars Spent (uncapped)',
      flex:1,
      minWidth: 180,
    },
  
  
  
  
    ];
  
  
  
  
    const priorityFormater = (cell) => {
      if (cell === ''){
        return ""
      } else {
      let cell_value = Math.round(cell)?.toLocaleString()
      if (cell_value.includes('-')){
        cell_value = cell_value.replace('-', '');
        cell_value = "-"+"$"+cell_value
        return cell_value
      } else {
        return "$"+cell_value?.toLocaleString()
      }
      }
  
  };
  

    const [sortModel, setSortModel] = useState([
        {
          field: 'line_status',
          sort: 'asc',
        },
      ]);
      const [quickFilter, setQuickFilter] = useState();




    let query = ''
    let queryType = ''
    if(searchParams.get('poc')){
        //console.log(poc)
        queryType = 'poc'
        query = searchParams.get('poc')

    } else if (searchParams.get('product')) {
        queryType = 'product'
        query = searchParams.get('product')

    } else if (searchParams.get('holdco')) {
        queryType = 'holdco'
        query = searchParams.get('holdco')

    } else if (searchParams.get('display_ae')) {
      queryType = 'display_ae'
      query = searchParams.get('display_ae')

  } else if (searchParams.get('display_advertiser')) {
    queryType = 'display_advertiser'
    query = searchParams.get('display_advertiser')

  } else if (searchParams.get('display_product')) {
    queryType = 'display_product'
    query = searchParams.get('display_product')

  } else {

    }
    const [queryName, setQueryName] = useState(query);


  //  let quarterStart = moment().startOf('quarter').format("YYYY-MM-DD")
    const quarterStart = getStartOfQuarter();


    const handleSortChange = (
        newSortModel
      ) => {
        localStorage.setItem('yieldSort', JSON.stringify(newSortModel));
  
        setSortModel(newSortModel);
      };

      const handleToggle = (
        event
      ) => {
      //  setAlignment(event.target.value);
  
              setQuickFilter(event.target.value);
              switch(event.target.value){
                case "delivering":
                  localStorage.setItem('quickFilter', JSON.stringify(event.target.value));
  
                  setFilterModel({
                    items: [
                      {
                        columnField: 'line_status',
                        operatorValue: 'contains',
                        value: 'Delivering',
                      },
                    ],
                  })
                                  break;
                
                case "completed":
                  localStorage.setItem('quickFilter', JSON.stringify(event.target.value));
  
                  setFilterModel({
                    items: [
                      {
                        columnField: 'line_status',
                        operatorValue: 'contains',
                        value: 'Completed',
                      },
                    ],
                  })              
                    break;
                  case "future":
                    setFilterModel({
                      items: [
                        {
                          columnField: 'line_status',
                          operatorValue: 'contains',
                          value: 'Future Start',
                        },
                      ],
                    })              
                      break;
                      case "late":
                        localStorage.setItem('quickFilter', JSON.stringify(event.target.value));
  
                    setFilterModel({
                      
                      items: [
                        {
                          columnField: 'line_status',
                          operatorValue: 'contains',
                          value: 'Not Live',
                        },
                      ],
                    })              
                      break;

                      case "paused":
  
                    setFilterModel({
                      
                      items: [
                        {
                          columnField: 'line_status',
                          operatorValue: 'contains',
                          value: 'Paused',
                        },
                      ],
                    })              
                      break;
                    case "all":
                      localStorage.removeItem('quickFilter')
                      setFilterModel({items: []})
                                 
                        break;
  
                        default:
                          localStorage.removeItem('quickFilter')
  
                          setFilterModel({items: []})          
                            break;            
              }
  
       // setFilterModel(v)
      };
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const itemsToDisplay = orderData.slice(startIndex, endIndex);
    
      const totalPages = Math.ceil(orderData.length / itemsPerPage);
      const displayAE = searchParams.get('display_ae');
      const displayAdvertiser = searchParams.get('display_advertiser');
      const displayProduct = searchParams.get('display_product');

      const scrollToTop = () => {
        window.scrollTo(0, 0);
      };

      useEffect(() => {

        var quickFilter = localStorage.getItem('quickFilter')
    
        if (quickFilter !== null){
    
          quickFilter = JSON.parse(localStorage.getItem('quickFilter'))
          setQuickFilter(quickFilter)
          setFilterModel({
            items: [
              {
                columnField: 'line_status',
                operatorValue: 'contains',
                value: quickFilter,
              },
            ],
          })
        } else {
          setQuickFilter('all')
    
        }
    
    
    
        var yieldSort = localStorage.getItem('yieldSort')
    
        if (yieldSort !== null){
    
            yieldSort = JSON.parse(localStorage.getItem('yieldSort'))
          setSortModel(yieldSort)
        }
    
    
    
      }, []);

      useEffect(() => {
        const fetchData = async () => {
          if (!authState || !authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
          } else {
            try {
              let accessToken = authState.accessToken;
      
              // Check if the token is expired and refresh it if necessary
              if (oktaAuth.tokenManager.hasExpired(accessToken)) {
                accessToken = await oktaAuth.tokenManager.renew('accessToken');
              }
      
              setUserInfo(authState.idToken.claims);
              const options = {
                method: 'GET',
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + accessToken.accessToken
                }
              };
      
              const response = await fetch(`/yieldrisk?quarterStart=${quarterStart}&type=${queryType}&q=${query}`, options);
              const json = await response.json();
      
              if (json.lines === 'error') {
                console.log('error');
                navigate('/yield'); // Replace '/yield' with the path of your error page
              } else {
                setOrderData(json.lines);
                setLoading(false);
              }
      
            } catch (error) {
              console.error('Error fetching data: ', error);
              // Handle errors such as network issues, parsing issues, etc.
            }
          }
        };
      
        fetchData();
      }, [authState, oktaAuth, quarterStart, queryType, query]); // Include all used variables in the dependency array

      const handleRowEditCommit = async (params) => {
        const document_id = params.id;
        const key = params.field;
        const value = params.value;
      
        try {
          let accessToken = authState.accessToken;
      
          // Check if the token is expired and refresh it if necessary
          if (oktaAuth.tokenManager.hasExpired(accessToken)) {
            accessToken = await oktaAuth.tokenManager.renew('accessToken');
          }
      
          var options = {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + accessToken.accessToken
            }
          };
      
          let endpoint = '';
          let bodyData = {};
      
          if (key === 'comment') {
            const formattedDateTime = formatDateWithTime();
            bodyData = {
              document_id: document_id, 
              comment: value,
              comment_date: formattedDateTime, 
              yield_comment_owner: userInfo.name
            };
            endpoint = "/api/add_yield_comment";
          } else {
            let real_risk_ind = value !== 0;
            let real_risk_clean = (typeof value === 'undefined' || typeof value === 'string') ? 0 : value;
            bodyData = {
              document_id: document_id, 
              real_risk_amt: real_risk_clean, 
              real_risk_ind: real_risk_ind
            };
            endpoint = "/api/add_yield_real_risk";
          }
      
          options.body = JSON.stringify(bodyData);
      
          const response = await fetch(endpoint, options);
      
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
      
          if (response.headers.get("content-type")?.includes("application/json")) {
            const json = await response.json();
            console.log(json);
          } else {
            //console.log('No JSON response body.');
          }
      
        } catch (error) {
          console.error('Error in handleRowEditCommit: ', error);
        }
      };
    
        




    

  if (loading) {
    return (             
    <LoadingComponent />
    )
  } else {

    return (
        <div sx={{ backgroundColor: '#ffffff' }}>
        <Grid container rowSpacing={0} columnSpacing={{ xs: 0}} alignItems="center" sx={{borderBottom: '1px solid #DBDCDC',
            backgroundColor: '#ffffff'}}>
                    <Grid item xs={0.15}>
    </Grid> 
    <Grid item xs={11.85}>        
        <Typography variant="body2" marginTop={0} display="flex">
        
        <Link fontSize="small" style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft:'20px',
                backgroundColor: '#ffffff',
                color: '#000787',
                fontWeight:500
              }} to={displayAE || displayAdvertiser || displayProduct
                ? `/yield-display` : `/yield`}>Home</Link> 
                        <Link fontSize="small" style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft:'0px',
                backgroundColor: '#ffffff',
                color: '#000787',
                fontWeight:500
              }} to={``}><NavigateNextIcon fontSize="small" />{queryName}</Link>            
                      
        
        </Typography>
        </Grid>
                </Grid>         
        
        
               <br></br>
        
        
        
        
        <Grid container rowSpacing={0} columnSpacing={{ xs: 0}} >
        
        
        
         
        
            <Grid item xs={0.25}>
            </Grid>
            <Grid item xs={3}>
            </Grid>
            <Grid item xs={5.5}>  
             
        <Box display="flex" justifyContent="center">
        <ToggleButtonGroup  
             color="primary"
             value={quickFilter}
             exclusive
             onChange={handleToggle
             // setQuickFilter(v);
             // setFilterModel(v)
            }
             
             aria-label="Platform"
        
           >
             <ToggleButton value="all">All</ToggleButton>
             <ToggleButton value="delivering">Delivering</ToggleButton>
             <ToggleButton value="paused">Paused</ToggleButton>

             <ToggleButton value="late">Not Live</ToggleButton>
             <ToggleButton value="completed">Completed</ToggleButton>
             <ToggleButton value="future">Future Start</ToggleButton>
        
           </ToggleButtonGroup>
        
        </Box>
        </Grid>
        <Grid item xs={3}>  
        

           </Grid>
        
        
        <Grid item xs={0.25}>
            </Grid>
        
        
            <Grid item xs={0.25}>
            </Grid>
            <Grid item xs={3}>
            </Grid>
            <Grid item xs={5.5}>
         
        </Grid>
            <Grid item xs={3}>
            
            <Box display="flex" justifyContent="flex-end" sx={{paddingTop: '5px'}}>
              {/*
            <Stack direction="row" spacing={1} alignItems="center">
        
        
        <Button size='small' variant="outlined" 
              disabled={currentPage === 1}
              onClick={() => {
                setCurrentPage(currentPage - 1);
                scrollToTop();
              }}        >
                        <ArrowBackIosNewRoundedIcon fontSize="small" /> 
        
            </Button>
            <Typography variant="body2" gutterBottom>
        
            {currentPage} of {totalPages}
            </Typography>

            <Button size='small' variant="outlined" 
            disabled={endIndex >= orderData.length}
        onClick={() => {
                setCurrentPage(currentPage + 1);
                scrollToTop();
              }}        >
              <ArrowForwardIosRoundedIcon fontSize="small" /> 
            </Button>
        </Stack>
            */}
        </Box>
        </Grid>
            <Grid item xs={0.25}>
            </Grid>
        
        </Grid>
        
        <Grid container rowSpacing={0} columnSpacing={{ xs: 0}} >
        <Grid item xs={0.25}>
            </Grid>
        
        <Grid item xs={11.5}>
        
        <div>
                        <p />
        <Box sx={{ boxShadow: 1, border: '1px solid #DBDCDC', 
        backgroundColor: '#ffffff'}}> 
                          <Grid style={{ height: 650, width: '100%' }} container rowSpacing={0} columnSpacing={{ xs: 0}} >
                <DataGrid
                //rows={itemsToDisplay}
                rows={orderData}
                columns={columns}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {handleSortChange(newSortModel)}}
                components={{
                  Toolbar: CustomToolbar,
                }}
                componentsProps={{ toolbar: { queryName: queryName} }}
        
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) => {setQuickFilter('all')
                  setFilterModel(newFilterModel)}}
                  getRowHeight={() => 'auto'}
             //rowHeight={100}
              getRowId={(row) => row.document_id}
              disableSelectionOnClick
             //   pageSize={10}
                hideFooter={true}
                onCellEditCommit={handleRowEditCommit}

     
   
                sx={{
                    fontSize: 12,
                    border: 0,
                  //  padding: 1,
                    [`& .${gridClasses.cell}`]: {
                      py: 1,
                    },
                    '& .super-app-theme--cell': {
                        backgroundColor: 'rgba(224, 183, 60, 0.55)',
                        color: '#1a3e72',
                        fontWeight: '600',
                      },
                    '& .super-app.red': {
                        backgroundColor: '#d47483',
        
              
                      },
                    
                    '& .super-app.yellow': {
                        backgroundColor: '#edff64',
        
              
                      },
                      '& .super-app.green': {
                        backgroundColor: 'rgba(100, 255, 118, 0.49)',
                      },}}            
        
        
            />
            
        </Grid>
                  
        
                 

              </Box>
              </div>
              </Grid>
              <Grid item xs={0.25}>
            </Grid>
            </Grid>
            <div><br></br>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 0}} >
        <Grid item xs={0.25}>
            </Grid>
            <Grid item xs={11.5}>
            <Box display="flex" justifyContent="flex-end">
              {/*
                <Stack direction="row" spacing={1} alignItems="center">
        
            <Button variant="outlined" 
                  disabled={currentPage === 1}
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                    scrollToTop();
                  }}        >
                            <ArrowBackIosNewRoundedIcon fontSize="small" /> 
        
                </Button>
                <Typography variant="body2" gutterBottom>
        
                {currentPage} of {totalPages}
                </Typography>
                <Button variant="outlined" 
                disabled={endIndex >= orderData.length}
         onClick={() => {
                    setCurrentPage(currentPage + 1);
                    scrollToTop();
                  }}        >
                  <ArrowForwardIosRoundedIcon fontSize="small" /> 
                </Button>
        </Stack>
                */}
        </Box>
        </Grid>
        <Grid item xs={0.25}>
            </Grid>
        </Grid>
        <br></br>
        </div>
        <UpdateCheck />
              </div>
        )

    }
}
