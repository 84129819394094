import { useParams } from "react-router-dom";
import React, {useEffect, useState} from 'react'
import Typography from '@mui/material/Typography';

import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

import { useOktaAuth } from '@okta/okta-react';

import TextField from '@mui/material/TextField';

import Grid from "@mui/material/Grid";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CancelIcon from '@mui/icons-material/Cancel';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';


function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }
  
  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }
  
  function union(a, b) {
    return [...a, ...not(b, a)];
  }



const TestPage = () => {


    const audience = [{id:'1153499',name:'1153499 - LR - Paramount Addressable / Acxiom_InMarket_NewLuxuryVehicle_July24'},
        {id:'1153500',name:'1153500 - LR - Paramount Addressable / Acxiom_InMarket_NewVehicle_July24'},
        {id:'1153501',name:'1153501 - LR - Paramount Addressable / Acxiom_InMarket_UsedVehicle_July24'},
        {id:'1153509',name:'1153509 - LR - Paramount Addressable / Acxiom_Vehicle_Truck_July24'},
        {id:'1153502',name:'1153502 - LR - Paramount Addressable / AnalyticsIQ_VehicleType_AllElectricVehicleDrivers_July24'},
        {id:'1153503',name:'1153503 - LR - Paramount Addressable / AnalyticsIQ_ElectricHybridVehiclesv2_July24'},
        {id:'1153505',name:'1153505 - LR - Paramount Addressable / AnalyticsIQ_VehicleType_InMarket_Truck_July24'},
        {id:'1153510',name:'1153510 - LR - Paramount Addressable / DataAxle_AutoIntenders_July24'},
        {id:'1136904',name:'1136904 - LR - Paramount Addressable / ATVEXP_Auto_DIYOnlineAutoProducts_Feb24'},
        {id:'1136905',name:'1136905 - LR - Paramount Addressable / ATVEXP_Auto_InMarket_BuyerUsed_Feb24'},
        {id:'1135061',name:'1135061 - LR - Paramount Addressable / ATVEXP_Auto_InMarket_NewCar_Feb24'},
        {id:'1136906',name:'1136906 - LR - Paramount Addressable / ATVEXP_Auto_MotorcycleEnthusiasts_Feb24'},
        {id:'1135077',name:'1135077 - LR - Paramount Addressable / ATVEXP_AutosCarsTrucksAftermarketDIYCarsandAutoRepair_Feb24'},
        {id:'1135066',name:'1135066 - LR - Paramount Addressable / ATVEXP_AutosCarsTrucksAftermarketDIYInStoreAutoProducts_Feb24'},
        {id:'1135076',name:'1135076 - LR - Paramount Addressable / ATVEXP_AutosCarsTrucksInMarketBodyStylesLuxuryCar_Feb24'},
        {id:'1135067',name:'1135067 - LR - Paramount Addressable / ATVEXP_AutosCarsTrucksInMarketFuelTypeElectric_Feb24'},
        {id:'1135071',name:'1135071 - LR - Paramount Addressable / ATVEXP_AutosCarsTrucksInMarketFuelTypeElectricCar_Feb24'},
        {id:'1135072',name:'1135072 - LR - Paramount Addressable / ATVEXP_AutosCarsTrucksInMarketFuelTypeElectricLuxury_Feb24'},
        {id:'1135060',name:'1135060 - LR - Paramount Addressable / ATVEXP_AutosCarsTrucksInMarketFuelTypeElectricNonLuxury_Feb24'},
        {id:'1135070',name:'1135070 - LR - Paramount Addressable / ATVEXP_AutosCarsTrucksInMarketLeaseLuxuryLease_Feb24'},
        {id:'1135059',name:'1135059 - LR - Paramount Addressable / ATVEXP_AutosCarsTrucksInMarketSwitchersElectric_Feb24'},
        {id:'1135065',name:'1135065 - LR - Paramount Addressable / ATVEXP_AutosCarsTrucksInMarketSwitchersLuxury_Feb24'},
        {id:'1135161',name:'1135161 - LR-VCBS Addressable/ ATVEXP_PurchaseBased_AutoPartsAccessories_FrequentSpenders_Feb24'},
        {id:'1153506',name:'1153506 - LR - Paramount Addressable / LoopMe_CarOwners_July24'},
        {id:'1153504',name:'1153504 - LR - Paramount Addressable / PolkAudiences_ElectricVehicles_July24'},
        {id:'1139544',name:'1139544 - LR-VCBS Addressable/ PolkAudiences_NewLuxuryVehicle_Apr24'},
        {id:'1139545',name:'1139545 - LR-VCBS Addressable/ PolkAudiences_NewVehicle_Apr24'},
        {id:'1139546',name:'1139546 - LR-VCBS Addressable/ PolkAudiences_UsedVehicle_Apr24'},
        {id:'1139547',name:'1139547 - LR-VCBS Addressable/ PolkAudiences_NewMidSizePickup_Apr24'},
        {id:'1139548',name:'1139548 - LR-VCBS Addressable/ PolkAudiences_NewHalfTonFullSizePickup_Apr24'},
        {id:'1139549',name:'1139549 - LR-VCBS Addressable/ PolkAudiences_New3QTonFullSizePickup_Apr24'},
        {id:'1139550',name:'1139550 - LR-VCBS Addressable/ PolkAudiences_NewSubCompactUtilityVehicle_Apr24'},
        {id:'1139551',name:'1139551 - LR-VCBS Addressable/ PolkAudiences_NewCompactUtilityVehicle_Apr24'},
        {id:'1139552',name:'1139552 - LR-VCBS Addressable/ PolkAudiences_NewFullSizeUtilityVehicle_Apr24'},
        {id:'1139560',name:'1139560 - LR-VCBS Addressable/ PolkAudiences_NewLowerMidSizeUtilityVehicle_Apr24'},
        {id:'1139561',name:'1139561 - LR-VCBS Addressable/ PolkAudiences_NewUpperMidSizeUtilityVehicle_Apr24'},
        {id:'1139562',name:'1139562 - LR-VCBS Addressable/ PolkAudiences_NewCompactCar_Apr24'},
        {id:'1139563',name:'1139563 - LR-VCBS Addressable/ PolkAudiences_NewMidSizeCar_Apr24'},
        {id:'1139569',name:'1139569 - LR-VCBS Addressable/ PolkAudiences_NewFullSizeCar_Apr24'},
        {id:'1139570',name:'1139570 - LR-VCBS Addressable/ PolkAudiences_NewEV_Apr24'},
        {id:'1139571',name:'1139571 - LR-VCBS Addressable/ PolkAudiences_ServiceTires_Apr24'},
        {id:'1139572',name:'1139572 - LR-VCBS Addressable/ PolkAudiences_ServiceGeneral_Apr24'},
        {id:'1139574',name:'1139574 - LR-VCBS Addressable/ PolkAudiences_NewLuxurySubCompactUtilityVehicle_Apr24'},
        {id:'1139575',name:'1139575 - LR-VCBS Addressable/ PolkAudiences_NewLuxuryCompactUtilityVehicle_Apr24'},
        {id:'1139576',name:'1139576 - LR-VCBS Addressable/ PolkAudiences_NewLuxuryFullSizeUtilityVehicle_Apr24'},
        {id:'1139579',name:'1139579 - LR-VCBS Addressable/ PolkAudiences_NewLuxuryLowerMidSizeUtilityVehicle_Apr24'},
        {id:'1139580',name:'1139580 - LR-VCBS Addressable/ PolkAudiences_NewLuxuryUpperMidSizeUtilityVehicle_Apr24'},
        {id:'1139581',name:'1139581 - LR-VCBS Addressable/ PolkAudiences_NewLuxuryCompactCar_Apr24'},
        {id:'1153507',name:'1153507 - LR - Paramount Addressable / Stirista_AutoEnthusiasts_July24'},
        {id:'1153511',name:'1153511 - LR - Paramount Addressable / UltimateData_BoatingYachting_July24'},
        {id:'1153508',name:'1153508 - LR - Paramount Addressable / V12_Automotive_Truck_July24'},
        {id:'1153576',name:'1153576 - LR - Paramount Addressable / AnalyticsIQ_BusinessOwners_July24'},
        {id:'1153512',name:'1153512 - LR - Paramount Addressable / Bombora_B2B_DecisionMakers_Business_July24'},
        {id:'1153513',name:'1153513 - LR - Paramount Addressable / Bombora_B2B_DecisionMakers_Finance_Jul24'},
        {id:'1153514',name:'1153514 - LR - Paramount Addressable / Bombora_B2B_DecisionMakers_Marketing_July24'},
        {id:'1153515',name:'1153515 - LR - Paramount Addressable / Bombora_B2B_DecisionMakers_Sales_July24'},
        {id:'1153516',name:'1153516 - LR - Paramount Addressable / Bombora_B2B_DecisionMakers_SmallBusiness_July24'},
        {id:'1153517',name:'1153517 - LR - Paramount Addressable / Bombora_B2B_Industry_BusinessServices_July24'},
        {id:'1153518',name:'1153518 - LR - Paramount Addressable / Bombora_B2B_Industry_Construction_July24'},
        {id:'1153519',name:'1153519 - LR - Paramount Addressable / Bombora_B2B_Industry_Education_July24'},
        {id:'1153520',name:'1153520 - LR - Paramount Addressable / Bombora_B2B_Industry_EnergyUtilitiesWaste_July24'},
        {id:'1153521',name:'1153521 - LR - Paramount Addressable / Bombora_B2B_Industry_Finance_July24'},
        {id:'1153522',name:'1153522 - LR - Paramount Addressable / Bombora_B2B_Industry_Government_July24'},
        {id:'1153523',name:'1153523 - LR - Paramount Addressable / Bombora_B2B_Industry_Healthcare_July24'},
        {id:'1153524',name:'1153524 - LR - Paramount Addressable / Bombora_B2B_Industry_HospitalityHotels_July24'},
        {id:'1153525',name:'1153525 - LR - Paramount Addressable / Bombora_B2B_Industry_Insurance_July24'},
        {id:'1153526',name:'1153526 - LR - Paramount Addressable / Bombora_B2B_Industry_MediaInternet_July24'},
        {id:'1153527',name:'1153527 - LR - Paramount Addressable / Bombora_B2B_Industry_RealEstate_July24'},
        {id:'1153528',name:'1153528 - LR - Paramount Addressable / Bombora_B2B_Industry_Retail_July24'},
        {id:'1153529',name:'1153529 - LR - Paramount Addressable / Bombora_B2B_Industry_Software_July24'},
        {id:'1153530',name:'1153530 - LR - Paramount Addressable / Bombora_B2B_Industry_Telecommunications_July24'},
        {id:'1153531',name:'1153531 - LR - Paramount Addressable / Bombora_B2B_Industry_TransportationsTravel_July24'},
        {id:'1153532',name:'1153532 - LR - Paramount Addressable / Bombora_JobFunction_Engineering_July24'},
        {id:'1152913',name:'1152913 - LR - Paramount Addressable / Bombora_JobFunction_IT_July24'},
        {id:'1152914',name:'1152914 - LR - Paramount Addressable / Bombora_JobFunction_IT_SoftwareDevelopers_July24'},
        {id:'1152916',name:'1152916 - LR - Paramount Addressable / Bombora_JobFunction_IT_WebDevelopment_July24'},
        {id:'1153533',name:'1153533 - LR - Paramount Addressable / Bombora_JobFunction_Operations_July24'},
        {id:'1153534',name:'1153534 - LR - Paramount Addressable / Bombora_JobFunction_Sales_July24'},
        {id:'1153535',name:'1153535 - LR - Paramount Addressable / Bombora_Entrepreneur_July24'},
        {id:'1153536',name:'1153536 - LR - Paramount Addressable / DB_B2B_CompanyAge_2-5yrs_July24'},
        {id:'1153537',name:'1153537 - LR - Paramount Addressable / DB_B2B_CompanyAge_LessThan2yrs_July24'},
        {id:'1153538',name:'1153538 - LR - Paramount Addressable / DB_B2B_Revenue_Sales_1-5M_July24'},
        {id:'1153539',name:'1153539 - LR - Paramount Addressable / DB_B2B_Revenue_Sales_5-20M_July24'},
        {id:'1153540',name:'1153540 - LR - Paramount Addressable / DB_B2B_CompanySize_Large_1001-2000_July24'},
        {id:'1153541',name:'1153541 - LR - Paramount Addressable / DB_B2B_CompanySize_Large_251-500_July24'},
        {id:'1153542',name:'1153542 - LR - Paramount Addressable / DB_B2B_CompanySize_Large_501-1000_July24'},
        {id:'1153543',name:'1153543 - LR - Paramount Addressable / DB_B2B_CompanySize_Large_MoreThan2000_July24'},
        {id:'1153545',name:'1153545 - LR - Paramount Addressable / DB_B2B_CompanySize_Medium_101-250_July24'},
        {id:'1153546',name:'1153546 - LR - Paramount Addressable / DB_B2B_CompanySize_Medium_51-100_July24'},
        {id:'1153547',name:'1153547 - LR - Paramount Addressable / DB_B2B_CompanySize_Micro_1-4_July24'},
        {id:'1153549',name:'1153549 - LR - Paramount Addressable / DB_B2B_CompanySize_Micro_5-10_July24'},
        {id:'1153550',name:'1153550 - LR - Paramount Addressable / DB_B2B_CompanySize_Small_11-20_July24'},
        {id:'1153551',name:'1153551 - LR - Paramount Addressable / DB_B2B_CompanySize_Small_21-30_July24'},
        {id:'1153552',name:'1153552 - LR - Paramount Addressable / DB_B2B_CompanySize_Small_31-50_July24'},
        {id:'1153554',name:'1153554 - LR - Paramount Addressable / DB_B2BDecisionMakers_July24'},
        {id:'1153555',name:'1153555 - LR - Paramount Addressable / DB_SmallBusinessDecisionMakers_July24'},
        {id:'1153556',name:'1153556 - LR - Paramount Addressable / DB_SmallBusinessOwnersSBO_July24'},
        {id:'1153557',name:'1153557 - LR - Paramount Addressable / DB_B2B_Industry_Agriculture_July24'},
        {id:'1153560',name:'1153560 - LR - Paramount Addressable / DB_FinanceInsuranceIndustry_July24'},
        {id:'1153562',name:'1153562 - LR - Paramount Addressable / DB_B2B_JobFunction-Roles_Admin_July24'},
        {id:'1153567',name:'1153567 - LR - Paramount Addressable / DB_BusinessOwnersBoardJobFunction_July24'},
        {id:'1153569',name:'1153569 - LR - Paramount Addressable / DB_B2B_Function_Nurse_July24'},
        {id:'1153571',name:'1153571 - LR - Paramount Addressable / DB_B2B_JobFunction-Roles_HR_July24'},
        {id:'1153575',name:'1153575 - LR - Paramount Addressable / DB_B2BLocationType_OwnedBusinessLocations_July24'},
        {id:'1114581',name:'1114581 - LR-VCBS Addressable/ DBPrecision_BusinessOwners_Nov23'},
        {id:'1145002',name:'1145002 - LR-VCBS Addressable/ DataAxle_B2B_FunctionalArea_Government_May24'},
        {id:'1145005',name:'1145005 - LR-VCBS Addressable/ DataAxle_B2B_FunctionalArea_Education_May24'},
        {id:'1145008',name:'1145008 - LR-VCBS Addressable/ DataAxle_B2B_FunctionalArea_Medical-Health_May24'},
        {id:'1145007',name:'1145007 - LR-VCBS Addressable/ DataAxle_B2B_FunctionalArea_C-Level_May24'},
        {id:'1145003',name:'1145003 - LR-VCBS Addressable/ DataAxle_B2B_FunctionalArea_Legal_May24'},
        {id:'1145006',name:'1145006 - LR-VCBS Addressable/ DataAxle_B2B_C-Level-C-SuiteExecutives_May24'},
        {id:'1145484',name:'1145484 - LR - Paramount Addressable / DB_HealthcareDecisionMakers_May24'},
        {id:'1145487',name:'1145487 - LR - Paramount Addressable / Stirista_HR_DecisionMaker_May24'},
        {id:'1145486',name:'1145486 - LR - Paramount Addressable / Stirista_IT_DecisionMaker_May24'},
        {id:'1145485',name:'1145485 - LR - Paramount Addressable / Stirista_Manufacturing_DecisionMaker_May24'},
        {id:'1135073',name:'1135073 - LR - Paramount Addressable / ATVEXP_B2B_ExecutiveTitle_CEOCOOCTOCIOCFO_Feb24'},
        {id:'1135092',name:'1135092 - LR - Paramount Addressable / ATVEXP_B2B_Industry_PublicAdministrationandGovernment_Feb24'},
        {id:'1135074',name:'1135074 - LR - Paramount Addressable / ATVEXP_BusinessExecutivesB2BExecutiveTitleExecutiveManagement_Feb24'},
        {id:'1135075',name:'1135075 - LR - Paramount Addressable / ATVEXP_BusinessExecutivesB2BExecutiveTitleITTechnical_Feb24'},
        {id:'1135063',name:'1135063 - LR - Paramount Addressable / ATVEXP_BusinessExecutivesB2BExecutiveTitleOwner_Feb24'},
        {id:'1135091',name:'1135091 - LR - Paramount Addressable / ATVEXP_Occupation_SmallBusinessOwners_Feb24'},
        {id:'1135079',name:'1135079 - LR - Paramount Addressable / ATVEXP_ConsumerBehaviorsGroceryPickUpandDelivery_Feb24'},
        {id:'1153577',name:'1153577 - LR - Paramount Addressable / IRI_BackToSchool_July24'},
        {id:'1153578',name:'1153578 - LR - Paramount Addressable / IRI_BeautyMavens_July24'},
        {id:'1153579',name:'1153579 - LR - Paramount Addressable / IRI_ColdFluPreppers_July24'},
        {id:'1153580',name:'1153580 - LR - Paramount Addressable / IRI_ConvenienceSeekers_July24'},
        {id:'1153581',name:'1153581 - LR - Paramount Addressable / IRI_EarlyAdopters_July24'},
        {id:'1153582',name:'1153582 - LR - Paramount Addressable / IRI_EnergySeekers_July24'},
        {id:'1153583',name:'1153583 - LR - Paramount Addressable / IRI_GreenLiving_July24'},
        {id:'1153584',name:'1153584 - LR - Paramount Addressable / IRI_NewBaby_July24'},
        {id:'1153585',name:'1153585 - LR - Paramount Addressable / IRI_Nutritionist_July24'},
        {id:'1153586',name:'1153586 - LR - Paramount Addressable / IRI_SweetTooth_July24'},
        {id:'1153587',name:'1153587 - LR - Paramount Addressable / Asterisks_FTP_July24'},
        {id:'1153588',name:'1153588 - LR - Paramount Addressable / Acxiom_Age_18_24_July24'},
        {id:'1153590',name:'1153590 - LR - Paramount Addressable / Acxiom_Age_65_plus_July24'},
        {id:'1153592',name:'1153592 - LR - Paramount Addressable / Acxiom_AA_July24'},
        {id:'1153595',name:'1153595 - LR - Paramount Addressable / Acxiom_Hispanic_July24'},
        {id:'1153596',name:'1153596 - LR - Paramount Addressable / Acxiom_Female_July24'},
        {id:'1153598',name:'1153598 - LR - Paramount Addressable / Acxiom_Male_July24'},
        {id:'1153599',name:'1153599 - LR - Paramount Addressable / Acxiom_HomeOwnership_Owner_July24'},
        {id:'1153600',name:'1153600 - LR - Paramount Addressable / Acxiom_Renter_July24'},
        {id:'1153601',name:'1153601 - LR - Paramount Addressable / Acxiom_Demo_Veteran_July24'},
        {id:'1153603',name:'1153603 - LR - Paramount Addressable / Acxiom_InMarket_BuyaVacationorSecondHome_July24'},
        {id:'1153604',name:'1153604 - LR - Paramount Addressable / Acxiom_InMarket_BuyFirstHome_July24'},
        {id:'1153616',name:'1153616 - LR - Paramount Addressable / Acxiom_Military_Jul24'},
        {id:'1154678',name:'1154678 - LR - Paramount Addressable / Acxiom_25-29_Jul24'},
        {id:'1154672',name:'1154672 - LR - Paramount Addressable / Acxiom_30-34_Jul24'},
        {id:'1154667',name:'1154667 - LR - Paramount Addressable / Acxiom_35-39_Jul24'},
        {id:'1154659',name:'1154659 - LR - Paramount Addressable / Acxiom_40-44_Jul24'},
        {id:'1154651',name:'1154651 - LR - Paramount Addressable / Acxiom_45-49_Jul24'},
        {id:'1154645',name:'1154645 - LR - Paramount Addressable / Acxiom_50-54_Jul24'},
        {id:'1154640',name:'1154640 - LR - Paramount Addressable / Acxiom_55-59_Jul24'},
        {id:'1154635',name:'1154635 - LR - Paramount Addressable / Acxiom_60-64_Jul24'},
        {id:'1153605',name:'1153605 - LR - Paramount Addressable / Adsquare_Employed_July24'},
        {id:'1146165',name:'1146165 - LR - Paramount Addressable / AnalyticsIQ_PreMovers_June24'},
        {id:'1153615',name:'1153615 - LR - Paramount Addressable / AnalyticsIQ_ActivelySeekingNewJob_Jul24'},
        {id:'1146166',name:'1146166 - LR - Paramount Addressable / AnalyticsIQ_MilitaryVeteran_June24'},
        {id:'1135149',name:'1135149 - LR - Paramount Addressable / ATVEXP_LifeEvents_NewHomeownersLast12Months_Feb24'},
        {id:'1135142',name:'1135142 - LR - Paramount Addressable / ATVEXP_LifeEvents_NewHomeownersLast6Months_Feb24'},
        {id:'1135110',name:'1135110 - LR - Paramount Addressable / ATVEXP_LifeEvents_NewMoversLast12Months_Feb24'},
        {id:'1135098',name:'1135098 - LR - Paramount Addressable / ATVEXP_LifeEvents_NewMoversLast6Months_Feb24'},
        {id:'1135134',name:'1135134 - LR - Paramount Addressable / ATVEXP_LifeEvents_NewParentsChildAge0to36Months_Feb24'},
        {id:'1155118',name:'1155118 - LR - Paramount Addressable / Speedeon_Age_18plus_Jul24'},
        {id:'1112273',name:'1112273 - LR-VCBS Addressable/ Speedeon_Age_18-24_Apr24'},
        {id:'1155117',name:'1155117 - LR - Paramount Addressable / Speedeon_Age_18-34_Jul24'},
        {id:'1113132',name:'1113132 - LR-VCBS Addressable/ Speedeon_Age_18-44_Apr24'},
        {id:'1113133',name:'1113133 - LR-VCBS Addressable/ Speedeon_Age_18-49_Apr24'},
        {id:'1113134',name:'1113134 - LR-VCBS Addressable/ Speedeon_Age_21plus_Apr24'},
        {id:'1113135',name:'1113135 - LR-VCBS Addressable/ Speedeon_Age_21-34_Apr24'},
        {id:'1155116',name:'1155116 - LR - Paramount Addressable / Speedeon_Age_21-44_Jul24'},
        {id:'1113137',name:'1113137 - LR-VCBS Addressable/ Speedeon_Age_21-49_Apr24'},
        {id:'1113138',name:'1113138 - LR-VCBS Addressable/ Speedeon_Age_21-54_Apr24'},
        {id:'1113139',name:'1113139 - LR-VCBS Addressable/ Speedeon_Age_25plus_Apr24'},
        {id:'1113141',name:'1113141 - LR-VCBS Addressable/ Speedeon_Age_25-34_Apr24'},
        {id:'1113143',name:'1113143 - LR-VCBS Addressable/ Speedeon_Age_25-44_Apr24'},
        {id:'1113144',name:'1113144 - LR-VCBS Addressable/ Speedeon_Age_25-49_Apr24'},
        {id:'1113145',name:'1113145 - LR-VCBS Addressable/ Speedeon_Age_25-54_Apr24'},
        {id:'1113146',name:'1113146 - LR-VCBS Addressable/ Speedeon_Age_35plus_Apr24'},
        {id:'1113148',name:'1113148 - LR-VCBS Addressable/ Speedeon_Age_35-44_Apr24'},
        {id:'1113150',name:'1113150 - LR-VCBS Addressable/ Speedeon_Age_35-49_Apr24'},
        {id:'1113151',name:'1113151 - LR-VCBS Addressable/ Speedeon_Age_35-54_Apr24'},
        {id:'1113153',name:'1113153 - LR-VCBS Addressable/ Speedeon_Age_35-64_Apr24'},
        {id:'1113155',name:'1113155 - LR-VCBS Addressable/ Speedeon_Age_45plus_Apr24'},
        {id:'1113156',name:'1113156 - LR-VCBS Addressable/ Speedeon_Age_45-54_Apr24'},
        {id:'1113160',name:'1113160 - LR-VCBS Addressable/ Speedeon_Age_49-54_Apr24'},
        {id:'1113161',name:'1113161 - LR-VCBS Addressable/ Speedeon_Age_55plus_Apr24'},
        {id:'1113164',name:'1113164 - LR-VCBS Addressable/ Speedeon_Age_55-64_Apr24'},
        {id:'1113165',name:'1113165 - LR-VCBS Addressable/ Speedeon_Age_64plus_Apr24'},
        {id:'1113166',name:'1113166 - LR-VCBS Addressable/ Speedeon_BusinessOwner_Apr24'},
        {id:'1113167',name:'1113167 - LR-VCBS Addressable/ Speedeon_Ethnicity_AA_Apr24'},
        {id:'1113168',name:'1113168 - LR-VCBS Addressable/ Speedeon_Ethnicity_Asain_Apr24'},
        {id:'1113169',name:'1113169 - LR-VCBS Addressable/ Speedeon_Ethnicity_Hispanic_Apr24'},
        {id:'1113170',name:'1113170 - LR-VCBS Addressable/ Speedeon_Female_Apr24'},
        {id:'1113171',name:'1113171 - LR-VCBS Addressable/ Speedeon_Male_Apr24'},
        {id:'1113172',name:'1113172 - LR-VCBS Addressable/ Speedeon_HHI100kplus_Apr24'},
        {id:'1113173',name:'1113173 - LR-VCBS Addressable/ Speedeon_HHI125kplus_Apr24'},
        {id:'1113174',name:'1113174 - LR-VCBS Addressable/ Speedeon_HHI150kplus_Apr24'},
        {id:'1113175',name:'1113175 - LR-VCBS Addressable/ Speedeon_HHI175kplus_Apr24'},
        {id:'1113176',name:'1113176 - LR-VCBS Addressable/ Speedeon_HHI200kplus_Apr24'},
        {id:'1112144',name:'1112144 - LR-VCBS Addressable/ Speedeon_HHI250kplus_Apr24'},
        {id:'1113180',name:'1113180 - LR-VCBS Addressable/ Speedeon_HHI40kplus_Apr24'},
        {id:'1113182',name:'1113182 - LR-VCBS Addressable/ Speedeon_HHI50kplus_Apr24'},
        {id:'1113184',name:'1113184 - LR-VCBS Addressable/ Speedeon_HHI60kplus_Apr24'},
        {id:'1113185',name:'1113185 - LR-VCBS Addressable/ Speedeon_HHI75kplus_Apr24'},
        {id:'1113186',name:'1113186 - LR-VCBS Addressable/ Speedeon_HHIlessthan100k_Apr24'},
        {id:'1113188',name:'1113188 - LR-VCBS Addressable/ Speedeon_HHIlessthan75k_Apr24'},
        {id:'1113187',name:'1113187 - LR-VCBS Addressable/ Speedeon_HHIlessthan50k_Apr24'},
        {id:'1112502',name:'1112502 - LR-VCBS Addressable/ Speedeon_HomeOwner_Apr24'},
        {id:'1113191',name:'1113191 - LR-VCBS Addressable/ Speedeon_POC2plus_Apr24'},
        {id:'1113192',name:'1113192 - LR-VCBS Addressable/ Speedeon_POC_Apr24'},
        {id:'1113194',name:'1113194 - LR-VCBS Addressable/ Speedeon_No_POC_Apr24'},
        {id:'1113197',name:'1113197 - LR-VCBS Addressable/ Speedeon_POC_One_Apr24'},
        {id:'1113198',name:'1113198 - LR-VCBS Addressable/ Speedeon_EduDegree_Bachelor_Apr24'},
        {id:'1113199',name:'1113199 - LR-VCBS Addressable/ Speedeon_EduDegree_Graduates_Apr24'},
        {id:'1113202',name:'1113202 - LR-VCBS Addressable/ Speedeon_EduDegree_HighSchool_Apr24'},
        {id:'1113203',name:'1113203 - LR-VCBS Addressable/ Speedeon_EduDegree_College_Apr24'},
        {id:'1113204',name:'1113204 - LR-VCBS Addressable/ Speedeon_EduDegree_NoCollege_Apr24'},
        {id:'1113205',name:'1113205 - LR-VCBS Addressable/ Speedeon_Single_Apr24'},
        {id:'1113206',name:'1113206 - LR-VCBS Addressable/ Speedeon_Married_Apr24'},
        {id:'1113207',name:'1113207 - LR-VCBS Addressable/ Speedeon_Renters_Apr24'},
        {id:'1156716',name:'1156716 - LR - Paramount Addressable / Adstra_TVMovies_Comedy_Jul24'},
        {id:'1156720',name:'1156720 - LR - Paramount Addressable / SambaTV_ComedyMovies_Jul24'},
        {id:'1153675',name:'1153675 - LR - Paramount Addressable / Asterisks_Action-AdventureMovies_Jul24'},
        {id:'1153617',name:'1153617 - LR - Paramount Addressable / Acxiom_CountryMusicPerformances_Jul24'},
        {id:'1153618',name:'1153618 - LR - Paramount Addressable / Acxiom_Interest_LiveTheater_Jul24'},
        {id:'1153677',name:'1153677 - LR - Paramount Addressable / AcxiomUSMedia_Documentaries_Jul24'},
        {id:'1153679',name:'1153679 - LR - Paramount Addressable / AcxiomUSMedia_GameShows_Jul24'},
        {id:'1153676',name:'1153676 - LR - Paramount Addressable / AffinityAnswers_AwardShows_Jul24'},
        {id:'1153619',name:'1153619 - LR - Paramount Addressable / Alliant_HarryPotterInterestPropensity_Jul24'},
        {id:'1153620',name:'1153620 - LR - Paramount Addressable / Alliant_MarvelInterestPropensity_Jul24'},
        {id:'1154611',name:'1154611 - LR - Paramount Addressable / Alliant_Movies_DCComics_Jul24'},
        {id:'1154497',name:'1154497 - LR - Paramount Addressable / Alliant_SportsEnthusiasts_Jul24'},
        {id:'1153621',name:'1153621 - LR - Paramount Addressable / Clickagy_Animation_Anime_July24'},
        {id:'1154496',name:'1154496 - LR - Paramount Addressable / Clickagy_MysteryThriller_Movies_Jul24'},
        {id:'1153622',name:'1153622 - LR - Paramount Addressable / Comscore_Network_AE_Jul24'},
        {id:'1153623',name:'1153623 - LR - Paramount Addressable / Comscore_Network_Bravo_Jul24'},
        {id:'1153624',name:'1153624 - LR - Paramount Addressable / Comscore_Network_CBS_Jul24'},
        {id:'1153625',name:'1153625 - LR - Paramount Addressable / Comscore_Network_Cinemax_Jul24'},
        {id:'1153626',name:'1153626 - LR - Paramount Addressable / Comscore_Network_CMT_Jul24'},
        {id:'1153627',name:'1153627 - LR - Paramount Addressable / Comscore_Network_ComedyCentral_Jul24'},
        {id:'1153628',name:'1153628 - LR - Paramount Addressable / Comscore_Network_E_Jul24'},
        {id:'1153629',name:'1153629 - LR - Paramount Addressable / Comscore_Network_ESPN_Jul24'},
        {id:'1153630',name:'1153630 - LR - Paramount Addressable / Comscore_Network_FoodNetwork_Jul24'},
        {id:'1153631',name:'1153631 - LR - Paramount Addressable / Comscore_Network_FX_Jul24'},
        {id:'1153632',name:'1153632 - LR - Paramount Addressable / Comscore_Network_Golf_Jul24'},
        {id:'1153633',name:'1153633 - LR - Paramount Addressable / Comscore_Network_HBO_Jul24'},
        {id:'1153635',name:'1153635 - LR - Paramount Addressable / Comscore_Network_Showtime_Jul24'},
        {id:'1153636',name:'1153636 - LR - Paramount Addressable / Comscore_Network_Paramount_Jul24'},
        {id:'1153637',name:'1153637 - LR - Paramount Addressable / Comscore_Network_Syfy_Jul24'},
        {id:'1153638',name:'1153638 - LR - Paramount Addressable / Comscore_Network_TNT_Jul24'},
        {id:'1153639',name:'1153639 - LR - Paramount Addressable / Comscore_Network_VH1_Jul24'},
        {id:'1154495',name:'1154495 - LR - Paramount Addressable / Comscore_SpanishLanguageTVWatchers_Jul24'},
        {id:'1153678',name:'1153678 - LR - Paramount Addressable / ContextualGraph_FantasyMovies_Jul24'},
        {id:'1154494',name:'1154494 - LR - Paramount Addressable / ContextualGraph_MusicTV_Jul24'},
        {id:'1145470',name:'1145470 - LR - Paramount Addressable / Experian_FrequentMovieAttendee_May24'},
        {id:'1135160',name:'1135160 - LR-VCBS Addressable/ ATVEXP_PurchaseBased_Entertainment_MusicConcertsandFestivalsHighSpenders_Feb24'},
        {id:'1144663',name:'1144663 - LR - Paramount Addressable / SambaTV_TVViewership_Network_BET_May24'},
        {id:'1144662',name:'1144662 - LR - Paramount Addressable / SambaTV_TVViewership_Network_History_May24'},
        {id:'1144661',name:'1144661 - LR - Paramount Addressable / SambaTV_TVViewership_Network_MTV_May24'},
        {id:'1154550',name:'1154550 - LR-Paramount Addressable/ UltimateData_ActionAdventureMovies_Jul24'},
        {id:'1154551',name:'1154551 - LR-Paramount Addressable/ UltimateData_ComedyMovies_Jul24'},
        {id:'1154552',name:'1154552 - LR-Paramount Addressable/ UltimateData_TVDrama_Jul24'},
        {id:'1154553',name:'1154553 - LR-Paramount Addressable/ UltimateData_FamilyFriendlyStreaming_Jul24'},
        {id:'1154554',name:'1154554 - LR-Paramount Addressable/ UUltimateData_HomeImprovementDIY_Jul24'},
        {id:'1154555',name:'1154555 - LR-Paramount Addressable/ UltimateData_HorrorMovies_Jul24'},
        {id:'1154556',name:'1154556 - LR-Paramount Addressable/ UltimateData_MusicalDanceMoviesJul24'},
        {id:'1154557',name:'1154557 - LR-Paramount Addressable/ UltimateData_PremiumChannels_Jul24'},
        {id:'1154558',name:'1154558 - LR-Paramount Addressable/ UltimateData_RealityTV_Jul24'},
        {id:'1154559',name:'1154559 - LR-Paramount Addressable/ UltimateData_RomanceMovies_Jul24'},
        {id:'1154560',name:'1154560 - LR-Paramount Addressable/ UltimateData_SciFiMovies_Jul24'},
        {id:'619065',name:'619065 - AAM - ViacomCBS Addressable / d0: Profiles | Super Hero Fans : VCBS Jul21'},
        {id:'619145',name:'619145 - AAM - ViacomCBS Addressable / d12: Extension | CBSNews : CBSNews Jul21'},
        {id:'618314',name:'618314 - AAM - ViacomCBS Addressable / d12: Extension | Moneywatch : Topic - CBSNews Jul21'},
        {id:'619147',name:'619147 - AAM - ViacomCBS Addressable / d12: Extension | Politics : CBSNews Jul21'},
        {id:'619152',name:'619152 - AAM - ViacomCBS Addressable / d15: Profiles | DC : Franchise - Movies Jul21'},
        {id:'619153',name:'619153 - AAM - ViacomCBS Addressable / d15: Profiles | Marvel : Franchise - Movies Jul21'},
        {id:'619156',name:'619156 - AAM - ViacomCBS Addressable / d19: Profile | Country Music Fans : Music Jul21'},
        {id:'619160',name:'619160 - AAM - ViacomCBS Addressable / d19: Profiles | Music : Interest - VCBS Jul21'},
        {id:'619265',name:'619265 - AAM - ViacomCBS Addressable / d27: Extension | ET Online : Site Jul21'},
        {id:'619317',name:'619317 - AAM - ViacomCBS Addressable / d28: Profiles | BET : Interest - VCBS Jul21'},
        {id:'619318',name:'619318 - AAM - ViacomCBS Addressable / d28: Profiles | CBS : Content Brand Interest - VCBS Jul21'},
        {id:'619321',name:'619321 - AAM - ViacomCBS Addressable / d28: Profiles | Comedy : Interest - VCBS Jul21'},
        {id:'619327',name:'619327 - AAM - ViacomCBS Addressable / d28: Profiles | Drama : Interest - VCBS Jul21'},
        {id:'619328',name:'619328 - AAM - ViacomCBS Addressable / d28: Profiles | Horror : Interest - VCBS Jul21'},
        {id:'619330',name:'619330 - AAM - ViacomCBS Addressable / d28: Profiles | Movie : Interest - VCBS Jul21'},
        {id:'619331',name:'619331 - AAM - ViacomCBS Addressable / d28: Profiles | MTV : Content Brand Interest - VCBS Jul21'},
        {id:'619332',name:'619332 - AAM - ViacomCBS Addressable / d28: Profiles | News : Interest - VCBS Jul21'},
        {id:'619335',name:'619335 - AAM - ViacomCBS Addressable / d28: Profiles | Reality TV : Interest - VCBS Jul21'},
        {id:'619336',name:'619336 - AAM - ViacomCBS Addressable / d28: Profiles | Smithsonian Channel : Content Brand Interest - VCBS Jul21'},
        {id:'619337',name:'619337 - AAM - ViacomCBS Addressable / d28: Profiles | TV & Movies : Interest - VCBS Jul21'},
        {id:'619314',name:'619314 - AAM - ViacomCBS Addressable / d28: Profile | Star Trek Fans : VCBS Jul21'},
        {id:'619296',name:'619296 - AAM - ViacomCBS Addressable / d6: Extension | Anime : Topic - Comicbook Jul21'},
        {id:'1110795',name:'1110795 - LR - Paramount Addressable / Acxiom_PurchasedFinancialProductsForSmallBusiness_Oct23'},
        {id:'1154499',name:'1154499 - LR - Paramount Addressable / Acxiom_Landlords_Jul24'},
        {id:'1154500',name:'1154500 - LR - Paramount Addressable / Clickagy_InvestmentType_Cryptocurrency_Jul24'},
        {id:'1154501',name:'1154501 - LR - Paramount Addressable / Clickagy_InvestmentType_RealEstate_Jul24'},
        {id:'1154502',name:'1154502 - LR - Paramount Addressable / Experian_TaxPreparationServicesBuyers_Jul24'},
        {id:'1135145',name:'1135145 - LR - Paramount Addressable / ATVEXP_Finance_ComplacentCardUserLowCreditCardBalance_Feb24'},
        {id:'1135086',name:'1135086 - LR - Paramount Addressable / ATVEXP_Finance_DiscretionarySpend_10000-24999_Feb24'},
        {id:'1135078',name:'1135078 - LR - Paramount Addressable / ATVEXP_Finance_DiscretionarySpend_1-4999_Feb24'},
        {id:'1135080',name:'1135080 - LR - Paramount Addressable / ATVEXP_Finance_DiscretionarySpend_25000-49999_Feb24'},
        {id:'1135084',name:'1135084 - LR - Paramount Addressable / ATVEXP_Finance_DiscretionarySpend_50000plus_Feb24'},
        {id:'1135090',name:'1135090 - LR - Paramount Addressable / ATVEXP_Finance_DiscretionarySpend_5000-9999_Feb24'},
        {id:'1135097',name:'1135097 - LR - Paramount Addressable / ATVEXP_Finance_InMarketAutoLoan_Feb24'},
        {id:'1135132',name:'1135132 - LR - Paramount Addressable / ATVEXP_Finance_InMarketBankcardRevolving_Feb24'},
        {id:'1135152',name:'1135152 - LR - Paramount Addressable / ATVEXP_Finance_InMarketHomeEquity_Feb24'},
        {id:'1135153',name:'1135153 - LR - Paramount Addressable / ATVEXP_Finance_LoyalRewardsEnthusiastLowCreditCardBalance_Feb24'},
        {id:'1135094',name:'1135094 - LR - Paramount Addressable / ATVEXP_Finance_NetWorth_1000000plus_Feb24'},
        {id:'1135087',name:'1135087 - LR - Paramount Addressable / ATVEXP_Finance_NetWorth_2500000plus_Feb24'},
        {id:'1135096',name:'1135096 - LR - Paramount Addressable / ATVEXP_Finance_NetWorth_250000-4999999_Feb24'},
        {id:'1135146',name:'1135146 - LR - Paramount Addressable / ATVEXP_Finance_NetWorth_5000000plus_Feb24'},
        {id:'1135179',name:'1135179 - LR-VCBS Addressable/ ATVEXP_ConsumerFinancialInsights_NetWorth_1M-2M_Feb24'},
        {id:'1135151',name:'1135151 - LR - Paramount Addressable / ATVEXP_FinancialBehavior_SmartInvestors_Feb24'},
        {id:'1135133',name:'1135133 - LR - Paramount Addressable / ATVEXP_FinancialBehaviorInvestmentLeaders_Feb24'},
        {id:'1145468',name:'1145468 - LR - Paramount Addressable / ICXMedia_StudentLoans_May24'},
        {id:'1145472',name:'1145472 - LR - Paramount Addressable / ICXMedia_CreditCards_May24'},
        {id:'619058',name:'619058 - AAM - ViacomCBS Addressable / d0: Profile | Personal Finance : VCBS Jul21'},
        {id:'1154503',name:'1154503 - LR - Paramount Addressable / Acxiom_VideoGames_Subscriber_PlayStation_Jul24'},
        {id:'1154504',name:'1154504 - LR - Paramount Addressable / Acxiom_VideoGames_PowerGamer_Jul24'},
        {id:'1154505',name:'1154505 - LR - Paramount Addressable / Acxiom_VideoGamers_Jul24'},
        {id:'1154506',name:'1154506 - LR - Paramount Addressable / Acxiom_VideoGames_Subscriber_XBoxLive_Jul24'},
        {id:'1154512',name:'1154512 - LR - Paramount Addressable / Alliant_InterestPropensityMobileVideoGames_Jul24'},
        {id:'1154513',name:'1154513 - LR - Paramount Addressable / Clickagy_VideoGames_Genre_Action_Jul24'},
        {id:'1154515',name:'1154515 - LR - Paramount Addressable / Clickagy_VideoGames_Genre_Adventure_Jul24'},
        {id:'1154516',name:'1154516 - LR - Paramount Addressable / Clickagy_VideoGames_Genre_Casual_Jul24'},
        {id:'1154517',name:'1154517 - LR - Paramount Addressable / Clickagy_VideoGames_Genre_MMO_Jul24'},
        {id:'1154518',name:'1154518 - LR - Paramount Addressable / Clickagy_VideoGames_Genre_MOBA_Jul24'},
        {id:'1154519',name:'1154519 - LR - Paramount Addressable / Clickagy_VideoGames_Genre_Racing-VehicleSimulators_Jul24'},
        {id:'1154520',name:'1154520 - LR - Paramount Addressable / Clickagy_VideoGames_Genre_RPG_Jul24'},
        {id:'1154521',name:'1154521 - LR - Paramount Addressable / Clickagy_VideoGames_Genre_RTS_Jul24'},
        {id:'1154522',name:'1154522 - LR - Paramount Addressable / Clickagy_VideoGames_Genre_Simulation_Jul24'},
        {id:'1154524',name:'1154524 - LR - Paramount Addressable / Clickagy_VideoGames_Genre_Sports_Jul24'},
        {id:'1154525',name:'1154525 - LR - Paramount Addressable / Clickagy_VideoGames_Genre_Strategy_Jul24'},
        {id:'1154526',name:'1154526 - LR - Paramount Addressable / Clickagy_VideoGames_Genre_VirtualReality_Jul24'},
        {id:'1154527',name:'1154527 - LR - Paramount Addressable / Clickagy_VideoGames_Platform_Computer_Jul24'},
        {id:'1154528',name:'1154528 - LR - Paramount Addressable / Clickagy_VideoGames_Platform_MicrosoftXboxOne_Jul24'},
        {id:'1154529',name:'1154529 - LR - Paramount Addressable / Clickagy_VideoGames_Platform_Mobile_Jul24'},
        {id:'1154530',name:'1154530 - LR - Paramount Addressable / Clickagy_VideoGames_Platform_NintendoDS-3DS_Jul24'},
        {id:'1154531',name:'1154531 - LR - Paramount Addressable / Clickagy_VideoGames_Platform_NintendoSwitch_Jul24'},
        {id:'1154532',name:'1154532 - LR - Paramount Addressable / Clickagy_VideoGames_Platform_SonyPlaystation_Jul24'},
        {id:'1154533',name:'1154533 - LR - Paramount Addressable / Comscore_AmazonWatchers_Jul24'},
        {id:'1154534',name:'1154534 - LR - Paramount Addressable / Comscore_HuluWatchers_Jul24'},
        {id:'1154535',name:'1154535 - LR - Paramount Addressable / Comscore_NetflixWatchers_Jul24'},
        {id:'1154536',name:'1154536 - LR - Paramount Addressable / Comscore_OTTWatchers_Jul24'},
        {id:'1154537',name:'1154537 - LR - Paramount Addressable / Comscore_YouTubeWatchers_Jul24'},
        {id:'1145567',name:'1145567 - LR - Paramount Addressable / SambaTV_GamingGenre_Fighting_May24'},
        {id:'618310',name:'618310 - AAM - ViacomCBS Addressable / d6: Extension | Games : Topic - Comicbook Jul21'},
        {id:'618827',name:'618827 - AAM - ViacomCBS Addressable / d6: Extension | Nintendo : Platform - Games Jul21'},
        {id:'618828',name:'618828 - AAM - ViacomCBS Addressable / d6: Extension | PC : Platform - Games Jul21'},
        {id:'618829',name:'618829 - AAM - ViacomCBS Addressable / d6: Extension | Playstation : Platform - Games Jul21'},
        {id:'618830',name:'618830 - AAM - ViacomCBS Addressable / d6: Extension | Xbox : Platform - Games Jul21'},
        {id:'1154498',name:'1154498 - LR - Paramount Addressable / Acxiom_PurchasedBusinessInsurance_Jul24'},
        {id:'1154539',name:'1154539 - LR - Paramount Addressable / AnalyticsIQ_InMarket_DentalInsurance_Jul24'},
        {id:'1154540',name:'1154540 - LR - Paramount Addressable / AnalyticsIQ_InMarket_HomeownersInsurance_Jul24'},
        {id:'1154541',name:'1154541 - LR - Paramount Addressable / AnalyticsIQ_InMarket_HealthInsurance_Jul24'},
        {id:'1154542',name:'1154542 - LR - Paramount Addressable / AnalyticsIQ_AutoInsurance_Jul24'},
        {id:'1154543',name:'1154543 - LR - Paramount Addressable / AcxiomUSInvestment_1M-2M_Jul24'},
        {id:'1154612',name:'1154612 - LR - Paramount Addressable / AcxiomUSInvestment_100K-249K_Jul24'},
        {id:'1154544',name:'1154544 - LR - Paramount Addressable / AcxiomUSInvestment_250K-499K_Jul24'},
        {id:'1154545',name:'1154545 - LR - Paramount Addressable / AcxiomUSInvestment_500K-999K_Jul24'},
        {id:'1154770',name:'1154770 - LR - Paramount Addressable / Acxiom_LiquidInvestible_500k-5M_plus_Jul24'},
        {id:'1154548',name:'1154548 - LR - Paramount Addressable / Acxiom_Interest_Arts_Jul24'},
        {id:'1154549',name:'1154549 - LR - Paramount Addressable / Acxiom_Interest_Baking_Jul24'},
        {id:'1154250',name:'1154250 - LR - Paramount Addressable / Acxiom_Interest_Barbecuing_Jul24'},
        {id:'1154251',name:'1154251 - LR - Paramount Addressable / Acxiom_Interest_Career_Jul24'},
        {id:'1154252',name:'1154252 - LR - Paramount Addressable / Acxiom_Interest_Charities_Jul24'},
        {id:'1153606',name:'1153606 - LR - Paramount Addressable / Acxiom_Interest_CurrentAffairsPolitics_July24'},
        {id:'1153607',name:'1153607 - LR - Paramount Addressable / Acxiom_Interest_EnvironmentalIssues_July24'},
        {id:'1153610',name:'1153610 - LR - Paramount Addressable / Acxiom_Interest_Fashion_July24'},
        {id:'1153611',name:'1153611 - LR - Paramount Addressable / Acxiom_Interest_Wines_July24'},
        {id:'1153612',name:'1153612 - LR - Paramount Addressable / Acxiom_Interest_HomeFurnishingsDecorating_July24'},
        {id:'1153613',name:'1153613 - LR - Paramount Addressable / Acxiom_Interest_Gardening_July24'},
        {id:'1153614',name:'1153614 - LR - Paramount Addressable / Acxiom_Interest_HomeImprovementDIY_July24'},
        {id:'1153437',name:'1153437 - LR - Paramount Addressable / Acxiom_Interest_HomeImprovement_Jul24'},
        {id:'1153438',name:'1153438 - LR - Paramount Addressable / Acxiom_Interest_Home_UpscaleLiving_Jul24'},
        {id:'1153439',name:'1153439 - LR - Paramount Addressable / Acxiom_Interest_Museums_Jul24'},
        {id:'1153440',name:'1153440 - LR - Paramount Addressable / Acxiom_OutdoorActivitiesCampingHikingInterest_Jul24'},
        {id:'1153442',name:'1153442 - LR - Paramount Addressable / Acxiom_OutdoorActivitiesGroupingInterest_Jul24'},
        {id:'1110827',name:'1110827 - LR - Paramount Addressable / Acxiom_Interest_Parenting_Feb24'},
        {id:'1153443',name:'1153443 - LR - Paramount Addressable / Acxiom_Interest_Photography_Jul24'},
        {id:'1153444',name:'1153444 - LR - Paramount Addressable / Acxiom_Interest_Reading_Jul24'},
        {id:'1153445',name:'1153445 - LR - Paramount Addressable / AcxiomUSMedia_NewsShows_Jul24'},
        {id:'1100517',name:'1100517 - LR - Paramount Addressable / Acxiom_PropertyType_Single_Jul23'},
        {id:'1100516',name:'1100516 - LR - Paramount Addressable / Acxiom_PropertyTypeDetail_SingleFamilyUnit_Jul23'},
        {id:'1153448',name:'1153448 - LR - Paramount Addressable / Acxiom_LowCarbDiet_Jul24'},
        {id:'1153450',name:'1153450 - LR - Paramount Addressable / Acxiom_VeganDiet_Jul24'},
        {id:'1154253',name:'1154253 - LR - Paramount Addressable / Acxiom_VegetarianDiet_Jul24'},
        {id:'1156718',name:'1156718 - LR - Paramount Addressable / CausewaySolutions_NewHomeOwner_Jul24'},
        {id:'1153447',name:'1153447 - LR - Paramount Addressable/ Bridge_JobSeekers_Jul24'},
        {id:'1153452',name:'1153452 - LR - Paramount Addressable / Claritas_BuyEcoFriendlyCleanProducts_Jul24'},
        {id:'1153453',name:'1153453 - LR - Paramount Addressable / Claritas_OrganicProductPurchasers_Jul24'},
        {id:'1153449',name:'1153449 - LR - Paramount Addressable / DatastreamGroup_PlantBasedEaters_Jul24'},
        {id:'1135100',name:'1135100 - LR - Paramount Addressable / ATVEXP_LifestyleandInterests_AmusementParkVisitors_Feb24'},
        {id:'1135150',name:'1135150 - LR - Paramount Addressable / ATVEXP_LifestyleandInterests_CordCutters_Feb24'},
        {id:'1135109',name:'1135109 - LR - Paramount Addressable / ATVEXP_LifestyleandInterests_InMarketFurniture_Feb24'},
        {id:'1135136',name:'1135136 - LR - Paramount Addressable / ATVEXP_LifestyleandInterests_InMarketHomeImprovement_Feb24'},
        {id:'1135144',name:'1135144 - LR - Paramount Addressable / ATVEXP_LifestyleandInterests_Movies_ActionAdventureMovieLovers_Feb24'},
        {id:'1135140',name:'1135140 - LR - Paramount Addressable / ATVEXP_LifestyleandInterests_Movies_SciFiMovieBuff_Feb24'},
        {id:'1135148',name:'1135148 - LR - Paramount Addressable / ATVEXP_LifestyleandInterests_Pets_DogOwners_Feb24'},
        {id:'1135106',name:'1135106 - LR - Paramount Addressable / ATVEXP_LifestyleandInterests_VideoGamer_Feb24'},
        {id:'1135089',name:'1135089 - LR-VCBS Addressable/ ATVEXP_LifestyleandInterests_Sports_CollegeFootballBowls_Feb24'},
        {id:'1135083',name:'1135083 - LR-VCBS Addressable/ ATVEXP_LifestyleandInterests_SportsandRecreation_SportsEnthusiast_Feb24'},
        {id:'1135099',name:'1135099 - LR-VCBS Addressable/ ATVEXP_LifestyleandInterests_StyleandFashion_Feb24'},
        {id:'1135101',name:'1135101 - LR-VCBS Addressable/ ATVEXP_LifestyleandInterests_TelevisionTV_RealityTVShowViewer_Feb24'},
        {id:'1135082',name:'1135082 - LR-VCBS Addressable/ ATVEXP_LifestyleandInterests_TelevisionTVNews_Feb24'},
        {id:'1154546',name:'1154546 - LR - Paramount Addressable / Acxiom_Interest_Dieting_WeightLoss_Jul24'},
        {id:'1154547',name:'1154547 - LR - Paramount Addressable / Acxiom_Interest_Exercise_Health_Jul24'},
        {id:'1156717',name:'1156717 - LR - Paramount Addressable / Stirista_BlueCollar_Jul24'},
        {id:'1153454',name:'1153454 - LR - Paramount Addressable / AnalyticsIQ_PetOwners_CatOrDog_Jul24'},
        {id:'1122680',name:'1122680 - LR - Paramount Addressable / AnalyticsIQ_PetOwner_Cat_Dec23'},
        {id:'1122681',name:'1122681 - LR - Paramount Addressable / AnalyticsIQ_PetOwner_Dog_Dec23'},
        {id:'1153455',name:'1153455 - LR - Paramount Addressable / IRI_PetSupplies_Jul24'},
        {id:'1153456',name:'1153456 - LR - Paramount Addressable/ Acxiom_PoliticalViews_Conservative_Jul24'},
        {id:'1153457',name:'1153457 - LR - Paramount Addressable / Acxiom_PoliticalViews_Liberal_Jul24'},
        {id:'1153458',name:'1153458 - LR Paramount Addressable / Acxiom_PoliticalViews_Neutral_Jul24'},
        {id:'1153459',name:'1153459 - LR - Paramount Addressable / Acxiom_PoliticalViews_NotInterestedinPolitics_Jul24'},
        {id:'1153460',name:'1153460 - LR - Paramount Addressable / Acxiom_PoliticalViews_Responsibility_Jul24'},
        {id:'1153461',name:'1153461 - LR - Paramount Addressable / Acxiom_PoliticalViews_VeryInterestedinPolitics_Jul24'},
        {id:'1153824',name:'1153824 - LR - Paramount Addressable / Acxiom_PoliticalActivities_WorkedPolitics-PastYear_Jul24'},
        {id:'1153827',name:'1153827 - LR - Paramount Addressable / Acxiom_PoliticalActivities_Held-RanforOffice_Jul24'},
        {id:'1153828',name:'1153828 - LR - Paramount Addressable / Acxiom_PoliticalActivities_WorkedPoliticalParty_Jul24'},
        {id:'1153829',name:'1153829 - LR - Paramount Addressable / Acxiom_PoliticalParty_Democrat_Jul24'},
        {id:'1153830',name:'1153830 - LR - Paramount Addressable / Acxiom_PoliticalParty_Independent_Jul24'},
        {id:'1153831',name:'1153831 - LR - Paramount Addressable / Acxiom_PoliticalParty_Republican_Jul24'},
        {id:'1153832',name:'1153832 - LR - Paramount Addressable / Acxiom_PoliticalParty_Unknown_Jul24'},
        {id:'1153836',name:'1153836 - LR - Paramount Addressable / Adstra_AllVoters_Jul24'},
        {id:'1154111',name:'1154111 - LR - Paramount Addressable / Adstra_Democrat-PersuadableSoft_Jul24'},
        {id:'1154112',name:'1154112 - LR - Paramount Addressable / Adstra_Democrat-YellowDog_Jul24'},
        {id:'1154113',name:'1154113 - LR - Paramount Addressable / Adstra_DemocratHousehold_Jul24'},
        {id:'1154114',name:'1154114 - LR - Paramount Addressable / Adstra_RepublicanHardcore_Jul24'},
        {id:'1154123',name:'1154123 - LR - Paramount Addressable / Adstra_Republican-PersuadableSoft_Jul24'},
        {id:'1154187',name:'1154187 - LR - Paramount Addressable / Adstra_RepublicanHouseholds_Jul24'},
        {id:'1154188',name:'1154188 - LR - Paramount Addressable / Adstra_FemaleVoters_Jul24'},
        {id:'1154189',name:'1154189 - LR - Paramount Addressable / Adstra_MaleVoters_Jul24'},
        {id:'1154190',name:'1154190 - LR - Paramount Addressable / Adstra_MarriedVoters_Jul24'},
        {id:'1154191',name:'1154191 - LR - Paramount Addressable / Adstra_SingleVoters_Jul24'},
        {id:'1154193',name:'1154193 - LR - Paramount Addressable / Adstra_MillennialVoters_Jul24'},
        {id:'1154194',name:'1154194 - LR - Paramount Addressable / Adstra_SeniorVoters_Jul24'},
        {id:'1154195',name:'1154195 - LR - Paramount Addressable / Adstra_VotersWithChildren_Jul24'},
        {id:'1154196',name:'1154196 - LR - Paramount Addressable / Adstra_NewVoters_Jul24'},
        {id:'1153833',name:'1153833 - LR - Paramount Addressable / Acxiom_SociallyConservative_Jul24'},
        {id:'1153834',name:'1153834 - LR - Paramount Addressable / Acxiom_SociallyLiberal_Jul24'},
        {id:'1153835',name:'1153835 - LR - Paramount Addressable / Acxiom_SociallyModerate_Jul24'},
        {id:'1136910',name:'1136910 - LR - Paramount Addressable / ATVEXP_Politics_PoliticalAffiliation_Independent_Feb24'},
        {id:'1135114',name:'1135114 - LR-VCBS Addressable/ ATVEXP_Politics_PoliticalAffiliation_Democrat_Feb24'},
        {id:'1135085',name:'1135085 - LR-VCBS Addressable/ ATVEXP_Politics_PoliticalAffiliation_Republican_Feb24'},
        {id:'1135117',name:'1135117 - LR-VCBS Addressable/ ATVEXP_Politics_PoliticalAffiliation_Unregistered_Feb24'},
        {id:'1135103',name:'1135103 - LR-VCBS Addressable/ ATVEXP_Politics_UltraConservatives_Feb24'},
        {id:'1154201',name:'1154201 - LR - Paramount Addressable / Acxiom_Likely_FineDiningRestaurant_Jul24'},
        {id:'1139847',name:'1139847 - LR - Paramount Addressable / AuDigent_Foodies_Apr24'},
        {id:'1139832',name:'1139832 - LR - Paramount Addressable / Clickagy_CasualDining_Apr24'},
        {id:'1139833',name:'1139833 - LR - Paramount Addressable / DatastreamGroup_RegularCoffeeDrinkers_Apr24'},
        {id:'1136907',name:'1136907 - LR - Paramount Addressable / AATVEXP_PurchasePredictors_FoodSnacksBeverages_Feb24'},
        {id:'1135125',name:'1135125 - LR-VCBS Addressable/ ATVEXP_PurchaseBased_FoodandDrinkRestaurants_CasualDiningFrequentSpenders_Feb24'},
        {id:'1135124',name:'1135124 - LR-VCBS Addressable/ ATVEXP_PurchaseBased_FoodandDrinkRestaurants_FastFoodQSRFrequentSpenders_Feb24'},
        {id:'1139834',name:'1139834 - LR - Paramount Addressable / Kantar_FineDining_Apr24'},
        {id:'1140059',name:'1140059 - LR - Paramount Addressable / NInthDecimal_Retail_JewelryStores_Apr24'},
        {id:'1154203',name:'1154203 - LR - Paramount Addressable / Acxiom_QSR_BurgerKing_Jul24'},
        {id:'1154204',name:'1154204 - LR - Paramount Addressable / Acxiom_QSR_Chick-Fil-A_Jul24'},
        {id:'1154205',name:'1154205 - LR - Paramount Addressable / Acxiom_QSR_KFC_Jul24'},
        {id:'1154207',name:'1154207 - LR - Paramount Addressable / Acxiom_QSR_TacoBell_Jul24'},
        {id:'1154208',name:'1154208 - LR - Paramount Addressable / Acxiom_QSR_McDonalds_Jul24'},
        {id:'1097933',name:'1097933 - LR-VCBS Addressable/ Acxiom_QSR_Wendys_June23'},
        {id:'1154218',name:'1154218 - LR - Paramount Addressable / AffinityAnswers_Intent_Jewelry_Jul24'},
        {id:'1140064',name:'1140064 - LR - Paramount Addressable / AnalyticsIQ_HomeDecorating_Apr24'},
        {id:'1154209',name:'1154209 - LR - Paramount Addressable / AnalyticsIQ_Online_Shoppers_Jul24'},
        {id:'1140058',name:'1140058 - LR - Paramount Addressable / Claritas_Retail_JCPenney_Apr24'},
        {id:'1140060',name:'1140060 - LR - Paramount Addressable / Claritas_Retail_Target_Apr24'},
        {id:'1140061',name:'1140061 - LR - Paramount Addressable / Claritas_Retail_Walmart_Apr24'},
        {id:'1140062',name:'1140062 - LR - Paramount Addressable / Claritas_Retail_BestBuy_Apr24'},
        {id:'1154210',name:'1154210 - LR - Paramount Addressable / Cubeiq_DIY_Shoppers_Jul24'},
        {id:'1154212',name:'1154212 - LR - Paramount Addressable / Experian_HolidayShoppers_ConsumerElectronics_Jul24'},
        {id:'1154213',name:'1154213 - LR - Paramount Addressable / Experian_HolidayShoppers_LastMinute_Jul24'},
        {id:'1154214',name:'1154214 - LR - Paramount Addressable / Experian_RetailShoppers_CamerasPhotography_HighSpender_Jul24'},
        {id:'1154215',name:'1154215 - LR - Paramount Addressable / Experian_RetailShoppers_ConsumerElectronics_InStoreHighSpender_Jul24'},
        {id:'1135107',name:'1135107 - LR-VCBS Addressable/ ATVEXP_PersonasOnlineBuyerHeavyOnlineShopperModel_Feb24'},
        {id:'1135119',name:'1135119 - LR-VCBS Addressable/ ATVEXP_PsychographicAttitudesShoppingBehaviorBrandLoyals_Feb24'},
        {id:'1136916',name:'1136916 - LR - Paramount Addressable / ATVEXP_PurchaseTransactions_CosmeticsBeautyProductsHighSpenders_Feb24'},
        {id:'1135137',name:'1135137 - LR-VCBS Addressable/ ATVEXP_PurchaseTransactions_CosmeticsandBeautyProductsSpenders_Feb24'},
        {id:'1135143',name:'1135143 - LR-VCBS Addressable/ ATVEXP_PurchaseTransactions_DietaryHealthSupplementsSpenders_Feb24'},
        {id:'1135162',name:'1135162 - LR-VCBS Addressable/ ATVEXP_PurchaseBased_HomeImprovement_DIY_InStoreHighSpenders_Feb24'},
        {id:'1135159',name:'1135159 - LR-VCBS Addressable/ ATVEXP_PurchaseTransactions_HomeImprovementDIYSpenders_Feb24'},
        {id:'1135139',name:'1135139 - LR-VCBS Addressable/ ATVEXP_PurchaseTransactions_HouseholdGoodsSpenders_Feb24'},
        {id:'1135141',name:'1135141 - LR-VCBS Addressable/ ATVEXP_PurchaseTransactions_JewelrySpenders_Feb24'},
        {id:'1135131',name:'1135131 - LR-VCBS Addressable/ ATVEXP_PurchaseTransactions_PaymentTypeCreditCardsHighSpenders_Feb24'},
        {id:'1135135',name:'1135135 - LR-VCBS Addressable/ ATVEXP_PurchaseTransactions_PetsSpenders_Feb24'},
        {id:'1135165',name:'1135165 - LR-VCBS Addressable/ ATVEXP_PurchaseBased_ShoppingBehavior_ChildrenFamilyFamilieswithBabies_Feb24'},
        {id:'1135123',name:'1135123 - LR-VCBS Addressable/ ATVEXP_PurchaseBased_ShoppingBehavior_BabyProductsInStoreFrequentSpenders_Feb24'},
        {id:'1136914',name:'1136914 - LR - Paramount Addressable / ATVEXP_PurchasePredictors_ElectronicsAppliances_Feb24'},
        {id:'1135163',name:'1135163 - LR-VCBS Addressable/ ATVEXP_PurchaseBased_ConsumerElectronics_InStoreHighSpenders_Feb24'},
        {id:'1135164',name:'1135164 - LR-VCBS Addressable/ ATVEXP_PurchaseBased_ConsumerElectronics_OnlineHighSpenders_Feb24'},
        {id:'1135120',name:'1135120 - LR-VCBS Addressable/ ATVEXP_PurchaseBased_ShoppingBehavior_JewelryWatchesHighSpenders_Feb24'},
        {id:'1140236',name:'1140236 - LR - Paramount Addressable / Ibotta_CPG_HomeImprovement_Apr24'},
        {id:'1154211',name:'1154211 - LR - Paramount Addressable / Stirista_BlackFriday_CyberMonday_Shoppers_Jul24'},
        {id:'1154216',name:'1154216 - LR - Paramount Addressable / UltimateData_ModernFurniture_Jul24'},
        {id:'1154217',name:'1154217 - LR - Paramount Addressable / UltimateData_HomeImprovementDecorRetailCooperativeBrands_Jul24'},
        {id:'1154219',name:'1154219 - LR - Paramount Addressable / Acxiom_Interests_Sports_Jul24'},
        {id:'1156719',name:'1156719 - LR - Paramount Addressable / SambaTV_CollegeFootballTVViewers_Jul24'},
        {id:'1154220',name:'1154220 - LR - Paramount Addressable / AffinityAnswers_Events_NBAPlayoffs_Jul24'},
        {id:'1154221',name:'1154221 - LR - Paramount Addressable / AffinityAnswers_Events_MarchMadness_Jul24'},
        {id:'1154222',name:'1154222 - LR - Paramount Addressable / AffinityAnswers_Events_PGAChampionship_Jul24'},
        {id:'1154223',name:'1154223 - LR - Paramount Addressable / AffinityAnswers_Events_USOpen_Jul24'},
        {id:'1154224',name:'1154224 - LR - Paramount Addressable / AffinityAnswers_Sports_AutoRacing_Jul24'},
        {id:'1154225',name:'1154225 - LR - Paramount Addressable / AffinityAnswers_Sports_HorseRacing_Jul24'},
        {id:'1154226',name:'1154226 - LR - Paramount Addressable / AffinityAnswers_Sports_MLB_Jul24'},
        {id:'1154227',name:'1154227 - LR - Paramount Addressable / AffinityAnswers_Sports_MLS_Jul24'},
        {id:'1154228',name:'1154228 - LR - Paramount Addressable / AffinityAnswers_Sports_NBA_Jul24'},
        {id:'1154229',name:'1154229 - LR - Paramount Addressable / AffinityAnswers_Sports_NFL_Jul24'},
        {id:'1154230',name:'1154230 - LR - Paramount Addressable / AffinityAnswers_Sports_NHL_Jul24'},
        {id:'1154231',name:'1154231 - LR - Paramount Addressable / AffinityAnswers_Sports_Rugby_Jul24'},
        {id:'1154232',name:'1154232 - LR - Paramount Addressable / AffinityAnswers_Sports_UFC_Jul24'},
        {id:'1154233',name:'1154233 - LR - Paramount Addressable / AffinityAnswers_Sports_WWE_Jul24'},
        {id:'619080',name:'619080 - AAM - ViacomCBS Addressable / d10: Extension | Baseball : Interest - Maxpreps Jul21'},
        {id:'619082',name:'619082 - AAM - ViacomCBS Addressable / d10: Extension | Boxing : Sport - Sports Jul21'},
        {id:'619085',name:'619085 - AAM - ViacomCBS Addressable / d10: Extension | College Basketball - Sport - Sports Jul21'},
        {id:'619086',name:'619086 - AAM - ViacomCBS Addressable / d10: Extension | College Football : Interest - Sports Jul21'},
        {id:'619089',name:'619089 - AAM - ViacomCBS Addressable / d10: Extension | Fantasy Sports : CBSSports Jul21'},
        {id:'619090',name:'619090 - AAM - ViacomCBS Addressable / d10: Extension | Golf : Sport - Sports Jul21'},
        {id:'619092',name:'619092 - AAM - ViacomCBS Addressable / d10: Extension | MLB Baseball : Interest - Sports Jul21'},
        {id:'619093',name:'619093 - AAM - ViacomCBS Addressable / d10: Extension | NBA : Sport - Sports Jul21'},
        {id:'619094',name:'619094 - AAM - ViacomCBS Addressable / d10: Extension | NBA and Basketball - Sport - Sports Jul21'},
        {id:'618437',name:'618437 - AAM - ViacomCBS Addressable / d10: Extension | NFL : Sport - Sports Jul21'},
        {id:'619109',name:'619109 - AAM - ViacomCBS Addressable / d10: Extension | NHL & Hockey : Sport - CBSSports Jul21'},
        {id:'619110',name:'619110 - AAM - ViacomCBS Addressable / d10: Extension | Olympics : Sport - Sports Jul21'},
        {id:'616447',name:'616447 - AAM - ViacomCBS Addressable / d10: Extension | Soccer : Sport - Sports Jun21'},
        {id:'619113',name:'619113 - AAM - ViacomCBS Addressable / d10: Extension | Sports : MaxPreps Jul21'},
        {id:'619114',name:'619114 - AAM - ViacomCBS Addressable / d10: Extension | Sports HQ : Live Stream - Sports Jul21'},
        {id:'619115',name:'619115 - AAM - ViacomCBS Addressable / d10: Extension | Super Bowl 2021 : Live Stream Jul21'},
        {id:'619119',name:'619119 - AAM - ViacomCBS Addressable / d10: Extension | Tennis : Sport - Sports Jul21'},
        {id:'619128',name:'619128 - AAM - ViacomCBS Addressable / d10: Extension | Women\'s NCAA - Sports Jul21'},
        {id:'619132',name:'619132 - AAM - ViacomCBS Addressable / d10: Extension | WWE : Sport - Sports Jul21'},
        {id:'619136',name:'619136 - AAM - ViacomCBS Addressable / d11: Profiles | Sports : Interest - Sports Jul21'},
        {id:'1154235',name:'1154235 - LR - Paramount Addressable / Acxiom_InMarket_Desktop_Jul24'},
        {id:'1154236',name:'1154236 - LR - Paramount Addressable / Acxiom_LaptopLikelyProductType_Jul24'},
        {id:'1156736',name:'1156736 - LR - Paramount Addressable / Acxiom_SubscribetoStreamingVidServices_July24'},
        {id:'1154238',name:'1154238 - LR - Paramount Addressable / Bridge_HHDoesNotSubscribetoCable_Jul24'},
        {id:'1154234',name:'1154234 - LR - Paramount Addressable / Claritas_EarlyAdopters_Jul24'},
        {id:'1123728',name:'1123728 - LR - Paramount Addressable / Epsilon_ElectronicsEnthusiast_Jan24'},
        {id:'1154237',name:'1154237 - LR - Paramount Addressable / Experian_CordCutter_Recent_Jul24'},
        {id:'1135128',name:'1135128 - LR-VCBS Addressable/ ATVEXP_PurchaseBased_Technology_Telecom_ATTmobilephoneSpenders_Feb24'},
        {id:'1135129',name:'1135129 - LR-VCBS Addressable/ ATVEXP_PurchaseBased_Technology_Telecom_MobileswitchersfromATT_Feb24'},
        {id:'1135172',name:'1135172 - LR-VCBS Addressable/ ATVEXP_PurchaseBased_Technology_Telecom_MobileswitchersfromBoost_Feb24'},
        {id:'1135170',name:'1135170 - LR-VCBS Addressable/ ATVEXP_PurchaseBased_Technology_Telecom_MobileswitchersfromCricket_Feb24'},
        {id:'1135171',name:'1135171 - LR-VCBS Addressable/ ATVEXP_PurchaseBased_Technology_Telecom_MobileswitchersfromSprint_Feb24'},
        {id:'1135168',name:'1135168 - LR-VCBS Addressable/ ATVEXP_PurchaseBased_Technology_Telecom_MobileswitchersfromTMobile_Feb24'},
        {id:'1135169',name:'1135169 - LR-VCBS Addressable/ ATVEXP_PurchaseBased_Technology_Telecom_MobileswitchersfromVerizon_Feb24'},
        {id:'1135167',name:'1135167 - LR-VCBS Addressable/ ATVEXP_PurchaseBased_Technology_Telecom_TMobilemobilephoneSpenders_Feb24'},
        {id:'1135166',name:'1135166 - LR-VCBS Addressable/ ATVEXP_PurchaseBased_Technology_Telecom_VerizonmobilephoneSpenders_Feb24'},
        {id:'1154239',name:'1154239 - LR - Paramount Addressable / Acxiom_Travel_FamilyTravelers_Jul24'},
        {id:'1140065',name:'1140065 - LR - Paramount Addressable / Acxiom_Travel_CasinoVacation_Apr24'},
        {id:'1154245',name:'1154245 - LR - Paramount Addressable / Acxiom_Travel_AmusementPark_Jul24'},
        {id:'1154246',name:'1154246 - LR - Paramount Addressable / Acxiom_Travel_Golfing_Jul24'},
        {id:'1154247',name:'1154247 - LR - Paramount Addressable / AffinitySolutions_AmusementPark_Jul24'},
        {id:'1144664',name:'1144664 - LR - Paramount Addressable / AffinitySolutions_UniversalOrlandoIntenders_May24'},
        {id:'1140238',name:'1140238 - LR - Paramount Addressable / Alliant_Propensity_EconomyHotelVisitors_Apr24'},
        {id:'1140239',name:'1140239 - LR - Paramount Addressable / Alliant_Propensity_LuxuryHotelVisitors_Apr24'},
        {id:'1129181',name:'1129181 - LR - Paramount Addressable / AnalyticsIQ_Travel_PostponedTravel_Jan24'},
        {id:'1129180',name:'1129180 - LR - Paramount Addressable / AnalyticsIQ_Travel_TravelLikely3mth_Jan24'},
        {id:'1154240',name:'1154240 - LR - Paramount Addressable / AnalyticsIQ_Travel_LuxuryTravelIntenders_Jul24'},
        {id:'1154248',name:'1154248 - LR - Paramount Addressable / AnalyticsIQ_Interest_InternationalTravel_Jul24'},
        {id:'1154249',name:'1154249 - LR - Paramount Addressable / AnalyticsIQ_Intender_InternationalTravel_Jul24'},
        {id:'1123731',name:'1123731 - LR - Paramount Addressable /AnalyticsIQ_TravelInterest_Domestic_Dec23'},
        {id:'1154241',name:'1154241 - LR - Paramount Addressable / AuDigent_TripsToCalifornia_Jul24'},
        {id:'1154242',name:'1154242 - LR - Paramount Addressable / AuDigent_TripsToLA_Jul24'},
        {id:'1140067',name:'1140067 - LR - Paramount Addressable / AuDigent_Travel_FamilyVacationers_Apr24'},
        {id:'1145471',name:'1145471 - LR - Paramount Addressable / Claritas_PlanToTravelToFlorida_May24'},
        {id:'1154243',name:'1154243 - LR - Paramount Addressable / Clickagy_Amusement-ThemeParks_Jul24'},
        {id:'1154244',name:'1154244 - LR - Paramount Addressable / Clickagy_Travel_LA_Jul24'},
        {id:'1136915',name:'1136915 - LR - Paramount Addressable / ATVEXP_PurchasePredictorsShoppers_Travel_Feb24'},
        {id:'1135173',name:'1135173 - LR-VCBS Addressable/ ATVEXP_PurchaseBased_Travel_HotelsEconomyMidscale_Feb24'},
        {id:'1135174',name:'1135174 - LR-VCBS Addressable/ ATVEXP_PurchaseBased_TravelHotelsLuxury_Feb24'},
        {id:'1135180',name:'1135180 - LR-VCBS Addressable/ ATVEXP_RetailShoppers_Travel_SummerTrips_Feb24'},
        {id:'1135178',name:'1135178 - LR-VCBS Addressable/ ATVEXP_RetailShoppersPurchaseBasedTravelVacationLeisureTravelersLuxury_Feb24'},
        {id:'1135176',name:'1135176 - LR-VCBS Addressable/ ATVEXP_TravelIntent_LikelihoodtovisitAmusementPark_Feb24'},
        {id:'1135177',name:'1135177 - LR-VCBS Addressable/ ATVEXP_TravelIntent_TravelPreference_BeachWaterfront_Feb24'},
        {id:'1135175',name:'1135175 - LR-VCBS Addressable/ ATVEXP_TravelIntent_TravelType_TraveledforLeisureinlast12months_Feb24'},
        {id:'1140237',name:'1140237 - LR - Paramount Addressable / Stitista_ThemeandAmusementParks_Apr24'}]
        
        const geos = ['State: Alabama - 2256',	'State: Alaska - 2257',	'State: Arizona - 2258',	'State: Arkansas - 2259',	
        'State: California - 2263',	'State: Colorado - 2264',	'State: Connecticut - 2265',	
        'State: Delaware - 2266',	'State: District Of Columbia - 2267',	'State: Florida - 2268',	
        'State: Georgia - 2269',	'State: Hawaii - 2270',	'State: Idaho - 2271',	'State: Illinois - 2272',	
        'State: Indiana - 2273',	'State: Iowa - 2274',	'State: Kansas - 2275',	'State: Kentucky - 2276',	
        'State: Louisiana - 2277',	'State: Maine - 2278',	'State: Maryland - 2279',	'State: Massachusetts - 2280',	
        'State: Michigan - 2281',	'State: Minnesota - 2282',	'State: Mississippi - 2283',	'State: Missouri - 2284',	
        'State: Montana - 2285',	'State: Nebraska - 2286',	'State: Nevada - 2287',	'State: New Hampshire - 2288',	
        'State: New Jersey - 2289',	'State: New Mexico - 2290',	'State: New York - 2291',	'State: North Carolina - 2292',	
        'State: North Dakota - 2293',	'State: Ohio - 2294',	'State: Oklahoma - 2295',	'State: Oregon - 2296',	'State: Pennsylvania - 2297',	
        'State: Rhode Island - 2298',	'State: South Carolina - 2299',	'State: South Dakota - 2300',	'State: Tennessee - 2301',	'State: Texas - 2302',
        'State: Utah - 2303',	'State: Vermont - 2304',	'State: Virginia - 2305',	'State: Washington - 2306',	'State: West Virginia - 2307',	
        'State: Wisconsin - 2308',	'State: Wyoming - 2309',	'DMA: Portland-Auburn, ME - 1',	'DMA: New York, NY - 2',	'DMA: Binghamton, NY - 3',	
        'DMA: Macon, GA - 4',	'DMA: Philadelphia, PA - 5',	'DMA: Detroit, MI - 6',	'DMA: Boston-Manchester, MA - 7',	'DMA: Savannah, GA-SC - 8',	
        'DMA: Pittsburgh, PA - 9',	'DMA: Ft Wayne, IN - 10',	'DMA: Cleveland-Akron-Canton, OH - 11',	'DMA: Washington-Hagerstown, DC - 12',	'DMA: Baltimore, MD - 13',	
        'DMA: Flint-Saginaw-Bay City, MI - 14',	'DMA: Buffalo, NY - 15',	'DMA: Cincinnati, OH - 16',	'DMA: Erie, PA - 17',	'DMA: Charlotte, NC-SC - 18',	
        'DMA: Winston Salem-Greensboro-High Point, NC - 19',	'DMA: Charleston, SC - 20',	'DMA: Augusta-Aiken, GA-SC - 21',	'DMA: Providence-New Bedford, RI - 22',
        'DMA: Columbus, GA-Opelika, AL - 23',	'DMA: Burlington, VT-Plattsburgh, NY - 24',	'DMA: Atlanta, GA - 25',	'DMA: Albany, GA - 26',	'DMA: Utica-Rome, NY - 27',
        'DMA: Indianapolis, IN - 28',	'DMA: Miami-Ft. Lauderdale, FL - 29',	'DMA: Louisville, KY - 30',	'DMA: Tallahassee-Thomasville, FL - 31',	
        'DMA: Tri-Cities, TN - 32',	'DMA: Albany-Schenectady-Troy, NY - 33',	'DMA: Hartford-New Haven, CT - 34',	'DMA: Orlando-Daytona Beach-Melbourne, FL - 35',	
        'DMA: Columbus, OH - 36',	'DMA: Youngstown-Warren, OH - 37',	'DMA: Bangor, ME - 38',	'DMA: Rochester, NY - 39',	
        'DMA: Tampa-St. Petersburg-Sarasota, FL - 40',	'DMA: Traverse City-Cadillac, MI - 41',	'DMA: Lexington, KY - 42',	
        'DMA: Dayton, OH - 43',	'DMA: Springfield-Holyoke, MA - 44',	'DMA: Norfolk-Portsmouth, VA - 45',	'DMA: Greenville-New Bern-Washington, NC - 46',
        'DMA: Columbia, SC - 47',	'DMA: Toledo, OH - 48',	'DMA: West Palm Beach-Ft. Pierce, FL - 49',	'DMA: Watertown, NY - 50',	'DMA: Wilmington, NC - 51',
        'DMA: Lansing, MI - 52',	'DMA: Presque Isle, ME - 53',	'DMA: Marquette, MI - 54',	'DMA: Wheeling, WV-Steubenville, OH - 55',	
        'DMA: Syracuse, NY - 56',	'DMA: Richmond-Petersburg, VA - 57',	'DMA: Knoxville, TN - 58',	'DMA: Lima, OH - 59',	
        'DMA: Bluefield-Beckley-Oak Hill, WV - 60',	'DMA: Raleigh-Durham-Fayetteville, NC - 61',	'DMA: Jacksonville, FL - 62',	
        'DMA: Grand Rapids-Kalamazoo-Battle Creek, MI - 63',	'DMA: Charleston-Huntington, WV - 64',	'DMA: Elmira-Corning, NY - 65',	
        'DMA: Harrisburg-Lancaster-Lebanon-York, PA - 66',	'DMA: Greenville-Spartanburg-Asheville, SC - 67',	'DMA: Harrisonburg, VA - 68',	
        'DMA: Myrtle Beach-Florence, SC - 69',	'DMA: Ft. Myers-Naples, FL - 70',	'DMA: Roanoke-Lynchburg, VA - 71',	
        'DMA: Johnstown-Altoona-State College, PA - 72',	'DMA: Chattanooga, TN - 73',	'DMA: Salisbury, MD - 74',	
        'DMA: Wilkes Barre-Scranton-Hazleton, PA - 75',	'DMA: Terre Haute, IN - 76',	'DMA: Lafayette, IN - 77',	
        'DMA: Alpena, MI - 78',	'DMA: Charlottesville, VA - 79',	'DMA: South Bend-Elkhart, IN - 80',	'DMA: Gainesville, FL - 81',	
        'DMA: Zanesville, OH - 82',	'DMA: Parkersburg, WV - 83',	'DMA: Clarksburg-Weston, WV - 84',	'DMA: Corpus Christi, TX - 85',	
        'DMA: Chicago, IL - 86',	'DMA: Joplin-Pittsburg, MO - 87',	'DMA: Columbia-Jefferson City, MO - 88',	'DMA: Topeka, KS - 89',
        'DMA: Dothan, AL - 90',	'DMA: St Louis, MO - 91',	'DMA: Rockford, IL - 92',	'DMA: Rochester-Mason City-Austin, MN - 93',	
        'DMA: Shreveport, LA - 94',	'DMA: Minneapolis-St Paul, MN - 95',	'DMA: Kansas City, MO - 96',	'DMA: Milwaukee, WI - 97',	
        'DMA: Houston, TX - 98',	'DMA: Springfield, MO - 99',	'DMA: New Orleans, LA - 100',	'DMA: Dallas-Fort Worth, TX - 101',
        'DMA: Sioux City, IA - 102',	'DMA: Waco-Temple-Bryan, TX - 103',	'DMA: Victoria, TX - 104',	'DMA: Wichita Falls-Lawton, TX - 105',	
        'DMA: Monroe, LA-El Dorado, AR - 106',	'DMA: Birmingham-Anniston-Tuscaloosa, AL - 107',	'DMA: Ottumwa-Kirksville, IA - 108',	
        'DMA: Paducah, KY-Cape Girardeau, MO-Harrisburg-Mt Vernon, IL - 109',	'DMA: Odessa-Midland, TX - 110',	'DMA: Amarillo, TX - 111',	
        'DMA: Austin, TX - 112',	'DMA: Harlingen-Weslaco-Brownsville-McAllen, TX - 113',	'DMA: Cedar Rapids-Waterloo-Iowa City-Dubuque, IA - 114',
        'DMA: St Joseph, MO - 115',	'DMA: Jackson, TN - 116',	'DMA: Memphis, TN - 117',	'DMA: San Antonio, TX - 118',	'DMA: Lafayette, LA - 119',	
        'DMA: Lake Charles, LA - 120',	'DMA: Alexandria, LA - 121',	'DMA: Champaign-Springfield-Decatur, IL - 122',	'DMA: Evansville, IN - 123',
        'DMA: Oklahoma City, OK - 124',	'DMA: Lubbock, TX - 125',	'DMA: Omaha, NE - 126',	'DMA: Panama City, FL - 127',	
        'DMA: Sherman, TX-Ada, OK - 128',	'DMA: Green Bay-Appleton, WI - 129',	'DMA: Nashville, TN - 130',	'DMA: San Angelo, TX - 131',	
        'DMA: Abilene-Sweetwater, TX - 132',	'DMA: Madison, WI - 133',	'DMA: Ft. Smith-Fayetteville-Springdale-Rogers, AR - 134',	
        'DMA: Tulsa, OK - 135',	'DMA: Columbus-Tupelo-West Point-Houston, MS - 136',	'DMA: Peoria-Bloomington, IL - 137',	
        'DMA: Duluth-Superior, MN - 138',	'DMA: Wichita-Hutchinson, KS - 139',	'DMA: Des Moines-Ames, IA - 140',	
        'DMA: Davenport-Rock Island-Moline, IL - 141',	'DMA: Mobile, AL-Pensacola, FL - 142',	'DMA: Minot-Bismarck-Dickinson, ND - 143',	
        'DMA: Huntsville-Decatur, AL - 144',	'DMA: Beaumont-Port Arthur, TX - 145',	'DMA: Little Rock-Pine Bluff, AR - 146',
        'DMA: Montgomery-Selma, AL - 147',	'DMA: La Crosse-Eau Claire, WI - 148',	'DMA: Wausau-Rhinelander, WI - 149',
        'DMA: Tyler-Longview-Jacksonville(Lufkin-Nacogdoches), TX - 150',	'DMA: Hattiesburg-Laurel, MS - 151',	
        'DMA: Meridian, MS - 152',	'DMA: Baton Rouge, LA - 153',	'DMA: Quincy-Hannibal-Keokuk, IL - 154',	
        'DMA: Jackson, MS - 155',	'DMA: Lincoln-Hastings-Kearny, NE - 156',	'DMA: Fargo-Valley City, ND - 157',
        'DMA: Sioux Falls-Mitchell, SD - 158',	'DMA: Jonesboro, AR - 159',	'DMA: Bowling Green, KY - 160',	'DMA: Mankato, MN - 161',
        'DMA: North Platte, NE - 162',	'DMA: Anchorage, AK - 163',	'DMA: Honolulu, HI - 164',	'DMA: Fairbanks, AK - 165',
        'DMA: Biloxi-Gulfport, MS - 166',	'DMA: Laredo, TX - 167',	'DMA: Denver, CO - 168',	
        'DMA: Colorado Springs-Pueblo, CO - 169',	'DMA: Phoenix-Prescott, AZ - 170',	
        'DMA: Butte-Bozeman, MT - 171',	'DMA: Great Falls, MT - 172',	'DMA: Billings, MT - 173',	
        'DMA: Boise, ID - 174',	'DMA: Idaho Falls-Pocatello-Jackson, ID - 175',	'DMA: Cheyenne-Scottsbluff, WY - 176',
        'DMA: Twin Falls, ID - 177',	'DMA: Missoula, MT - 178',	'DMA: Rapid City, SD - 179',	
        'DMA: El Paso-Las Cruces, TX - 180',	'DMA: Helena, MT - 181',	'DMA: Casper-Riverton, WY - 182',
        'DMA: Salt Lake City, UT - 183',	'DMA: Yuma-El Centro, AZ - 184',	'DMA: Grand Junction-Montrose, CO - 185',
        'DMA: Tucson-Sierra Vista, AZ - 186',	'DMA: Albuquerque-Santa Fe, NM - 187',	
        'DMA: Glendive, MT - 188',	'DMA: Bakersfield, CA - 189',	'DMA: Eugene, OR - 190',	
        'DMA: Eureka, CA - 191',	'DMA: Los Angeles, CA - 192',	'DMA: Palm Springs, CA - 193',	
        'DMA: San Francisco-Oakland-San Jose, CA - 194',	'DMA: Yakima-Pasco-Richland-Kennewick, WA - 195',	
        'DMA: Reno, NV - 196',	'DMA: Medford-Klamath Falls, OR - 197',	'DMA: Seattle-Tacoma, WA - 198',
        'DMA: Portland, OR - 199',	'DMA: Bend, OR - 200',	'DMA: San Diego, CA - 201',	
        'DMA: Monterey-Salinas, CA - 202',	'DMA: Las Vegas, NV - 203',
        'DMA: Santa Barbara-San Marcos-San Luis Obispo, CA - 204',	
        'DMA: Sacramento-Stockton-Modesto, CA - 205',	'DMA: Fresno-Visalia, CA - 206',	
        'DMA: Chico-Redding, CA - 207',	'DMA: Spokane, WA - 208',	'DMA: Greenwood-Greenville, MS - 209',
        'DMA: Juneau, AK - 210']
        
        
            const [requestDict, setRequestDict] = React.useState([{product: 'EyeQ RON', 
            platforms: {desktop: true, mobileWeb: true, mobileApp: true, ctv: true},
            demo: 'P2+',
            startDate: dayjs('2024-10-01'),
            endDate: dayjs('2024-12-31'),
            frequencyCaps: 
                [{impressions: '1', time: '30', unit: 'Minutes'}
            ],
            audienceData:
            [],
            left: geos,
            right: ['Country: United States - 165'],
            checked: [],
            searchQuery: '',
            lastSelectedIndex: null,
            geoSwitchChecked: true,
        
        }])
        /*
        const [requests, setRequests] = React.useState([
            {

            },
          ]);
        */
        
        
        
        
        
        
            const [selectValueGeo, setSelectValueGeo] = React.useState('Include');
        
            const [checked, setChecked] = React.useState([]);
            const [left, setLeft] = React.useState(geos);

          
        
            const [geoSwitchChecked, setGeoSwitchChecked] = React.useState(true)
        
        
        
            const formatDataSet = (dataSet) => {
                return dataSet
                  .map((subArray, subArrayIndex) => {
                    if (subArray.length > 1) {
                      return (
                        <div style={{ marginLeft: '20px' }} key={`subArray-${subArrayIndex}`}>
                          {`• (${subArray.map(item => item.name).join(' OR ')})`}
                        </div>
                      );
                    }
                    return (
                      <div style={{ marginLeft: '20px' }} key={`singleItem-${subArrayIndex}`}>
                        {`• ${subArray[0]?.name || ''}`}
                      </div>
                    );
                  })
                  .reduce((acc, elem, idx, arr) => {
                    if (idx === arr.length - 1) {
                      return [...acc, elem];
                    }
                    return [...acc, elem, <div style={{ marginLeft: '20px' }} key={`and-${idx}`}>AND</div>];
                  }, []);
              };
        
              const handleToggle = (value, index, listIndex, isLeft) => (event) => {
                console.log(requestDict)
                const currentList = requestDict[listIndex];
                const currentIndex = currentList.checked.indexOf(value);
                const newChecked = [...currentList.checked];
              
                if (event.shiftKey && currentList.lastSelectedIndex !== null) {
                  const start = Math.min(currentList.lastSelectedIndex, index);
                  const end = Math.max(currentList.lastSelectedIndex, index);
                  
                  // Determine which list (left or right) we are working with
                  const range = isLeft
                    ? currentList.left.slice(start, end + 1)
                    : currentList.right.slice(start, end + 1);
              
                  range.forEach((item) => {
                    const itemIndex = newChecked.indexOf(item);
                    if (itemIndex === -1) {
                      newChecked.push(item);
                    }
                  });
                } else {
                  if (currentIndex === -1) {
                    newChecked.push(value);
                  } else {
                    newChecked.splice(currentIndex, 1);
                  }
                  currentList.lastSelectedIndex = index;
                }
              
                updateRequest(listIndex, { checked: newChecked });
              };
            
              const updateRequest = (index, updatedProperties) => {
                const updatedRequests = [...requestDict];
                updatedRequests[index] = { ...updatedRequests[index], ...updatedProperties };
                setRequestDict(updatedRequests);
              };
            
              const handleCheckedRight = (index) => {
                const currentList = requestDict[index];
                const leftChecked = intersection(currentList.checked, currentList.left);
                const newRight = currentList.right.concat(leftChecked);
                const newLeft = not(currentList.left, leftChecked);
            
                updateRequest(index, {
                  right: newRight,
                  left: newLeft,
                  checked: not(currentList.checked, leftChecked),
                });
              };
            
              const handleCheckedLeft = (index) => {
                const currentList = requestDict[index];
                const rightChecked = intersection(currentList.checked, currentList.right);
                const newLeft = currentList.left.concat(rightChecked);
                const newRight = not(currentList.right, rightChecked);
            
                updateRequest(index, {
                  left: newLeft,
                  right: newRight,
                  checked: not(currentList.checked, rightChecked),
                });
              };
            
              const handleSearchChange = (index) => (event) => {
                updateRequest(index, { searchQuery: event.target.value });
              };
            
              const handleGeoSwitchChange = (index) => (event) => {
                updateRequest(index, { geoSwitchChecked: event.target.checked });
              };
          
          
            const handleToggleAll = (items, listIndex) => {
                const currentList = requestDict[listIndex];
                const checkedItems = intersection(currentList.checked, items);
              
                if (checkedItems.length === items.length) {
                  updateRequest(listIndex, {
                    checked: not(currentList.checked, items),
                  });
                } else {
                  updateRequest(listIndex, {
                    checked: union(currentList.checked, items),
                  });
                }
              };
          
          
        
              const addDataSet = (index) => {
                setRequestDict(prevRequestDict => {
                  const updatedRequestDict = [...prevRequestDict];
                  updatedRequestDict[index].audienceData.push([]);
                  return updatedRequestDict;
                });
              };
        
              const handleAutoCompleteChange = (index, dataIndex, value) => {
                setRequestDict(prevRequestDict => {
                  const updatedRequestDict = [...prevRequestDict];
                  updatedRequestDict[index].audienceData[dataIndex] = value;
                  return updatedRequestDict;
                });
              };
        
              const removeDataSet = (index, dataIndex) => {
                setRequestDict(prevRequestDict => {
                  const updatedRequestDict = [...prevRequestDict];
                  updatedRequestDict[index].audienceData.splice(dataIndex, 1);
                  return updatedRequestDict;
                });
              };
        
              const addAnotherRequest = () => {
                /*
                setRequests([
                    ...requests,
                    {
                      left: geos,
                      right: ['Country: United States - 165'],
                      checked: [],
                      searchQuery: '',
                      lastSelectedIndex: null,
                      geoSwitchChecked: true,
                    },
                  ]);
                  */
                setRequestDict(prevState => {
                  const lastRequest = prevState[prevState.length - 1];
                  const newRequest = {
                    ...lastRequest, 
                    //startDate: dayjs('2024-10-01'), // Reset the startDate and endDate if needed
                    //endDate: dayjs('2024-12-31'),
                    // Any other fields that need to be reset or updated
                    checked: [],
                    searchQuery: '',
                    lastSelectedIndex: null,
                  };
                  return [...prevState, newRequest];
                });
              };
        
        
   
          
              const customList = (title, items, listIndex, isLeft = false) => {
                const currentList = requestDict[listIndex];
                const filteredItems = isLeft
                  ? currentList.left.filter((item) =>
                      item.toString().includes(currentList.searchQuery)
                    )
                  : items;
              
                return (
                  <Card>
                    <CardHeader
                      sx={{ px: 2, py: 1 }}
                      avatar={
                        <Checkbox
                          onClick={() => handleToggleAll(filteredItems, listIndex)}
                          checked={
                            intersection(currentList.checked, filteredItems).length ===
                              filteredItems.length && filteredItems.length !== 0
                          }
                          indeterminate={
                            intersection(currentList.checked, filteredItems).length !==
                              filteredItems.length &&
                            intersection(currentList.checked, filteredItems).length !== 0
                          }
                          disabled={filteredItems.length === 0}
                          inputProps={{
                            'aria-label': 'all items selected',
                          }}
                        />
                      }
                      title={title}
                    />
                    <Divider />
                    {isLeft && (
                      <TextField
                        sx={{ m: 1 }}
                        variant="outlined"
                        size="small"
                        placeholder="Search"
                        value={currentList.searchQuery}
                        onChange={handleSearchChange(listIndex)}
                      />
                    )}
                    {!isLeft && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height={50}
                      >
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography>Exclude</Typography>
                          <Switch
                            checked={currentList.geoSwitchChecked}
                            onChange={handleGeoSwitchChange(listIndex)}
                            sx={{
                              '& .MuiSwitch-track': {
                                backgroundColor: 'primary.main',
                              },
                              '& .MuiSwitch-thumb': {
                                color: 'primary.main',
                              },
                              '& .Mui-checked': {
                                color: 'primary.main',
                              },
                            }}
                          />
                          <Typography>Include</Typography>
                        </Stack>
                      </Box>
                    )}
                    <List
                      sx={{
                        width: 265,
                        height: isLeft ? 230 : 235, // Adjust height based on the presence of the search bar
                        bgcolor: 'background.paper',
                        overflow: 'auto',
                      }}
                      dense
                      component="div"
                      role="list"
                    >
                      {filteredItems.map((value, index) => {
                        const labelId = `transfer-list-all-item-${value}-label`;
              
                        return (
                          <ListItemButton
                            key={value}
                            role="listitem"
                            onClick={handleToggle(value, index, listIndex, isLeft)}
                          >
                            <ListItemIcon>
                              <Checkbox
                                checked={currentList.checked.indexOf(value) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value} />
                          </ListItemButton>
                        );
                      })}
                    </List>
                  </Card>
                );
              };
        
            const handleProductChange = (index, event) => {
                const newProduct = event.target.value;
                setRequestDict(prevState => {
                  const updatedState = [...prevState];
                  updatedState[index] = {
                    ...updatedState[index],
                    product: newProduct
                  };
                  return updatedState;
                });
              };
        
        
              const handleStartChange = (index, newStart) => {
        
                setRequestDict(prevState => {
                  const updatedState = [...prevState];
                  updatedState[index] = {
                    ...updatedState[index],
                    startDate: newStart
                  };
                  return updatedState;
                });
              };
        
              const handleEndChange = (index, newEnd) => {
                setRequestDict(prevState => {
                  const updatedState = [...prevState];
                  updatedState[index] = {
                    ...updatedState[index],
                    endDate: newEnd
                  };
                  return updatedState;
                });
              };
        
        
        
              const handlePlatformChange = (index, platform) => {
                setRequestDict(prevState => {
                  const updatedState = [...prevState];
                  updatedState[index] = {
                    ...updatedState[index],
                    platforms: {
                      ...updatedState[index].platforms,
                      [platform]: !updatedState[index].platforms[platform],
                    },
                  };
                  return updatedState;
                });
              };
        
        
        
              const handleDemoChange = (index, event) => {
                const newDemo = event.target.value;
                setRequestDict(prevState => {
                  const updatedState = [...prevState];
                  updatedState[index] = {
                    ...updatedState[index],
                    demo: newDemo
                  };
                  return updatedState;
                });
              };
        
              const addFrequencyCap = (index) => {
                
                const newCap = { impressions: 1, time: 30, unit: 'Minutes' };
                setRequestDict(prevState => {
                  const updatedState = [...prevState];
                  if (updatedState[index].frequencyCaps.length < 3) {
                  updatedState[index].frequencyCaps = [...updatedState[index].frequencyCaps, newCap];
                  }
                  return updatedState;
                });
            }
        
            const removeFrequencyCap = (index, capIndex) => {
                setRequestDict(prevState => {
                    const updatedState = [...prevState];
                    updatedState[index].frequencyCaps.splice(capIndex,1)   
                    return updatedState;
                  });
            }
        
        
        
        
        
              const getPlatformsMessage = (index) => {
                const platforms = requestDict[index].platforms;
                const platformNames = {
                  desktop: "Desktop",
                  mobileWeb: "Mobile Web",
                  mobileApp: "Mobile App",
                  ctv: "CTV",
                };
              
                const truePlatforms = Object.entries(platforms).filter(([key, value]) => value).map(([key]) => platformNames[key]);
                const falsePlatforms = Object.entries(platforms).filter(([key, value]) => !value).map(([key]) => platformNames[key]);
              
                if (truePlatforms.length === 4) {
                  return "All Platforms";
                } else if (truePlatforms.length === 3) {
                  return `Exclude ${falsePlatforms[0]}`;
                } else {
                  return `${truePlatforms.join(', ')} Only`;
                }
              };
        
        
        
        
            return (
                
                <Box sx={{ backgroundColor: '#fafafa'}} >
                          <Grid container rowSpacing={0} alignItems="center" columnSpacing={{ xs: 0}} sx={{borderBottom: '1px solid #DBDCDC',
        backgroundColor: '#ffffff', paddingTop: '1px', paddingBottom:'1px'}} >
         <Grid item xs={.25}>
        </Grid>
        <Grid item xs={9.75}><Typography variant="h5" marginTop={2} marginLeft={2} marginBottom={1}>
        Impression Availability Request</Typography>
        <Typography variant="body" marginTop={0} marginLeft={4} marginBottom={0}>
        sf_opp_placeholder</Typography>
        </Grid>
        </Grid>
                <Grid container margin={3} columnSpacing={2}>
                <Grid item xs={12} md={9.5}  rowSpacing={0} alignItems="center" columnSpacing={{ xs: 0}} sx={{border: '1px solid #DBDCDC',
        backgroundColor: '#ffffff', paddingTop: '1px', paddingBottom:'1px'}}  >
        
        {requestDict.map((item, index) => (
        
                <Grid key={index} container rowSpacing={1} alignItems="center" columnSpacing={{ xs: 0}} border={1} >
        
               
        
        
        
        <Grid container>
        <Grid item xs={5.5}>
        <Box display="flex" justifyContent="left" marginLeft={3} marginRight={5}>
        
        <Typography variant="h6" marginTop={4}>
        Request #{index+1}</Typography>
        
        </Box>
        <Box display="flex" justifyContent="left" marginLeft={3} >
        
        <FormControl fullWidth sx={{ maxWidth: 300 }} size="small">
        <InputLabel id={`select-label-${index}`}></InputLabel>
        <Select
                      labelId={`select-label-${index}`}
                      id={`select-${index}`}
                      value={item.product}
                      onChange={(event) => handleProductChange(index, event)}
                    >
                  <MenuItem value={"EyeQ RON"}>EyeQ RON</MenuItem>
                  <MenuItem value={"EyeQ Vantage"}>EyeQ Vantage</MenuItem>
                  <MenuItem value={"EyeQ Sports"}>EyeQ Sports</MenuItem>
                </Select>
              </FormControl>
              </Box>
              <Box display="flex" justifyContent="left" marginLeft={3} marginRight={5}>
        
        <Typography variant="h6" marginTop={3}>
        Platforms</Typography>
        
        </Box>
        <Box display="flex" justifyContent="left" marginLeft={3} marginTop={0}>
        <Stack direction="row" spacing={3} alignItems="center">
        
        <FormControlLabel
              control={
                <Checkbox
                  checked={item.platforms.desktop}
                  checkedIcon={<CheckCircleIcon sx={{ color: 'green' }} />} // Green icon when checked
                  icon={<CancelIcon sx={{ color: 'red' }} />}               // Red icon when unchecked
                />
              }
              label="Desktop" // The label text for the checkbox
              onChange={() => handlePlatformChange(index, 'desktop')}
              />
        
        <FormControlLabel
              control={
                <Checkbox
                checked={item.platforms.mobileWeb}
                  checkedIcon={<CheckCircleIcon sx={{ color: 'green' }} />} // Green icon when checked
                  icon={<CancelIcon sx={{ color: 'red' }} />}               // Red icon when unchecked
                />
              }
              label="Mobile Web" // The label text for the checkbox
              onChange={() => handlePlatformChange(index, 'mobileWeb')}
        
            />
        <FormControlLabel
              control={
                <Checkbox
                checked={item.platforms.mobileApp}
        
                  checkedIcon={<CheckCircleIcon sx={{ color: 'green' }} />} // Green icon when checked
                  icon={<CancelIcon sx={{ color: 'red' }} />}               // Red icon when unchecked
                />
              }
              label="Mobile App" // The label text for the checkbox
              onChange={() => handlePlatformChange(index, 'mobileApp')}
        
            />
        <FormControlLabel
              control={
                <Checkbox
                checked={item.platforms.ctv}
        
                  checkedIcon={<CheckCircleIcon sx={{ color: 'green' }} />} // Green icon when checked
                  icon={<CancelIcon sx={{ color: 'red' }} />}               // Red icon when unchecked
                />
              }
              label="CTV" // The label text for the checkbox
              onChange={() => handlePlatformChange(index, 'ctv')}
        
            />
        </Stack>
        </Box>
        
        <Box display="flex" justifyContent="left" marginLeft={3} marginRight={5}>
        
        <Typography variant="h6" marginTop={3}>
        Demographic Guarantee</Typography>
        
        </Box>
        <Box display="flex" justifyContent="left" marginLeft={3} >
        
        <FormControl fullWidth sx={{ maxWidth: 300 }} size="small">
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={item.demo}
                  label=""
                  onChange={(event) => handleDemoChange(index, event)}
                  >
                  <MenuItem value={"P2+"}>P2+</MenuItem>
                  <MenuItem value={"A1849"}>A1849</MenuItem>
                  <MenuItem value={"A2554"}>A2554</MenuItem>
                </Select>
              </FormControl>
        
        
        
              
              </Box>
        <Box display="flex" justifyContent="left" marginLeft={3} marginRight={5}>
        
        <Typography variant="h6" marginTop={3}>
        Frequency Cap</Typography>
        
        </Box>
        
        {item.frequencyCaps.map((cap, capIndex) => (
        
        <Box key={capIndex} display="flex" justifyContent="left" marginLeft={3} marginTop={1}>
        <Stack direction="row" spacing={0.5} alignItems="center">
        
        <TextField id="outlined-basic" label="" variant="outlined" size="small" value={cap.impressions}
                          onChange={(event) => {
                            const updatedCaps = [...item.frequencyCaps];
                            updatedCaps[capIndex].impressions = event.target.value;
                            const updatedDict = [...requestDict];
                            updatedDict[index].frequencyCaps = updatedCaps;
                            setRequestDict(updatedDict);
                          }}  
                          sx={{ maxWidth: 50 }} />
        <Typography variant="body" component="span">
        Impressions per
        </Typography>
        
        <TextField id="outlined-basic" label="" variant="outlined" size="small" value={cap.time}
                   onChange={(event) => {
                    const updatedCaps = [...item.frequencyCaps];
                    updatedCaps[capIndex].time = event.target.value;
                    const updatedDict = [...requestDict];
                    updatedDict[index].frequencyCaps = updatedCaps;
                    setRequestDict(updatedDict);
                  }}  
                  sx={{ maxWidth: 50 }} />
        <FormControl sx={{ maxWidth: 150 }} size="small">
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={cap.unit}
                  label=""
                  onChange={(event) => {
                    const updatedCaps = [...item.frequencyCaps];
                    updatedCaps[capIndex].unit = event.target.value;
                    const updatedDict = [...requestDict];
                    updatedDict[index].frequencyCaps = updatedCaps;
                    setRequestDict(updatedDict);
                  }}
                  >
                  <MenuItem value={'Minutes'}>Minutes</MenuItem>
                  <MenuItem value={'Hours'}>Hours</MenuItem>
                  <MenuItem value={'Days'}>Days</MenuItem>
                  <MenuItem value={'Months'}>Months</MenuItem>
        
                </Select>
              </FormControl>
              <IconButton onClick={() => addFrequencyCap(index)}
                  sx={{marginBottom:1, marginLeft:0}}><AddCircleIcon fontSize="medium" /></IconButton>
        {capIndex >= 1 && (
        <IconButton onClick={() => removeFrequencyCap(index, capIndex)}
                  sx={{marginBottom:1, marginLeft:0}}><RemoveCircleIcon fontSize="medium" /></IconButton>
        )}
              </Stack>
              
        </Box>
        ))}
        
        <Box display="flex" justifyContent="left" marginLeft={3} marginRight={5}>
        
        <Typography variant="h6" marginTop={6}>
        Audience Targeting</Typography>
        
        </Box>
        <Box justifyContent="left" marginLeft={3} marginTop={1}>
        {item.audienceData.map((dataItems, dataIndex) => (
            
              <Box key={`${index}-${dataIndex}`} mb={3}>
                {dataIndex >= 1 && (
                  <Button disabled variant="contained" size="small" sx={{ marginBottom: 3 }}>
                    And
                  </Button>
                )}
                
                <Box display="flex" alignItems="center">
                  <Box flexGrow={1}>
                  <Autocomplete
          multiple
          id={`tags-outlined-${index}-${dataIndex}`}
          options={audience}
          getOptionLabel={(option) => option.name}
          value={dataItems}
          onChange={(event, value) => handleAutoCompleteChange(index, dataIndex, value)}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              label="Audience Segments"
              placeholder="Segments"
            />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        
        />
                  </Box>
                  <IconButton
                    edge="start"
                    onClick={() => removeDataSet(index, dataIndex)}
                    sx={{ marginBottom: 1, marginLeft: 1 }}
                  >
                    <RemoveCircleIcon fontSize="medium" />
                  </IconButton>
                </Box>
              </Box>
            ))}
            <Button
              variant="contained"
              size="small"
              onClick={() => addDataSet(index)}
              startIcon={<AddCircleIcon />}
            >
              Add Data Set
            </Button>
        
              
              </Box>
        
            </Grid>
            <Grid item xs={6.5}>
            <Box display="flex" justifyContent="right" marginTop={1} marginRight={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
        
              <StaticDatePicker localeText={{
          toolbarTitle: "Start Date",
        }} disablePast value={item.startDate} views={['month', 'year', 'day']}
              
               slots={{
                actionBar: () => null, // Remove the action bar
              }}
              onChange={(newDate) => handleStartChange(index, newDate)}
              
              />
              <StaticDatePicker localeText={{
          toolbarTitle: "End Date",
        }} disablePast value={item.endDate} views={['month', 'year', 'day']}
              
               slots={{
                actionBar: () => null, // Remove the action bar
              }}
              onChange={(newDate) => handleEndChange(index, newDate)}
        
              />
              </LocalizationProvider>
        </Box>
        <Box display="flex" justifyContent="left" marginRight={0}>
        
        <Typography variant="h6" marginTop={0} marginBottom={1} marginLeft={15}>
        Geographic Targeting</Typography>
        
        
        </Box>

        <Grid container spacing={1} justifyContent="center" alignItems="center" marginLeft={5}>
        <Grid item>
            {customList('Select All', item.left, index, true)}
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={() => handleCheckedRight(index)}
                disabled={
                  intersection(item.checked, item.left).length === 0
                }
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={() => handleCheckedLeft(index)}
                disabled={
                  intersection(item.checked, item.right).length === 0
                }
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item>{customList('Select All', item.right, index)}</Grid>
        </Grid>
    
        </Grid>

            
        
        
        
        
        
        </Grid>
        
        
        
        
        
        
        
        
        
        
        
        
            </Grid>
        ))}
        
                    </Grid>
         {/* Sticky Box */}
         <Grid item xs={12} md={2} rowSpacing={0} marginLeft={2} alignItems="center" columnSpacing={{ xs: 0}} sx={{border: '1px solid #DBDCDC',
        backgroundColor: '#ffffff', paddingTop: '1px', paddingBottom:'1px'}}>
                <Box
                  sx={{
                    position: 'sticky',
                    top: '20px',
                    width: '100%',
                    padding: '16px',
        
                  }}
                >
                             <Typography variant="h6">Avails Request Summary</Typography>
        
                    {requestDict.map((item, index) => (
                        <div key={index}>
            <Typography>
              {index + 1}. {item.product}
            </Typography>
            <Typography fontSize={14}>
              {item.startDate.format('MM/DD/YYYY')} - {item.endDate.format('MM/DD/YYYY')}
            </Typography>
            <Typography fontSize={11}>
              - {getPlatformsMessage(index)}
            </Typography>
            <Typography fontSize={11}>
              - {item.demo}
            </Typography>
            {item.frequencyCaps.map((cap, capIndex) => (
              <Typography fontSize={11} key={`cap-${capIndex}`}>
                - {cap.impressions} impressions per {cap.time} {cap.unit}
              </Typography>
            ))}
            <Typography fontSize={11}>
              - Geo {geoSwitchChecked ? "Targeted:" : "Excluding"}
            </Typography>
            <Typography fontSize={11} component="div">
              {item.right.map((rightItem, rightIndex) => (
                <div style={{ marginLeft: '20px' }} key={`right-${rightIndex}`}>• {rightItem}</div>
              ))}
            </Typography>
            <Typography fontSize={11} component="div" sx={{ whiteSpace: 'pre-line' }}>
              {item.audienceData.length > 0 ? "- Audience Targeting:" : ""}
              <br />
              {formatDataSet(item.audienceData).map((element, elemIndex) => (
                <React.Fragment key={`audience-${elemIndex}`}>
                  {element}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
              </div>
                    ))}
                </Box>
              </Grid>
              
        </Grid>
        
        
        
        
        <Box display="flex" justifyContent="left"  marginLeft={4} marginTop={5} marginBottom={5}>
        <Stack spacing={2} direction="row">
        <Button variant="contained" size="small" 
        onClick={addAnotherRequest}
         startIcon={<AddCircleIcon />}>Add Another Request</Button>
            </Stack>
            </Box>
        
        
        
        
        <Box display="flex" justifyContent="left"  marginLeft={4} marginTop={5}>
        <Stack spacing={2} direction="row">
              <Button variant="contained">Send Avails Request</Button>
              <Button variant="outlined" color="error">Cancel</Button>
            </Stack>
            </Box>
        
        
        </Box>
        
            )
        
        }



export default TestPage;
