import React, {useEffect, useState} from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { ExportToCsv } from 'export-to-csv';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useOktaAuth } from '@okta/okta-react';
import dayjs from 'dayjs';
import Grid from "@mui/material/Grid";

function getCurrentDate() {
    const currentDate = new Date();
    return currentDate.toISOString().slice(0, 10);
  }


function ExportDates(currentView) {
    const [rawData, setRawData] = useState(currentView)
    const [exportButtonOff, setExportButtonOff] = useState(true)

    const currentDate = getCurrentDate();
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);


    const [startRange, setStartRange] = useState('')
    const [endRange, setEndRange] = useState('')

    const { authState, oktaAuth } = useOktaAuth();

    const [token, setToken] = useState('');

    useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
        }else{
          var options = {
            method: 'GET',
            headers: {"Content-Type": "application/json"}
      
          }
          setToken(authState.accessToken.accessToken)
          options.headers["Authorization"] = "Bearer " + authState.accessToken.accessToken;
    
        }


      }, [token,authState, oktaAuth])

      useEffect(() => {
        // Check if both DatePickers have a date selected
        if (startRange && endRange) {
          setExportButtonOff(false);
        } else {
          setExportButtonOff(true);
        }
      }, [startRange, endRange]);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const [openDatePopUp, setOpenDatePopUp] = React.useState(false);

    const handleClickDateOpen = () => {
      handleClose();
      setOpenDatePopUp(true);
    };
    const handleDateClose = () => {
      setOpenDatePopUp(false);
    };

    const handleQTRExport = () => {
      setAnchorEl(null);
      const options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: rawData['currentPage'] + '-' + currentDate
      };

      
    
    const csvExporter = new ExportToCsv(options);
    
    csvExporter.generateCsv(rawData['currentView']);
    };

    const handleDateExport = () => {

        setExportButtonOff(true)

        const earlierDate = dayjs(startRange).isBefore(endRange) ? startRange : endRange;
        const laterDate = dayjs(startRange).isBefore(endRange) ? endRange : startRange;
    
        // Format the dates
        const startRangeFormatted = dayjs(earlierDate).format('YYYY-MM-DD');
        const endRangeFormatted = dayjs(laterDate).format('YYYY-MM-DD');

  

        var options = {
            method: 'GET',
            headers: {"Content-Type": "application/json"}
      
          }
          options.headers["Authorization"] = "Bearer " + token;
            fetch(`/api/export?startrange=${startRangeFormatted}&endrange=${endRangeFormatted}&linetype=${rawData['currentPage']}`, options)
              .then((response) => response.json())
              .then((json) => 
              
    
    {

        if (json.lines && json.lines.length > 0) {
        const options = { 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true, 
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: rawData['currentPage'] + '-' + currentDate
          };
        
        const csvExporter = new ExportToCsv(options);
        
        csvExporter.generateCsv(json.lines);
        }

        handleDateClose()
        setStartRange('')
        setEndRange('')
    })







       // setAnchorEl(null);


/*
        const options = { 
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true, 
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true,
          filename: rawData['currentPage'] + '-' + currentDate
        };
      
      const csvExporter = new ExportToCsv(options);
      
      csvExporter.generateCsv(rawData['currentView']);
      */
      };



    return (

        <div>

<Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
                <FileDownloadOutlinedIcon fontSize="small" /> 

    </Button>
    <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleQTRExport}>Export Current QTR</MenuItem>
        <MenuItem onClick={handleClickDateOpen}>Export Date Range</MenuItem>
      </Menu>

      <Dialog
       // fullWidth={fullWidth}
       // maxWidth={maxWidth}
        open={openDatePopUp}
        onClose={handleDateClose}
      >




 


        <DialogTitle>Export lines with flight end dates between:</DialogTitle>
        
        <DialogContent> 
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container rowSpacing={0} columnSpacing={{ xs: 1}} >

            <Grid item xs={6}>
      <DatePicker value={startRange} 
          onChange={(newValue) => setStartRange(newValue)}

          />     
      </Grid>
      <Grid item xs={6}>

       <DatePicker value={endRange}
            onChange={(newValue) => setEndRange(newValue)}
            onError={(newError) => setExportButtonOff(true)}

                 />
</Grid>
</Grid>

    </LocalizationProvider> 
    <br></br>
    <Button fullWidth variant="contained" 
    disabled={exportButtonOff} 
    onClick={handleDateExport}>Export</Button>
         
          </DialogContent>

          </Dialog>
          </div>     
    )


}

export default ExportDates
