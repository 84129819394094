import React, {useEffect, useState} from 'react'
import Box from "@mui/material/Box";


import Grid from "@mui/material/Grid";
import RiskComponent from "../components/RiskComponent";
import SalespersonGrid from "../components/SalespersonGrid";
import YieldPieChart from "../components/YieldPieChart";
import CircularProgress from '@mui/material/CircularProgress';
import DashboardLineGraph from "../components/DashboardLineGraph";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import { useOktaAuth } from '@okta/okta-react';
import LoadingComponent from "../components/LoadingComponent";
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { useNavigate, createSearchParams } from "react-router-dom";







  function YieldHoldCoRiskTable({tableData}) {

    const navigate = useNavigate();


    const handleYieldHoldCo = (holdco) =>
    navigate({
      pathname: '/yield/risk',
      search: `?${createSearchParams(holdco)}`,
    });

    const columns = [
        {
          field: 'id',
          headerName: 'Id',
          hide: true
        //  renderCell: (params) => 
          /*
          <Link style={{ textDecoration: 'none' }} to={`order/${params.row.order_id}`}>      <Button size="small" variant="contained">
          {params.row.order_id}</Button></Link>
      */
        },
        {
          field: 'hold_co',
          headerName: 'Hold Co',
          flex:1,
            minWidth: 100,
            renderCell: (params)=>
    <Button   style={{justifyContent: "flex-start"}}
    onClick={() => 
        handleYieldHoldCo({holdco: params.row.hold_co})}>{params.row.hold_co}</Button>
      
        },
      
      
      {
        field: 'straight_line_risk',
        headerName: 'Risk',
        flex:0.5,
        minWidth: 100,
          type: 'number',
      
        valueFormatter: ({ value }) => priorityFormater(value),
      },
      
      {
        field: 'real_risk',
        headerName: 'Real Risk',
         flex:0.5,
            minWidth: 100,  type: 'number',
      
        valueFormatter: ({ value }) => priorityFormater(value),
      },
      
      ];
    
      const priorityFormater = (cell) => {
        if (cell == null){
          return "$0"
        } else {
        let cell_value = Math.round(cell)?.toLocaleString()
        if (cell_value.includes('-')){
          cell_value = cell_value.replace('-', '');
          cell_value = "-"+"$"+cell_value
          return cell_value
        } else {
          return "$"+cell_value?.toLocaleString()
        }
        }
    
    };

return(
    <DataGrid 
        rows={tableData}
        columns={columns}
      //  loading={loading}
      disableColumnMenu={true}
      disableSelectionOnClick
      autoHeight={true}

        pageSize={10}
        rowsPerPageOptions={[10]}
        rowHeight={25}
        initialState={{
            sorting: {
              sortModel: [{ field: 'straight_line_risk', sort: 'asc' }],
            },
          }}
              //  hideFooter={true}
        sx={{
          
          border: 0,
        }}

    />

)



  }


  export default YieldHoldCoRiskTable