import React, {useState} from 'react'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import OptimizationGuidelinesYield from '../components/OptimizationGuidelinesYield';
import OptimizationGuidelinesAdOps from '../components/OptimizationGuidelinesAdOps';
import GettingStarted from '../components/GettingStarted';
import SiteStatus from '../components/SiteStatus';
const drawerWidth = 300;





export const HelpPage = () => {

    const [selectedItem, setSelectedItem] = useState('Yield Optimization Guidelines');

    const [openYield, setOpenYield] = useState(true);
    const [openAdops, setOpenAdops] = useState(false);
    const [openGettingStarted, setOpenGettingStarted] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);


    const handleListItemClick = (text) => {
      setSelectedItem(text);

      if(text === 'Yield Optimization Guidelines'){
        setOpenYield(!openYield);
        setOpenAdops(false)
        setOpenGettingStarted(false)
        setOpenStatus(false)


      } else if (text === 'Ad Ops Optimization Guidelines'){
        setOpenAdops(!openAdops)
        setOpenYield(false);
        setOpenGettingStarted(false)
        setOpenStatus(false)

      }
      else if (text === 'Getting Started'){
        setOpenGettingStarted(!openGettingStarted)
        setOpenAdops(false)
        setOpenYield(false);
        setOpenStatus(false)

      }

      else if (text === 'Status Dashboard'){
        setOpenStatus(!openStatus)
        setOpenAdops(false)
        setOpenYield(false);
        setOpenGettingStarted(false)

      }
      };








      const handleScrollToSelectedSection = (sectionName) => {
        // Generate the sectionId based on the sectionName
        const sectionId = sectionName.toLowerCase().replace(/ /g, '-');
      
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
      };

return(
    <Box sx={{ display: 'flex' }}>
    <CssBaseline />
   
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0, zIndex: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
        <ListItem disablePadding
            onClick={() => handleListItemClick('Yield Optimization Guidelines')}
            >
                
              <ListItemButton>

                <ListItemText disableTypography primary='Yield Optimization Guidelines' sx={{textAlign:'left',
                fontWeight: 'Yield Optimization Guidelines' === selectedItem ? 'bold' : 'normal', // Conditionally set fontWeight
 }}/>
         {openYield ? <ExpandLess /> : <ExpandMore />}

              </ListItemButton>
              
            </ListItem>
            <Collapse in={openYield} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <ListItemButton onClick={() => handleScrollToSelectedSection('Introduction')} sx={{ pl: 4 }}>

<ListItemText primary="Introduction" disableTypography sx={{fontSize:12}} />
</ListItemButton>


<ListItemButton onClick={() => handleScrollToSelectedSection('Common Speed Bumps')} sx={{ pl: 4 }}>

<ListItemText primary="Common Speed Bumps" disableTypography sx={{fontSize:12}} />
</ListItemButton>



<ListItemButton onClick={() => handleScrollToSelectedSection('Standard Optimization Hierarchy')} sx={{ pl: 4 }}>
            <ListItemText primary="Standard Optimization Hierarchy" disableTypography sx={{fontSize:12}} />
          </ListItemButton>

          <ListItemButton onClick={() => handleScrollToSelectedSection('Priority Increase Guidelines')} sx={{ pl: 4 }}>
            <ListItemText primary="Priority Increase Guidelines" disableTypography sx={{fontSize:12}} />
          </ListItemButton>

          <ListItemButton onClick={() => handleScrollToSelectedSection('Special Cases')} sx={{ pl: 4 }}>
            <ListItemText primary="Special Cases" disableTypography sx={{fontSize:12}} />
          </ListItemButton>

        </List>
      </Collapse>


{/*
        <ListItem disablePadding
            onClick={() => handleListItemClick('Ad Ops Optimization Guidelines')}
            >
                
              <ListItemButton>

                <ListItemText disableTypography primary='Ad Ops Optimization Guidelines' sx={{textAlign:'left',
                fontWeight: 'Ad Ops Optimization Guidelines' === selectedItem ? 'bold' : 'normal', // Conditionally set fontWeight
 }}/>
         {openAdops ? <ExpandLess /> : <ExpandMore />}

              </ListItemButton>
              
            </ListItem>
            <Collapse in={openAdops} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <ListItemButton onClick={() => handleScrollToSelectedSection('Introduction')} sx={{ pl: 4 }}>

<ListItemText primary="Introduction" disableTypography sx={{fontSize:12}} />
</ListItemButton>


<ListItemButton onClick={() => handleScrollToSelectedSection('Common Speed Bumps')} sx={{ pl: 4 }}>

<ListItemText primary="Common Speed Bumps" disableTypography sx={{fontSize:12}} />
</ListItemButton>



<ListItemButton onClick={() => handleScrollToSelectedSection('Standard Optimization Hierarchy')} sx={{ pl: 4 }}>
            <ListItemText primary="Standard Optimization Hierarchy" disableTypography sx={{fontSize:12}} />
          </ListItemButton>

          <ListItemButton onClick={() => handleScrollToSelectedSection('Priority Increase Guidelines')} sx={{ pl: 4 }}>
            <ListItemText primary="Priority Increase Guidelines" disableTypography sx={{fontSize:12}} />
          </ListItemButton>

          <ListItemButton onClick={() => handleScrollToSelectedSection('Special Cases')} sx={{ pl: 4 }}>
            <ListItemText primary="Special Cases" disableTypography sx={{fontSize:12}} />
          </ListItemButton>

        </List>
      </Collapse>


      <ListItem disablePadding
            onClick={() => handleListItemClick('Getting Started')}
            >
                
              <ListItemButton>

                <ListItemText disableTypography primary='Getting Started' sx={{textAlign:'left',
                fontWeight: 'Getting Started' === selectedItem ? 'bold' : 'normal', // Conditionally set fontWeight
 }}/>
         {openGettingStarted ? <ExpandLess /> : <ExpandMore />}

              </ListItemButton>
              
            </ListItem>
            <Collapse in={openGettingStarted} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <ListItemButton onClick={() => handleScrollToSelectedSection('Introduction')} sx={{ pl: 4 }}>

<ListItemText primary="Introduction" disableTypography sx={{fontSize:12}} />
</ListItemButton>





        </List>
      </Collapse>

*/}

<ListItem disablePadding
            onClick={() => handleListItemClick('Status Dashboard')}
            >
                
              <ListItemButton>

                <ListItemText disableTypography primary='Status Dashboard' sx={{textAlign:'left',
                fontWeight: 'Status Dashboard' === selectedItem ? 'bold' : 'normal', // Conditionally set fontWeight
 }}/>

              </ListItemButton>
              
            </ListItem>
            <Collapse in={openStatus} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>


      





        </List>
      </Collapse>


        </List>
        <Divider />
       
      </Box>
    </Drawer>
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    {selectedItem === 'Yield Optimization Guidelines' && <OptimizationGuidelinesYield />}
      {selectedItem === 'Ad Ops Optimization Guidelines' && <OptimizationGuidelinesAdOps />}
      {selectedItem === 'Getting Started' && <GettingStarted />}
      {selectedItem === 'Status Dashboard' && <SiteStatus />}


</Box>
  </Box>
);


}