import React from 'react'
import Grid from "@mui/material/Grid";

import Box from "@mui/material/Box";

import { Link } from "react-router-dom";



import Typography from '@mui/material/Typography';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';






function RiskHitTop({salespersonId, salesperson_nm }) {

    return(
    <Box sx={{ backgroundColor: '#fafafa' }}>


<Grid container rowSpacing={0} columnSpacing={{ xs: 0}} alignItems="center">
    <Grid item xs={1}>
    </Grid> 
    <Grid item xs={11}>
    <Typography variant="body2" marginTop={0} display="flex">

    <Link fontSize="small" style={{
display: 'flex',
alignItems: 'center',
paddingLeft:'20px',
backgroundColor: '#ffffff',
color: '#000787',
fontWeight:500
}} to={`/dashboard/${salespersonId}`}>Home</Link> 
            
<Link fontSize="small" style={{
display: 'flex',
alignItems: 'center',
paddingLeft:'0px',
backgroundColor: '#ffffff',
color: '#000787',
fontWeight:500
}} to={``}><NavigateNextIcon fontSize="small" />At Risk</Link> 
            
         </Typography>   
        </Grid>

</Grid>   
<Grid container rowSpacing={0} columnSpacing={{ xs: 0}} alignItems="center" sx={{
    backgroundColor: '#ffffff'}} >
  <Grid item xs={12}>
    
  <Typography variant="h6" marginTop={0} marginLeft={5} gutterBottom>
    {salesperson_nm}'s Lines at Risk
    </Typography>
    </Grid>  
    </Grid>    
</Box>
    )
}

export default RiskHitTop