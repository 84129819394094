import React, {useEffect, useState} from 'react'

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PendingIcon from '@mui/icons-material/Pending';
import Dialog from '@mui/material/Dialog';
//import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from "@mui/material/Grid";
import { ExportToCsv } from 'export-to-csv';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';





const columns = [
  { id: 'date', label: 'Date', minWidth: 120 },
  {
      id: '1P P2+',
      label: '1P P2+ Imps',
      minWidth: 120,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: '3P P2+',
      label: '3P P2+ Imps',
      minWidth: 120,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'Discrepancy',
      label: 'Discrepancy',
      minWidth: 120,
      align: 'right',
      format: (value) => (value*100).toFixed(2)+'%',
    },
    {
      id: 'On Target Net',
      label: 'On Target Net',
      minWidth: 120,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'Burt Billable Raw',
      label: 'Billable Raw',
      minWidth: 120,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'Final Billable',
      label: 'Final Billable',
      minWidth: 120,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'Daily Comp',
      label: 'Daily Comp',
      minWidth: 120,
      align: 'right',
      format: (value) => (value*100).toFixed(2)+'%',
    },
    { id: 'Final Indicator', label: 'Billable Indicator', minWidth: 70, align: 'right' },

];



const Transition = React.forwardRef(function Transition(props, ref) {
return <Slide direction="up" ref={ref} {...props} />;
});



export default function DeliveryMenu({dailyData, sales_order_line_item_id, token, document_id,
booked_revenue_amt, flight_length, billable_days_completed, final_billable, op_impression_goal_contracted, adjusted_impressions_ind,
adjusted_impressions, dark_day_ind, dark_days, line_status, risk, sales_order_id, package_nm, flight}) {





  const [openDailyDelivery, setOpenDailyDelivery] = useState(false);
  const [openOverride, setOpenOverride] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [success, setSuccess] = useState(false);
  const timer = React.useRef();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const reversedObject = Object.values(dailyData).reverse();

  const [alignment, setAlignment] = useState('adjustment');

  const [adjustmentValue, setAdjustmentValue] = useState(adjusted_impressions)
  const [adjustmentError, setAdjustmentError] = useState(false)

  const [billInFullChecked, setBillInFullChecked] = useState(false)
  const [applyAllBIFChecked, setApplyAllBIFChecked] = useState(false)

  const [addState, setAddState] = useState(false)



  const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");
  const removeCommas = num => num.toString().replace(",", "")
  const [value, setValue] = useState('');

  const [darkDayValue, setDarkDayValue] = useState(dark_days);
  const [darkError, setDarkError] = useState('')

  const yesterday = dayjs().subtract(1, 'day');
  const [dateRanges, setDateRanges] = useState([]);
  const [darkDateRanges, setDarkDateRanges] = useState([]);

  const [selectedDate, setSelectedDate] = useState(dayjs().subtract(1, 'day'));


  const splitFlight = flight.split('-');

  const startDarkDate = dayjs(splitFlight[0], 'M/D/YYYY');
  const endDarkDate = dayjs(splitFlight[1], 'M/D/YYYY');


  const [selectedStartDarkDate, setStartDarkSelectedDate] = useState(startDarkDate);
  const [selectedEndDarkDate, setEndDarkSelectedDate] = useState(endDarkDate);

  
  const [didEditFirstDates, setDidEditFirstDates] = useState(false)
  const [didEditFirstDarkDates, setDidEditFirstDarkDates] = useState(false)


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };



  const handleCloseDailyDelivery = () => {
    setAnchorEl(null);
    handleDailyOpen();
  };

  const handleCloseOverrideDelivery = () => {
    setAnchorEl(null);
    handleOverrideOpen();
  };


  const handleDailyOpen = () => {
    setOpenDailyDelivery(true);
  };

  const handleDailyClose = () => {
    setOpenDailyDelivery(false);
  };

  const handleOverrideOpen = () => {
    setOpenOverride(true);
  };

  const handleOverrideClose = () => {
    setOpenOverride(false);
  };

  const handleChangeButtonGroup = (
    event
  ) => {
    
    setAlignment(event.target.value);
  };

  const handleCancelClose = () => {
    setOpenOverride(false);
    //handleAddCommentClicked()
  };
  const addDateRange = () => {
    setDidEditFirstDates(true)

    setDateRanges([...dateRanges, { startDate: selectedDate, endDate: selectedDate }]);
    
  };

  const removeDateRange = (index) => {
    setDidEditFirstDates(true)
    const updatedDateRanges = [...dateRanges];
    updatedDateRanges.splice(index, 1);
    setDateRanges(updatedDateRanges);
  };

  const handleStartDateChange = (index, value) => {
    setDidEditFirstDates(true)
    const updatedDateRanges = [...dateRanges];
    updatedDateRanges[index].startDate = value;
    setDateRanges(updatedDateRanges);
  };

  const handleEndDateChange = (index, value) => {
    setDidEditFirstDates(true)
    const updatedDateRanges = [...dateRanges];
    updatedDateRanges[index].endDate = value;
    setDateRanges(updatedDateRanges);
  };


  const addDarkDateRange = () => {
    setDidEditFirstDates(true)

    setDarkDateRanges([...darkDateRanges, { startDate: selectedStartDarkDate, endDate: selectedEndDarkDate }]);
    
  };

  const removeDarkDateRange = (index) => {
    setDidEditFirstDarkDates(true)
    const updatedDateRanges = [...darkDateRanges];
    updatedDateRanges.splice(index, 1);
    setDarkDateRanges(updatedDateRanges);
  };

  const handleStartDarkDateChange = (index, value) => {
    setDidEditFirstDarkDates(true)
    const updatedDateRanges = [...darkDateRanges];
    updatedDateRanges[index].startDate = value;
    setDarkDateRanges(updatedDateRanges);
  };

  const handleEndDarkDateChange = (index, value) => {
    setDidEditFirstDarkDates(true)
    const updatedDateRanges = [...darkDateRanges];
    updatedDateRanges[index].endDate = value;
    setDarkDateRanges(updatedDateRanges);
  };



  


  const renderDatePickers = () => {
    return dateRanges.map((dateRange, index) => (
      <div key={index}>
        <br></br>
        <Grid container rowSpacing={0} alignItems="center" columnSpacing={{ xs: 2}} sx={{paddingTop: '1px', paddingBottom:'1px'}} >
<Grid item xs={2}>
        <DatePicker
          value={dateRange.startDate}
          label="Start Date"
          onChange={(value) => handleStartDateChange(index, value)}
          disableHighlightToday
          maxDate={yesterday}
        />
        </Grid>
        <Grid item xs={2}>

        <DatePicker
          value={dateRange.endDate}
          label="End Date"
          onChange={(value) => handleEndDateChange(index, value)}
          disableHighlightToday
          maxDate={yesterday}
        />
        </Grid>

        {index === dateRanges.length - 1 && (
         // <button onClick={() => removeDateRange(index)}>Remove Date Range</button>
         <Grid item xs={8}>
          <IconButton edge="start" onClick={() => removeDateRange(index)} 
          sx={{marginBottom:1}}><RemoveCircleIcon fontSize="medium" /></IconButton>
          <IconButton edge="start" onClick={addDateRange} sx={{marginBottom:1}}><AddCircleIcon fontSize="medium" /></IconButton>


</Grid>

        )}
        </Grid>
      </div>
    ));
  };


  const renderDarkDatePickers = () => {
    return darkDateRanges.map((darkDateRange, index) => (
      <div key={index}>
        <br></br>
        <Grid container rowSpacing={0} alignItems="center" columnSpacing={{ xs: 2}} sx={{paddingTop: '1px', paddingBottom:'1px'}} >
<Grid item xs={2}>
        <DatePicker
          value={darkDateRange.startDate}
          label="Start Date"
          onChange={(value) => handleStartDarkDateChange(index, value)}
          disableHighlightToday
          minDate={selectedStartDarkDate}
          maxDate={selectedEndDarkDate}
        />
        </Grid>
        <Grid item xs={2}>

        <DatePicker
          value={darkDateRange.endDate}
          label="End Date"
          onChange={(value) => handleEndDarkDateChange(index, value)}
          disableHighlightToday
          maxDate={selectedEndDarkDate}
        />
        </Grid>

        {index === darkDateRanges.length - 1 && (
         // <button onClick={() => removeDateRange(index)}>Remove Date Range</button>
         <Grid item xs={8}>
          <IconButton edge="start" onClick={() => removeDarkDateRange(index)} 
          sx={{marginBottom:1}}><RemoveCircleIcon fontSize="medium" /></IconButton>
          <IconButton edge="start" onClick={addDarkDateRange} sx={{marginBottom:1}}><AddCircleIcon fontSize="medium" /></IconButton>


</Grid>

        )}
        </Grid>
      </div>
    ));
  };






  const handleExport = () => {
    setAnchorEl(null);
    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: sales_order_line_item_id + "_Daily_Delivery" 
    };
  
  const csvExporter = new ExportToCsv(options);
  
  csvExporter.generateCsv(dailyData);
  };


  const handleBillInFullChecked = () => {

    setBillInFullChecked(!billInFullChecked);

  }

  const handleAllBillInFullChecked = () => {

    setApplyAllBIFChecked(!applyAllBIFChecked);

  }

  const handleDelete = () => {

    if (!loading) {
      setSuccess(false);
      setDeleteLoading(true);
    }

    if(alignment==='adjustment'){




    var options = {
      method: 'POST',
      body: JSON.stringify({
          sales_order_line_item_id: sales_order_line_item_id, adjusted_impressions: Number(adjustmentValue), document_id: document_id,
          booked_revenue_amt: booked_revenue_amt, flight_length: flight_length, billable_days_completed: billable_days_completed,
          final_billable: final_billable, op_impression_goal_contracted:op_impression_goal_contracted, adjusted_impressions_ind: false
      }),
      headers: {"Content-Type": "application/json"}

    }
    options.headers["Authorization"] = "Bearer " + token;


      fetch("/delete_billable_adjustment",options)
          .then(function(response){
          return response
          }).then(function(body){
            
                timer.current = window.setTimeout(() => {
                setSuccess(true);
                setDeleteLoading(false);
              }, 2000);
            

            window.location.reload(true);
        


  })
} else if (alignment==='dark'){


  var options = {
    method: 'POST',
    body: JSON.stringify({
        sales_order_line_item_id: sales_order_line_item_id, dark_days: Number(darkDayValue), document_id: document_id,
        booked_revenue_amt: booked_revenue_amt, flight_length: flight_length, billable_days_completed: billable_days_completed,
        final_billable: final_billable, op_impression_goal_contracted:op_impression_goal_contracted, dark_day_ind: false
    }),
    headers: {"Content-Type": "application/json"}

  }
  options.headers["Authorization"] = "Bearer " + token;


    fetch("/delete_dark_adjustment",options)
        .then(function(response){
        return response
        }).then(function(body){
          
              timer.current = window.setTimeout(() => {
              setSuccess(true);
              setDeleteLoading(false);
            }, 2000);
          

          window.location.reload(true);
      


})



}
}



  const handleSaveClose = () => {


    if(alignment==='adjustment'){


    let final_billable_number = Number(final_billable.replaceAll(',',''))
    let adjustmentValueNumber = Number(adjustmentValue)


    if(!addState)
      {
    if (value.length > 0 && adjustmentValueNumber <= final_billable_number)
    {




      if (adjustmentValueNumber !== Number(adjusted_impressions)) {
      if (!loading) {
        setSuccess(false);
        setLoading(true);
      }

      
      var options = {
        method: 'POST',
        body: JSON.stringify({
            sales_order_line_item_id: sales_order_line_item_id, adjusted_impressions: adjustmentValueNumber, document_id: document_id,
            booked_revenue_amt: booked_revenue_amt, flight_length: flight_length, billable_days_completed: billable_days_completed,
            final_billable: final_billable, op_impression_goal_contracted:op_impression_goal_contracted, adjusted_impressions_ind: true
        }),
        headers: {"Content-Type": "application/json"}
  
      }
      options.headers["Authorization"] = "Bearer " + token;


        fetch("/billable_adjustment",options)
            .then(function(response){
            return response
            }).then(function(body){
              
              if (!loading){
               // timer.current = window.setTimeout(() => {
                  setSuccess(true);
                  setLoading(false);
              //  }, 2000);
              }
            //  setOpenOverride(false);

              window.location.reload(true);
             // setDefaultDashboard(result)
             //   navigate("/dashboard/"+result[0], { replace: true });

                
            });
          } else{
            
            setOpenOverride(false);

          }
        
            
   // 
    } else{
      setAdjustmentError(true)

      
    }
   } else {
    if (value.length > 0){



      if (adjustmentValueNumber !== Number(adjusted_impressions)) {
        if (!loading) {
          setSuccess(false);
          setLoading(true);
        }
  
        adjustmentValueNumber = -Math.abs(adjustmentValueNumber);      
        var options = {
          method: 'POST',
          body: JSON.stringify({
              sales_order_line_item_id: sales_order_line_item_id, adjusted_impressions: adjustmentValueNumber, document_id: document_id,
              booked_revenue_amt: booked_revenue_amt, flight_length: flight_length, billable_days_completed: billable_days_completed,
              final_billable: final_billable, op_impression_goal_contracted:op_impression_goal_contracted, adjusted_impressions_ind: true
          }),
          headers: {"Content-Type": "application/json"}
    
        }
        options.headers["Authorization"] = "Bearer " + token;
  
  
          fetch("/billable_adjustment",options)
              .then(function(response){
              return response
              }).then(function(body){
                
                if (!loading){
                 // timer.current = window.setTimeout(() => {
                    setSuccess(true);
                    setLoading(false);
                //  }, 2000);
                }
              //  setOpenOverride(false);
  
                window.location.reload(true);
               // setDefaultDashboard(result)
               //   navigate("/dashboard/"+result[0], { replace: true });
  
                  
              });
            } else{
              
              setOpenOverride(false);
  
            }
          
              
     // 
      } else{
        setAdjustmentError(true)
  
        
      }
    

    }

  } else if (alignment==='dark'){
    let dateArray = [];
    let validDateRange = true;

    darkDateRanges.forEach((darkDateRange) => {
      const startDate = (dayjs(darkDateRange.startDate).format('YYYY-MM-DD'));
      const endDate = (dayjs(darkDateRange.endDate).format('YYYY-MM-DD'))

      if (dayjs(startDate).isAfter(endDate) || dayjs(endDate).isBefore(startDate)) {
        validDateRange = false;
        return; // Exit the forEach loop early
      }
      
  // Check if the current date range overlaps with any other date range
  const overlap = darkDateRanges.some((otherRange) => {
    if (otherRange !== darkDateRange) {
      const otherStartDate = dayjs(otherRange.startDate).format('YYYY-MM-DD');
      const otherEndDate = dayjs(otherRange.endDate).format('YYYY-MM-DD');
      const doesOverlap = (dayjs(startDate).isBetween(otherStartDate, otherEndDate, null, '[]') ||
                           dayjs(endDate).isBetween(otherStartDate, otherEndDate, null, '[]'));
      return doesOverlap;
    }
    return false;
  });

  if (overlap) {
    validDateRange = false;
    return; // Exit the forEach loop early
  }


      let dateEntry = {
        startDate: startDate,
        endDate: endDate
    };
    dateArray.push(dateEntry);


    })


    if(validDateRange){

    var options = {
      method: 'POST',
      body: JSON.stringify({
          sales_order_line_item_id: sales_order_line_item_id, dateRanges: dateArray, document_id: document_id, fbDateRanges: dateRanges,
          sales_order_id: sales_order_id
      }),
      headers: {"Content-Type": "application/json"}

    }
    options.headers["Authorization"] = "Bearer " + token;


    fetch("/dark_adjustmentv2",options)
        .then(function(response){
        return response
        }).then(function(body){
         
          if (!loading){
              setSuccess(true);
              setLoading(false);
          }

          window.location.reload(true);


            
        });
      }else{
        setDarkError('Invalid Date Ranges')
        console.log('invalid date range')
      }

  } else if (alignment==='full'){

    if(billInFullChecked){
      setLoading(true)
    var options = {
      method: 'POST',
      body: JSON.stringify({
          sales_order_line_item_id: sales_order_line_item_id, document_id: document_id, package_nm: package_nm, full_package: applyAllBIFChecked, order_id: sales_order_id
      }),
      headers: {"Content-Type": "application/json"}

    }
    options.headers["Authorization"] = "Bearer " + token;


    fetch("/bill_in_full",options)
        .then(function(response){
        return response
        }).then(function(body){
          
          if (!loading){
           // timer.current = window.setTimeout(() => {
              setSuccess(true);
              setLoading(false);
          //  }, 2000);
          }
          setOpenOverride(false);
          window.location.reload(true);


            
        });
      }



  } else if (alignment==='burt'){

    
    const allDates = [];
 // Loop through dateRanges
 dateRanges.forEach((dateRange) => {
  const startDate = dateRange.startDate;
  const endDate = dateRange.endDate;

  // Iterate between startDate and endDate
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
  //  allDates.push(currentDate.toISOString()); // You can use any date format you prefer
  allDates.push(dayjs(currentDate).format('YYYY-MM-DD'));
  currentDate.setDate(currentDate.getDate() + 1); // Move to the next day


  }
});


// Now, 'allDates' contains all the dates between start and end for each date range

if(allDates.length > 0 && didEditFirstDates){
const uniqueDates = [...new Set(allDates)];


if (!loading) {
  setSuccess(false);
  setLoading(true);
}

const dateRanges = [];
let startDate = new Date(uniqueDates[0]);
let endDate = new Date(uniqueDates[0]);

for (let i = 1; i < uniqueDates.length; i++) {
  const currentDate = new Date(uniqueDates[i]);
  const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds

  // Check if the current date is consecutive to the previous one
  if (currentDate - endDate === oneDay) {
    endDate = currentDate;
  } else {
    // If not consecutive, add the current range and start a new one
    dateRanges.push({ min: startDate.toISOString().slice(0, 10), max: endDate.toISOString().slice(0, 10) });
    startDate = currentDate;
    endDate = currentDate;
  }
}

// Add the last date range
dateRanges.push({ min: startDate.toISOString().slice(0, 10), max: endDate.toISOString().slice(0, 10)});



    var options = {
      method: 'POST',
      body: JSON.stringify({
          sales_order_line_item_id: sales_order_line_item_id, dateRanges: uniqueDates, document_id: document_id, fbDateRanges: dateRanges,
          sales_order_id: sales_order_id
      }),
      headers: {"Content-Type": "application/json"}

    }
    options.headers["Authorization"] = "Bearer " + token;


    fetch("/first_party_dates",options)
        .then(function(response){
        return response
        }).then(function(body){
         
          if (!loading){
              setSuccess(true);
              setLoading(false);
          }

          window.location.reload(true);


            
        });
      } else{
       // console.log('no dates or not changed')
      }      

    //setOpenOverride(false);






  }

    };




    
    useEffect(() => {

      if(adjusted_impressions != 0){
        setValue(addCommas(Math.abs(adjusted_impressions)))
      }
      
      if(adjusted_impressions < 0){
        setAddState(true)
      }

    }, [sales_order_line_item_id]);

      useEffect(() => {
        if(token){

        var options = {
          method: 'GET',
          headers: {"Content-Type": "application/json"}
    
        }
        options.headers["Authorization"] = "Bearer " + token;
        fetch(`/date_range?doc_id=${document_id}`, options)
              .then(
                (response) => response.json())
              .then((json) => {
                const dataArrayWithDayjsDates = json.map((dateRange) => {
                  return {
                    startDate: dayjs(dateRange.startDate),
                    endDate: dayjs(dateRange.endDate),
                  };
                });
                setDateRanges(dataArrayWithDayjsDates)
            
                // Now, dataArrayWithDayjsDates contains Day.js dates
                
              })
            //  .catch(() => onError())
            }
      }, [token, sales_order_line_item_id]);


      useEffect(() => {
        if(token){

        var options = {
          method: 'GET',
          headers: {"Content-Type": "application/json"}
    
        }
        options.headers["Authorization"] = "Bearer " + token;
        fetch(`/dark_date_range?doc_id=${document_id}`, options)
              .then(
                (response) => response.json())
              .then((json) => {
                const dataArrayWithDayjsDates = json.map((dateRange) => {
                  return {
                    startDate: dayjs(dateRange.startDate),
                    endDate: dayjs(dateRange.endDate),
                  };
                });

                setDarkDateRanges(dataArrayWithDayjsDates)
            
                // Now, dataArrayWithDayjsDates contains Day.js dates
                
              })
            //  .catch(() => onError())
            }
      }, [token,sales_order_line_item_id]);



const handleChange = event => {
  setValue(addCommas(removeNonNumeric(event.target.value)));
  setAdjustmentValue(removeCommas(event.target.value))

};

const handleDarkDayFieldChange = event => {
  setDarkDayValue(removeNonNumeric(event.target.value));

};


const handleSwitchChange = event => {

  setAddState(!addState)
  console.log(addState)

};



  return (
    <div>

<IconButton id="basic-button" edge='start' color='primary' sx={{ m: 0, p: 0, minWidth:0 }} onClick={handleClick}><PendingIcon /></IconButton>


      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >

        <MenuItem onClick={handleCloseDailyDelivery}>Daily Delivery</MenuItem>
        <MenuItem onClick={handleCloseOverrideDelivery}>Override Delivery</MenuItem>
      </Menu>

      <Dialog
        open={openDailyDelivery}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDailyClose}
        fullWidth={true}
        maxWidth="lg"
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid container rowSpacing={0} alignItems="center" columnSpacing={{ xs: 0}} sx={{paddingTop: '1px', paddingBottom:'1px'}} >
<Grid item xs={4}>
<DialogTitle>Daily Delivery</DialogTitle>
</Grid>
<Grid item xs={7.7} align='right'>
<Button
        id="basic-button"

        onClick={handleExport}
      >
                <FileDownloadOutlinedIcon fontSize="small" /> Export

    </Button>
</Grid>
<Grid item xs={.3} align='right'>
  </Grid>
</Grid>
        <DialogContent>
        <TableContainer sx={{ maxHeight: 640 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {reversedObject
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.date}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={dailyData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

        </DialogContent>

      </Dialog>




      <Dialog
        open={openOverride}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleOverrideClose}
        fullWidth={true}
        maxWidth="lg"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Manually Override Delivery"}</DialogTitle>
        <DialogContent>
        <Box display="flex" justifyContent="center" sx={{padding: '5px'}}>

        <ToggleButtonGroup 
     color="primary"
     value={alignment}
     exclusive
     onChange={handleChangeButtonGroup}
     aria-label="Platform"

   >

     <ToggleButton value="adjustment">Delivery Adjustment</ToggleButton>
     <ToggleButton value="burt">Use First Party</ToggleButton>
     <ToggleButton value="dark">Dark Dates</ToggleButton>
     {line_status === "Completed" && risk < 0 && (
    <ToggleButton value="full">Bill in Full</ToggleButton>
  )}
   </ToggleButtonGroup>
   </Box>
        {alignment==='adjustment' &&
        <Box>
          <br></br>
       <Typography variant="body2" marginTop={0} marginLeft={0} gutterBottom>
        A delivery adjustment should be used if there are impressions that ran but we cannot bill for them. Common reasons 
        are the wrong creative ran or line/creative was set live before the actual start date. In rare instances where impressions need to be added to a line
        switch to "Add" and impressions will be added to the billable total.
       </Typography>
       {adjusted_impressions_ind === true ?
       <Box columnGap={1} justifyContent="left" sx={{display: 'flex', alignItems: 'center'}}
       > 
       <br></br>


               <TextField
              //   type="number"
                 error={adjustmentError}
                 id="standard-error-helper-text"
                 label={addState ? "Added impressions" : "Subtracted impressions"}
                 disabled={true}
                 //defaultValue={value}
                 value={value}
                 variant="filled"
               />



                         <Box sx={{ m: 1, position: 'relative' }}>

                         <Button variant="outlined" 
                         color="error"
          onClick={handleDelete}           
          disabled={deleteLoading}
          startIcon={<DeleteIcon />}>DELETE</Button>
                    {deleteLoading && (
          <CircularProgress
            size={24}
            color='error'
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
            />
            )}

            </Box>

</Box>:       
       <Box> 
       <br></br>
       <Stack direction="row" spacing={1} alignItems="center">
       <Typography>Subtract</Typography>
          <Switch
          value={addState}
          onChange={handleSwitchChange}
            sx={{
              '& .MuiSwitch-track': {
                backgroundColor: 'primary.main',
              },
              '& .MuiSwitch-thumb': {
                color: 'primary.main',
              },
              '& .Mui-checked': {
                color: 'primary.main',
              },
            }}
          />
          <Typography>Add</Typography>
</Stack>
               <TextField
            //   type="number"
               error={adjustmentError}
               id="standard-error-helper-text"
               label={addState ? "Add impressions" : "Subtract impressions"}
               //defaultValue={value}
               value={value}
               variant="standard"
               helperText={addState ? "Will be added to billable imps" : "Will be removed from billable imps"}
               onChange={handleChange}
             />
             
</Box>
       }


           </Box>
    
}
{alignment==='burt' &&
 <Box>
 <br></br>
<Typography variant="body2" marginTop={0} marginLeft={0} gutterBottom>
Add date ranges where delivery should be calculated based on first party delivery numbers.
</Typography>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
      {renderDatePickers()}
      </LocalizationProvider>
      {dateRanges.length == 0 &&
      <Button variant="contained" 
          onClick={addDateRange}           
         
          startIcon={<AddCircleIcon />}>Add Range</Button>
      }
</Box>
}
{alignment==='dark' &&
  <Box>
  <br></br>
<Typography variant="body2" marginTop={0} marginLeft={0} gutterBottom>
Add date ranges where this placement is dark. Do not include late starts as dark dates.
</Typography>

<LocalizationProvider dateAdapter={AdapterDayjs}>
      {renderDarkDatePickers()}
      </LocalizationProvider>
      {darkDateRanges.length == 0 &&
      <Button variant="contained" 
          onClick={addDarkDateRange}           
         
          startIcon={<AddCircleIcon />}>Add Range</Button>
      }

<Typography variant="body2" marginTop={0} marginLeft={0} color={'red'} gutterBottom>
{darkError}
</Typography>

</Box>
}
{alignment==='full' &&
 <Box>
 <br></br>
<Typography variant="body2" marginTop={0} marginLeft={0} gutterBottom>
This line has ended and is showing under delivery.  If we are billing the client in full please check the box below and hit save to remove
the risk from this line.
</Typography>
<FormGroup>
      <FormControlLabel control={<Checkbox
      checked={billInFullChecked}
      onChange={handleBillInFullChecked}
      inputProps={{ 'aria-label': 'controlled' }}
    />} label="Client has agreed to bill this line in full" />

{billInFullChecked && (
        <FormControlLabel
          control={
            <Checkbox
              checked={applyAllBIFChecked}
              onChange={handleAllBillInFullChecked}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Apply to all lines in package"
        />
      )}
    </FormGroup>
</Box>
}

        </DialogContent>
        
        <DialogActions>

          <Button onClick={handleCancelClose}>Cancel</Button>
          <Box sx={{ m: 1, position: 'relative' }}>

          <Button variant="contained" 
          onClick={handleSaveClose}           
          disabled={loading}>Save</Button>
          {loading && (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
            />
            )}

</Box>
        </DialogActions>
        
      </Dialog>


    </div>
  );
}