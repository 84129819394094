import { useParams } from "react-router-dom";
import {useEffect, useState} from 'react'
import { useOktaAuth } from '@okta/okta-react';

import Box from "@mui/material/Box";

import SponsorshipGrid from "../components/SponsorshipGrid"
import LoadingComponent from "../components/LoadingComponent";
import UpdateCheck from "../components/UpdateCheck";




export const SponsorshipPage = () => {
  const { authState, oktaAuth } = useOktaAuth();

  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState('');

    



            useEffect(() => {
              if (!authState || !authState.isAuthenticated) {
              }else{
                var options = {
                  method: 'GET',
                  headers: {"Content-Type": "application/json"}
            
                }
                setToken(authState.accessToken.accessToken)
                options.headers["Authorization"] = "Bearer " + authState.accessToken.accessToken;
          
              }
              if(token){
                setLoading(false)

              }

            }, [token,authState, oktaAuth])

            if (loading) {
              return (    
        <LoadingComponent />
        )
                } else {
        return (    
          <Box sx={{ backgroundColor: '#fafafa' }}>
    
           <SponsorshipGrid token={token} sponsorships="sponsorships"/>
           <UpdateCheck />
           </Box>
        )
        }
      
}