import React, { useEffect, useState } from 'react'
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CustomNoRowsOverlay } from './CustomNoRowsOverlay';
import moment from 'moment';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
//import { useAuth } from "../hooks/useAuth";





export function handleClick(commentValue, orderId, lineId) {



  };




  function CommentTable ({sports_ind, displayName, orderId, lineId, salespersonId,advertiser_nm, campaign_nm, line_item_nm, flight, token, onError }){
//console.log(salespersonId)
    const teams = [
      { title: 'Sales' },
      { title: 'Yield' },
      { title: 'Sports Yield' },

      { title: 'Client Services' }
      
    ]
    const [defaultTeams, setDefaultTeams] = useState([]);
    const [value, setValue] = useState([]);

    useEffect(() => {

    var defaultTeamsTmp = []
    
    let role = localStorage.getItem("role")
    const roleParsed = JSON.parse(role);
    //const roleParsed = 'AM';
    
    //console.log(roleParsed)
    switch(roleParsed){
      case "Yield":
        defaultTeamsTmp.push({title: 'Sales'}, {title: 'Client Services'})
        break;

        case "Sports Yield":
          defaultTeamsTmp.push({title: 'Sales'}, {title: 'Client Services'})
          break;
      
      case "AM":
        if(sports_ind){
          defaultTeamsTmp.push({title: 'Sports Yield'}, {title: 'Client Services'})

        }else{
        defaultTeamsTmp.push({title: 'Yield'}, {title: 'Client Services'})
        }
        break;
    
        case "AE":
          if(sports_ind){
            defaultTeamsTmp.push({title: 'Sports Yield'}, {title: 'Client Services'})
  
          }else{
          defaultTeamsTmp.push({title: 'Yield'}, {title: 'Client Services'})
          }
          break;
    
          case "SA":
            defaultTeamsTmp.push({title: 'Sales'}, {title: 'Client Services'})
            break;
    
            case "Client Services":
              if(sports_ind){
                defaultTeamsTmp.push({title: 'Sports Yield'}, {title: 'Sales'})
      
              }else{
              defaultTeamsTmp.push({title: 'Yield'}, {title: 'Sales'})
              }
              break;
              default:
                defaultTeamsTmp.push({title: 'Sales'}, {title: 'Yield'},{title: 'Client Services'})
    
    }
    setDefaultTeams(defaultTeamsTmp)
    setValue(defaultTeamsTmp)
  }, [])
   // console.log(defaultTeams)
   // console.log(teams)
    
    
    const columns = [
        {
          field: 'username',
          headerName: 'User',
          width: 150,
        },
        {
          field: 'commentDate',
          headerName: 'Date',
          width: 180,
        },
        {
          field: 'comment',
          headerName: 'Comment',
          width: 550,
        },
    
      ];
    
      const userTableStyles = {
        height: '650px',
    };
    
   // console.log(localStorage.getItem("role"))

    const [comments, setComments] = useState([]);
    const [open, setOpen] = useState(false);
    const [commentValue, setCommentValue] = useState('')

    //const { user } = useAuth();

    const handleClickOpen = () => {
      setOpen(true);
      
    };
  
    const handleCancelClose = () => {
      setOpen(false);
      //handleAddCommentClicked()
    };
  
    const handleSaveClose = () => {
        if(commentValue){
    //        var datetime=getservertime(false,"");
    let result = value.map(a => a.title);

          const addComment = { id: comments.length+1, username: displayName, commentDate: moment().format('l LTS'), comment: commentValue }
       // console.log(comments.length)
             setComments(current => [addComment, ...current]);
  
                    setOpen(false);
                    var options = {
                      method: 'POST',
                      body: JSON.stringify({
                          comment: commentValue, commenter: displayName, orderId:orderId, lineId:lineId, salespersonId: salespersonId,
                          advertiser_nm:advertiser_nm, campaign_nm:campaign_nm, line_item_nm:line_item_nm, flight: flight, groups: result
                      }),
                      headers: {"Content-Type": "application/json"}
                    }
                    options.headers["Authorization"] = "Bearer " + token;

        fetch("/api/:orderId/:lineId/add_comment",options)
            .then(function(response){
            return response
            }).then(function(body){
        //    console.log(body);
 
            });
         //   handleAddCommentClicked(commentValue, orderId, lineId)
      //    const test = { id: 45, username: 'Snow', commentDate: 'Jon', comment: 'sdfsdfsdfsdfsd sdfsdfdsf' }
       //   setComments(test)
            //reset to null
            setCommentValue('')
        }
  
      };

    useEffect(() => {
      var options = {
        method: 'GET',
        headers: {"Content-Type": "application/json"}
  
      }
      options.headers["Authorization"] = "Bearer " + token;

        fetch("/api/comments/"+orderId+"/"+lineId, options)
            .then((response) => response.json())
            .then((json) => setComments(json.comments))
            .catch(() => onError())
    }, [lineId]);





    return (
        <Box sx={{  height: 400, width: '100%'}}> 

        <DataGrid 
            rows={comments}
            columns={columns}
          //  loading={loading}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
            pageSize={5}
            rowsPerPageOptions={[5]}
            getRowHeight={() => 'auto'}
            disableSelectionOnClick
            sx={{
                border: 0,
                [`& .${gridClasses.cell}`]: {
                  py: 1,
                },
              }}            
 

        />
         <Box sx={{ textAlign:"center"}}> 

        <Button sx={{width:"90%"}} onClick={handleClickOpen} variant="contained" 
         startIcon={<AddCircleIcon />}>
        Comment
      </Button>
      </Box>
            <Dialog open={open} onClose={handleCancelClose} fullWidth
        maxWidth="sm" >
              <DialogTitle>New Comment</DialogTitle>
              <DialogContent>
      
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  multiline
      
                  maxRows={Infinity}
                  label="Comment"
                  fullWidth
                  variant="outlined"
                  onChange={e => {
                      setCommentValue(e.target.value)
                    }}
                />
<br></br><br></br>
<Autocomplete
        multiple
        id="tags-standard"
        options={teams}
        isOptionEqualToValue={(option, value) => option.title === value.title}
        onChange={(event, newValue) => {
          setValue(newValue);

        }}
        getOptionLabel={(option) => option.title}
   defaultValue={defaultTeams}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <MyChip {...getTagProps({ index })} label={option.title} />
          ));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Tags"
            placeholder=""
          />
        )}
      />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancelClose}>Cancel</Button>
                <Button onClick={handleSaveClose}>Save</Button>
                
              </DialogActions>
            </Dialog>

</Box>

    );
};



const MyChip = (props) => {

  return (
      <Chip
      color="primary"
      size="small"
              {...props}
      />
  );
};

export default CommentTable
