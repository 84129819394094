import { useParams } from "react-router-dom";
import React, {useEffect, useState} from 'react'

import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

import { useOktaAuth } from '@okta/okta-react';

import Button from '@mui/material/Button';

import Grid from "@mui/material/Grid";
import SalespersonGrid from "../components/SalespersonGrid";
import PieChart from "../components/PieChart";
import DashboardBarGraph from "../components/DashboardBarGraph";
import { getAnalytics, logEvent } from "firebase/analytics";

//import '@fontsource/roboto/300.css';
//import '@fontsource/roboto/400.css';
//import '@fontsource/roboto/500.css';
//import '@fontsource/roboto/700.css';
import Typography from '@mui/material/Typography';
//import { useNavigate } from "react-router-dom";
import SalespersonSelector from "../components/SalespersonSelector";
import LoadingComponent from "../components/LoadingComponent";
import UpdateCheck from "../components/UpdateCheck";
import AdvertiserRisk from "../components/AdvertiserRisk";

const DashboardPage = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  
  
  const {salespersonId} = useParams();
    //const { token, checkTest } = useAuth();
    //const [salesperson, setSalesperson] = React.useState(salespersonId);
  //  const navigate = useNavigate();

    const [salesLines, setSalesLines] = useState([]);
    const [topLevelRiskData, setTopLevelRiskData] = useState({})
    const [pieData, setPieData] = useState([])
    const [loading, setLoading] = useState(true)
    const [barData, setBarData] = useState([])
    const [keys, setKeys] = useState([])
    const [advertiserData, setAdvertiserData] = useState([])

 //console.log(test)


 useEffect(() => {
  const fetchData = async () => {

  if (!authState || !authState.isAuthenticated) {
    // When user isn't authenticated, forget any user info
    setUserInfo(null);

  } else {
    try {
      let accessToken = authState.accessToken;

      // Check if the token is expired and refresh it if necessary
      if (oktaAuth.tokenManager.hasExpired(accessToken)) {
        accessToken = await oktaAuth.tokenManager.renew('accessToken');
        console.log('expired')
      }

      const options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + accessToken.accessToken
        }
      };

      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 15);
      const formattedDate = currentDate.toISOString().slice(0, 10);

      const response = await fetch(`/salesperson?day_dt=${formattedDate}&salespersonId=${salespersonId}`, options);
      const json = await response.json();

            setBarData(json.daily)
            setKeys(json.orderKey)
              let active_straight_line_risk_adj = 0
              let active_revenue_amt = 0
              let activate_campaign_count = 0

              let active_line_count = 0
              let line_risk_count = 0
              let order_risk_count = 0

              let qtr_spend = 0
              let qtr_ud = 0

              let tmpPieData = []

              for (let i = 0; i < json.lines.length; i++){
                  active_revenue_amt += json.lines[i].active_revenue_amt
                  active_straight_line_risk_adj += json.lines[i].active_straight_line_risk_adj

                  active_line_count += json.lines[i].active_line_count
                  qtr_spend += json.lines[i].qtr_spend
                  qtr_ud += json.lines[i].qtr_ud


                  if (json.lines[i].active_straight_line_risk_adj < 0){
                    let active_risk = Math.round(json.lines[i].active_straight_line_risk_adj)
                    let campaign_nm = json.lines[i].campaign_nm
                    let advertiser_nm = json.lines[i].advertiser_nm
                    let order_id = json.lines[i].order_id
  
                    let pieLine = {"id": order_id, "label": campaign_nm,"advertiser_nm":advertiser_nm, "campaign_nm": campaign_nm,
                    "order_id":order_id,"value": Math.abs(active_risk), "index": order_risk_count}
                    tmpPieData.push(pieLine)
                    }


                  if (json.lines[i].order_status === 'Active'){
                    activate_campaign_count += 1
                }

                  if (json.lines[i].line_risk_count> 0){
                    line_risk_count += json.lines[i].line_risk_count
                    order_risk_count += 1
                }


              }
              active_revenue_amt = '$' + Math.round(active_revenue_amt)?.toLocaleString()
              if (active_revenue_amt.includes('-')){
                  active_revenue_amt = active_revenue_amt.replace('-', '');
                  active_revenue_amt = "-"+active_revenue_amt
              }
              active_straight_line_risk_adj = '$' + Math.abs(Math.round(active_straight_line_risk_adj))?.toLocaleString()
              if (active_straight_line_risk_adj.includes('-')){
                  active_straight_line_risk_adj = active_straight_line_risk_adj.replace('-', '');
                  active_straight_line_risk_adj = "-"+active_straight_line_risk_adj
              }

              qtr_spend = '$'+Math.round(qtr_spend)?.toLocaleString()
              qtr_ud = '$'+Math.round(qtr_ud)?.toLocaleString()

              const salespersonIdMappings = {
                '38': 'CBS Local - Detroit',
                '149': 'CBS Local - New York',
                '161': 'CBS Local - Sacramento',
                '173': 'CBS Local - Minneapolis',
                '194': 'CBS Local - Pittsburgh',
                '202': 'CBS Local - Atlanta',
                '212': 'CBS Local - Denver',
                '219': 'CBS Local - Chicago',
                '237': 'CBS Local - Baltimore',
                '238': 'CBS Local - Tampa',
                '239': 'CBS Local - Boston',
                '241': 'CBS Local - Seattle',
                '244': 'CBS Local - Philadelphia',
                '250': 'CBS Local - Dallas',
                '259': 'CBS Local - Miami',
                '280': 'CBS Local - Los Angeles',
                '287': 'CBS Local - San Francisco',
              };
              
              let primary_salesperson_nm;

              // Check if json.lines exists and has at least one element
              if (json.lines && json.lines.length > 0 && json.lines[0]) {
                  primary_salesperson_nm = json.lines[0].primary_salesperson_nm;
              } else {
                  // Handle the case where json.lines is empty or undefined
                  // You can set primary_salesperson_nm to a default value or handle it as needed
                  primary_salesperson_nm = 'ERROR'; // Replace this with an appropriate default or error handling
              }

              if (salespersonId in salespersonIdMappings) {
                primary_salesperson_nm = salespersonIdMappings[salespersonId];
              }

              setTopLevelRiskData({'campaign_number': activate_campaign_count,'line_risk_count': line_risk_count, 'primary_salesperson_nm': primary_salesperson_nm,
              'active_revenue_amt': active_revenue_amt, 'active_straight_line_risk_adj': active_straight_line_risk_adj, 
              "active_line_count": active_line_count, "order_risk_count": order_risk_count, "qtr_spend": qtr_spend, "qtr_ud":qtr_ud
          })
        //  console.log(tmpPieData)
          setPieData(tmpPieData)
        //  console.log(tmpPieData)
          setSalesLines(json.lines)
          setAdvertiserData(json.advertiser)
          //console.log(json.lines)
          setLoading(false)

         

       // }))
       const analytics = getAnalytics();
       const pageTitle = 'Dashboard - ' + salespersonId;
       logEvent(analytics, 'page_view', { email: authState.idToken.claims.email, page_title: pageTitle });

     } catch (error) {
      console.error('Error fetching data: ', error);
      // Handle errors such as network issues, parsing issues, etc.
    }
  }
};

fetchData();
}, [authState, oktaAuth, salespersonId]); // Include salespersonId in the dependency array


    if (loading) {
      return (    
        <LoadingComponent />
        )
                } else {

                  if (salesLines.length > 0){
        return (    
          <Box sx={{ backgroundColor: '#fafafa' }}>
                    <Grid container rowSpacing={0} alignItems="center" columnSpacing={{ xs: 0}} sx={{borderBottom: '1px solid #DBDCDC',
    backgroundColor: '#ffffff'}} >
            <Grid item xs={.25}>
      </Grid>
        <Grid item xs={9.75}><Typography variant="h4" marginLeft={0}>
{topLevelRiskData.primary_salesperson_nm}</Typography>
      </Grid>
      <Grid item xs={1.75} textAlign='right'>
      <SalespersonSelector salespersonId={salespersonId} />
      </Grid>
      <Grid item xs={.25}>
      </Grid>
    </Grid>
<br></br>

<Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >

<Grid item xs={.25}></Grid>
<Grid item xs={.05} sx={{borderTop: '1px solid #DBDCDC',borderLeft: '1px solid #DBDCDC', borderBottom: '1px solid #DBDCDC',
        backgroundColor: '#000ac2', boxShadow: 1}}></Grid>

<Grid item xs={3.62} sx={{borderTop: '1px solid #DBDCDC',borderBottom: '1px solid #DBDCDC',borderRight: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>

<Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >
<Grid item xs={12}>
<Typography variant="h6" marginTop={0} marginLeft={1} textAlign='left'>

Book Snapshot
{/*/ Active campaigns / Active revenue / active risk /
 percentage of total  at risk 
        */}
</Typography>
</Grid>
<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center'>

{topLevelRiskData.campaign_number}
</Typography>
</Grid>

<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center'>

{topLevelRiskData.active_line_count}
</Typography>
</Grid>

<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center'>

{topLevelRiskData.active_revenue_amt}
</Typography>
</Grid>
<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={0} textAlign='center' gutterBottom>
Active Campaigns
</Typography>

</Grid>


<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={0} textAlign='center' gutterBottom>
Active Lines
</Typography>

</Grid>

<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={0} textAlign='center' gutterBottom>
Booked Active Revenue
</Typography>

</Grid>

</Grid>
</Grid>



<Grid item xs={.25}></Grid>
<Grid item xs={.05} sx={{borderTop: '1px solid #DBDCDC',borderLeft: '1px solid #DBDCDC', borderBottom: '1px solid #DBDCDC',
        backgroundColor: '#000ac2', boxShadow: 1}}></Grid>

<Grid item xs={3.62} sx={{borderTop: '1px solid #DBDCDC',borderBottom: '1px solid #DBDCDC',borderRight: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>

<Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >
<Grid item xs={8}>
<Typography variant="h6" marginTop={0} marginLeft={1} textAlign='left'>

Risk Snapshot
{/*/ Active campaigns / Active revenue / active risk /
 percentage of total  at risk 
        */}
</Typography>
</Grid>
<Grid textAlign='right' item xs={4}>

<Link style={{ textDecoration: 'none' }} to={`/risk/${salespersonId}`}>      <Button size="small">
     View Lines</Button></Link>
  </Grid>

  <Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center'>

{topLevelRiskData.order_risk_count}
</Typography>
</Grid>

<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center'>

{topLevelRiskData.line_risk_count}
</Typography>
</Grid>
<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center'>

{topLevelRiskData.active_straight_line_risk_adj}
</Typography>
</Grid>

<Grid item xs={4}>
<Typography variant="body2" textAlign='center' gutterBottom>
Orders with Risk
</Typography>

</Grid>

<Grid item xs={4}>
<Typography variant="body2" textAlign='center' gutterBottom>
Lines at Risk
</Typography>

</Grid>
<Grid item xs={4}>
<Typography variant="body2" textAlign='center' gutterBottom>
Straightline Risk
</Typography>

</Grid>

</Grid>
</Grid>

<Grid item xs={.25}></Grid>
<Grid item xs={.05} sx={{borderTop: '1px solid #DBDCDC',borderLeft: '1px solid #DBDCDC', borderBottom: '1px solid #DBDCDC',
        backgroundColor: '#000ac2', boxShadow: 1}}></Grid>

<Grid item xs={3.62} sx={{borderTop: '1px solid #DBDCDC',borderBottom: '1px solid #DBDCDC',borderRight: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>
<Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >
<Grid item xs={12}>
<Typography variant="h6" marginTop={0} marginLeft={1} textAlign='left'>

Delivered Snapshot

</Typography>
</Grid>


  <Grid item xs={6}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center'>

{topLevelRiskData.qtr_spend}
</Typography>
</Grid>

<Grid item xs={6}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center'>

{topLevelRiskData.qtr_ud}
</Typography>
</Grid>


<Grid item xs={6}>
<Typography variant="body2" textAlign='center' gutterBottom>
Total Spend QTD
</Typography>

</Grid>

<Grid item xs={6}>
<Typography variant="body2" textAlign='center' gutterBottom>
Under Delivery QTD
</Typography>

</Grid>


</Grid>

</Grid>

</Grid>
<br></br>
        
<Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >
  <Grid item xs={.25}></Grid>

  <Grid item xs={3.67} sx={{border: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>
            <Typography variant="h6" marginTop={1} marginLeft={2}>
Active Campaign Risk Breakout</Typography>
<PieChart pieData={pieData} orderCount={topLevelRiskData.order_risk_count} svpPage={false}/>

            </Grid>
            <Grid item xs={.25}></Grid>

  <Grid item xs={3.67} sx={{border: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>
  <Grid container rowSpacing={0} columnSpacing={{ xs: 0}} >
    

    <Grid item xs={12}>
        
    <Typography variant="h6" marginTop={1} marginLeft={2} gutterBottom>
Risk Over Time</Typography>
</Grid>
<Grid item xs={12}>

<DashboardBarGraph barData={barData} keyData={keys}/>
</Grid>
</Grid>    
    
    </Grid>

    <Grid item xs={.25}></Grid>

<Grid item xs={3.67} sx={{border: '1px solid #DBDCDC',
      backgroundColor: '#ffffff',boxShadow: 1}}>
                    {/*
                    <LatestComments salespersonId={salespersonId}></LatestComments>
      */}
      <AdvertiserRisk data={advertiserData} />
        </Grid>
  



  <Grid item xs={.25}></Grid>

        </Grid>


<br></br>
        <Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >
  <Grid item xs={.25}></Grid>




  <Grid item xs={11.5} sx={{border: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>
            <SalespersonGrid salesLines={salesLines} salespersonId={salespersonId}></SalespersonGrid>
        </Grid>


<Grid item xs={.25}></Grid>
  {/*comment section
  <Grid item xs={3} sx={{border: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>
            
            </Grid>
        <Grid item xs={.25}></Grid>
        */}
  </Grid>
<br></br>
<UpdateCheck />
</Box>
        )
      } else {
        return (    
          <Box sx={{ backgroundColor: '#fafafa' }}>
                    <Grid container rowSpacing={0} alignItems="center" columnSpacing={{ xs: 0}} sx={{borderBottom: '1px solid #DBDCDC',
    backgroundColor: '#ffffff'}} >
            <Grid item xs={.25}>
      </Grid>
        <Grid item xs={9.75}><Typography variant="h4" marginLeft={0}>
No Data Found</Typography>
      </Grid>
      <Grid item xs={1.75} textAlign='right'>
      <SalespersonSelector salespersonId={salespersonId} />
      </Grid>
      <Grid item xs={.25}>
      </Grid>
    </Grid>
    </Box>
            )
      }
      
}
}

export default DashboardPage;

/*
<FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
<InputLabel id="demo-simple-select-filled-label">Salesperson</InputLabel>
<Select
  labelId="demo-simple-select-filled-label"
  id="demo-simple-select-filled"
  value={age}
  onChange={handleChange}
>
  <MenuItem value="">
    <em>None</em>
  </MenuItem>
  <MenuItem value={10}>Kevin Flynn</MenuItem>
  <MenuItem value={20}>Twenty</MenuItem>
  <MenuItem value={30}>Thirty</MenuItem>
</Select>
</FormControl>
*/