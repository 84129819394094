import React, {useEffect, useState} from 'react'

import Button from '@mui/material/Button';


import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { useNavigate, createSearchParams } from "react-router-dom";







  function RiskByAESVP({tableData}) {

    const navigate = useNavigate();


    const handleAEClick = (salesperson_id) => {
        console.log("Salesperson ID:", salesperson_id.name);
        navigate("/dashboard/" + salesperson_id.name);
    };
    const columns = [
        {
          field: 'id',
          headerName: 'Id',
          hide: true
        //  renderCell: (params) => 
          /*
          <Link style={{ textDecoration: 'none' }} to={`order/${params.row.order_id}`}>      <Button size="small" variant="contained">
          {params.row.order_id}</Button></Link>
      */
        },
        {
          field: 'salesperson_nm',
          headerName: 'Account Executive',
          flex:0.7,
            minWidth: 70,
            renderCell: (params)=>
                <a
            href={`/dashboard/${params.row.id}`}
          //  target="_blank"
          //  rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
              display: 'inline-block',
              width: '100%'
            }}
          >
            <Button
              sx={{
                justifyContent: 'flex-start',
                textTransform: 'none',
                width: '100%'
              }}
            >
              {params.row.salesperson_nm}
            </Button>
          </a>
          },
      
      
      {
        field: 'active_straight_line_risk_adj',
        headerName: 'Risk',
        flex:0.5,
        minWidth: 100,
          type: 'number',
      
        valueFormatter: ({ value }) => priorityFormater(value),
      },
      
      {
        field: 'active_revenue_amt',
        headerName: 'Active Revenue',
         flex:0.5,
            minWidth: 100,  type: 'number',
      
        valueFormatter: ({ value }) => priorityFormater(value),
      },
      
      ];
    
      const priorityFormater = (cell) => {
        if (cell == null){
          return "$0"
        } else {
        let cell_value = Math.round(Math.abs(cell))?.toLocaleString()

          return "$"+cell_value
        }
        }
    
    ;

return(
    <DataGrid 
        rows={tableData}
        columns={columns}
      //  loading={loading}
      disableColumnMenu={true}
      disableSelectionOnClick
      autoHeight={true}

        pageSize={10}
        rowsPerPageOptions={[10]}
        rowHeight={25}
        initialState={{
            sorting: {
              sortModel: [{ field: 'straight_line_risk', sort: 'asc' }],
            },
          }}
              //  hideFooter={true}
              sx={{
            
                "& .MuiDataGrid-columnSeparator": {
                    display: "none"
                },
                '&, [class^=MuiDataGrid]': { borderTop: 'none' },
              border: 0,
              [`& .${gridClasses.cell}`]: {
                py: 1,
              },
            }}

    />

)



  }


  export default RiskByAESVP