import React, {useEffect, useState} from 'react'
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from "@mui/material/Box";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Link } from "react-router-dom";
import { ExportToCsv } from 'export-to-csv';

import { DataGrid, gridClasses, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import clsx from 'clsx';
import LoadingComponent from "../components/LoadingComponent";
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LaunchIcon from '@mui/icons-material/Launch';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import IconButton from '@mui/material/IconButton';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Menu from '@mui/material/Menu';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ExportDates from './ExportDates';

function getStartOfQuarter() {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentQuarter = Math.floor(currentMonth / 3);
  const startOfQuarter = new Date(currentDate.getFullYear(), currentQuarter * 3, 1);

  return startOfQuarter.toISOString().slice(0, 10);
}

function getCurrentDate() {
  const currentDate = new Date();
  return currentDate.toISOString().slice(0, 10);
}

const formatStartEnd = (date) => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC'
  };
    return date.toLocaleDateString(undefined, options);
};

const columns = [
    {
        field: 'ad_server_url',
        headerName: '',
        flex:1,
        minWidth: 40,
        align: 'center',
        renderCell: (params) => {
        if (params.value === 'N/A') {
          return '';
        } else {
          return ( <Tooltip TransitionComponent={Zoom} title="View in ad server">
          <IconButton href={params.value} target="_blank" sx={{color: '#000000', marginBottom:1}}><LaunchIcon fontSize="small" /></IconButton>
          </Tooltip>)
        }
       
        }
      },
    {
      field: 'sales_order_line_item_id',
      headerName: 'Line ID',
      flex:1,
      minWidth: 100,
      align: 'center',
      renderCell: (params) => 
      <Link style={{ textDecoration: 'none' }} 
      to={`/dashboard/${params.row.salesperson_id}/order/${params.row.order_id}/line/${params.row.sales_order_line_item_id}`} target="_blank" rel="noopener noreferrer"> <Button size="small" variant="contained">{params.row.sales_order_line_item_id}</Button></Link>
    //  <a href={params.row.sales_order_line_item_id}>{params.row.sales_order_line_item_id}</a>,

    },
    {
      field: 'line_item_nm',
      headerName: 'Line Name',
      flex:1,
      minWidth: 400,
    },
    {
    field: 'op_line_start_dt',
    headerName: 'Start Date',
    flex:1,
    minWidth: 100,
    /*
    renderCell: params => {
      const start_dt = new Date(params.row.op_line_start_dt);
      const formattedDate = formatStartEnd(start_dt);
      return <div>{formattedDate}</div>;
      }*/
  },
  {
    field: 'op_line_end_dt',
    headerName: 'End Date',
    flex:1,
    minWidth: 100,
    /*
    renderCell: params => {
      const end_dt = new Date(params.row.op_line_end_dt);
      const formattedDate = formatStartEnd(end_dt);
      return <div>{formattedDate}</div>;
      }*/
    },
  {
    field: 'line_status',
    headerName: 'Status',
    flex:1,
    minWidth: 100,
  },
  {
    field: 'op_impression_goal_contracted',
    headerName: 'Goal',
    flex:1,
    minWidth: 150,
    valueFormatter: ({ value }) => value.toLocaleString(),

  },

  {
    field: 'sold_cpm_amt',
    headerName: 'CPM',
    flex:1,
    minWidth: 100,
    },

  {
    field: 'booked_revenue_amt',
    headerName: 'Booked Revenue',
    flex:1,
    minWidth: 150,
    type: 'number',
    valueFormatter: ({ value }) => priorityFormater(value),

  },


  {
    field: 'rev_at_risk_amt',
    headerName: 'Rev at Risk',
    flex:1,
    minWidth: 150,
    type: 'number',
    cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
  
        return clsx('super-app', {
          red: params.value < 0 ,
          green: params.value > 0,        });
      },
    valueFormatter: ({ value }) => priorityFormater(value),
  },



  {
    field: 'pacing',
    headerName: 'Pacing',
    flex:1,
    minWidth: 150,
    type: 'number',
    valueFormatter: ({ value }) => `${value}%`,
    cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
  
        return clsx('super-app', {
          red: params.value < 90 ,
          yellow: params.value < 98 && params.value > 90,
          green: params.value > 98,
        });
      },
  },

  {
    field: 'rev_pacing',
    headerName: '$ Ahead/Behind',
    flex:1,
    minWidth: 150,
    type: 'number',
    cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
  
        return clsx('super-app', {
          red: params.value < 0 ,
          green: params.value > 0,        });
      },
    valueFormatter: ({ value }) => priorityFormater(value),
  },
  

  {
    field: 'final_billable',
    headerName: 'Final Billable Imps',
    flex:1,
    minWidth: 150,
    valueFormatter: ({ value }) =>  Math.round(value)?.toLocaleString(),

  },

  {
    field: 'first_party_p2_delivery',
    headerName: '1P P2+ Delivery',
    width: 150,
  },



  {
    field: 'billable_p2_delivery',
    headerName: '3P P2+ Delivery',
    flex:1,
    minWidth: 150,
  },



  {
    field: 'on_target_net',
    headerName: 'Ontarget Net Delivered Impressions',
    flex:1,
    minWidth: 150,
  },



  

  {
    field: 'discrepancy_3p',
    headerName: 'Discrepancy',
    flex:1,
    minWidth: 150,
    type: 'number',
    valueFormatter: ({ value }) => `${value}%`,
    cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
  
        return clsx('super-app', {
          red: params.value < 0 
        });
      },
  },

 {
    field: 'value_of_discrepancy',
    headerName: 'Value of Discrepancy',
    flex:1,
    minWidth: 150,
   
  },

  {
    field: 'needed_buffer',
    headerName: 'Needed Buffer',
    flex:1,
    minWidth: 150,
  },

  {
    field: 'fw_scheduled_quantity',
    headerName: 'Current Buffer',
    flex:1,
    minWidth: 150,
  },

  {
    field: 'rbp_comp_revised',
    headerName: 'RBP Comp',
    flex:1,
    minWidth: 150,
  },
  {
    field: 'to_goal',
    headerName: 'Total Delivery %',
    flex:1,
    minWidth: 150,
  },
  {
    field: 'spend_to_date',
    headerName: 'Dollars Spent (uncapped)',
    flex:1,
    minWidth: 180,
  },




  ];

  const priorityFormater = (cell) => {
    let cell_value = Math.round(cell)?.toLocaleString()
    if (cell_value.includes('-')){
      cell_value = cell_value.replace('-', '');
      cell_value = "-"+"$"+cell_value
      return cell_value
    } else {
      return "$"+cell_value?.toLocaleString()
    }
  

};



const TopItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    paddingTop: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const BottomItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    paddingBottom: theme.spacing(1),
    textAlign: 'center',
    fontWeight: 'bold',

    color: theme.palette.text.secondary,
  }));

  
  const PackageItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    paddingTop: theme.spacing(1),
    textAlign: 'left',
    whiteSpace: 'nowrap',
    color: theme.palette.text.secondary,
  }));

var groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

Object.defineProperty(Array.prototype, 'sponsorshipGroup', {
    enumerable: true,
    value: function (key) {
      let map = {};
      this.map(e => ({k: key(e), d: e})).forEach(e => {
        map[e.k] = map[e.k] || [];
        map[e.k].push(e.d);
      });
      return Object.keys(map).map(k => ({package_nm: k, line: map[k]}));
    }
  });


  function CustomToolbar(props) {

    let package_risk = Math.round(props.package_risk)?.toLocaleString()

    if (package_risk.includes('-')){
      package_risk = package_risk.replace('-', '');
      package_risk = "-"+"$"+package_risk
  } else {
    package_risk = "$"+package_risk

  }




    return (
      <GridToolbarContainer>

<Grid container rowSpacing={0} columnSpacing={{ xs: 1}} >
<Grid item marginTop={1} xs={6}>
<Typography variant="h5" gutterBottom>
<Link fontSize="small" style={{
        alignItems: 'center',
        backgroundColor: '#ffffff',
        marginLeft:'10px',
        color: '#000787',
        fontWeight:500
      }} to={`/dashboard/${props.salesperson_id}/order/${props.orderId}`} target="_blank" rel="noopener noreferrer">
        {props.orderId + ' - ' + props.advertiser_nm}
        </Link>





</Typography>

</Grid>
<Grid item xs={6} marginTop={1} textAlign={'right'} >

<GridToolbarExport
        csvOptions={{ fileName: props.orderId + '-' + props.name}}
        printOptions={{ disableToolbarButton: true}}/>

</Grid>
<Grid item marginTop={1} xs={6}>
<Typography variant="h6" gutterBottom>
&ensp;{props.name}</Typography>

</Grid>
</Grid>

<div style={{ display: 'flex', justifyContent: 'space-evenly', paddingLeft: '10px' }}>
  <div style={{ flex: 1 }}>
    <PackageItem elevation={0} style={{ paddingRight: '30px' }}>
      <span style={{ whiteSpace: 'nowrap' }}>Booked Revenue:</span> <b>{props.booked_revenue}</b>
    </PackageItem>
  </div>
  <div style={{ flex: 1 }}>
    <PackageItem elevation={0} style={{ paddingRight: '30px' }}>
      <span style={{ whiteSpace: 'nowrap' }}>Impression Goal:</span> <b>{props.impression_goal}</b>
    </PackageItem>
  </div>
  <div style={{ flex: 1 }}>
    <PackageItem elevation={0} style={{ paddingRight: '30px' }}>
      <span style={{ whiteSpace: 'nowrap' }}>Billable Imps Delivered:</span> <b>{props.billable_imps_delivered}</b>
    </PackageItem>
  </div>
  <div style={{ flex: 1 }}>
  <PackageItem elevation={0} style={{ paddingRight: '30px' }}>
  <span style={{ whiteSpace: 'nowrap' }}>
    {props.page_type !== "political" ? "Sponsorship Pacing:" : "Package Pacing:"}
  </span>
  <b>{props.io_package_pacing}%</b>
</PackageItem>
  </div>
  <div style={{ flex: 1 }}>
    <Tooltip TransitionComponent={Zoom} title="The amount of total risk excluding any over pacing">
      <PackageItem elevation={0} style={{ paddingRight: '30px' }}>
        <span style={{ whiteSpace: 'nowrap' }}>Line Risk:</span> <b>{props.line_risk}</b>
      </PackageItem>
    </Tooltip>
  </div>
  {props.page_type !== "political" && (

  <div style={{ flex: 1 }}>
    <Tooltip TransitionComponent={Zoom} title="The amount of package risk when factoring in both under and over pacing">
      <PackageItem elevation={0} style={{ paddingRight: '30px' }}>
        <span style={{ whiteSpace: 'nowrap' }}>Sponsorship Risk:</span> <b>{package_risk}</b>
      </PackageItem>
    </Tooltip>
  </div>
  )}
</div>

      </GridToolbarContainer>
    );
  }
  



function SponsorshipGrid({token, sponsorships }) {

    const [orderData, setOrderData] = useState([{}])
    const [rawData, setRawData] = useState([{}])
    //const [topLevelOrderData, setTopLevelOrderData] = useState({})

    const [sortModel, setSortModel] = useState([
      {
        field: 'rev_at_risk_amt',
        sort: 'asc',
      },
    ]);
    
   // const {salespersonId} = useParams();

    const [quickFilter, setQuickFilter] = useState();

    const [loading, setLoading] = useState(true)

    const [currentPage, setCurrentPage] = useState(1); // current page number
    const [itemsPerPage, setItemsPerPage] = useState(10); // number of items per page
    const [sortOption, setSortOption] = useState('Risk'); // sort option selected from menu
    const quarterStart = getStartOfQuarter();
    const currentDate = getCurrentDate();
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
  

    const [filterModel, setFilterModel] = useState({items: []});

    const handleSortChange = (
      newSortModel
    ) => {
      //localStorage.setItem('yieldSort', JSON.stringify(newSortModel));

      setSortModel(newSortModel);
    };


    const handleToggle = (
      event
    ) => {
    //  setAlignment(event.target.value);

            setQuickFilter(event.target.value);
            switch(event.target.value){
              case "delivering":
                localStorage.setItem('quickFilter', JSON.stringify(event.target.value));

                setFilterModel({
                  items: [
                    {
                      columnField: 'line_status',
                      operatorValue: 'contains',
                      value: 'Delivering',
                    },
                  ],
                })
                                break;
              
              case "completed":
                localStorage.setItem('quickFilter', JSON.stringify(event.target.value));

                setFilterModel({
                  items: [
                    {
                      columnField: 'line_status',
                      operatorValue: 'contains',
                      value: 'Completed',
                    },
                  ],
                })              
                  break;
                case "future":
                  setFilterModel({
                    items: [
                      {
                        columnField: 'line_status',
                        operatorValue: 'contains',
                        value: 'Future Start',
                      },
                    ],
                  })              
                    break;
                    case "late":
                      localStorage.setItem('quickFilter', JSON.stringify(event.target.value));

                  setFilterModel({
                    
                    items: [
                      {
                        columnField: 'line_status',
                        operatorValue: 'contains',
                        value: 'Not Live',
                      },
                    ],
                  })              
                    break;

                    case "paused":

                  setFilterModel({
                    
                    items: [
                      {
                        columnField: 'line_status',
                        operatorValue: 'contains',
                        value: 'Paused',
                      },
                    ],
                  })              
                    break;
                  case "all":
                    localStorage.removeItem('quickFilter')
                    setFilterModel({items: []})
                               
                      break;

                      default:
                        localStorage.removeItem('quickFilter')

                        setFilterModel({items: []})          
                          break;            
            }

     // setFilterModel(v)
    };

//export menu handler
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleQTRExport = () => {
      setAnchorEl(null);
      const options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: sponsorships + "-" + currentDate
      };
    
    const csvExporter = new ExportToCsv(options);
    
    csvExporter.generateCsv(rawData);
    };
  

    const [openDatePopUp, setOpenDatePopUp] = React.useState(false);

    const handleClickDateOpen = () => {
      handleClose();
      setOpenDatePopUp(true);
    };
    const handleDateClose = () => {
      setOpenDatePopUp(false);
    };




    useEffect(() => {
      var options = {
        method: 'GET',
        headers: {"Content-Type": "application/json"}
  
      }
      options.headers["Authorization"] = "Bearer " + token;
        fetch(`/api/sponsorships?sponsorships=${sponsorships}&quarterStart=${quarterStart}`, options)
          .then((response) => response.json())
          .then((json) => 

{
  setRawData(json.lines)
          return json.lines.group(item => item.package_nm)


}).then((groupedByPackage) => {
            
         //   let advertiser_nm = groupedByPackage[0].line[0].advertiser_nm
          //  let campaign_nm = groupedByPackage[0].line[0].campaign_nm
           // let total_revenue_amt = 0
           // let total_revenue_at_risk = 0
            for (let index = 0; index < groupedByPackage.length; index++) {

              let package_booked = 0
              let package_risk = 0
              let package_pacing = 0
              let package_goal = 0
              let billable_imps_delivered = 0
              let io_package_pacing = 0

              let order_id = groupedByPackage[index].line[0].order_id
              let advertiser_nm = groupedByPackage[index].line[0].advertiser_nm
              let campaign_nm = groupedByPackage[index].line[0].campaign_nm
              let salesperson_id = groupedByPackage[index].line[0].salesperson_id

              //  console.log(groupedByPackage[index].line[0])
                for (let i = 0; i < groupedByPackage[index].line.length; i++) {
             //       total_revenue_amt += groupedByPackage[index].line[i].booked_revenue_amt
             //       total_revenue_at_risk += groupedByPackage[index].line[i].rev_at_risk_amt

                    package_booked += groupedByPackage[index].line[i].booked_revenue_amt
                    package_risk += groupedByPackage[index].line[i].rev_at_risk_amt
                    package_pacing += groupedByPackage[index].line[i].rev_pacing

                    package_goal +=groupedByPackage[index].line[i].op_impression_goal_contracted
                    billable_imps_delivered +=groupedByPackage[index].line[i].final_billable
                    if(groupedByPackage[index].line[i].io_package_pacing > io_package_pacing){
                      io_package_pacing = groupedByPackage[index].line[i].io_package_pacing

                    }

                }
                groupedByPackage[index]['package_booked'] = '$'+ Math.round(package_booked)?.toLocaleString()
                groupedByPackage[index]['package_goal'] = package_goal?.toLocaleString()
                groupedByPackage[index]['billable_imps_delivered'] = Math.round(billable_imps_delivered)?.toLocaleString()

                groupedByPackage[index]['order_id'] = order_id
                groupedByPackage[index]['advertiser_nm'] = advertiser_nm
                groupedByPackage[index]['campaign_nm'] = campaign_nm
                groupedByPackage[index]['salesperson_id'] = salesperson_id



                package_risk = Math.round(package_risk)?.toLocaleString()

                if (package_risk.includes('-')){
                  package_risk = package_risk.replace('-', '');
                  package_risk = "-"+"$"+package_risk
              } else {
                package_risk = "$"+package_risk
        
              }


                groupedByPackage[index]['package_risk'] = package_risk
                groupedByPackage[index]['package_pacing'] = package_pacing
                groupedByPackage[index]['io_package_pacing'] = io_package_pacing


            }

            setOrderData(sortData(sortOption, groupedByPackage)); // sort data by 'Risk' on initial render
        setLoading(false)
        })
         // .catch(() => onError())
  }, []);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const itemsToDisplay = orderData.slice(startIndex, endIndex);
  const totalPages = Math.ceil(orderData.length / itemsPerPage);

 // Sort orderData array based on selected option
 const sortData = (option, data) => {
    switch (option) {
      case 'OD':
        return data.sort((a, b) => b.package_pacing - a.package_pacing);
      case 'Risk':
        return data.sort((a, b) => a.package_pacing - b.package_pacing);
      default:
        return data;
    }
  };

  // Handle change event for Select component
  const handleChange = (event) => {
    const selectedOption = event.target.value;
    setSortOption(selectedOption);
    setOrderData(sortData(selectedOption, orderData));
    setCurrentPage(1); // Reset current page to 1 when sorting changes
  };


    // Scroll to top of page on pagination button click
    const scrollToTop = () => {
        window.scrollTo(0, 0);
      };

  useEffect(() => {

    var quickFilter = localStorage.getItem('quickFilter')

    if (quickFilter !== null){

      quickFilter = JSON.parse(localStorage.getItem('quickFilter'))
      setQuickFilter(quickFilter)
      setFilterModel({
        items: [
          {
            columnField: 'line_status',
            operatorValue: 'contains',
            value: quickFilter,
          },
        ],
      })
    } else {
      setQuickFilter('all')

    }


/*
    var yieldSort = localStorage.getItem('yieldSort')

    if (yieldSort !== null){

        yieldSort = JSON.parse(localStorage.getItem('yieldSort'))
      setSortModel(yieldSort)
    }
*/


  }, []);


  if (loading) {
    return (             
    <LoadingComponent />
    )
  } else {





return (

    
    <div sx={{ backgroundColor: '#ffffff' }}>
<Grid container rowSpacing={0} columnSpacing={{ xs: 0}} alignItems="center" sx={{borderBottom: '1px solid #DBDCDC',
    backgroundColor: '#ffffff'}}>
          <Grid item xs={0.15}>
    </Grid> 
    <Grid item xs={11}>
<Typography variant="body2" marginTop={0} display="flex">

<Link fontSize="small" style={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft:'20px',
        backgroundColor: '#ffffff',
        color: '#000787',
        fontWeight:500
      }} to={`/yield`}>Yield</Link> 
                <Link fontSize="small" style={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft:'0px',
        backgroundColor: '#ffffff',
        color: '#000787',
        fontWeight:500
      }} to={``}><NavigateNextIcon fontSize="small" />{sponsorships.charAt(0).toUpperCase() + sponsorships.slice(1)}</Link>            
              

</Typography>
</Grid>   
<Grid item xs={0.7}>
<ExportDates currentView={rawData} currentPage={sponsorships}></ExportDates>
    </Grid>           
<Grid item xs={0.15}>
    </Grid> 
        </Grid>         


       <br></br>




<Grid container rowSpacing={0} columnSpacing={{ xs: 0}} >



 

    <Grid item xs={0.25}>
    </Grid>
    <Grid item xs={3}>
    </Grid>
    <Grid item xs={5.5}>  
     
<Box display="flex" justifyContent="center">
<ToggleButtonGroup  
     color="primary"
     value={quickFilter}
     exclusive
     onChange={handleToggle
     // setQuickFilter(v);
     // setFilterModel(v)
    }
     
     aria-label="Platform"

   >
     <ToggleButton value="all">All</ToggleButton>
     <ToggleButton value="delivering">Delivering</ToggleButton>
     <ToggleButton value="paused">Paused</ToggleButton>

     <ToggleButton value="late">Not Live</ToggleButton>
     <ToggleButton value="completed">Completed</ToggleButton>
     <ToggleButton value="future">Future Start</ToggleButton>

   </ToggleButtonGroup>

</Box>
</Grid>
<Grid item xs={3}>  

<Box display="flex" justifyContent="right" sx={{padding: '0px'}}>

<FormControl>
  <InputLabel id="demo-simple-select-label">Sort</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={sortOption}
    label="Sort"
    onChange={handleChange}

>
    <MenuItem value={'Risk'}>Most Risk to Most Over Delivery</MenuItem>
    <MenuItem value={'OD'}>Most Over Delivery to Most Risk</MenuItem>
  </Select>
</FormControl>


   
       

   </Box>
   </Grid>


<Grid item xs={0.25}>
    </Grid>


    <Grid item xs={0.25}>
    </Grid>
    <Grid item xs={3}>
    </Grid>
    <Grid item xs={5.5}>
 
</Grid>
    <Grid item xs={3}>
    
    <Box display="flex" justifyContent="flex-end" sx={{paddingTop: '5px'}}>
    <Stack direction="row" spacing={1} alignItems="center">


<Button size='small' variant="outlined" 
      disabled={currentPage === 1}
      onClick={() => {
        setCurrentPage(currentPage - 1);
        scrollToTop();
      }}        >
                <ArrowBackIosNewRoundedIcon fontSize="small" /> 

    </Button>
    <Typography variant="body2" gutterBottom>

    {currentPage} of {totalPages}
    </Typography>
    <Button size='small' variant="outlined" 
    disabled={endIndex >= orderData.length}
onClick={() => {
        setCurrentPage(currentPage + 1);
        scrollToTop();
      }}        >
      <ArrowForwardIosRoundedIcon fontSize="small" /> 
    </Button>
</Stack>
</Box>
</Grid>
    <Grid item xs={0.25}>
    </Grid>

</Grid>

<Grid container rowSpacing={0} columnSpacing={{ xs: 0}} >
<Grid item xs={0.25}>
    </Grid>

<Grid item xs={11.5}>

{itemsToDisplay.map((item, index) => (
                    <div key={index}>
                        <p />
        <Box key={index} sx={{ boxShadow: 1, border: '1px solid #DBDCDC', 
        backgroundColor: '#ffffff' }}> 



          {item.line?.length > 0 &&
          
        <DataGrid autoHeight
        rows={item.line}
        columns={columns}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => {handleSortChange(newSortModel)}}
        components={{
          Toolbar: CustomToolbar,
        }}
        componentsProps={{ toolbar: { orderId: item.order_id, name: item.package_nm, 
            campaign_nm: item.campaign_nm, advertiser_nm: item.advertiser_nm,
        booked_revenue: item.package_booked, impression_goal: item.package_goal, line_risk: item.package_risk, 
        package_risk: item.package_pacing,io_package_pacing: item.io_package_pacing, salesperson_id: item.salesperson_id, billable_imps_delivered: item.billable_imps_delivered,
      page_type: sponsorships
      } }}

        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => {setQuickFilter('all')
          setFilterModel(newFilterModel)}}
          getRowHeight={() => 'auto'}
      getRowId={(row) => row.sales_order_line_item_id}
      disableSelectionOnClick
       // pageSize={1}
        hideFooter={true}
        
      //  rowsPerPageOptions={[10]}
     //   getRowHeight={() => 'auto'}
        sx={{
          
            border: 0,
          //  padding: 1,
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
            '& .super-app-theme--cell': {
                backgroundColor: 'rgba(224, 183, 60, 0.55)',
                color: '#1a3e72',
                fontWeight: '600',
              },
            '& .super-app.red': {
                backgroundColor: '#d47483',

      
              },
            
            '& .super-app.yellow': {
                backgroundColor: '#edff64',

      
              },
              '& .super-app.green': {
                backgroundColor: 'rgba(100, 255, 118, 0.49)',
              },}}            


    />

          }

         
          </Box>
       </div> 
      ))}
      </Grid>
      <Grid item xs={0.25}>
    </Grid>
    </Grid>
    <div><br></br>
    <Grid container rowSpacing={0} columnSpacing={{ xs: 0}} >
<Grid item xs={0.25}>
    </Grid>
    <Grid item xs={11.5}>
    <Box display="flex" justifyContent="flex-end">
        <Stack direction="row" spacing={1} alignItems="center">

    <Button variant="outlined" 
          disabled={currentPage === 1}
          onClick={() => {
            setCurrentPage(currentPage - 1);
            scrollToTop();
          }}        >
                    <ArrowBackIosNewRoundedIcon fontSize="small" /> 

        </Button>
        <Typography variant="body2" gutterBottom>

        {currentPage} of {totalPages}
        </Typography>
        <Button variant="outlined" 
        disabled={endIndex >= orderData.length}
 onClick={() => {
            setCurrentPage(currentPage + 1);
            scrollToTop();
          }}        >
          <ArrowForwardIosRoundedIcon fontSize="small" /> 
        </Button>
</Stack>
</Box>
</Grid>
<Grid item xs={0.25}>
    </Grid>
</Grid>
<br></br>
</div>
      </div>
)
}
}
                     //   <JSONPretty id="json-pretty" data={orderData}></JSONPretty>



export default SponsorshipGrid