import React from 'react'
import Grid from "@mui/material/Grid";
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate, createSearchParams } from "react-router-dom";
import Button from '@mui/material/Button';



function AdvertiserRiskSVP({data}){

    const navigate = useNavigate();

const handleAdvertiserClick = (name) =>
navigate({
  pathname: '/advertiser',
  search: `?${createSearchParams(name)}`,
});


const columns = [

    {
      field: 'advertiser_nm',
      headerName: 'Advertiser',
      flex:.7,
      minWidth: 70,
      renderCell: (params)=>
      <Button size='small' onClick={() => handleAdvertiserClick({name: params.row.advertiser_nm})}
      sx={{
        justifyContent: 'flex-start', // Aligns text to the right
        textTransform: 'none'
      }}>
                {params.row.advertiser_nm}</Button>
    },

  
  
    {
        field: 'active_straight_line_risk_adj',
        headerName: 'Risk',
        flex:0.5,
        minWidth: 100,
        type: 'number',
       
        valueFormatter: ({ value }) => priorityFormater(value),
      },
  {
    field: 'active_revenue_amt',
    headerName: 'Active Revenue',
    flex:0.5,
    minWidth: 100,
    type: 'number',
   
    valueFormatter: ({ value }) => priorityFormater(value),
  },

  ]

  const priorityFormater = (cell) => {
    if (cell == null){
      return "$0"
    } else {
    let cell_value = Math.round(Math.abs(cell))?.toLocaleString()

      return "$"+cell_value
    }
    }



    if (!data || data.length == 0) {

        return (          
          <Grid container rowSpacing={0} columnSpacing={{ xs: 0}} 
          sx={{display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
          <Grid item xs={12} textAlign='left'>
          <Typography variant="h6" marginLeft={2} marginTop={1}>
          Risk By Advertiser</Typography>
          </Grid>
          <p style={{marginLeft:'15px'}}>No risk found</p>

          </Grid>
        )
      } else {

        if (data.length > 0){

return(

    <DataGrid 
        rows={data}
        columns={columns}
        
      //  loading={loading}
      hideFooterPagination={false}
      autoHeight={true}

        pageSize={10}
        rowsPerPageOptions={[10]}
        rowHeight={25}    
          disableColumnMenu={true}
      disableSelectionOnClick
      getRowId={(row) => row.advertiser_nm}
      initialState={{
        sorting: {
          sortModel: [{ field: 'active_straight_line_risk_adj', sort: 'asc' }],
        },
      }}
     // pagination
     // getRowHeight={() => 'auto'}

                
        sx={{
            
            "& .MuiDataGrid-columnSeparator": {
                display: "none"
            },
            '&, [class^=MuiDataGrid]': { borderTop: 'none' },
          border: 0,
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        }}

    />
     
)
    }
}

}

export default AdvertiserRiskSVP