import { useParams } from "react-router-dom";
import React, {useEffect, useState} from 'react'
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from "@mui/material/Box";
import CommentTable from "../components/CommentTable" 
import { Link } from "react-router-dom";
import LineGraph2 from "../components/LineGraph2" 
import BundledLines from "../components/BundledGrid" 
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

import LaunchIcon from '@mui/icons-material/Launch';
import InfoIcon from '@mui/icons-material/Info';
import LoadingComponent from "../components/LoadingComponent";
import { red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import IconButton from '@mui/material/IconButton';
import DeliveryMenu from "../components/DeliveryMenu";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useOktaAuth } from '@okta/okta-react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { getAnalytics, logEvent } from "firebase/analytics";
import UpdateCheck from "../components/UpdateCheck";



const TopItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  paddingTop: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const BottomItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  paddingBottom: theme.spacing(1),
  textAlign: 'center',
  fontWeight: 'bold',

  color: theme.palette.text.secondary,
}));

  const TopLineDetail = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.p,
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const BottomLineDetail = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.p,
    padding: theme.spacing(0),
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));



  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export const LinePage = () => {

  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

    const {lineId} = useParams();
    const {orderId} = useParams();
    const {salespersonId} = useParams();
    const document_id = orderId.concat("_", salespersonId, "_", lineId).toLowerCase().replace("/","")

   // const document_id = orderId + salespersonId + lineId
    const [loading1, setLoading1] = useState(true)
    const [loading2, setLoading2] = useState(true)
    const [loading3, setLoading3] = useState(true)

    const [token, setToken] = useState('')
    const [statusColor, setStatusColor] = useState('primary')
    const [dailyData, setDailyData] = useState([]);

    
/*
    var displayName = ''
    if ( user.displayName.indexOf(',') > -1) { 
      displayName= user.displayName.split(", ")[1] + " " + user.displayName.split(", ")[0] 
    } else {
      displayName = user.displayName
    }
*/

    
    const [backendData, setBackendData] = useState([{}])

    const handleViewInAdserver = () => {
      //setOpen(true);
      console.log('test')
    };




    useEffect(() => {
      if (!authState || !authState.isAuthenticated) {
        // When user isn't authenticated, forget any user info
        setUserInfo(null);
      } else {
     //   oktaAuth.getUser().then((info) => {
          setUserInfo(authState.idToken.claims);
          setLoading3(false)
        
      //        })
            }
    })

/*
    useEffect(() => {
      if (!authState || !authState.isAuthenticated) {
      }else{
              var options = {
                method: 'GET',
                headers: {"Content-Type": "application/json"}
          
              }
              setToken(authState.accessToken.accessToken)

              options.headers["Authorization"] = "Bearer " + authState.accessToken.accessToken;
        
                fetch("/api/"+orderId+"/"+lineId, options).then(
                  response => response.json()
                ).then(
                  data => {
                  
                    setBackendData(data)
                    setLoading1(false)

                    if (data.line.line_status === 'Completed'){
                      setStatusColor('success')
                    } else if (data.line.line_status === 'Not Live') {
                      setStatusColor('error')

                    }else if (data.line.line_status === 'Paused') {
                      setStatusColor('warning')

                    } else {
                      setStatusColor('primary')
                    }

                  }
                )
            

              
                }

          }, [lineId, token, authState, oktaAuth]);

*/


          useEffect(() => {
            const fetchData = async () => {
              if (!authState || !authState.isAuthenticated) {
                // Not authenticated
              } else {
                try {
                  let accessToken = authState.accessToken;
          
                  // Check if the token is expired and refresh it if necessary
                  if (oktaAuth.tokenManager.hasExpired(accessToken)) {
                    console.log('expired')
                    accessToken = await oktaAuth.tokenManager.renew('accessToken');
                  }
                  // Set the token either way
                  setToken(accessToken.accessToken);
          
                  const options = {
                    method: 'GET',
                    headers: {
                      "Content-Type": "application/json",
                      "Authorization": "Bearer " + accessToken.accessToken
                    }
                  };
          
                  const response = await fetch("/api/" + orderId + "/" + lineId, options);
                  const data = await response.json();
          
                  setBackendData(data);
                  setLoading1(false);
                  if (data.line.line_status === 'Completed'){
                    setStatusColor('success')
                  } else if (data.line.line_status === 'Not Live') {
                    setStatusColor('error')

                  }else if (data.line.line_status === 'Paused') {
                    setStatusColor('warning')

                  } else {
                    setStatusColor('primary')
                  }
                } catch (error) {
                  // Handle errors such as network issues, parsing issues, etc.
                  console.error('Error fetching data: ', error);
                }
              }
            };
          
            fetchData();
          }, [lineId, token, authState, oktaAuth]);







          useEffect(() => {
            if(token){

            var options = {
              method: 'GET',
              headers: {"Content-Type": "application/json"}
        
            }
            options.headers["Authorization"] = "Bearer " + token;
  
              fetch("/daily/"+document_id, options)
                  .then(
                    (response) => response.json())
                  .then((json) => {setDailyData(json.data);
                    setLoading2(false)
                  })
                //  .catch(() => onError())
                const analytics = getAnalytics();
                logEvent(analytics, 'page_view', { email: authState.idToken.claims.email, page_title:'Line'});
                }
          }, [lineId, token, authState, oktaAuth]);
      





      if (loading1 || loading2 || loading3) {
        return (           
        <LoadingComponent />
        )
      } else {


        const renderFinalBillable = () => {
          if (backendData.line.demo_gtd === true && backendData.line.line_status === "Delivering" && backendData.line.final_billable === 0) {
            return (    <Tooltip TransitionComponent={Zoom} title="This line has not been running long enough to generate Nielsen demo comp for billable impressions.">

            <BottomLineDetail elevation={0}>{backendData.line.final_billable} <InfoIcon fontSize="small" sx={{ color: red[500] }}/>
            </BottomLineDetail>
            </Tooltip>
            )

          } else {
            return <BottomLineDetail elevation={0}>{backendData.line.final_billable}</BottomLineDetail>

          }
        }

        return (

          <Box sx={{ backgroundColor: '#fafafa' }}>


                  <Grid container rowSpacing={0} columnSpacing={{ xs: 0}} alignItems="center" sx={{
    backgroundColor: '#ffffff'}}>

                  <Grid item xs={0.15}>
    </Grid> 
    <Grid item xs={11.85}>

                  <Typography variant="body2" marginTop={0} display="flex">

                  <Link fontSize="small" style={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft:'20px',
        backgroundColor: '#ffffff',
        color: '#000787',
        fontWeight:500
      }} to={`/dashboard/${salespersonId}`}>Home</Link> 
                          
                  <Link fontSize="small" style={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft:'0px',
        backgroundColor: '#ffffff',
        color: '#000787',
        fontWeight:500
      }} to={`/dashboard/${salespersonId}/order/${orderId}`}><NavigateNextIcon fontSize="small" />{orderId}-{backendData.line.campaign_nm}</Link> 

<Link fontSize="small" style={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft:'0px',
        backgroundColor: '#ffffff',
        color: '#000787',
        fontWeight:500
      }} to={``}><NavigateNextIcon fontSize="small" />{lineId}</Link> 
                          
              </Typography>            
                    </Grid>  

      </Grid>         
    
        
        <Grid container rowSpacing={0} columnSpacing={{ xs: 1}} alignItems="center" sx={{
    backgroundColor: '#ffffff'}} >
  <Grid item xs={10}>
    
  <Typography variant="h6" marginTop={0} marginLeft={5} gutterBottom>
  {lineId}-{backendData.line.line_item_nm} <Chip color={statusColor} label={backendData.line.line_status} />&nbsp;&nbsp;
  {backendData.line.ad_server_url === 'N/A' ? '':   
    <Tooltip TransitionComponent={Zoom} title="View in ad server">
  
  <IconButton edge="start" href={backendData.line.ad_server_url} target="_blank" sx={{color: '#000000', marginBottom:1}}><LaunchIcon fontSize="small" /></IconButton>
  </Tooltip>

}
   
  </Typography>
    </Grid>
    <Grid item xs={2}>
    <Typography variant="subtitle2" marginTop={0} marginRight={5} textAlign={"right"} align={"center"}>
    {/*  <CheckCircleIcon color="success"></CheckCircleIcon><br></br> */}
    {backendData.line.advertiser_nm}<br></br>
    {backendData.line.flight}
    </Typography>
      </Grid>
      </Grid>
      <Grid container rowSpacing={0} columnSpacing={{ xs: 1}} alignItems="center" sx={{borderBottom: '1px solid #DBDCDC',
    backgroundColor: '#ffffff'}} >
      <Grid item xs={2}>
    <Typography display="inline" variant="body2" marginTop={0} marginLeft={5} textAlign={"right"}>
    Booked Revenue:
    </Typography>
    <Typography display="inline" variant="subtitle2" marginTop={0} marginLeft={1} textAlign={"right"}>
    ${backendData.line.booked_revenue_amt.toLocaleString()}
    </Typography>
    
      </Grid>
      <Grid item xs={2}>
    <Typography display="inline" variant="body2" marginTop={0} marginLeft={5} textAlign={"right"}>
    Impression Goal:
    </Typography>
    <Typography display="inline" variant="subtitle2" marginTop={0} marginLeft={1} textAlign={"right"}>
    {backendData.line.op_impression_goal_contracted}
    </Typography>
    
      </Grid>
      <Grid item xs={2}>
    <Typography display="inline" variant="body2" marginTop={0} marginLeft={5} textAlign={"right"}>
    CPM:
    </Typography>
    <Typography display="inline" variant="subtitle2" marginTop={0} marginLeft={1} textAlign={"right"}>
    {backendData.line.sold_cpm_amt}
    </Typography>
    
      </Grid>




    <Grid item xs={4}></Grid>


      <Grid item xs={2}>    <Typography variant="subtitle2" marginTop={0} marginRight={5} textAlign={"right"} align={"center"}>
{backendData.line.billable_third_party_desc}</Typography></Grid>
</Grid>

<p />


<Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >

<Grid item xs={.25}></Grid>
<Grid item xs={.05} sx={{borderTop: '1px solid #DBDCDC',borderLeft: '1px solid #DBDCDC', borderBottom: '1px solid #DBDCDC',
        backgroundColor: '#000ac2', boxShadow: 1}}></Grid>

<Grid item xs={3.7166} sx={{borderTop: '1px solid #DBDCDC',borderBottom: '1px solid #DBDCDC',borderRight: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>
<Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >
<Grid item xs={11.25}>

<Typography variant="h6" marginTop={0} marginLeft={1} textAlign='left'>

Pacing
</Typography>
</Grid>
<Grid item xs={0.75}> <Tooltip TransitionComponent={Zoom} title={<>
  <Typography variant="body2">{backendData.line.product_category_desc === 'Live Sports' ? 
'• This is an in-game live stream line so pacing may fluctuate as there are not live events daily and the viewership for each event may differ.' : 
''}</Typography>
<Typography variant="body2">• Straight line pacing at ${backendData.line.pacing}%</Typography>
<Typography variant="body2">• {backendData.line.rev_at_risk_num > 0 ? 
'Straight line revenue is ahead ' + backendData.line.rev_at_risk_amt : 
'Straight line revenue at risk of ' + backendData.line.rev_at_risk_amt}</Typography>
<Typography variant="body2">{backendData.line.yield_risk_amt === '$0'
? '• Yield is projecting this line will deliver in full': 
backendData.line.yield_risk_amt === 'N/A' ? '': '• Yield is projecting risk of ' + backendData.line.yield_risk_amt}</Typography>
<Typography variant="body2">{backendData.line.pacing_last_7 >= 100
? '• In the last 7 days this line has been pacing to DIF': 
backendData.line.pacing_last_7 === 'N/A' ? '': 
'• If the average daily delivery over the last 7 days stays the same this line will deliver ' + backendData.line.pacing_last_7+'% of the total goal'}</Typography>
<Typography variant="body2">{backendData.line.dark_day_ind ? '• '+ backendData.line.dark_days+' dark days': ''}</Typography></>}>
  <InfoIcon color="primary"/></Tooltip></Grid>

<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center' sx={{color: backendData.line.pacing >= 100
? 'green': backendData.line.pacing >= 95 ? 'black': 'red'}}>

{backendData.line.pacing}%
</Typography>
</Grid>
<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center' sx={{color: backendData.line.rev_at_risk_num >= 0
? 'green': 'red'}}>
{backendData.line.rev_at_risk_amt}</Typography></Grid>
<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center' >
{backendData.line.to_goal}</Typography></Grid>

<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={1} textAlign='center' gutterBottom>

pacing vs {backendData.line.pacing_lw} LW
</Typography>

</Grid>
<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={1} textAlign='center' gutterBottom>
{backendData.line.rev_at_risk_num > 0 ? 'ahead': 'risk'} vs {backendData.line.rev_at_risk_amt_lw} LW
</Typography>

</Grid>
<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={1} textAlign='center' gutterBottom>

of total goal delivered
</Typography>

</Grid>
<br></br><br></br>

<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center' sx={{color: backendData.line.ffdr_pct >= 100 
? 'green': backendData.line.ffdr_pct === 'N/A' ? 'black': 'red'}}>

{backendData.line.ffdr_pct}%
</Typography>
</Grid>
<Grid item xs={4}>
  
<Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center' sx={{color: backendData.line.yield_risk_amt === '$0'
? 'green': backendData.line.yield_risk_amt === 'N/A' ? 'black': 'red'}}>
{backendData.line.yield_risk_amt}</Typography></Grid>
<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center' >

{backendData.line.pacing_last_7 === 'N/A' ? 'N/A': backendData.line.pacing_last_7 + '%'}</Typography></Grid>

<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={1} textAlign='center' gutterBottom>

FFDR vs {backendData.line.ffdr_pct_lw} LW
</Typography>

</Grid>
<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={1} textAlign='center' gutterBottom>

Yield Projected Risk
</Typography>

</Grid>
<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={1} textAlign='center' gutterBottom>

pacing last 7 days
</Typography>

</Grid>


</Grid>


</Grid>
<Grid item xs={.1}></Grid>
<Grid item xs={.05}  sx={{borderTop: '1px solid #DBDCDC',borderLeft: '1px solid #DBDCDC', borderBottom: '1px solid #DBDCDC',
        backgroundColor: '#000ac2', boxShadow: 1}}></Grid>

<Grid item xs={3.7166} sx={{borderTop: '1px solid #DBDCDC',borderBottom: '1px solid #DBDCDC',borderRight: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>
<Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >
<Grid item xs={11.25}>

<Typography variant="h6" marginTop={0} marginLeft={1} textAlign='left'>

Delivery
</Typography>
</Grid>


<Grid item xs={0.75}>
 
 <DeliveryMenu dailyData={dailyData} sales_order_line_item_id={lineId} token={token} document_id={document_id}
 booked_revenue_amt={backendData.line.booked_revenue_amt} flight_length={backendData.line.flight_length}
 billable_days_completed={backendData.line.billable_days_completed} final_billable={backendData.line.final_billable}
 op_impression_goal_contracted={backendData.line.op_impression_goal_contracted} 
 adjusted_impressions_ind={backendData.line.adjusted_impressions_ind} adjusted_impressions={backendData.line.adjusted_impressions}
 dark_day_ind={backendData.line.dark_day_ind} dark_days={backendData.line.dark_days} line_status={backendData.line.line_status} risk={backendData.line.rev_at_risk_num}
 sales_order_id={backendData.line.order_id} package_nm={backendData.line.package_nm} flight={backendData.line.flight}></DeliveryMenu>
{/*<DailyDeliveryTable dailyData={dailyData} />
*/}
</Grid>
<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center' >

  {backendData.line.final_billable}
</Typography>
</Grid>
<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center' >
{backendData.line.spend}</Typography></Grid>
<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center' >
{backendData.line.left_to_deliver}</Typography></Grid>



<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={1} textAlign='center' gutterBottom>

Billable Impressions
</Typography>

</Grid>
<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={1} textAlign='center' gutterBottom>

Spend to Date
</Typography>

</Grid>
<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={1} textAlign='center' gutterBottom>

Imps Left to Deliver
</Typography>

</Grid>

<br></br><br></br>

<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center' >

  {backendData.line.first_party_p2_delivery.toLocaleString()}
</Typography>
</Grid>
<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center' >
{backendData.line.billable_p2_delivery}</Typography></Grid>
<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center' >
{backendData.line.rbp_comp_revised}</Typography></Grid>



<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={1} textAlign='center' gutterBottom>

1p Impressions
</Typography>

</Grid>
<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={1} textAlign='center' gutterBottom>

3p Impressions
</Typography>

</Grid>
<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={1} textAlign='center' gutterBottom>

RBP Comp
</Typography>

</Grid>

</Grid>


</Grid>
<Grid item xs={.1}></Grid>
<Grid item xs={.05}  sx={{borderTop: '1px solid #DBDCDC',borderLeft: '1px solid #DBDCDC', borderBottom: '1px solid #DBDCDC',
        backgroundColor: '#000ac2', boxShadow: 1}}></Grid>

<Grid item xs={3.7166} sx={{borderTop: '1px solid #DBDCDC',borderBottom: '1px solid #DBDCDC',borderRight: '1px solid #DBDCDC',
        backgroundColor: '#ffffff',boxShadow: 1}}>

<Grid container rowSpacing={0} columnSpacing={{ xs: 0}}  >
<Grid item xs={11.25}>

<Typography variant="h6" marginTop={0} marginLeft={1} textAlign='left'>

Discrepancy & Gross Impression Cap
</Typography>
</Grid>
<Grid item xs={0.75}> <Tooltip TransitionComponent={Zoom} title={<>
<Typography variant="body2">{backendData.line.discrepancy_3p > 0 ? '• This line has a discrepancy in our favor so no buffer is needed': 
backendData.line.discrepancy_3p === 0 ? '• This line has no discrepancy so no buffer is needed': 
backendData.line.discrepancy_3p < 0 && backendData.line.first_party_p2_delivery >= 1000 ?
'• This line has a discrepancy of '+ backendData.line.discrepancy_3p + '% and needs a buffer of ' + backendData.line.needed_buffer +'%':
'This line is showing a discrepancy of ' + backendData.line.discrepancy_3p +'% but has delivered a very limited number of impressions so this could resolve itself with more delivery' }</Typography>
<Typography variant="body2">{backendData.line.needed_buffer > 0 && backendData.line.needed_buffer <= 1
? '• This line has a discrepancy of '+ backendData.line.discrepancy_3p + '% and needs a buffer of ' + backendData.line.needed_buffer: 
backendData.line.discrepancy_3p === 0 ? '• This line has no discrepancy so no buffer is needed': 
''}</Typography>
<Typography variant="body2">{backendData.line.needed_buffer >= 5 && backendData.line.first_party_p2_delivery >= 1000
? '• Please have trafficking look into the large discrepancy': ''}</Typography>

<Typography variant="body2">{backendData.line.rbp_ind === 1 && backendData.line.fw_scheduled_quantity >= backendData.line.needed_gic
? '• The gross impression cap is correctly set' : 
backendData.line.rbp_ind === 1 && backendData.line.fw_scheduled_quantity < backendData.line.needed_gic ?
'• The gross impression cap is too low which will cause this line to stop delivering before reaching the on-target goal': ''}</Typography>

</>}>
  <InfoIcon color="primary"/></Tooltip></Grid>

<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center' sx={{color: backendData.line.discrepancy_3p <
  0 ? 'red': backendData.line.discrepancy_3p === 0 ? 'black': 'green'}}>

  {backendData.line.discrepancy_3p}%
</Typography>
</Grid>
<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center' >
{backendData.line.needed_buffer}%</Typography></Grid>

<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center' sx={{color: backendData.line.needed_buffer >=
  backendData.line.fw_over_delivery_value ? 'red': 'green'}}>
  {backendData.line.fw_over_delivery_value}%</Typography></Grid>

<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={1} textAlign='center'>

Discrepancy
</Typography>

</Grid>
<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={1} textAlign='center'>

Needed Buffer
</Typography>

</Grid>

<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={1} textAlign='center'>

Current Buffer
</Typography>

</Grid>

<br></br><br></br>
<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center' >

  {backendData.line.value_of_discrepancy} 
</Typography>
</Grid>
<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center' sx={{color: 
  backendData.line.fw_scheduled_quantity === 'N/A' || backendData.line.needed_gic === 'N/A' ? 'black':backendData.line.fw_scheduled_quantity >
  backendData.line.needed_gic ? 'green': 'red'}}>
{isNaN(backendData.line.fw_scheduled_quantity) ? 'N/A': Intl.NumberFormat('en-US', {
            notation: "compact",
            maximumFractionDigits: 1
          }).format(backendData.line.fw_scheduled_quantity)}</Typography></Grid>

<Grid item xs={4}>
  <Typography variant="h5" marginTop={0} marginLeft={0} textAlign='center' sx={{color: 
  backendData.line.fw_scheduled_quantity === 'N/A' || backendData.line.needed_gic === 'N/A' ?  'black':backendData.line.fw_scheduled_quantity >
  backendData.line.needed_gic ? 'green': 'red'}}>
{isNaN(backendData.line.needed_gic) ? 'N/A': Intl.NumberFormat('en-US', {
            notation: "compact",
            maximumFractionDigits: 1
          }).format(backendData.line.needed_gic)}</Typography></Grid>

<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={1} textAlign='center' gutterBottom>

Value of Discrepancy
</Typography>

</Grid>
<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={1} textAlign='center' gutterBottom>

GIC
</Typography>

</Grid>

<Grid item xs={4}>
<Typography variant="body2" marginTop={0} marginLeft={1} textAlign='center' gutterBottom>

Needed GIC
</Typography>

</Grid>

</Grid>


</Grid>



<Grid item xs={.25}></Grid>

</Grid>
<br></br>


<Grid container rowSpacing={0} columnSpacing={{ xs: 1}} >

<Grid item xs={.25}></Grid>

  <Grid item xs={5.75}>
    <Box sx={{ height: '500px', boxShadow: 1, border: '1px solid #DBDCDC', 
        backgroundColor: '#ffffff' }}> 

         <LineGraph2 dailyData={dailyData} bookedRev={backendData.line.booked_revenue_amt}/>
  </Box>
  </Grid>
  {/*
  <Grid item xs={4}>
    <Box sx={{ height: '500px',boxShadow: 1, border: '1px solid #DBDCDC', 
        backgroundColor: '#ffffff', padding: '10px' }}>

          <Grid container rowSpacing={0} columnSpacing={{ xs: 1}} >
          <Grid item xs={4}>
    <TopLineDetail elevation={0}>Booked Impressions</TopLineDetail>
  </Grid>
  <Grid item xs={4}>
    <TopLineDetail elevation={0}>CPM</TopLineDetail>
  </Grid>
  <Grid item xs={4}>
    <TopLineDetail elevation={0}>Line Value</TopLineDetail>
  </Grid>

  <Grid item xs={4}>
    <BottomLineDetail elevation={0}>{backendData.line.op_impression_goal_contracted}</BottomLineDetail>
  </Grid>
  <Grid item xs={4}>
    <BottomLineDetail elevation={0}>{backendData.line.sold_cpm_amt}</BottomLineDetail>
  </Grid>
  <Grid item xs={4}>
    <BottomLineDetail elevation={0}>${backendData.line.booked_revenue_amt.toLocaleString()}</BottomLineDetail>
  </Grid>
            </Grid>

<br />            <br />


            <Grid container rowSpacing={0} columnSpacing={{ xs: 1}} >
          <Grid item xs={4}>
            
    <TopLineDetail elevation={0}>Revenue at Risk</TopLineDetail>
  </Grid>
  <Grid item xs={4}>
    <TopLineDetail elevation={0}>Pacing</TopLineDetail>
  </Grid>
  <Grid item xs={4}>
    <TopLineDetail elevation={0}>Billable Impressions</TopLineDetail>
  </Grid>

  <Grid item xs={4}>
  <Tooltip TransitionComponent={Zoom} title="This line has improved compared to the x at risk 7 days ago">

    <BottomLineDetail elevation={0}>{backendData.line.rev_at_risk_amt}
    </BottomLineDetail>
    </Tooltip>
  </Grid>
  <Grid item xs={4}>
    <BottomLineDetail elevation={0}>{backendData.line.pacing}</BottomLineDetail>
  </Grid>
  <Grid item xs={4}>
   {renderFinalBillable()}

  </Grid>
            </Grid>

            <br />            <br />

            <Grid container rowSpacing={0} columnSpacing={{ xs: 1}} >
          <Grid item xs={4}>
            
    <TopLineDetail elevation={0}>1P P2+ Imps</TopLineDetail>
  </Grid>
  <Grid item xs={4}>
    <TopLineDetail elevation={0}>3P P2+ Imps</TopLineDetail>
  </Grid>
  <Grid item xs={4}>
    <TopLineDetail elevation={0}>On Target Net</TopLineDetail>
  </Grid>

  <Grid item xs={4}>

    <BottomLineDetail elevation={0}>{backendData.line.first_party_p2_delivery}
    </BottomLineDetail>
  </Grid>
  <Grid item xs={4}>
    <BottomLineDetail elevation={0}>{backendData.line.billable_p2_delivery}</BottomLineDetail>
  </Grid>
  <Grid item xs={4}>
    <BottomLineDetail elevation={0}>{backendData.line.on_target_net}</BottomLineDetail>
  </Grid>
            </Grid>

            <br />            <br />


            <Grid container rowSpacing={0} columnSpacing={{ xs: 1}} >
          <Grid item xs={4}>
    <TopLineDetail elevation={0}>Discrepancy</TopLineDetail>
  </Grid>
  <Grid item xs={4}>
    <TopLineDetail elevation={0}>Needed Buffer</TopLineDetail>
  </Grid>
  <Grid item xs={4}>
    <TopLineDetail elevation={0}>Current Buffer</TopLineDetail>
  </Grid>

  <Grid item xs={4}>
    <BottomLineDetail elevation={0}>{backendData.line.discrepancy_3p}</BottomLineDetail>
  </Grid>
  <Grid item xs={4}>
    <BottomLineDetail elevation={0}>{backendData.line.needed_buffer}</BottomLineDetail>
  </Grid>
  <Grid item xs={4}>
    <BottomLineDetail elevation={0}>{backendData.line.fw_over_delivery_value}</BottomLineDetail>
  </Grid>
            </Grid>


            <br />            <br />


            <AvgComponent lineId={lineId} advertiser_nm={backendData.line.advertiser_nm} orderId={orderId} 
total_billable={backendData.line.final_billable} needed_avg={backendData.line.forward_needed_daily_avg} token={token} />
        
        </Box>
  </Grid>
    */}
  <Grid item xs={5.75}>
    <Box sx={{ height: 500, width: '100%', boxShadow: 1, border: '1px solid #DBDCDC', backgroundColor: '#ffffff' }}> 

<CommentTable sports_ind={backendData.line.sports_ind} displayName={userInfo.name} orderId={orderId} lineId={backendData.line.line_id} salespersonId={salespersonId} 
advertiser_nm={backendData.line.advertiser_nm} campaign_nm={backendData.line.campaign_nm} line_item_nm={backendData.line.line_item_nm} flight={backendData.line.flight}
token={token}/>

     </Box>
  </Grid>

  <Grid item xs={.25}></Grid>


</Grid>

<p />

<Grid container rowSpacing={0} columnSpacing={{ xs: 1}}  >
<Grid item xs={.25}></Grid>

<Grid item xs={11.5}>

    <BundledLines orderId={backendData.line.order_id} package_nm={backendData.line.package_nm} lineId={backendData.line.line_id} token={token}  />
    </Grid>
    <Grid item xs={.25}></Grid>

</Grid>


<br></br>
<Snackbar open={backendData.line.adjusted_impressions_ind || backendData.line.bill_in_full_override_ind || backendData.line.processing}>
        <Alert severity="warning" sx={{ width: '100%' }}>
        {backendData.line.adjusted_impressions_ind ? (
  backendData.line.adjusted_impressions < 0 ? 
    `${Math.abs(backendData.line.adjusted_impressions).toLocaleString()} impressions manually added to Billable Impressions` :
    `${backendData.line.adjusted_impressions.toLocaleString()} impressions manually removed from Billable Impressions`
) : null}

{backendData.line.bill_in_full_override_ind ? (
      `This line under delivered but is being billed in full`
    ) : null}
    {backendData.line.processing ? (
      `Reprocessing delivery override data...`
    ) : null}
            </Alert>
      </Snackbar>
      <UpdateCheck />
            </Box>
          );
      }
    /*
           {(typeof backendData.users === 'undefined') ? (
          <p>Loading...</p>
         ): (
          backendData.users.map((user, i) => (
            <p key={i}>{user}</p>
          ))
         )}
*/

    };
