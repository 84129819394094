import React, { useState, useEffect } from 'react'
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ErrorIcon from '@mui/icons-material/Error';import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useNavigate } from "react-router-dom";
import { useOktaAuth } from '@okta/okta-react';


const theme = createTheme();





export const NotAssigned = () => {
    
    const { authState, oktaAuth } = useOktaAuth();





    const logout = async () => oktaAuth.signOut({postLogoutRedirectUri: 'https://revatrisk.com/usercheck'});

    useEffect(() => {
      if (!authState || !authState.isAuthenticated) {
        // When user isn't authenticated, forget any user info
      } else {
        logout()

      }
    }, [authState, oktaAuth]); // Update if authState changes
  



  return (
    <>
   
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <ErrorIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            User not found
          </Typography>
          <Typography variant="body2" align="center">
            This user has not been given permission to use this application.  Please contact the admin for access.
          </Typography>




  


          </Box>
      </Container>
    </ThemeProvider>
    </>
    );
};
