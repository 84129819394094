import React, { useState} from 'react'
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import { Link } from "react-router-dom";


import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';


import clsx from 'clsx';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import LaunchIcon from '@mui/icons-material/Launch';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Avatar from '@mui/material/Avatar';
import Grid from "@mui/material/Grid";



function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}



const columns = [

  {
    field: 'ad_server_url',
    headerName: '',
    flex:1,
    minWidth: 40,
    align: 'center',
    renderCell: (params) => {
    if (params.value === 'N/A') {
      return '';
    } else {
      return ( <Tooltip TransitionComponent={Zoom} title="View in ad server">
      <IconButton href={params.value} target="_blank" sx={{color: '#000000', marginBottom:1}}><LaunchIcon fontSize="small" /></IconButton>
      </Tooltip>)
    }
   
    }
  },

  {
    field: 'order_id',
    headerName: 'Order ID',
    flex:1,
    minWidth: 80,
    align: 'center',

    renderCell: (params) => 
    <Link style={{ textDecoration: 'none' }} 
    to={`/dashboard/${params.row.salesperson_id}/order/${params.row.order_id}`}> <Button size="small" variant="contained">{params.row.order_id}</Button></Link>
    
  },
  {
    field: 'campaign_nm',
    headerName: 'Order',
    flex:1,
    minWidth: 250,
  },

  {
    field: 'advertiser_nm',
    headerName: 'Advertiser',
    flex:1,
    minWidth: 150,
  },


  {
    field: 'sales_order_line_item_id',
    headerName: 'Line ID',
    flex:1,
    minWidth: 100,
    align:'left',
    renderCell: (params) => 
    <Link style={{ textDecoration: 'none' }} 
    to={`/dashboard/${params.row.salesperson_id}/order/${params.row.order_id}/line/${params.row.sales_order_line_item_id}`}> <Button size="small" variant="contained">{params.row.sales_order_line_item_id}</Button></Link>
    
  },
  {
    field: 'line_item_nm',
    headerName: 'Line',
    flex:1,
    minWidth: 320,
  },
  {
  field: 'op_line_start_dt',
  headerName: 'Start Date',
  flex:1,
  minWidth: 100,
},
{
  field: 'op_line_end_dt',
  headerName: 'End Date',
  flex:1,
  minWidth: 100,
},
{
  field: 'line_status',
  headerName: 'Status',
  flex:1,
  minWidth: 100,
},
{
  field: 'rev_at_risk_amt',
  headerName: 'Risk',
  flex:1,
  minWidth: 100,
    type: 'number',
  cellClassName: (params) => {
      if (params.value == null) {
        return '';
      }

      return clsx('super-app', {
        red: params.value < 0 ,
        green: params.value > 0,        });
    },
  valueFormatter: ({ value }) => priorityFormater(value),
},



{
  field: 'pacing',
  headerName: 'Pacing',
  flex:1,
  minWidth: 100,
    type: 'number',
  valueFormatter: ({ value }) => `${value}%`,
  cellClassName: (params) => {
      if (params.value == null) {
        return '';
      }

      return clsx('super-app', {
        red: params.value < 90 ,
        yellow: params.value < 98 && params.value > 90,
        green: params.value > 98,
      });
    },
},
/*
{
  field: 'comment',
  headerName: 'Last Comment',
  flex:1,
  minWidth: 400,
  renderCell: (params) => {
  if (params.value.length > 0){
    return (
      <Grid container rowSpacing={0} columnSpacing={{ xs:0}} padding={0} 
      sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Grid item xs={1.5} textAlign='left'>
      <Avatar {...stringAvatar(params.row.commenter)} />
      </Grid>
      <Grid item xs={10.5} textAlign='left'>
      <Typography variant="body2" marginTop={0} marginLeft={0}>{params.row.commenter}
</Typography>
<Typography variant="body2" marginTop={0} marginLeft={0} ><b>{params.row.comment}</b>
</Typography>
<Typography variant="caption" marginTop={0} marginLeft={0} >{params.row.commentDate}
</Typography>


  </Grid>
     
     
     
      </Grid>
    )
} else {
  return 'No comment found'
}
}
},
*/




];

const priorityFormater = (cell) => {
  let cell_value = Math.round(cell)?.toLocaleString()
  if (cell_value.includes('-')){
    cell_value = cell_value.replace('-', '');
    cell_value = "-"+"$"+cell_value
    return cell_value
  } else {
    return "$"+cell_value?.toLocaleString()
  }


};



const TopItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  paddingTop: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const BottomItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  paddingBottom: theme.spacing(1),
  textAlign: 'center',
  fontWeight: 'bold',

  color: theme.palette.text.secondary,
}));


const PackageItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  paddingTop: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

var groupBy = function(xs, key) {
return xs.reduce(function(rv, x) {
  (rv[x[key]] = rv[x[key]] || []).push(x);
  return rv;
}, {});
};






function RiskGrid({riskData, onError }) {


  //const [riskData, setRiskData] = useState([{}])

    const [sortModel, setSortModel] = useState([
      {
        field: 'rev_at_risk_amt',
        sort: 'asc',
      },
    ]);   
    const [quickFilter, setQuickFilter] = useState();

     const handleSortChange = (
      newSortModel
    ) => {

      setSortModel(newSortModel);
    };


if(riskData.length > 0){


return (

    
    <div sx={{ backgroundColor: '#ffffff' }}>
                  <DataGrid autoHeight
        rows={riskData}
        columns={columns}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => {handleSortChange(newSortModel)}}
          getRowHeight={() => 'auto'}

      getRowId={(row) => row.sales_order_line_item_id}
      disableSelectionOnClick={true}
      pageSize={25}
            rowsPerPageOptions={[25]}
//        hideFooter={true}
      //  rowsPerPageOptions={[10]}
     //   getRowHeight={() => 'auto'}
        sx={{
          "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
            display: "none"
          },
            border: 0,
          //  padding: 1,
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
            '& .super-app-theme--cell': {
                backgroundColor: 'rgba(224, 183, 60, 0.55)',
                color: '#1a3e72',
                fontWeight: '600',
              },
            '& .super-app.red': {
                backgroundColor: '#d47483',

      
              },
            
            '& .super-app.yellow': {
                backgroundColor: '#edff64',

      
              },
              '& .super-app.green': {
                backgroundColor: 'rgba(100, 255, 118, 0.49)',
              },
            
            
            
            
            }}            


    />

          

         
      </div>
)
            } 
}



export default RiskGrid