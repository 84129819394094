import Box from "@mui/material/Box";


import CircularProgress from '@mui/material/CircularProgress';



function LoadingComponent() {
    return (    
        <Box sx={{ backgroundColor: '#ffffff' }}>
                <Box
                  sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                                <CircularProgress />
                                </Box>
            
                        </Box>   
                                )


}

export default LoadingComponent